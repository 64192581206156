import React from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { Icon } from 'shared';
import { getCssVar, icons } from 'styles/theme';
import { TableTitles } from './constants';

export type PermissionType = {
  option?: string;
  level?: string;
  title?: string;
  disabled?: boolean;
  fullAccessDisabled?: boolean;
};

export const getTableColumns = (): ColumnsType<PermissionType> => [
  {
    key: 'function',
    title: TableTitles.Function,
    dataIndex: 'title',
  },
  {
    key: 'noAccess',
    title: TableTitles.NoAccess,
    dataIndex: 'level',
    render: (level: string) =>
      level !== TableTitles.NoAccess ? (
        ''
      ) : (
        <Icon component={icons.done} color={getCssVar('successColor')} />
      ),
    align: 'center',
  },
  {
    key: 'viewOnly',
    title: TableTitles.ViewOnly,
    dataIndex: 'level',
    render: (level: string) =>
      level !== TableTitles.ViewOnly ? (
        ''
      ) : (
        <Icon component={icons.done} color={getCssVar('successColor')} />
      ),
    align: 'center',
  },
  {
    key: 'fullAccess',
    title: TableTitles.FullAccess,
    dataIndex: 'level',
    render: (level: string) =>
      level !== TableTitles.FullAccess ? (
        ''
      ) : (
        <Icon component={icons.done} color={getCssVar('successColor')} />
      ),
    align: 'center',
  },
];
