import { Theme } from 'styles/theme/types';
import styled, { css } from 'styled-components';
import { StatusLevel } from 'services/constants';
import { NotificationStatus, SupportRequestsStatus } from 'services/api';
import { getCssVar } from 'styles/theme';
import { Status } from './Status';

type Props = { theme: Theme };

export const StatusComponent = styled(Status)`
  border-radius: 2px;
  color: ${getCssVar('textColor')};
  display: inline;
  font-size: 15px;
  font-weight: ${({ theme }: Props) => theme.fontWeights.normal};
  margin: 8px 0 0 8px;
  padding: 4px 7px;
  vertical-align: middle;
  white-space: nowrap;

  span:before {
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 8px;
    margin-bottom: 1px;
    margin-right: 8px;
    width: 8px;
  }

  ${({ level }) =>
    level === StatusLevel.Critical &&
    css`
      background-color: ${({ theme }: Props) =>
        `${theme.colorSet.severity.critical}${theme.opacity[20]}`};

      span:before {
        background: ${getCssVar('severity.critical')};
      }
    `};

  ${({ level }) =>
    level === StatusLevel.High &&
    css`
      background-color: ${({ theme }: Props) =>
        `${theme.colorSet.severity.high}${theme.opacity[20]}`};

      span:before {
        background: ${getCssVar('severity.high')};
      }
    `};

  ${({ level }) =>
    level === StatusLevel.Medium &&
    css`
      background: ${({ theme }: Props) => `${theme.colorSet.severity.meduim}${theme.opacity[20]}`};

      span:before {
        background: ${getCssVar('severity.meduim')};
      }
    `};
  ${({ level }) =>
    level === StatusLevel.Low &&
    css`
      background: ${({ theme }: Props) => `${theme.colorSet.severity.low}${theme.opacity[20]}`};

      span:before {
        background: ${getCssVar('severity.low')};
      }
    `};
  ${({ level }) =>
    level === StatusLevel.Informational &&
    css`
      background: ${({ theme }: Props) =>
        `${theme.colorSet.severity.informational}${theme.opacity[20]}`};

      span:before {
        background: ${getCssVar('severity.informational')};
      }
    `};
  ${({ level }) =>
    (level === StatusLevel.Open || level === SupportRequestsStatus.WITH_CUSTOMER) &&
    css`
      background: ${({ theme }: Props) =>
        `${theme.colorSet.status.warning.bg}${theme.opacity[20]}`};

      span:before {
        background: ${getCssVar('status.warning.bg')};
      }
    `};
  ${({ level }) =>
    (level === StatusLevel.Resolved || level === NotificationStatus.PUBLISHED) &&
    css`
      background: ${({ theme }: Props) =>
        `${theme.colorSet.status.success.bg}${theme.opacity[20]}`};

      span:before {
        background: ${getCssVar('status.success.bg')};
      }
    `};
  ${({ level }) =>
    (level === StatusLevel.Closed || level === NotificationStatus.DRAFTS) &&
    css`
      background: ${({ theme }: Props) => `${theme.colorSet.status.closed.bg}${theme.opacity[20]}`};

      span:before {
        background: ${getCssVar('status.closed.bg')};
      }
    `};
`;
