import { ActionNames } from 'app-constants';
import {
  Filter,
  HostSchema,
  HostSource,
  OrderBy,
  VulnerabilityDetails,
  VulnerabilityStatus,
} from 'services/api';
import { Severity } from 'services/constants';
import { ActionType, PopupConfigType } from 'shared/ActionPopup/types';
import { OptionType } from 'shared/Table/types';
import { icons } from 'styles/theme';
import { InputType } from 'services/api/models/InputType';
import {
  CascaderOptionsType,
  OnSelectChangeHandler,
} from 'shared/TableFilters/components/TableFilterGroupSelect/TableFilterGroupSelect';

export const CMDB_TABS = {
  active: {
    name: 'Active',
    key: 'active-hosts',
  },
  agedHosts: {
    name: 'Aged hosts',
    key: 'aged-hosts',
  },
};

export enum TableTitles {
  Priority = 'Priority',
  Hostname = 'Host name',
  Tags = 'Tags',
  OS = 'OS',
  Name = 'Name',
  Department = 'Department',
  AssetSource = 'Asset source',
  Vulnerability = 'Vulnerabilities',
  Alarms = 'Alarms',
  LastScan = 'Last scan',
  LastCheckedIn = 'Last checked-in',
  Actions = 'Actions',
}

export enum DeleteDropdownLabels {
  DeleteInternally = 'Soft delete',
  DeleteForever = 'Purge',
}

export enum ManageTagDropdownLabels {
  AddTags = 'Add tags',
  DeleteTags = 'Delete tags',
}

export enum DownloadDropdownLabels {
  Summary = 'Summary',
  Detailed = 'Detailed',
}

export enum ColumnKey {
  Priority = 'criticality',
  HostName = 'hostname',
  Tags = 'tags',
  OS = 'os',
  Name = 'name',
  Department = 'department',
  AssetSource = 'assetSource',
  Vulnerabilities = '_vulnerabilities',
  Alarms = 'alarms',
  LastScan = 'lastScan',
  LastCheckedIn = 'lastCheckedIn',
  Actions = 'actions',
}

export const defaultAssetSourcesOptions: OptionType[] = [
  { value: HostSource.EDR_AGENT_DEFENDER_, label: HostSource.EDR_AGENT_DEFENDER_ },
  { value: HostSource.EDR_AGENT_FORTI_EDR_, label: HostSource.EDR_AGENT_FORTI_EDR_ },
  { value: HostSource.EDR_AGENT_SENTINEL_ONE_, label: HostSource.EDR_AGENT_SENTINEL_ONE_ },
  { value: HostSource.EDR_AGENT_TRELLIX_, label: HostSource.EDR_AGENT_TRELLIX_ },
  { value: HostSource.VULNERABILITY_AGENT, label: 'Vulnerability agent' },
  { value: HostSource.NETWORK_SCAN, label: HostSource.NETWORK_SCAN },
];

export const optionsChangeCriticality: OptionType[] = [
  { value: HostSchema.criticality._2, label: 'High' },
  { value: HostSchema.criticality._1, label: 'Medium' },
  { value: HostSchema.criticality._0, label: 'Low' },
];

export const optionsCriticalityStatus: Record<string, string> = {
  [HostSchema.criticality._0]: 'Low',
  [HostSchema.criticality._1]: 'Medium',
  [HostSchema.criticality._2]: 'High',
};

export const optionsVulnerability: OptionType[] = [
  { value: VulnerabilityDetails.severity._5, label: Severity.Critical },
  { value: VulnerabilityDetails.severity._4, label: Severity.High },
  { value: VulnerabilityDetails.severity._3, label: Severity.Medium },
  { value: VulnerabilityDetails.severity._2, label: Severity.Low },
  { value: VulnerabilityDetails.severity._1, label: Severity.Info },
];

export const getFilterFields = (
  customerAssetSources: OptionType[],
  tagsOptions?: OptionType[],
  excludeNoTagsOption = false,
) => [
  {
    field: 'assetSource',
    options: customerAssetSources || [],
    label: 'Asset source',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'tagsAssets',
    label: 'Tags',
    inputType: InputType.GROUP_SELECTOR,
    filterType: Filter.type.MULTIPLE,
    options: [
      { value: 'any', label: 'Include any of tags', children: tagsOptions },
      { value: 'exclude', label: 'Exclude all tags', children: tagsOptions },
      ...(!excludeNoTagsOption ? [{ value: 'no', label: 'No tags', children: [] }] : []),
    ],
    modifyDefaultValues(filter: null | Filter) {
      if (!filter || !Array.isArray(filter.value)) {
        return [];
      }

      if (filter.value.length === 0) {
        return [['no']];
      }

      return filter.value.map((value) => [filter.negate ? 'exclude' : 'any', value]);
    },
    modifyValuesBeforeOnSelect(onSelect: OnSelectChangeHandler, values: CascaderOptionsType) {
      if (values.length === 0) {
        return onSelect('tagsAssets', '', Filter.type.MULTIPLE);
      }

      const [lastSelectedKey, lastSelectedValue = []] = values[values.length - 1] as [
        string,
        string?,
      ];

      if (lastSelectedValue.length === 0) {
        return onSelect('tagsAssets', [], Filter.type.MULTIPLE);
      }

      return onSelect(
        'tagsAssets',
        values.map(([, value]) => value as string),
        Filter.type.MULTIPLE,
        {
          negate: lastSelectedKey === 'exclude',
        },
      );
    },
  },
  {
    field: 'criticality',
    options: optionsChangeCriticality,
    label: 'Priority',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
    maxTagCount: 3,
  },
  {
    field: 'vulnerabilitySeverity',
    options: optionsVulnerability,
    label: 'Vulnerability severity',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
    maxTagCount: 2,
  },
];

export const searchSettings = {
  fields: ['name', 'hostname', 'department', 'ip', 'os'],
  min: 2,
  tooltip: 'To search for multiple hosts, use a comma as the separator',
  placeholder: 'Search by IP/host/OS/name/department',
};

export const initialOrderBy = {
  field: '_vulnerabilities',
  type: OrderBy.type.DESC,
};

export const popupConfig: {
  purge: PopupConfigType;
  delete: PopupConfigType;
  softDelete: PopupConfigType;
  multyDelete: PopupConfigType;
} = {
  purge: {
    id: 'purge',
    title: DeleteDropdownLabels.DeleteForever,
    text: 'This action cannot be undone',
    onApplyTitle: 'Delete host',
    danger: true,
  },
  delete: {
    id: 'delete',
    title: 'Delete',
    text: 'This action cannot be undone',
    onApplyTitle: 'Delete host',
    danger: true,
  },
  softDelete: {
    id: 'softDelete',
    title: DeleteDropdownLabels.DeleteInternally,
    text: 'This action cannot be undone',
    onApplyTitle: 'Delete host',
    danger: true,
  },
  multyDelete: {
    id: 'multyDelete',
    title: 'Delete selected hosts',
    text: 'This action cannot be undone',
    onApplyTitle: 'Delete hosts',
    danger: true,
  },
};

export const getActions = (isNorm: boolean): ActionType[] => {
  return isNorm
    ? [
        {
          name: DeleteDropdownLabels.DeleteInternally,
          icon: icons.delete,
          popupId: popupConfig.softDelete.id,
          isDanger: false,
        },
        {
          name: DeleteDropdownLabels.DeleteForever,
          icon: icons.delete,
          popupId: popupConfig.purge.id,
          isDanger: true,
        },
      ]
    : [
        {
          name: ActionNames.Delete,
          icon: icons.delete,
          popupId: popupConfig.purge.id,
          isDanger: true,
        },
      ];
};

export const deleteDropdownItems = [
  {
    key: DeleteDropdownLabels.DeleteInternally,
    label: DeleteDropdownLabels.DeleteInternally,
  },
  {
    key: DeleteDropdownLabels.DeleteForever,
    label: DeleteDropdownLabels.DeleteForever,
    isDanger: true,
  },
];

export const manageTagDropDownItems = [
  {
    key: ManageTagDropdownLabels.AddTags,
    label: ManageTagDropdownLabels.AddTags,
  },
  {
    key: ManageTagDropdownLabels.DeleteTags,
    label: ManageTagDropdownLabels.DeleteTags,
    isDanger: true,
  },
];

export const confirmedVulnerabilitiesFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.CONFIRMED],
      inputType: InputType.SELECTOR,
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const potentialVulnerabilitiesFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.CONFIRMED, VulnerabilityStatus.POTENTIAL],
      inputType: InputType.SELECTOR,
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const downloadDropdownItems = [
  {
    key: DownloadDropdownLabels.Summary,
    label: DownloadDropdownLabels.Summary,
    icon: icons.download,
  },
  {
    key: DownloadDropdownLabels.Detailed,
    label: DownloadDropdownLabels.Detailed,
    icon: icons.download,
  },
];

export const MAX_TAG_LENGTH_DESKTOP = 30;
export const MAX_TAG_LENGTH_MOBILE = 16;

export const ELIPSIS_LENGTH = 3;

export const LIST_HEIGHT_FOR_10_ITEMS = 400;

export const allowedUploadOnlyCSV = 'text/csv';

export const activeTabTooltip =
  'List of Assets that have been discovered within your environment, either by an EDR agent, Vulnerability agent or Network scan.List of Assets that have been discovered within your environment, either by an EDR agent, Vulnerability agent or Network scan.';
export const agedHostsTabTooltip =
  'List of Assets that have been discovered within your environment, but that have not recently checked in.';
