import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetPermission } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { getCssVar, icons } from 'styles/theme';
import { TagSchema } from 'services/api';
import { AppDispatch, cmdbApi, ITagsAssetsProps, useApp, useCustomer } from 'store';
import { Box, Icon } from 'shared';
import { TagButton } from './TagButton';
import { TagColumnWrapper, TagsAsset } from '../styled';
import { truncateTextWithTooltip } from '../helpers';
import { MAX_TAGS_ON_HOSTS } from '../../CmdbHostDetails/components/AssetDetails/constants';

interface TagsColumnProps {
  hostId: string;
  isShowAllTags?: boolean;
  tagAssets?: Array<TagSchema>;
  onChanged?: () => void;
}

export const TagsColumn = ({
  hostId,
  tagAssets = [],
  isShowAllTags = false,
  onChanged,
}: TagsColumnProps) => {
  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();
  const [showLimitedTags, setShowLimitedTags] = useState(true);
  const isFullAccess = useGetPermission(PermissionOptions.CMDB) === PermissionsLevel.Full;

  const isLimitTags = tagAssets?.length >= MAX_TAGS_ON_HOSTS;

  const { isMobile } = useApp();

  const removeTagById = useCallback(
    (hostId, tagId) => {
      if (hostId && tagId) {
        dispatch(cmdbApi.removeTag({ customerId, hostId, tagId })).then(() => {
          if (onChanged) {
            onChanged();
          }
        });
      }
    },
    [customerId, dispatch, onChanged],
  );

  const showTagsAssets = useCallback(
    (tagList, showTags) => {
      const limitTags = 3;

      if (isShowAllTags) {
        return tagList;
      }

      if (tagList.length > limitTags && showTags) {
        const otherTagsCount = tagList.length - limitTags;
        const countTag = {
          id: 'countTag',
          name: `+ ${otherTagsCount} tags`,
        };

        return [...tagList.slice(0, limitTags), countTag];
      }

      return tagList;
    },
    [isShowAllTags],
  );

  const mutateTagAssets = showTagsAssets(tagAssets, showLimitedTags);
  const closeIcon = isFullAccess ? (
    <Icon
      component={icons.close}
      dimension={isMobile ? '17px' : '14px'}
      color={getCssVar('tagAssets.iconColor')}
    />
  ) : null;

  return (
    <TagColumnWrapper onClick={(event) => event.stopPropagation()}>
      <Box display='inline-block'>
        {!tagAssets.length && <TagButton rowId={hostId} />}
        {mutateTagAssets.map((item: ITagsAssetsProps) => {
          const isCountTag = item.id === 'countTag';

          return (
            <TagsAsset
              key={item.id}
              closeIcon={closeIcon}
              style={isCountTag ? { cursor: 'pointer' } : {}}
              onClose={() => removeTagById(hostId, item?.id)}
              closable={!isCountTag}
              onClick={() => {
                if (isCountTag) {
                  setShowLimitedTags(!showLimitedTags);
                }
              }}
            >
              {truncateTextWithTooltip(item.name, isMobile)}
            </TagsAsset>
          );
        })}
        {isFullAccess && !!tagAssets.length && !isLimitTags && (
          <TagButton onChanged={onChanged} rowId={hostId} />
        )}
      </Box>
    </TagColumnWrapper>
  );
};
