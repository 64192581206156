import { Tag, UcFirstLetter } from 'shared';
import { TagProps } from 'antd';
import { Severity } from 'services/constants';
import { getCssVar } from 'styles/theme';

type CustomTagProps = TagProps & {
  title: string;
};

export const TagColoring = ({ title, ...TagProps }: CustomTagProps) => {
  let textColor = getCssVar('status.error.color');

  const tagType = (title: string) => {
    switch (title) {
      case Severity.High:
        return getCssVar('severity.high');
      case Severity.Medium:
        return getCssVar('severity.meduim');
      case Severity.Low:
        textColor = getCssVar('status.success.color');

        return getCssVar('severity.low');
      case Severity.Critical:
        textColor = getCssVar('status.critical.color');

        return getCssVar('severity.critical');
      case Severity.Info:
        return getCssVar('severity.informational');
      case Severity.Analysing:
        return getCssVar('severity.analysis');
      default:
        return getCssVar('severity.high');
    }
  };

  return (
    <Tag color={tagType(title)} key={title} {...TagProps} title={title}>
      <div style={{ color: textColor }}>{UcFirstLetter(title)}</div>
    </Tag>
  );
};
