import styled from 'styled-components';
import { Button } from 'shared';
import { PAGE_WIDTH } from 'pages/Dashboard/components/Report/config';

export const StyledButton = styled(Button)`
  left: calc(${PAGE_WIDTH} - 150px);
  position: fixed !important;
  top: 20px;
  width: 130px;
  z-index: 1000;
`;
