import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import { InnerLayout, Card } from 'shared';
import { HistoryState } from 'services/interfaces';
import { getLayoutConfig } from './helpers';
import { Form } from './components/Form';

type IProps = {
  isServiceRequest?: boolean;
};

export const SupportRequestForm = ({ isServiceRequest }: IProps) => {
  const { state: historyState, search, pathname } = useLocation<HistoryState>();
  const query = new URLSearchParams(search);
  const serviceRequestType = query.get('type');
  const certificateName = query.get('certificate');
  const product = query.get('product');

  const layoutConfig = getLayoutConfig(
    historyState,
    isServiceRequest,
    serviceRequestType,
    pathname,
  );

  return (
    <InnerLayout {...layoutConfig}>
      <Row>
        <Col lg={24} xs={24}>
          <Card title={isServiceRequest ? 'Request' : 'Incident'} showDivider={false}>
            <Form
              isServiceRequest={isServiceRequest}
              purchaseProduct={product}
              purchaseCertificate={certificateName}
            />
          </Card>
        </Col>
      </Row>
    </InnerLayout>
  );
};
