import { PermissionOptions } from 'app-constants';

export enum TableTitles {
  Function = 'Function',
  NoAccess = 'No access',
  ViewOnly = 'View only',
  FullAccess = 'Full access',
}

export const columnsNames = [TableTitles.NoAccess, TableTitles.ViewOnly, TableTitles.FullAccess];

export enum PermissionTitle {
  Process = 'Process',
  // CybersafetyAndPhishing = 'Cybersafety and phishing',
  CybersafetyAndPhishing = 'Human risk management',
  VulnerabilityManagement = 'Vulnerability management',
  ThreatDetection = 'Threat detection',
  PenetrationTesting = 'Penetration testing',
  EmailSecurity = 'Email security',
  DataProtection = 'Data protection',
  CMDB = 'CMDB',
  SupportRequests = 'Support requests',
  Documents = 'Documents',
  Software = 'Software',
  AdminUsers = 'Admin users',
  SecuritySettings = 'Security settings',
  AuditLog = 'Audit log',
  Report = 'Report',
  PatchManagement = 'Patch management',
  MaintenanceSettings = 'Maintenance settings',
  Notifications = 'Notifications',
  ServicesIntegrations = 'Integrations',
}

export const functionTitles: { [key: string]: { title: string; order: number } } = {
  [PermissionOptions.Process]: { title: PermissionTitle.Process, order: 0 },
  [PermissionOptions.CybersafetyAndPhishing]: {
    title: PermissionTitle.CybersafetyAndPhishing,
    order: 1,
  },
  [PermissionOptions.VulnerabilityManagement]: {
    title: PermissionTitle.VulnerabilityManagement,
    order: 2,
  },
  [PermissionOptions.ThreatDetection]: { title: PermissionTitle.ThreatDetection, order: 3 },
  [PermissionOptions.PatchManagement]: { title: PermissionTitle.PatchManagement, order: 4 },
  [PermissionOptions.PenetrationTesting]: { title: PermissionTitle.PenetrationTesting, order: 5 },
  [PermissionOptions.EmailSecurity]: { title: PermissionTitle.EmailSecurity, order: 6 },
  [PermissionOptions.DataProtection]: { title: PermissionTitle.DataProtection, order: 7 },
  [PermissionOptions.CMDB]: { title: PermissionTitle.CMDB, order: 8 },
  [PermissionOptions.SupportRequests]: { title: PermissionTitle.SupportRequests, order: 9 },
  [PermissionOptions.Documents]: { title: PermissionTitle.Documents, order: 10 },
  [PermissionOptions.ManageSoftware]: { title: PermissionTitle.Software, order: 11 },
  [PermissionOptions.AdminUsers]: { title: PermissionTitle.AdminUsers, order: 12 },
  [PermissionOptions.SecuritySettings]: { title: PermissionTitle.SecuritySettings, order: 13 },
  [PermissionOptions.AuditLogs]: { title: PermissionTitle.AuditLog, order: 14 },
  [PermissionOptions.Report]: { title: PermissionTitle.Report, order: 15 },
  [PermissionOptions.MaintenanceSettings]: {
    title: PermissionTitle.MaintenanceSettings,
    order: 16,
  },
  [PermissionOptions.Notifications]: { title: PermissionTitle.Notifications, order: 17 },
  [PermissionOptions.ServicesIntegrations]: {
    title: PermissionTitle.ServicesIntegrations,
    order: 18,
  },
};
