import { useCallback } from 'react';
import { AppDispatch, useAssetDetails, useCustomer } from 'store';
import { Col, Row, Space } from 'antd';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { icons, getCssVar } from 'styles/theme';
import { Box, Icon } from 'shared';
import { Descriptions, LimitTagsMessage } from './styled';
import {
  DescriptionsTitle,
  EndpointProtectionTitle,
  IdentificationTitle,
  MAX_TAGS_ON_HOSTS,
  VulnerabilityAgentActivityTitle,
} from './constants';
import { renderDate, renderValue } from '../../helpers';
import { TagsColumn } from '../../../Cmdb/components/TagsColumn';

export const AssetDetails = () => {
  const customerId = useCustomer();
  const { assetDetails } = useAssetDetails();

  const { id } = useParams<{ id: string }>();

  const tagsAssets = assetDetails?.tagsAssets || [];

  const isLimitTags = tagsAssets.length >= MAX_TAGS_ON_HOSTS;

  const dispatch: AppDispatch = useDispatch();

  const onChangeHandler = useCallback(() => {
    dispatch(
      assetDetailsApi.getAssetDetails({
        customerId,
        hostId: id,
      }),
    );
  }, [customerId, dispatch, id]);

  return (
    <Row gutter={32}>
      <Col span={32} xs={24} xl={12}>
        <Descriptions title={DescriptionsTitle.Tags}>
          <Descriptions.Item label=''>
            <Box>
              {isLimitTags && (
                <LimitTagsMessage>
                  <Icon
                    component={icons.infoWarning}
                    color={getCssVar('tagAssets.limitMessage.iconColor')}
                  />
                  <Box pl='10px'>
                    You’ve reached the limit of 30 tags. To add a new tag, please remove some other.
                  </Box>
                </LimitTagsMessage>
              )}
              <TagsColumn
                hostId={id}
                tagAssets={tagsAssets}
                onChanged={onChangeHandler}
                isShowAllTags
              />
            </Box>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title={DescriptionsTitle.Identification} column={1}>
          <Descriptions.Item label={IdentificationTitle.IP}>
            {renderValue(assetDetails.ip)}
          </Descriptions.Item>

          <Descriptions.Item label={IdentificationTitle.Timezone}>
            {renderValue(assetDetails.timezone)}
          </Descriptions.Item>

          <Descriptions.Item label={IdentificationTitle.Manufacturer}>
            {renderValue(assetDetails.manufacturer)}
          </Descriptions.Item>

          <Descriptions.Item label={IdentificationTitle.Model}>
            {renderValue(assetDetails.model)}
          </Descriptions.Item>

          <Descriptions.Item label={IdentificationTitle.CPU}>
            {renderValue(assetDetails.cpu)}
          </Descriptions.Item>

          <Descriptions.Item label={IdentificationTitle.BIOS}>
            {renderValue(assetDetails.bios)}
          </Descriptions.Item>
        </Descriptions>
      </Col>

      <Col span={32} xs={24} xl={12}>
        <Space direction='vertical' size={0}>
          {assetDetails.vulnerabilityAgentActivity && (
            <Descriptions title={DescriptionsTitle.VulnerabilityAgentActivity} column={1}>
              <Descriptions.Item label={VulnerabilityAgentActivityTitle.Created}>
                {renderDate(assetDetails.vulnerabilityAgentActivity?.created)}
              </Descriptions.Item>

              <Descriptions.Item label={VulnerabilityAgentActivityTitle.LastModified}>
                {renderDate(assetDetails.vulnerabilityAgentActivity?.lastModified)}
              </Descriptions.Item>

              <Descriptions.Item label={VulnerabilityAgentActivityTitle.LastSystemBoot}>
                {renderDate(assetDetails.vulnerabilityAgentActivity?.lastSystemBoot)}
              </Descriptions.Item>

              <Descriptions.Item label={VulnerabilityAgentActivityTitle.LastCheckedIn}>
                {renderDate(assetDetails.vulnerabilityAgentActivity?.lastCheckedIn)}
              </Descriptions.Item>

              <Descriptions.Item label={VulnerabilityAgentActivityTitle.LastVulnerabilityScan}>
                {renderDate(assetDetails.vulnerabilityAgentActivity?.lastVulnerabilityScan)}
              </Descriptions.Item>
            </Descriptions>
          )}

          {assetDetails.endpointProtectionAgentActivity && (
            <Descriptions
              title={DescriptionsTitle.EndpointProtectionAgentActivity}
              column={1}
              isLast
            >
              <Descriptions.Item label={EndpointProtectionTitle.Created}>
                {renderDate(assetDetails.endpointProtectionAgentActivity?.created)}
              </Descriptions.Item>
              <Descriptions.Item label={EndpointProtectionTitle.LastCheckedIn}>
                {renderDate(assetDetails.endpointProtectionAgentActivity?.lastCheckedIn)}
              </Descriptions.Item>
              {assetDetails.endpointProtectionAgentActivity?.state ? (
                <Descriptions.Item label={EndpointProtectionTitle.State}>
                  {renderValue(assetDetails.endpointProtectionAgentActivity?.state)}
                </Descriptions.Item>
              ) : (
                ''
              )}
            </Descriptions>
          )}
        </Space>
      </Col>
    </Row>
  );
};
