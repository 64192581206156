import moment from 'moment';
import { dateFormat } from 'app-constants';
import { Box } from 'shared';
import { Tooltip } from 'antd';
import { CertificateStatusSchema } from 'services/api';
import { useTheme } from 'styled-components';
import { getCssVar } from 'styles/theme';
import { ABOUT_TO_EXPIRE_MONTHS } from '../../constants';
import { CertificateBodyProp } from '../../types';
import { ExpiryLabelBody, VendorName } from '../../styled';
import { Tag } from './Tag';

export const Body = ({ expirationDate, status, vendorName }: CertificateBodyProp) => {
  const theme = useTheme();

  const expiration = expirationDate ? moment(expirationDate).startOf('day') : null;
  const currentDate = moment().startOf('day');

  const isExpired =
    expiration?.isBefore(currentDate) || expiration?.format('LL') === moment().format('LL');

  const isCertified =
    status === CertificateStatusSchema.CERTIFIED || status === CertificateStatusSchema.DELIVERED;

  const isShownContent = isExpired || isCertified;

  const expiresInMonths = expiration?.diff(currentDate, 'month');
  const expiresInDays = expiration?.diff(currentDate, 'days');

  const isCertificateSoonExpire =
    (expiresInMonths && expiresInMonths < ABOUT_TO_EXPIRE_MONTHS) || expiresInMonths === 0;

  return (
    <Box
      visibility={isShownContent ? 'visible' : 'hidden'}
      m={`${theme.spacing[6]} ${theme.spacing[0]} ${theme.spacing[3]} ${theme.spacing[0]}`}
      minH='42px'
      d='flex'
      justify='space-between'
    >
      {vendorName && (
        <VendorName>
          <Tooltip title={vendorName}>{vendorName}</Tooltip>
        </VendorName>
      )}

      <ExpiryLabelBody hasDateButNotExpired={Boolean(expirationDate && !isExpired)}>
        <Box d='flex' justify={vendorName ? 'flex-end' : 'flex-start'}>
          <Box
            fontSize={theme.sizes[4]}
            color={isExpired ? getCssVar('errorSecondaryColor') : getCssVar('textColor')}
          >
            {expirationDate && (
              <Box
                color={
                  isExpired ? getCssVar('errorSecondaryColor') : getCssVar('process.dateColor')
                }
                fontSize='15px'
              >
                Exp. {moment(expirationDate).format(dateFormat)}
              </Box>
            )}
          </Box>

          {isCertificateSoonExpire && !isExpired && (
            <Tag
              color={getCssVar('process.soonExpired')}
              text={`in ${expiresInDays} days`}
              customProps={{
                position: 'static',
                color: `${getCssVar('textColor')}`,
                fontWeight: `${theme.fontWeights.light}`,
                mx: theme.spacing[2],
              }}
              lowerCase
            />
          )}
        </Box>
      </ExpiryLabelBody>
    </Box>
  );
};
