import { NewActions, NewGoals } from './styled';
import { GoalTitle, GoalText, Page } from '../styled';
import { BackgroundImage } from '../../components/BackgroundImage';

export function PdfPage14() {
  return (
    <Page>
      <BackgroundImage fileName='slide-14.jpg' />

      <NewGoals>
        <GoalTitle>Goal 1:</GoalTitle>
        <GoalText>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</GoalText>

        <GoalTitle>Goal 2:</GoalTitle>
        <GoalText>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</GoalText>

        <GoalTitle>Goal 3:</GoalTitle>
        <GoalText>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</GoalText>
      </NewGoals>

      <NewActions>
        <ul>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
        </ul>
      </NewActions>
    </Page>
  );
}
