import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const Primary = css<Props>`
  background-color: ${getCssVar('button.backgroundColor')};
  border: ${getCssVar('button.border')};
  border-radius: ${getCssVar('button.borderRadius')};
  color: ${getCssVar('button.color')};

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    &:not(:disabled) {
      background-color: ${getCssVar('button.hover.backgroundColor')};
      box-shadow: ${getCssVar('button.hover.boxShadow')};
      color: ${getCssVar('button.hover.color')};
      transform: ${getCssVar('button.hover.transform')};
      border-color: ${getCssVar('button.hover.backgroundColor')};
    }
  }

  &:focus,
  &.focus {
    &:not(:disabled) {
      box-shadow: none;
      transform: none;
    }
  }

  &:active,
  &.active {
    &:not(.ant-btn-dangerous):not(:disabled) {
      background-color: ${getCssVar('button.active.backgroundColor')};
      color: ${getCssVar('button.active.color')};
    }
  }

  &.ant-btn-loading,
  &:disabled,
  &:disabled:hover {
    background-color: ${getCssVar('buttonDisabled.backgroundColor')} !important;
    box-shadow: none;
    color: ${getCssVar('buttonDisabled.color')} !important;
    transform: none;

    svg > path {
      fill: ${getCssVar('buttonDisabled.color')} !important;
    }
  }
`;
