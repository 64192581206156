/* eslint-disable */
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form as AntdForm } from 'antd';
import { FormikProps, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  MessageType,
  processApi,
  supportRequestsApi,
  useApp,
  useCustomer,
  useLoading,
  useProcess,
  useSupportRequests,
} from 'store';
import { Box, Button, Input } from 'shared';
import { Routes } from 'services/entities';
import { showMessage } from 'services/helpers';
import {
  CertificateName,
  CreateSupportRequestRequest,
  CustomerProductsListSchema,
} from 'services/api';
import { IFormikValues } from '../types';
import { getFormFieldsConfig, getSelectsConfig } from '../helpers';
import { validationSchema as validation } from '../validation';
import { ButtonReset, WrapperSelect } from './styled';
import { Select } from './Select';
import { getInitialValues } from './helpers';
import { onCreateNewSupportRequest } from './onSubmit';
import { fontWeights, spacing } from '../../../styles/theme';

interface IProps {
  isServiceRequest?: boolean;
  purchaseProduct?: string | null;
  purchaseCertificate?: CertificateName | string | null;
}

export const Form = ({ isServiceRequest, purchaseProduct, purchaseCertificate }: IProps) => {
  const { isDesktop } = useApp();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const customerId = useCustomer();
  const { newSupportRequest } = useSupportRequests();
  const { customerProductsList } = useProcess();
  const isInit = useRef(true);

  const loading = useLoading([
    supportRequestsApi.createNewSupportRequest.typePrefix,
    processApi.getProducts.typePrefix,
  ]);
  const productsLoading = loading[processApi.getProducts.typePrefix];

  const validationSchema = isServiceRequest ? validation.service : validation.incident;

  const onSubmit = async (values: CreateSupportRequestRequest) => {
    await onCreateNewSupportRequest({ dispatch, values, customerId, purchaseCertificate, history });
  };

  useEffect(() => {
    dispatch(processApi.getProducts({ customerId }));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;

      return;
    }

    const notificationMessage = (
      <>
        <p>Message sent - ticket #{newSupportRequest.id}</p>
        <p>Our support team will contact you shortly</p>
      </>
    );

    showMessage(
      { content: notificationMessage, className: 'black-notification' },
      MessageType.Success,
    );
  }, [newSupportRequest.id]);

  const formik: FormikProps<IFormikValues> = useFormik({
    initialValues: getInitialValues({
      isServiceRequest,
      purchaseProduct,
      purchaseCertificate,
      customerProductsList,
    }),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const productsSelectOptions = customerProductsList?.map(
    (productName: CustomerProductsListSchema) => ({
      label: productName,
      value: productName,
    }),
  );

  const { product, priority, impact } = getSelectsConfig(
    formik,
    productsSelectOptions || [],
    productsLoading,
  );

  const { summary, description } = getFormFieldsConfig(
    isServiceRequest,
    formik,
    purchaseProduct || purchaseCertificate,
  );

  const [form] = AntdForm.useForm();

  useEffect(() => {
    form.setFieldsValue(formik.initialValues);
  }, [form, formik.initialValues]);

  const onCancel = () => {
    history.push(Routes.SUPPORT_REQUESTS);
  };

  const disabledSelect = Boolean(
    purchaseProduct || purchaseCertificate || productsLoading || !customerProductsList?.length,
  );

  const isServiceRequestWithoutPredefinedProduct =
    isServiceRequest && !purchaseProduct && !purchaseCertificate;

  return (
    <AntdForm
      form={form}
      layout='vertical'
      onFinish={formik.handleSubmit}
      initialValues={formik.initialValues}
    >
      {isServiceRequest ? (
        <WrapperSelect name='product' label='Product' rules={[{ required: true }]}>
          <Select {...product} disabled={disabledSelect} />
        </WrapperSelect>
      ) : (
        <>
          <WrapperSelect name='priority' label='Priority' rules={[{ required: true }]}>
            <Select {...priority} />
          </WrapperSelect>
          <WrapperSelect name='impact' label='Impact' rules={[{ required: true }]}>
            <Select {...impact} />
          </WrapperSelect>
        </>
      )}

      <Input {...summary} disabled={Boolean(purchaseProduct || purchaseCertificate)} />
      {!formik.errors.summary && (
        <Box
          width={isDesktop ? '712px' : 'auto'}
          textAlign='right'
          fontSize='14px'
          fontWeight={fontWeights.light}
          marginTop={`-${spacing['5']}`}
          marginBottom={spacing['5']}
        >
          {formik.values.summary?.length} / 200
        </Box>
      )}
      <Input {...description} />

      <Button
        type='primary'
        htmlType='submit'
        loading={loading[supportRequestsApi.createNewSupportRequest.typePrefix]}
        disabled={
          !formik.dirty ||
          !formik.isValid ||
          (!customerProductsList?.length && isServiceRequestWithoutPredefinedProduct)
        }
      >
        {isServiceRequest ? 'Submit request' : 'Report incident'}
      </Button>
      <ButtonReset type='secondary' htmlType='reset' onClick={onCancel}>
        Cancel
      </ButtonReset>
    </AntdForm>
  );
};
