import React, { RefObject } from 'react';
import { showError, showMessage } from 'services/helpers';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { HistoryState } from 'services/interfaces';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { AppDispatch, cmdbApi, MessageType } from 'store';
import { RequestStatus } from 'store/loading/slice';
import { toggleButton } from 'pages/VulnerabilityManagement/constants';
import { Tooltip } from 'antd';
import { CMDBReportType } from 'services/api';
import { icons } from 'styles/theme';
import { DropdownType } from 'shared/layouts/Inner/types';

import {
  allowedUploadOnlyCSV,
  downloadDropdownItems,
  ELIPSIS_LENGTH,
  MAX_TAG_LENGTH_DESKTOP,
  MAX_TAG_LENGTH_MOBILE,
  popupConfig,
} from './constants';

type Host = { id?: string; hostName?: string };

export const getLayoutConfig = (
  downloadCSV: (reportType?: CMDBReportType) => void,
  onSwitchToggleButton: (value: boolean) => void,
  loading: boolean,
  historyState?: HistoryState,
  pathname?: string,
  isToggleButtonActive?: boolean,
  isToggleButtonLoading?: boolean,
  showPotentialVulnerabilitiesToggle?: boolean,
  isMobile?: boolean,
  onUpload?: (event: React.FormEvent<HTMLInputElement>) => void,
  handleAttachButton?: () => void,
  inputRef?: RefObject<HTMLInputElement>,
  loadingTags?: boolean,
  isFullAccess?: boolean,
): InnerLayout => ({
  title: 'CMDB',
  uploadDoc:
    !isMobile && isFullAccess
      ? [
          {
            id: 1,
            title: 'Upload tags',
            onUpload,
            handleAttachButton,
            inputRef,
            accept: allowedUploadOnlyCSV,
            loading: loadingTags,
            tooltip: (
              <>
                The Upload Tags feature allows users to assign tags to hosts in bulk. Existing tags
                on the hosts will not be removed; new tags will be added to the existing ones. Tags
                should be uploaded in CSV format, with columns: "Host Name" and "Tags" (separated by
                semicolon).
                <br />
                <br />
                Here's an example of how your CSV file should look:
                <br />
                <br />
                Host Name, Tags
                <br />
                HostA, Tag1;Tag2;Tag3
                <br />
                HostB, Tag2;Tag4
                <br />
                HostC, Tag1;Tag3;Tag5
              </>
            ),
            icon: icons.rotated_download,
          },
        ]
      : undefined,
  downloadDoc: !isMobile
    ? [
        {
          id: 1,
          title: 'Download CSV',
          onDownload: downloadCSV,
          loading,
          type: DropdownType,
          dropdownItems: downloadDropdownItems,
        },
      ]
    : undefined,
  toggleButton: showPotentialVulnerabilitiesToggle
    ? {
        title: toggleButton.title,
        isActive: isToggleButtonActive,
        onChange: onSwitchToggleButton,
        loading: isToggleButtonLoading,
        tooltipTitle: toggleButton.tooltipTitle,
      }
    : undefined,
  backLink: <BackLink preferRoot historyState={historyState} />,
});

type DeletePopupParams = {
  setPopupConfig: (popupConfig: PopupConfigType) => void;
  setIsPopupVisible: (isVisible: boolean) => void;
  hostData: Host[];
  dispatch: AppDispatch;
  customerId: string;
  onSuccess: () => void;
  isNormUser?: boolean;
  soft?: boolean;
};

export const showDeletePopup = ({
  setPopupConfig,
  setIsPopupVisible,
  hostData,
  dispatch,
  customerId,
  onSuccess,
  isNormUser,
  soft,
}: DeletePopupParams) => {
  const isMultyDelete = hostData.length > 1;
  const type = soft ? 'softDelete' : 'purge';

  if (!hostData.length) {
    return;
  }

  setPopupConfig({
    ...popupConfig[type],
    onApplyTitle: isMultyDelete
      ? popupConfig.multyDelete.onApplyTitle
      : popupConfig[type].onApplyTitle,
    title: isMultyDelete
      ? popupConfig.multyDelete.title
      : `${popupConfig[type].title} ${hostData[0].hostName}?`,
    text: isMultyDelete ? popupConfig.multyDelete.text : popupConfig[type].text,
    onApply: async () => {
      const result = await dispatch(
        cmdbApi.deleteHosts({
          customerId,
          soft,
          hostIds: hostData
            .map((host: Host) => host.id)
            .filter((id: string | undefined) => id) as string[],
        }),
      );

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        showError();

        return;
      }

      if (isNormUser) {
        showMessage(
          {
            content: `${isMultyDelete ? 'Hosts' : hostData[0].hostName} ${
              isMultyDelete ? 'have' : 'has'
            } been deleted`,
          },
          MessageType.Success,
        );
      } else {
        showMessage(
          { content: 'The request for the host removal has been sent to Smartbloc team' },
          MessageType.Success,
        );
      }

      onSuccess();
    },
  });
  setIsPopupVisible(true);
};

export const truncateTextWithTooltip = (text = '', isMobile: boolean) => {
  const maxLength = isMobile ? MAX_TAG_LENGTH_MOBILE : MAX_TAG_LENGTH_DESKTOP;

  if (text.length > maxLength) {
    return (
      <Tooltip title={text} placement='top' trigger={isMobile ? 'click' : 'hover'}>
        {`${text.slice(0, maxLength - ELIPSIS_LENGTH)}...`}
      </Tooltip>
    );
  }

  return text;
};
