import { Tooltip } from 'antd';
import { HiddenFilterIcon } from 'shared/styled';
import { ResetFilterCol } from '../styled';
import { Button } from '../../buttons/styled';
import { Icon } from '../../Icon';
import { icons } from '../../../styles/theme';
import { Box } from '../../Box';
import { hiddenFilterTooltip } from '../constants';

interface IResetFilter {
  isVisible: boolean;
  clearAll: () => void;
  hasHiddenFilters?: boolean;
}

export const ResetFilter = ({ isVisible, clearAll, hasHiddenFilters }: IResetFilter) => {
  if (!isVisible) {
    return null;
  }

  return (
    <ResetFilterCol>
      <Button type='link' onClick={clearAll} icon={<Icon component={icons.replay} />}>
        Reset filters
      </Button>
      {hasHiddenFilters && (
        <Box mb='6px' mt='6px' alignSelf='flex-start'>
          <Tooltip title={hiddenFilterTooltip} placement='top'>
            <HiddenFilterIcon
              component={icons.hidden_filter}
              dimension='27px'
              className='left-space'
            />
          </Tooltip>
        </Box>
      )}
    </ResetFilterCol>
  );
};
