import { colors } from '../colors';
import { baseDarkNorm } from './base';

export const tableDarkNorm = {
  bg: baseDarkNorm.primaryBg,
  borderBottom: baseDarkNorm.borderColor,
  expandIcon: colors.white,

  specialColumnTitleColor: baseDarkNorm.lightBlue,

  th: {
    color: baseDarkNorm.borderColor, // hover/focus
    iconColor: baseDarkNorm.blue, // active icon
  },
  tr: {
    hover: {
      bg: baseDarkNorm.secondaryBg,
    },
    expanded: {
      bg: baseDarkNorm.secondaryBg,
    },
    selected: {
      bg: baseDarkNorm.secondaryBg,
    },
    bg: baseDarkNorm.primaryBg,
    color: colors.white,
    darkBg: baseDarkNorm.secondaryBg,
  },
  tableItem: {
    color: colors.white,
  },

  filters: {
    bg: baseDarkNorm.secondaryBg,
    moreFiltersIcon: baseDarkNorm.red,
    hiddenFilterIcon: baseDarkNorm.red,

    mobile: {
      bg: baseDarkNorm.primaryBg,
      color: colors.white,

      title: colors.white,
      resetIconColor: baseDarkNorm.lightBlue,
      closeIconColor: baseDarkNorm.grey,

      search: {
        bg: baseDarkNorm.secondaryBg,
      },

      sort: {
        item: {
          activeBg: baseDarkNorm.black,
          inactiveBg: colors.black,
        },
      },
    },
  },
};
