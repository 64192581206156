import { Filter, OrderBy, PatchCategory, PatchStatus } from 'services/api';
import { OptionType } from 'shared/Table/types';
import { initialOrderBy } from 'pages/PatchManagementPatches/constants';
import { Theme } from 'styles/theme/types';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { backlinkRoutes, initialPagination } from 'app-constants';

type FilterType = {
  name?: string;
  value?: string[];
  type?: Filter.type;
};

export const PBBackRoute = {
  backTo: {
    route: Routes.PATCH_MANAGEMENT,
    title: `Back to ${backlinkRoutes[Routes.PATCH_MANAGEMENT]}`,
  },
};

export const optionsOS: OptionType[] = [
  { value: 'Windows', label: 'Windows' },
  { value: 'Linux', label: 'Linux' },
  { value: 'Mac', label: 'Mac' },
];

export const MetricTitles = (platform: string) => {
  return {
    FailedPatchInstalls: `${platform} failed patch installs`,
    MissingPatches: `${platform} missing patches`,
    AllAssets: `All ${platform} assets`,
    AssetsAwaitingReboot: `${platform} assets awaiting reboot`,
  };
};

export const TooltipTitles = (platform: string) => ({
  WindowsPatchesByStatus: `Displays the status of all patches delivered to ${platform} OS machines over the last 7 days`,
  AssetsMissingPatchesByPlatform: `Displays the status of missing patches by ${platform} OS platform`,
  MissingPatchesOnWindows: `Displays missing patches on ${platform} OS machines by either Vendor or Category`,
  ByVendors: `Displays missing patches on ${platform} OS machines by Application Vendor`,
  ByCategory: `Displays missing patches on ${platform} OS machines by Category`,
});

export const initialOrderByPatches = {
  field: 'publishedDate',
  type: OrderBy.type.DESC,
};

export const initialOrderByAssets = {
  field: 'scanDateTime',
  type: OrderBy.type.DESC,
};

const getPatchParams = (order: string, isAwaitingReboot = false, hasMissingAssets = false) => {
  const orderBy = order === 'patches' ? initialOrderByPatches : initialOrderByAssets;

  const initialPatchParams = {
    orderBy,
    pagination: initialPagination,
    filter: {
      fields: [] as FilterType[],
    },
  };

  if (isAwaitingReboot) {
    initialPatchParams.filter.fields.push({
      name: 'assetStatus',
      value: ['Pending Reboot'],
      type: Filter.type.MULTIPLE,
    });
  }

  if (hasMissingAssets) {
    initialPatchParams?.filter.fields.push({
      name: 'patchStatus',
      value: [PatchStatus.MISSING],
      type: Filter.type.MULTIPLE,
    });
  }

  return initialPatchParams;
};

export const viewMoreConfig = (isAwaitingReboot = false, hasMissingAssets = false) => {
  return {
    patches: {
      route: `${Routes.PATCH_MANAGEMENT_PATCHES}${getQueryString(
        getPatchParams('patches', isAwaitingReboot, hasMissingAssets),
      )}`,
    },
    assets: {
      route: `${Routes.PATCH_MANAGEMENT_ASSETS}${getQueryString(
        getPatchParams('assets', isAwaitingReboot, hasMissingAssets),
      )}`,
    },
    assetsWithState: {
      pathname: Routes.PATCH_MANAGEMENT_ASSETS,
      route: Routes.PATCH_MANAGEMENT_ASSETS,
      search: getQueryString(getPatchParams('assets', isAwaitingReboot, hasMissingAssets)),
      state: PBBackRoute,
    },
  };
};

export const getPatchByStatusParams = (value: string[]) => ({
  orderBy: initialOrderBy,
  pagination: initialPagination,
  filter: {
    fields: [
      {
        name: 'patchId',
        value,
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'patchStatus',
        value: [PatchStatus.INSTALLED, PatchStatus.MISSING],
        type: Filter.type.MULTIPLE,
      },
    ],
  },
});

export const setOfMetrics = (
  platform: string,
  failedCount = 0,
  failedPatches: string[] | undefined,
  patchesByStatus = 0,
  allAssets = 0,
  assetsAwaitingReboot = 0,
) => [
  {
    title: MetricTitles(platform).FailedPatchInstalls,
    value: failedCount,
    // cursor: 'pointer',
    // route: `${Routes.PATCH_MANAGEMENT_PATCHES}${getQueryString(
    //   getPatchByStatusParams(failedPatches || []),
    // )}`,
  },
  {
    title: MetricTitles(platform).MissingPatches,
    value: patchesByStatus,
    cursor: 'pointer',
    route: viewMoreConfig(false, true).patches.route,
    tooltip: `Display all ${platform} OS devices`,
  },
  {
    title: MetricTitles(platform).AllAssets,
    value: allAssets,
    cursor: 'pointer',
    route: viewMoreConfig().assets.route,
    tooltip: `Display all ${platform} OS devices`,
  },
  {
    title: MetricTitles(platform).AssetsAwaitingReboot,
    value: assetsAwaitingReboot,
    cursor: 'pointer',
    route: viewMoreConfig(true).assets.route,
  },
];

export enum PatchGroupedBy {
  VENDOR = 'vendor',
  CATEGORY = 'category',
}

export const chartGap = (seriesBasics = {}, barWidth = '70px') => ({
  ...seriesBasics,
  barWidth,
});

export enum PatchByStatusBarChartTitles {
  FAILED = 'Failed',
  INSTALLED = 'Already Installed',
  SUCCESS = 'Success',
}

export const getPatchesByStatusChartColors = (theme: Theme) => ({
  [PatchByStatusBarChartTitles.SUCCESS]: theme.colorSet.baseColors.green,
  [PatchByStatusBarChartTitles.INSTALLED]: theme.colorSet.baseColors.grey,
  [PatchByStatusBarChartTitles.FAILED]: theme.colorSet.baseColors.red,
});

export const getPatchesByCategoryColors = (theme: Theme) => ({
  Security: theme.colorSet.baseColors.green,
  'Non Security': theme.colorSet.baseColors.grey,
});

export const getSecurityCategories = () => [PatchCategory.SECURITY_PATCHES];
export const getNonSecurityCategories = () => [
  PatchCategory.NON_SECURITY_PATCHES,
  PatchCategory.SECURITY_TOOLS,
  PatchCategory.CUSTOM_ACTIONS,
  PatchCategory.SOFTWARE_DISTRIBUTION,
];
