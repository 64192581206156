import { colors } from '../../colors';
import { inputsBase } from './base';

export const switchStyles = {
  bg: {
    inactive: inputsBase.inactiveColor,
    active: inputsBase.primaryColor,
    hover: inputsBase.hoverColor,
  },
  disabledOpacity: inputsBase.disabledOpacity,
};

export const checkBoxStyles = {
  borderColor: inputsBase.inactiveColor,
  active: {
    bg: inputsBase.primaryColor,
    color: colors.white,
  },
  hover: inputsBase.hoverColor,
  disabledOpacity: inputsBase.disabledOpacity,
};

export const radioStyles = {
  color: colors.white,
  borderColor: inputsBase.inactiveColor,
  active: {
    bg: 'transparent',
    color: inputsBase.primaryColor,
  },
  group: {
    bg: colors.white,
    borderColor: colors.gray[100],
  },
  hover: inputsBase.hoverColor,
  disabledOpacity: inputsBase.disabledOpacity,
};
