import moment from 'moment';
import { startCase } from 'lodash';
import {
  Filter,
  PhishingActivitySchema,
  PhishingByMonthResponse,
  PhishingByMonthSchema,
} from 'services/api';
import { initialPagination } from 'app-constants';
import { OptionParams } from 'shared/charts';
import { IEChartsOption, LabelFormatterParams, Series } from 'shared/charts/interfaces';
import { phishingByMonthTooltipFormatter } from 'shared/charts/formatters';
import { initialOrderBy, PhishingActivity } from 'pages/PeoplePhishing/constants';
import { getCertifiedDateFilterValue } from 'pages/People/helpers';
import { PhishingKeys, PhishingTooltipLabels } from 'pages/People/constants';

const rightGridForMobile = 38;
const rightGridForDesktop = 32;

export interface IParamsList {
  className: string;
  value: number;
  count: number;
  label: string;
}

const phishingTooltipFactory = ({ seriesName, data: { value, count } }: LabelFormatterParams) => {
  switch (seriesName) {
    case PhishingTooltipLabels.opened:
      return {
        className: PhishingTooltipLabels.opened,
        value,
        count,
        label: PhishingActivity.Opened,
      };
    case PhishingTooltipLabels.clicked:
      return {
        className: PhishingTooltipLabels.clicked,
        value,
        count,
        label: PhishingActivity.Clicked,
      };
    case PhishingTooltipLabels.providedCredentials:
      return {
        className: 'provided',
        value,
        count,
        label: PhishingActivity.providedCredentials,
      };
    default:
      return [];
  }
};

export const getSeriesName = (key: string) => {
  switch (key) {
    case 'providedCredentials':
      return PhishingActivity.providedCredentials;
    default:
      return startCase(key);
  }
};

const getData = (
  entries: PhishingByMonthSchema[],
): [{ value: string; count: number }[], string[]] => {
  const seriesData: { value: string; count: number }[] = [];
  const xAxisData: string[] = [];

  [...entries]
    .sort((valueA: PhishingByMonthSchema, valueB: PhishingByMonthSchema) => {
      if (!valueA.month || !valueB.month) {
        return 0;
      }

      return new Date(valueA.month).getTime() - new Date(valueB.month).getTime();
    })
    .map((entry: PhishingByMonthSchema) => {
      if (
        entry.count === undefined ||
        entry.month === undefined ||
        entry.percentage === undefined
      ) {
        return entry;
      }

      seriesData.push({ value: (entry.percentage || 0).toFixed(2), count: entry.count });
      xAxisData.push(entry.month);

      return entry;
    });

  return [seriesData, xAxisData];
};

const getMultiLineSeries = (entries: { [x: string]: PhishingByMonthSchema[] }) => {
  const baseSettings = {
    type: 'line',
  };
  const series: Series[] = [];
  let data: string[] = [];

  Object.entries(entries).map((entry: [string, PhishingByMonthSchema[]]) => {
    const [seriesData, xAxisData] = getData(entry[1]);

    series.push({
      ...baseSettings,
      name: entry[0],
      label: {
        formatter: () => getSeriesName(entry[0]),
      },
      data: seriesData,
      showSymbol: seriesData.length <= 1,
      symbolSize: 6,
    });

    data = xAxisData;

    return entry;
  });

  return { series, data };
};

export const getOption = ({
  values,
  theme,
  height,
  isMobile,
}: OptionParams<PhishingByMonthResponse>): IEChartsOption => {
  const { series, data } = getMultiLineSeries(values);
  const color = [
    theme.colorSet.pieChart.CurrentTrainingStatusStarted,
    theme.colorSet.pieChart.CurrentRefresherTestsIgnored,
    theme.colorSet.pieChart.PhishingLast30DaysProvidedCredentials,
  ];

  return {
    legend: { show: false },
    grid: {
      left: 18,
      top: 30,
      right: !isMobile ? rightGridForDesktop : rightGridForMobile,
      bottom: 0,
      containLabel: true,
      height: height || '260px',
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, '10%'],
      data: data.map((value: string) => moment(new Date(value)).format('MMM YYYY')),
      axisLabel: {
        fontSize: theme.sizes['3.4'],
        color: theme.colorSet.charts.axisLabel,
        fontFamily: theme.colorSet.lessFontFamily,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => `${value}%`,
        fontSize: theme.sizes['3.4'],
        color: theme.colorSet.charts.axisLabel,
        fontFamily: theme.colorSet.lessFontFamily,
      },
      min: 0,
    },

    series,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const paramsList: IParamsList[] = params.map(phishingTooltipFactory);

        return phishingByMonthTooltipFormatter(paramsList, params[0].name);
      },
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
        fontFamily: theme.colorSet.lessFontFamily,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
    },
    color,
  };
};

export const getPhishingActivityFilterValue = (value: string) => {
  let filterValue = '';

  switch (value) {
    case PhishingActivity.providedCredentials:
    case PhishingKeys.ProvidedCredentials:
    case PhishingTooltipLabels.providedCredentials:
      filterValue = PhishingActivitySchema.HIGH;
      break;
    case PhishingActivity.Clicked:
    case PhishingKeys.Clicked:
      filterValue = PhishingActivitySchema.MEDIUM;
      break;
    case PhishingActivity.Opened:
    case PhishingKeys.Opened:
      filterValue = PhishingActivitySchema.LOW;
      break;
    case PhishingActivity.noActions:
    case PhishingKeys.NoActions:
      filterValue = PhishingActivitySchema.OTHER;
      break;
    default:
      filterValue = PhishingActivitySchema.OTHER;
  }

  return filterValue;
};

export const getRedirectParams = (name: string, date: string) => ({
  orderBy: initialOrderBy,
  pagination: initialPagination,
  filter: {
    fields: [
      {
        name: 'phishingActivity',
        value: [getPhishingActivityFilterValue(name)],
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'phishingDate',
        value: getCertifiedDateFilterValue(date),
        type: Filter.type.RANGE,
      },
    ],
  },
});
