import styled, { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from './link';
import { Button } from './styled';

type Props = {
  theme: Theme;
  disabled?: boolean;
};

const CommonStyles = css<Props>`
  font-size: 17px !important;
  white-space: nowrap;
`;

export const ShowMoreButtonStyled = styled(Button)<Props>`
  ${CommonStyles};
`;

export const ShowMoreLinkStyled = styled(RouterLink)<Props>`
  ${Link};

  ${CommonStyles};
`;
