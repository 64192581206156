import { TagProps } from 'antd';
import { UcFirstLetter } from 'shared';
import { StatusTag } from 'pages/ThreatDetection/components/MonitoredServices/styled';
import { TagValue } from 'shared/TagColoring/constants';
import { RiskOptionLabel } from '../constants';

type TagColoringProps = TagProps & {
  title: string;
};

export const TagColoring = ({ title, ...tagProps }: TagColoringProps) => {
  const tagType = (title: string) => {
    switch (title) {
      case RiskOptionLabel.Critical:
        return TagValue.Critical;
      case RiskOptionLabel.High:
        return TagValue.Error;
      case RiskOptionLabel.Medium:
        return TagValue.Warning;
      case RiskOptionLabel.Low:
        return TagValue.Success;
      default:
        return TagValue.Default;
    }
  };

  return (
    <StatusTag key={title} {...tagProps} title={title} className={`ant-tag-${tagType(title)}`}>
      {UcFirstLetter(title)}
    </StatusTag>
  );
};
