import { colors } from '../colors';

export const severity = {
  critical: colors.red[650],
  high: colors.red[450],
  meduim: colors.yellow[460],
  low: colors.green[700],
  informational: colors.blue[650],
  analysis: colors.gray[120],
};
