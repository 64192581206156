import { colors } from '../colors';
import { baseDarkNorm } from './base';

export const leftMenu = {
  headerBgColor: '#383637',
  logoTop: 'menu-logo-top-dark-norm.svg',
  logoTopSmall: 'menu-logo-top-small-dark-norm.svg',
  logoTopWidth: '168',
  logoTopWidthCollapsed: '28',
  logoTopHeight: '30',
  logoTopHeightCollapsed: '25',
  toggleIconColor: '#5B5959',
  toggleIconColorMobile: colors.white,

  avatarColor: baseDarkNorm.blue,
  userNameColor: colors.white,
  userAvatarColor: colors.white,
  logoutColor: baseDarkNorm.grey,

  logoBottom: undefined,
  logoBottomCollapsed: undefined,

  drawerFooterLogo: undefined,

  footerBgColor: '#383637',

  themeSwitcher: {
    item: {
      bg: baseDarkNorm.blue,
      selected: colors.white,
    },
    bg: '#484648',
  },
};
