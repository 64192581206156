import styled from 'styled-components';
import { Theme } from 'styles/theme/types';

export type ThemeProps = { theme: Theme };

export const Wrapper = styled.div`
  color: #fff;
  padding: 110px 90px 0px 97px;
  position: relative;
`;

export const DescriptionText = styled.div`
  margin-bottom: 30px;
`;

export const YourContactsWrapper = styled.div`
  .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 15px !important;
  }
`;

export const MoreInformationText = styled.div`
  opacity: 0.3;
`;

export const Section = styled.div`
  margin-bottom: 30px;
`;

export const FieldWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  > div {
    flex-basis: 23%;

    &:nth-child(1) {
      text-align: center;
      flex-basis: 23%;
      margin-right: 20px;
    }

    &:nth-child(2) {
      flex-basis: 54%;
      margin-right: 20px;
    }
  }
`;

export const Field = styled.div`
  border: 1px solid #fff;
  border-radius: 30px;
  flex-basis: 25%;
  height: 42px;
  line-height: 42px;
  padding: 0 25px;
`;

export const FieldBlue = styled(Field)`
  background: ${({ theme }: ThemeProps) => theme.colorSet.baseColors.blue};
  border: none;
  font-weight: 700;
  white-space: nowrap;
`;
