import moment from 'moment';
import { dateFormat } from 'app-constants';
import { getIconName } from 'pages/Process/helpers';
import { CertificateStatusSchemaWithDownloadName } from 'services/api';
import { Icon, Box } from 'shared';
import { icons } from 'styles/theme/icons';
import { ABOUT_TO_EXPIRE_MONTHS } from 'pages/Process/constants';
import { useTheme } from 'styled-components';
import { ProcessItemContainer } from './styled';
import { getReportProccessStatusNameAndColor } from '../../helpers';
import { Badge } from '../styled';

interface IProcessItemProps extends CertificateStatusSchemaWithDownloadName {
  isLocked?: boolean;
}

export const ProcessItem = ({ status, title, expirationDate, isLocked }: IProcessItemProps) => {
  const theme = useTheme();
  const expiration = expirationDate ? moment(expirationDate) : null;
  const expired = status && expiration ? Number(expiration) < moment().valueOf() : null;
  const isCertificateSoonExpired =
    expiration && expiration.diff(moment(), 'month') < ABOUT_TO_EXPIRE_MONTHS;
  const { name: statusName, color: statusColor, textColor } = getReportProccessStatusNameAndColor({
    expired,
    isCertificateSoonExpired,
    status,
    theme,
    expirationDate,
  });

  const isCyberEssentials = title?.includes('Cyber');
  const iconClasses = isCyberEssentials ? 'icon icon-cyber-essentials' : 'icon';

  return (
    <ProcessItemContainer
      className='process-list-item'
      color={statusColor}
      isIcon={!status}
      isLocked={isLocked || !status}
      dateColor={expiration?.isBefore(moment()) ? theme.colorSet.errorSecondaryColor : 'inherit'}
    >
      <Badge className='title'>{title}</Badge>

      <div className={iconClasses}>
        {!status && <Icon component={icons.small_lock} className='lockicon' />}
        <Icon component={icons[getIconName(title, status)]} />
      </div>

      <div className='status-wrapper'>
        <Box
          textAlign='center'
          color={textColor || statusColor}
          fontWeight={theme.fontWeights.black}
        >
          {statusName}
        </Box>
        {expirationDate && <div className='date'>Exp. {expiration?.format(dateFormat)}</div>}
      </div>
    </ProcessItemContainer>
  );
};
