import { SecuritySettingsResponse } from 'services/api';
import { getCapitalizedString } from 'services/helpers';
import { getSettingsValue } from '../helpers';
import { Descriptions } from '../styled';
import { LabelForm } from './LabelForm';
import { formFields, LabelTitles, TooltipText } from '../constants';
import { Fields, Label, Tooltip } from '../types';

interface IProps {
  settings: SecuritySettingsResponse;
  loading: boolean;
}

export const SettingsViewMode = ({ settings, loading }: IProps) => {
  return (
    <Descriptions column={1}>
      {formFields.map((field: Fields) => {
        return (
          <Descriptions.Item
            key={field}
            label={
              <LabelForm
                tooltipText={TooltipText[getCapitalizedString(field, true) as Tooltip]}
                labelText={LabelTitles[getCapitalizedString(field, true) as Label]}
              />
            }
          >
            {!loading && getSettingsValue(field, settings)}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};
