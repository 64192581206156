import { colors } from '../colors';
import { baseNorm } from './base';

export const tableNorm = {
  bg: baseNorm.primaryBg,
  borderBottom: baseNorm.borderColor,
  expandIcon: colors.black,

  specialColumnTitleColor: baseNorm.blue,

  th: {
    color: baseNorm.borderColor, // hover/focus
    iconColor: baseNorm.blue, // active icon
  },
  tr: {
    hover: {
      bg: 'rgba(224,227,232, 0.2)',
    },
    expanded: {
      bg: 'rgba(224,227,232, 0.2)',
    },
    selected: {
      bg: 'rgba(224,227,232, 0.2)',
    },
    bg: colors.white,
    color: '#29292980',
    darkBg: colors.gray[20],
  },
  tableItem: {
    color: baseNorm.blue,
  },

  filters: {
    bg: colors.gray[20],
    moreFiltersIcon: baseNorm.red,
    hiddenFilterIcon: baseNorm.red,

    mobile: {
      bg: colors.gray[20],
      color: baseNorm.black,

      title: baseNorm.black,
      resetIconColor: baseNorm.blue,
      closeIconColor: baseNorm.black,

      search: {
        bg: colors.gray[20],
      },

      sort: {
        item: {
          activeBg: colors.gray[20],
          inactiveBg: colors.white,
        },
      },
    },
  },
};
