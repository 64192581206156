import { Descriptions, Row } from 'pages/CmdbHostDetails/styled';
import { Col, Space, Tooltip } from 'antd';
import { PatchDetailsResponse } from 'services/api';
import { renderValue } from 'pages/CmdbHostDetails/helpers';
import { getFormattedDate } from 'services/helpers';
import { Box, Icon } from 'shared';
import { useTheme } from 'styled-components';
import { getCssVar, icons } from 'styles/theme';
import { PatchDetailsTitle } from './types';
import { CVEList, MoreButton, PatchDetailsContainer } from './style';

interface PatchDetailsProps {
  details: PatchDetailsResponse;
}

export const PatchDetails = ({ details }: PatchDetailsProps) => {
  const theme = useTheme();
  const tooltipOverlayInnerStyle = {
    background: getCssVar('snackBar.bg'),
    maxHeight: '300px',
    overflow: 'auto',
    borderRadius: '8px',
  };

  const renderIcon = (value: boolean | undefined) => {
    return (
      <Icon
        component={value ? theme.icons.done : theme.icons.close}
        color={value ? theme.colorSet.iconCheck : theme.colors.gray[500]}
      />
    );
  };

  const getQualysPatchable = (downloadMethod?: string) => {
    switch (downloadMethod) {
      case 'Automatic':
        return true;
      case 'AcquireFromVendor':
        return false;
      case 'Unavailable':
        return false;
      default:
        return false;
    }
  };

  const renderList = (value: string[] | undefined) => {
    if (!value || !value.length) {
      return renderValue(undefined);
    }

    return (
      <Box display='flex' flexDirection='column'>
        <Box>{value[0]}</Box>
        {value.length > 1 && (
          <Tooltip
            overlay={value.slice(1).map((cve) => (
              <CVEList key={cve}>{cve}</CVEList>
            ))}
            overlayInnerStyle={tooltipOverlayInnerStyle}
            placement='bottom'
            trigger='click'
          >
            <MoreButton>
              {value.length - 1} more <Icon dimension='15px' component={icons.arrow_down_open} />
            </MoreButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  return (
    <PatchDetailsContainer>
      <Row gutter={64}>
        <Col span={32} xs={24} xl={12}>
          <Descriptions title='Identification' column={1}>
            <Descriptions.Item label={PatchDetailsTitle.Description}>
              {renderValue(details.description)}
            </Descriptions.Item>

            <Descriptions.Item label={PatchDetailsTitle.Type}>
              {renderValue(details.patchType)}
            </Descriptions.Item>

            <Descriptions.Item label={PatchDetailsTitle.Architecture}>
              {renderValue(details.architecture?.join(', '))}
            </Descriptions.Item>

            <Descriptions.Item label={PatchDetailsTitle.BulletinID}>
              {renderValue(details.bulletin)}
            </Descriptions.Item>

            <Descriptions.Item label={PatchDetailsTitle.KB}>
              {renderValue(details.kb)}
            </Descriptions.Item>

            <Descriptions.Item label={PatchDetailsTitle.Published}>
              {renderValue(getFormattedDate(details.publishedDate))}
            </Descriptions.Item>
            <Descriptions.Item label={PatchDetailsTitle.Modified}>
              {renderValue(getFormattedDate(details.modifiedDate))}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={32} xs={24} xl={12}>
          <Space direction='vertical' size={0}>
            <Descriptions title='Details' column={1}>
              <Descriptions.Item label={PatchDetailsTitle.Rollback}>
                {renderIcon(details.isRollback)}
              </Descriptions.Item>

              <Descriptions.Item label={PatchDetailsTitle.QualysPatchable}>
                {renderIcon(getQualysPatchable(details.downloadMethod))}
              </Descriptions.Item>

              <Descriptions.Item label={PatchDetailsTitle.DownloadMethod}>
                {renderValue(details.downloadMethod)}
              </Descriptions.Item>

              <Descriptions.Item label={PatchDetailsTitle.CVEs}>
                {renderList(details.cve)}
              </Descriptions.Item>

              <Descriptions.Item label={PatchDetailsTitle.Reboot}>
                {renderIcon(details.rebootRequired)}
              </Descriptions.Item>
              <Descriptions.Item label={PatchDetailsTitle.ServicePack}>
                {renderIcon(details.servicePack)}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Col>
      </Row>
    </PatchDetailsContainer>
  );
};
