import { colors } from '../../colors';
import { baseNasstar } from '../base';
import { inputsBase } from './base';

export const inputs = {
  label: {
    textColor: colors.blue[900],
    requiredIconColor: baseNasstar.deepRed,
  },
  backgroundColor: inputsBase.backgroundColor,
  backgroundColorDisabled: '#f5f5f5',
  textColor: inputsBase.textColor,
  textColorDisabled: inputsBase.textColorDisabled,
  borderColor: inputsBase.borderColor,
  borderColorHover: inputsBase.borderColorHover,
  borderColorDisabled: inputsBase.borderColorDisabled,
  borderColorError: baseNasstar.red,
  borderColorErrorHover: inputsBase.errorColor,
  errorMessageColor: inputsBase.errorColor,
  errorMessageColorHover: inputsBase.errorColor,
  prefixIconColor: inputsBase.iconColor,
  prefixIconBackground: inputsBase.backgroundColor,
};
