import { BackLink, Box, Button, Icon, InnerLayoutProps as InnerLayout, ActionsDDL } from 'shared';
import { omit } from 'lodash';
import { History } from 'history';
import { ActionType } from 'shared/ActionPopup/types';
import { RequestStatus } from 'store/loading/slice';
import { backlinkRoutes, loadingNotificationMessage } from 'app-constants';
import { MessageType, newsNotifications, newsNotificationsApi } from 'store';
import { Routes } from 'services/entities';
import { NotificationStatus } from 'services/api';
import { HistoryState } from 'services/interfaces';
import { showError, showMessage } from '../../services/helpers';
import { actions, popupConfig, userActions } from './constant';
import { PopupParams } from './types';
import { NotificationPayload } from '../NotificationsUpdate/types';
import { onCreateMessage, UpdateNotification } from '../NotificationsUpdate/constants';

export const redirectToNotificationDetails = (history: History, id: string) => {
  history.push(Routes.NOTIFICATIONS_DETAILS.replace(':id', id as string), {
    backTo: {
      route: Routes.NOTIFICATIONS,
      search: history.location.search,
      title: `Back to ${backlinkRoutes[Routes.NOTIFICATIONS]}`,
    },
  });
};

export const getLayoutConfig = (
  createNotification: () => void,
  historyState?: HistoryState,
  showing?: boolean,
): InnerLayout => ({
  title: 'Notifications',
  customComponent: showing ? (
    <Box mt='4px'>
      <Button type='primary' onClick={() => createNotification()}>
        {UpdateNotification.CREATE}
      </Button>
    </Box>
  ) : undefined,
  backLink: (
    <BackLink
      defaultRoute={Routes.DASHBOARD}
      defaultTitle={backlinkRoutes[Routes.DASHBOARD]}
      historyState={historyState}
    />
  ),
});

export const showPopup = ({
  popupId,
  setPopupDetails,
  setIsVisiblePopup,
  notificationDetails,
  dispatch,
  customerId,
  onSuccess,
  history,
}: PopupParams) => {
  switch (popupId) {
    case popupConfig.edit.id:
      history.push(Routes.NOTIFICATIONS_EDIT.replace(':id', notificationDetails.id as string), {
        backTo: {
          route: Routes.NOTIFICATIONS,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[Routes.NOTIFICATIONS]}`,
        },
      });
      break;
    case popupConfig.createAndPublish.id:
      setPopupDetails({
        ...popupConfig.createAndPublish,
        text: (
          <>
            If notification is published now, it will be promptly sent to all the platform users.
            <br />
            <br />
            Are you sure you want to publish the notification?
          </>
        ),
        onApply: async () => {
          showMessage(loadingNotificationMessage, MessageType.Loading);

          const result = await dispatch(
            newsNotificationsApi.createNotification({
              customerId,
              requestBody: notificationDetails,
            }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          redirectToNotificationDetails(history, (result as NotificationPayload).payload.id);
          showMessage(
            { content: onCreateMessage[NotificationStatus.PUBLISHED] },
            MessageType.Success,
          );
          onSuccess();
        },
      });
      setIsVisiblePopup(true);
      break;
    case popupConfig.updateAndPublish.id:
      setPopupDetails({
        ...popupConfig.createAndPublish,
        text: (
          <>
            If notification is published now, it will be promptly sent to all the platform users.
            <br />
            <br />
            Are you sure you want to publish the notification?
          </>
        ),
        onApply: async () => {
          showMessage(loadingNotificationMessage, MessageType.Loading);
          if (!notificationDetails.id) {
            return;
          }

          const result = await dispatch(
            newsNotificationsApi.updateNotification({
              customerId,
              notificationId: notificationDetails.id,
              requestBody: omit(notificationDetails, ['id']),
            }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          redirectToNotificationDetails(history, notificationDetails.id as string);
          showMessage(
            { content: onCreateMessage[NotificationStatus.PUBLISHED] },
            MessageType.Success,
          );
          onSuccess();
        },
      });
      setIsVisiblePopup(true);
      break;
    case popupConfig.unPublish.id:
      setPopupDetails({
        ...popupConfig.unPublish,
        text: (
          <>
            If the notification is Unpublished now, its status will change to draft.
            <br />
            <br />
            Are you sure you want to unpublish the notification?
          </>
        ),
        onApply: async () => {
          if (!notificationDetails.id) {
            return;
          }

          showMessage(loadingNotificationMessage, MessageType.Loading);

          const result = await dispatch(
            newsNotificationsApi.updateNotification({
              customerId,
              notificationId: notificationDetails?.id,
              requestBody: { status: NotificationStatus.DRAFTS },
            }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          dispatch(newsNotifications.actions.updatedNotificationGrid([notificationDetails.id]));
          showMessage({ content: onCreateMessage[NotificationStatus.DRAFTS] }, MessageType.Success);
          onSuccess();
        },
      });
      setIsVisiblePopup(true);
      break;
    case popupConfig.publish.id:
      setPopupDetails({
        ...popupConfig.createAndPublish,
        text: (
          <>
            If notification is published now, it will be promptly sent to all the platform users.
            <br />
            <br />
            Are you sure you want to publish the notification?
          </>
        ),
        onApply: async () => {
          if (!notificationDetails.id) {
            return;
          }

          showMessage(loadingNotificationMessage, MessageType.Loading);

          const result = await dispatch(
            newsNotificationsApi.updateNotification({
              customerId,
              notificationId: notificationDetails?.id,
              requestBody: { status: NotificationStatus.PUBLISHED },
            }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          dispatch(newsNotifications.actions.updatedNotificationGrid([notificationDetails.id]));
          showMessage(
            { content: onCreateMessage[NotificationStatus.PUBLISHED] },
            MessageType.Success,
          );
          onSuccess();
        },
      });
      setIsVisiblePopup(true);
      break;
    case popupConfig.markAsRead.id:
    case popupConfig.markAsUnread.id:
      if (notificationDetails.id) {
        const notificationId = notificationDetails.id as string;

        dispatch(
          newsNotificationsApi.setReadNotification({
            customerId,
            isRead: !notificationDetails.read,
            requestBody: { notificationIds: [notificationId] },
          }),
        ).then((result) => {
          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          dispatch(newsNotifications.actions.updatedNotificationGrid([notificationId]));
        });
      }

      break;
    case popupConfig.delete.id:
      setPopupDetails({
        ...popupConfig.delete,
        title: `${popupConfig.delete.title} ‘${notificationDetails.title}’?`,
        onApply: async () => {
          showMessage(loadingNotificationMessage, MessageType.Loading);

          if (!notificationDetails.id) {
            return;
          }

          const result = await dispatch(
            newsNotificationsApi.deleteNotification({
              customerId,
              notificationId: notificationDetails?.id,
            }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          dispatch(newsNotifications.actions.updatedNotificationGrid([notificationDetails.id]));

          showMessage(
            { content: `${notificationDetails.title} has been deleted` },
            MessageType.Success,
          );
          onSuccess();
        },
      });
      setIsVisiblePopup(true);
      break;
    default:
      setPopupDetails(null);
  }
};

export const getCustomMenu = (
  actions: ActionType[],
  handleClickAction: (popupId: string) => void,
) => (
  <ActionsDDL>
    {actions.map(({ name, icon, popupId, isDanger }) => {
      return (
        <ActionsDDL.Item
          key={name}
          danger={isDanger}
          icon={<Icon component={icon} />}
          onClick={() => handleClickAction(popupId)}
        >
          {name}
        </ActionsDDL.Item>
      );
    })}
  </ActionsDDL>
);

export const getActions = (isFullAccess: boolean, excludeActionName: string[]): ActionType[] => {
  const allowedActionsByPermission = isFullAccess ? actions : [...userActions];

  return allowedActionsByPermission.filter((action) => !excludeActionName.includes(action.name));
};
