import styled from 'styled-components';
import { getCssVar } from 'styles/theme';
import { Modal as AntModal } from 'antd';

export const ModalStyled = styled(AntModal)`
  .ant-modal-close-x {
    color: ${getCssVar('modal.closeIcon')};
  }

  .ant-modal-content {
    background-color: ${getCssVar('modal.bg')};
    color: ${getCssVar('textColor')};

    .ant-modal-title {
      color: ${getCssVar('textColor')} !important;
      font-size: 16px;
      font-weight: bold;
    }

    .ant-modal-body,
    .ant-modal-header,
    .ant-modal-footer {
      background-color: ${getCssVar('modal.bg')};
      border-color: ${getCssVar('modal.borderColor')};
      color: ${getCssVar('textColor')};
    }

    .ant-modal-body {
      .ant-menu {
        background-color: ${getCssVar('modal.bg')};
        border: transparent;
        color: ${getCssVar('textColor')};

        .ant-menu-title-content {
          font-weight: normal;
        }

        .ant-menu-item-divider {
          border-color: ${getCssVar('divider.color')};
        }
      }
    }
  }
`;
