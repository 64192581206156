import { colors } from '../colors';
import { fontWeights } from '../fontWeights';
import { mainFontFamily } from '../Nasstar/fontsNasstar';
import { baseNorm } from './base';

export const process = {
  labelWeight: fontWeights.bold,
  labelInputsWeight: fontWeights.medium,
  labelFontFamily: mainFontFamily,
  success: baseNorm.green,
  expired: baseNorm.deepRed,
  inProgress: baseNorm.red,
  soonExpired: '#f8d1c8',
  labelUppercase: 'none',
  hoverBg: 'rgba(224, 227, 232, 0.2)',
  dateColor: '#918f8f',
  helpMessageBg: 'rgba(35, 31, 32, 0.9)',
  helpMessageColor: colors.white,
  certificate: {
    statusLabelColor: baseNorm.blue,
  },
};
