import { PropsWithChildren } from 'react';
import { GradientPieReport } from './GradientPieReport';

type Props = {
  title?: string | JSX.Element;
  showScore?: boolean;
  score?: number | null;
  onLoad: () => void;
};

export const ReportTableSection = ({
  title,
  score,
  children,
  onLoad,
  showScore,
}: PropsWithChildren<Props>) => {
  return (
    <table className='table-section'>
      <tbody>
        <tr>
          <td>
            {(score || title) && (
              <div className='table-section-header'>
                {showScore && <GradientPieReport score={score} right onLoad={onLoad} />}

                {title && <h3>{title}</h3>}
              </div>
            )}
            <div className='table-section-body'>{children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
