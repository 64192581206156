import { inputs } from './inputs';
import { select } from './select';
import { switchStyles, checkBoxStyles, radioStyles } from './radioCheckbox';
import { wysiwyg } from './wysiwyg';
import { datePicker } from './datePicker';

export const inputsNormDark = {
  inputs,
  select,
  wysiwyg,
  datePicker,
  radio: radioStyles,
  switch: switchStyles,
  checkBox: checkBoxStyles,
};
