import { Avatar, Layout } from 'antd';
import styled from 'styled-components';
import { getCssVar } from 'styles/theme';
import { Link } from 'react-router-dom';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  background: ${getCssVar('layout.header.bg')};
  height: ${getCssVar('layout.header.height')};
  min-width: 100%;
  width: max-content;
`;

export const DropDownLink = styled(Link)`
  color: ${({ theme }) => theme.colorSet.textColor};
`;

export const DropDownAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.colorSet.leftMenu.avatarColor};
  color: ${({ theme }) => theme.colorSet.leftMenu.userAvatarColor};
  margin: 20px 8px 20px 0;
`;
