import { LogoPlaceholder } from './styled';
import { BackgroundImage } from '../../components/BackgroundImage';
import { Page } from '../styled';

export function PdfPage16() {
  return (
    <Page>
      <BackgroundImage fileName='slide-16.jpg' useFilter={false} />

      <LogoPlaceholder>Company Logo</LogoPlaceholder>
    </Page>
  );
}
