import styled from 'styled-components';
import { breakpoints } from 'styles/theme/breakpoints';

export const DatePickerWrapper = styled.div`
  .ant-picker-panel {
    &:last-child {
      position: relative;
      width: 0;

      .ant-picker-header {
        position: absolute;
        height: 40px;
        right: 0;
        border: none;

        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: hidden;
        }
      }

      .ant-picker-body {
        display: none;
      }

      @media (min-width: ${breakpoints.md}) {
        width: 280px !important;
        .ant-picker-header {
          position: relative;

          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: initial;
          }
        }

        .ant-picker-body {
          display: block;
        }
      }
    }
  }
`;
