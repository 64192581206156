import { colors } from '../../colors';
import { baseNasstar } from '../base';

export const inputsBase = {
  backgroundColor: colors.white,
  backgroundColorDisabled: '#f5f5f5',
  textColor: colors.black,
  textColorDisabled: colors.gray[80],
  borderColor: colors.gray[80],
  borderColorHover: colors.gray[200],
  borderColorDisabled: '#eeede8',
  iconColor: colors.gray[300],
  primaryColor: baseNasstar.blue,
  secondaryColor: baseNasstar.pink,
  disabledOpacity: 0.3,
  hoverColor: 'rgba(0, 0, 0, 0.1)',
  inactiveColor: colors.gray[150],
  errorColor: baseNasstar.deepRed,
};
