import moment from 'moment';
import { emptyDataString } from 'app-constants';
import { formatNumberWithCommas } from './string';

export const formatUpdatedOrCreatedTime = (date: string | number, withoutTime?: boolean) => {
  const format = withoutTime ? 'DD-MMM-YYYY' : 'DD-MMM-YYYY HH:mm';

  return moment(!+date ? date : +date).format(format);
};

export const getFormattedDate = (
  date?: string | number,
  emptyValue?: string,
  withoutTime?: boolean,
) => {
  if (!date) {
    return emptyValue || emptyDataString;
  }

  return formatUpdatedOrCreatedTime(date, withoutTime);
};

export const getTimeFromLastUpdateOrCreate = (date: string) => {
  return moment(!+date ? date : +date).fromNow();
};

export const getHowManyDaysAgo = (months: number): number => {
  const now = moment();
  const end = moment().subtract(months, 'months');
  const duration = moment.duration(now.diff(end));

  return duration.asDays();
};

export const isMoreThanNumberOfDaysAgo = (numberOfDays: number, date: string) => {
  const numberOfDaysAgo = moment().subtract(numberOfDays, 'days');
  const inputDate = moment(date);

  return inputDate.isBefore(numberOfDaysAgo);
};

export const getTimeSinceDate = (date?: string | number) => {
  if (!date) {
    return null;
  }

  const MINUTES_THRESHOLD = 60;
  const HOURS_THRESHOLD = 24;
  const duration = moment.duration(moment().diff(moment(date)));
  const minutes = Math.round(duration.asMinutes());
  const hours = Math.round(duration.asHours());
  const days = duration.asDays();

  switch (true) {
    case minutes < 1:
      return `Now`;
    case minutes === 1:
      return `${minutes} minute ago`;
    case minutes < MINUTES_THRESHOLD:
      return `${minutes} minutes ago`;
    case hours === 1:
      return `${hours} hour ago`;
    case hours < HOURS_THRESHOLD:
      return `${hours} hours ago`;
    case Math.round(days) === 1:
      return `1 day ago`;
    default:
      return `${formatNumberWithCommas(Math.round(days))} days ago`;
  }
};
