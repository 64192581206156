import { spacing } from '../spacing';
import { fontWeights } from '../fontWeights';
import { colors } from '../colors';
import { baseNorm } from './base';

const buttonShadow = '0px 4px 8px 0px rgba(0, 0, 0, 0.15)';

export const button = {
  fontSize: `${spacing[4]}`,
  fontWeight: `${fontWeights.bold}`,
  textTransform: 'none',
  backgroundColor: baseNorm.blue,
  color: colors.white,
  border: 'none',
  borderRadius: '0px',

  hover: {
    backgroundColor: baseNorm.blueHover,
    color: colors.white,
    transform: 'none',
    boxShadow: 'none',
  },

  active: {
    backgroundColor: baseNorm.blueActive,
    color: colors.white,
  },
};

export const buttonDisabled = {
  backgroundColor: '#D7D5D5',
  color: '#aba9aa',
};

export const buttonSecondary = {
  backgroundColor: 'transparent',
  color: baseNorm.black,
  border: `2px solid ${baseNorm.black}`,
  borderRadius: '0px',

  hover: {
    backgroundColor: '#F8F8F8',
    borderColor: baseNorm.black,
    color: baseNorm.black,
    boxShadow: buttonShadow,
  },

  active: {
    backgroundColor: colors.gray[20],
    borderColor: baseNorm.black,
    color: baseNorm.black,
  },

  disabled: {
    backgroundColor: 'transparent',
    color: buttonDisabled.color,
    borderColor: buttonDisabled.backgroundColor,
  },
};

export const buttonDanger = {
  backgroundColor: baseNorm.deepRed,
  color: colors.white,

  hover: {
    backgroundColor: baseNorm.deepRedHover,
    color: colors.white,
    transform: 'none',
    boxShadow: 'none',
  },

  active: {
    backgroundColor: baseNorm.deepRedActive,
    color: colors.white,
  },

  disabled: buttonDisabled,
};

export const buttonSimple = {
  borderColor: baseNorm.borderColor,

  hover: {
    backgroundColor: '#F8F8F8',
    borderColor: baseNorm.borderColor,
    boxShadow: buttonShadow,
  },

  active: {
    backgroundColor: colors.gray[20],
    borderColor: baseNorm.borderColor,
  },

  disabled: {
    backgroundColor: 'transparent',
    color: buttonDisabled.color,
    borderColor: buttonDisabled.backgroundColor,
  },
};
