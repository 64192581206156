import { colors } from '../colors';

export const pieChart = {
  CurrentTrainingStatusSuccess: colors.green[700],
  CurrentTrainingStatusStarted: colors.yellow[460],
  CurrentTrainingStatusNotStarted: colors.gray[70],

  CurrentRefresherTestsPassed: colors.green[310],
  CurrentRefresherTestsIgnored: colors.blue[750],
  CurrentRefresherTestsFailed: colors.red[450],

  PhishingLast30DaysOpened: colors.yellow[460],
  PhishingLast30DaysNoActions: colors.green[700],
  PhishingLast30DaysClicked: colors.red[450],
  PhishingLast30DaysProvidedCredentials: colors.purple[550],

  ActiveAlarms: colors.blue[410],
  UnActiveAlarms: colors.red[100],

  Radius: ['73%', '94%'],
  RestSectorOpacity: '0.1',
};
