import React from 'react';
import { SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { Icon } from 'shared';
import { FilterType, OptionType } from 'shared/Table/types';
import { icons } from 'styles/theme';
import { Filter } from 'services/api';
import { useApp } from 'store';
import { Select, SelectWithLabelContainer, StyledLabel } from './styled';

export type TableFiltersSelectProps = SelectProps<SelectValue> &
  FilterType & {
    value?: string[] | string;
    onSelectChange: (field: string, value: string[] | string, type: Filter.type) => void;
    maxTagCount?: number;
  };

export const TableFiltersSelect = ({
  label,
  value,
  field,
  onSelectChange,
  filterType,
  loading,
  placeholder = 'All',
  maxTagCount = 1,
  inputType,
  ...filterProps
}: TableFiltersSelectProps) => {
  const [checked, setChecked] = React.useState(false);
  const { isMobile } = useApp();
  const omittedValuesCount = (currentValue: string[]) => `+${currentValue.length}`;
  const selectContainerRef = React.useRef<HTMLDivElement>(null);
  const selectRef = React.useRef<HTMLSelectElement>(null);

  const handleSelectChange = React.useCallback(
    (selectValue: SelectValue) => {
      if (!selectValue) return;
      if (!Array.isArray(selectValue)) {
        onSelectChange(field || label?.toLowerCase(), `${selectValue}`, filterType);

        return;
      }

      if (selectValue.length === 0) {
        onSelectChange(field || label?.toLowerCase(), '', filterType);

        return;
      }

      onSelectChange(field || label?.toLowerCase(), selectValue as string[], filterType);
    },
    [onSelectChange, label, field, filterType],
  );

  React.useEffect(() => {
    if (value && value.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [value]);

  const onClear = () => {
    onSelectChange(field || label?.toLowerCase(), '', filterType);
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  return (
    <SelectWithLabelContainer ref={selectContainerRef}>
      <StyledLabel htmlFor={`id-${label}`}>{label}</StyledLabel>

      <Select
        id={`id-${label}`}
        className={!checked ? '' : 'checked'}
        mode={filterType === Filter.type.VALUE ? undefined : 'multiple'}
        placeholder={placeholder}
        ref={selectRef}
        suffixIcon={
          !checked ? (
            <Icon className='ant-select-suffix' component={icons.arrow_drop_down} />
          ) : (
            <Icon className='clear-icon' component={icons.select_clear_new} onClick={onClear} />
          )
        }
        maxTagPlaceholder={omittedValuesCount}
        maxTagCount={maxTagCount}
        showArrow
        value={value}
        onChange={handleSelectChange}
        filterOption={(inputValue: string, option: OptionData | OptionGroupData | undefined) =>
          (option as OptionType).label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
        getPopupContainer={() => selectContainerRef.current as HTMLElement}
        showSearch={!isMobile}
        {...filterProps}
      />
    </SelectWithLabelContainer>
  );
};
