import { TabsWrapper, TabsStyled } from './styled';
import { TabsProps } from './types';

const DEFAULT_HEIGHT = '40px';

export const Tabs = ({ children, tabHeight = DEFAULT_HEIGHT, tabMargin, ...props }: TabsProps) => {
  return (
    <TabsWrapper tabHeight={tabHeight} tabMargin={tabMargin} className='custom-tabs-wrapper'>
      <TabsStyled className='custom-tabs' {...props}>
        {children}
      </TabsStyled>
    </TabsWrapper>
  );
};
