import { colors } from '../../colors';
import { inputsBase } from './base';

export const wysiwyg = {
  placeholderColor: colors.brown[100],
  bg: inputsBase.backgroundColor,
  borderColor: inputsBase.borderColor,
  toolbarButtonBg: colors.gray[150],
  toolbarButtonBgHover: colors.gray[90],
  view: {
    table: {
      borderColor: colors.gray[70],
      cellPadding: '5px 12px',
    },
  },
};
