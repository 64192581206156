import React from 'react';
import { ThemeContext } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';
import { Divider, Dropdown } from './DownloadDocDropdown.styled';
import { Menu } from '../Menu/Menu.styled';
import { DropdownItem, Props } from './types';

import { Icon } from '../Icon';
import { Button } from '../buttons/styled';
import { DownloadIcon } from '../layouts/Inner/components';

export function DownloadDocDropdown({
  onDownload,
  icon,
  loading,
  title,
  dropdownItems = [],
}: Props) {
  const theme = React.useContext<Theme>(ThemeContext);
  const handleItemClick = ({ key }: { key: React.Key }) => {
    onDownload(key as string);
  };

  if (dropdownItems.length === 0) {
    return null;
  }

  const dropdownOptions = (
    <Menu style={{ background: getCssVar('select.bg') }} onClick={handleItemClick}>
      {dropdownItems.map(
        ({ key, danger, label, icon = theme.icons.download }: DropdownItem, index: number) => (
          <React.Fragment key={key}>
            {index > 0 && <Divider />}
            <Menu.Item key={key} danger={danger}>
              <Icon component={icon} /> {label}
            </Menu.Item>
          </React.Fragment>
        ),
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={dropdownOptions} trigger={['click']} placement='bottomLeft'>
      <div>
        <Button
          type='link'
          style={{ display: 'flex', alignItems: 'center', height: '24px' }}
          icon={<DownloadIcon component={icon || theme.icons.download} />}
          loading={loading}
        >
          {title || 'Download report'}
        </Button>
      </div>
    </Dropdown>
  );
}
