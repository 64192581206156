import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { Routes } from 'services/entities';
import { backlinkRoutes } from 'app-constants';
import { ShowMoreButton } from 'shared';

type ConfigureIntegrationLinkProps = {
  id: string;
};

export const ConfigureIntegrationLink = ({ id }: ConfigureIntegrationLinkProps) => {
  const history = useHistory();

  const redirectToServiceDetails = useCallback(() => {
    history.push(`${Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', id)}`, {
      backTo: {
        route: Routes.SERVICES_INTEGRATIONS,
        title: `Back to ${backlinkRoutes[Routes.SERVICES_INTEGRATIONS]}`,
      },
    });
  }, [history, id]);

  return (
    <ShowMoreButton mt='26px' disabled={!id} onClick={redirectToServiceDetails}>
      Integration details
    </ShowMoreButton>
  );
};
