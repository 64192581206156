import moment, { Moment } from 'moment';
import { dateFormat } from 'app-constants';
import { DownloadSchema } from 'services/api';
import { ColumnsType } from 'antd/lib/table';
import { ColumnCertified, ColumnName } from '../../styled';
import { DownloadComponent } from './DownloadComponent';

export const getTableColumns = (
  onDownload: (name: string) => void,
  downloading: string,
  setClickOn: (clickOn: string) => void,
): ColumnsType<DownloadSchema> => {
  return [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      width: '30%',
      render: (text: string) => <ColumnName>{text}</ColumnName>,
    },
    {
      title: 'Certified',
      dataIndex: 'created',
      key: 'created',
      width: '30%',
      render: (dateOfCertificate: Moment) => (
        <ColumnCertified>{moment(dateOfCertificate).format(dateFormat)}</ColumnCertified>
      ),
      className: 'right-head-title',
      align: 'right',
    },
    {
      title: '',
      dataIndex: 'download',
      key: 'download',
      width: '40%',
      render: (_: string, record: DownloadSchema) =>
        record.name && (
          <DownloadComponent
            name={record.name}
            downloadCertificate={onDownload}
            downloading={downloading}
            setClickOn={setClickOn}
          />
        ),
      align: 'right',
      className: 'right-head-title',
    },
  ];
};
