import { colors } from '../../colors';
import { baseNorm } from '../base';
import { inputsBase } from './base';

export const wysiwyg = {
  placeholderColor: inputsBase.textColorDisabled,
  bg: inputsBase.backgroundColor,
  borderColor: colors.gray[100],
  toolbarButtonBg: colors.gray[150],
  toolbarButtonBgHover: colors.gray[90],
  view: {
    table: {
      borderColor: baseNorm.borderColor,
      cellPadding: '5px 12px',
    },
  },
};
