import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { TableFiltersSelect as TableFiltersSelectStyled } from 'shared/TableFilters';
import { vendorSeverityFilterWidth } from './constants';
import { breakpoints, getCssVar } from '../../styles/theme';

type Props = { theme: Theme };

export const TableFiltersContainer = styled.div<Props>`
  background-color: ${getCssVar('table.filters.bg')};
  height: 64px;
  padding: 12px;
  position: sticky;
  top: 0;
  z-index: 999;

  @media (max-width: ${breakpoints.md}) {
    height: 80px;
  }
`;

export const TableFiltersSelect = styled(TableFiltersSelectStyled)`
  width: ${vendorSeverityFilterWidth}px;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;
