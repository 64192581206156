import { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTheme } from 'styled-components';
import { getOptions } from './helpers';
import { GradientPieContainer } from '../styled';
import { EChartsEventParams } from '../interfaces';
import { Annotations } from './types';

type Props = {
  value: number | null;
  height?: string;
  fontSize: number;
  isLoading?: boolean;
  scoreAnnotations?: Annotations;
  onEvents?: Record<string, (params?: EChartsEventParams) => void>;
  titleFontSize?: number;
  fontFamily?: string;
};

export const GradientPie = ({
  value,
  height,
  fontSize,
  isLoading,
  onEvents,
  scoreAnnotations,
  titleFontSize,
  fontFamily,
}: Props) => {
  const theme = useTheme();

  const option = useMemo(() => {
    if (isLoading) {
      return {};
    }

    return getOptions(
      isLoading ? null : value,
      fontSize,
      theme,
      Boolean(onEvents),
      scoreAnnotations,
      titleFontSize,
      fontFamily,
    );
  }, [fontFamily, fontSize, isLoading, onEvents, scoreAnnotations, theme, titleFontSize, value]);

  return (
    <GradientPieContainer>
      <ReactECharts style={{ height }} opts={{ renderer: 'canvas' }} option={option} notMerge />
    </GradientPieContainer>
  );
};
