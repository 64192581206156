import { colors, fontWeights, spacing } from 'styles/theme';
import { leftMenu as leftMenuNorm, login as loginNorm } from './Norm';
import * as buttonsNorm from './Norm/buttons';
import * as buttonsDarkNorm from './DarkNorm/buttons';
import { leftMenu as leftMenuDark } from './DarkNorm';

import { leftMenu as leftMenuNasstar, login as loginNasstar } from './Nasstar';
import * as buttonsNasstar from './Nasstar/buttons';
import { severity as severityNorm } from './Norm/severity';
import { severityDarkNorm } from './DarkNorm/severity';
import { severity as severityNasstar } from './Nasstar/severity';
import { mainFontFamily as nasstarFontFamily } from './Nasstar/fontsNasstar';
import { mainFontFamily as normFontFamily } from './Norm/fontsNorm';
import { process as processNasstar } from './Nasstar/process';
import { process as processNorm } from './Norm/process';
import { process as processDark } from './DarkNorm/process';

import { pieChart as pieChartNasstar } from './Nasstar/pieChart';
import { pieChart as pieChartNorm } from './Norm/pieChart';
import { pieChart as pieChartDarkNorm } from './DarkNorm/pieChart';
import { nasstarScoreGradient } from './Nasstar/scoreGradient';
import { normScoreGradient } from './Norm/scoreGradient';
import { darkNormScoreGradient } from './DarkNorm/scoreGradient';
import { activeEDRNorm } from './Norm/activeEDR';
import { activeEDRDarkNorm } from './DarkNorm/activeEDR';

import { tableNorm } from './Norm/table';
import { tableDarkNorm } from './DarkNorm/table';
import { tableNasstar } from './Nasstar/table';

import { baseNorm } from './Norm/base';
import { baseDarkNorm } from './DarkNorm/base';
import { baseNasstar } from './Nasstar/base';

import { inputsNorm } from './Norm/inputs';
import { inputsNormDark } from './DarkNorm/inputs';
import { inputsNasstar } from './Nasstar/inputs';

export const nasstar = {
  theme: 'nasstar',
  fontFamily: nasstarFontFamily,
  lessFontFamily: `-apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,

  baseColors: baseNasstar,

  bodyBackground: '#eeecec',
  bodyBackgroundMobile: '#eeecec',

  primaryColor: baseNasstar.blue,
  secondaryColor: baseNasstar.pink,
  successColor: baseNasstar.green,
  errorColor: baseNasstar.red,
  errorSecondaryColor: baseNasstar.deepRed,
  inactiveColor: colors.gray[150],
  activeColor: baseNasstar.blue,

  // codeFamily: `'Segoe UI', Consolas, 'Liberation Mono', Menlo, Courier, monospace;`,
  textColor: colors.black,
  headingColor: colors.black,

  labelFontWeight: fontWeights.semibold,

  iconCheck: colors.green[320],
  iconColor: colors.gray[150],
  iconLock: colors.gray[150],
  iconDocument: '#C2BAAA',
  iconClipboard: colors.gray[150],
  iconClipboardHover: baseNasstar.blue,

  layout: {
    body: {
      bg: 'transparent',
    },
    header: {
      bg: colors.white,
      height: '80px',
    },
    title: {
      fontWeight: fontWeights.bold,
      fontSize: '26px',
      color: colors.black,
    },
  },

  modal: {
    closeIcon: '#C2BAAA',
    borderColor: '#f0f0f0',
    bg: colors.white,
    overlayColor: 'rgba(35, 31, 32, 0.6)',
  },

  charts: {
    emptyChart: '#EEEDE8',
    tooltip: {
      color: '#292933',
      bg: colors.white,
    },
    axisLabel: '#6E7079',
    culture: {
      lineColor: colors.blue[50],
      areaStyle: {
        internalColor: colors.white,
        externalColor: colors.blue[50],
      },
    },
    lineColor: baseNasstar.blue,
    legend: {
      fontSize: '15px',
    },
  },
  card: {
    bg: colors.white,
    border: baseNasstar.borderColor,
    boxShadow: colors.blackAlpha[100],
    footerBorderTopColor: colors.gray[70],
    headBorderColor: colors.gray[100],
    headAccessMessageBG: colors.gray[100],
    headUnderTitleColor: colors.blue[900],

    header: {
      toggleIconColor: colors.black,
    },

    gradientPieContainer: {
      bg: colors.white,
      border: colors.gray[200],
      boxShadow: colors.blackAlpha[100],
      iconDropShadow: colors.black,
      iconDropShadowSecond: colors.blackAlpha[200],
    },

    lightTextContainer: { color: colors.blue[900] },
  },
  alert: {
    error: {
      bg: colors.red[100],
      borderColor: colors.red[100],
      color: colors.red[700],
    },

    banner: {
      bg: colors.gray[810],
      color: colors.white,
      iconColor: colors.red[400],
      linkColor: colors.yellow[460],
    },
  },

  blur: {
    bg: colors.blackAlpha[600],
  },
  divider: {
    color: colors.gray[80],
  },

  notifications: {
    drawer: {
      bg: '#eeede8',
      closeIcon: '#00000073',
      divider: colors.gray[70],
      button: {
        color: '#2a26f9',
      },
    },
    toggleIcon: {
      bg: colors.gray[100],
      bgMobile: colors.gray[300],
    },
    pulsingIcon: {
      boxShadow: `0 0 0 0 rgba(232, 76, 61, 0.7)`,
      bg: colors.red[700],
    },
    typeIconColor: baseNasstar.pink,
  },

  overlay: {
    open: 'rgba(0, 0, 0, 0.5)',
    close: 'rgba(0, 0, 0, 0)',
  },
  tagAssets: {
    bg: '#EEEDE8',
    color: baseNasstar.blue,
    borderColor: baseNasstar.blue,
    iconColor: baseNasstar.blue,
    limitMessage: {
      bg: '#f9e0b0',
      color: '#72551c',
      borderColor: '#f9e0b0',
      iconColor: '#72551c',
    },
  },

  spinContainer: {
    bg: colors.whiteAlpha[600],
  },

  link: {
    fontWeight: fontWeights.semibold,
    disabledColor: colors.brown[100],
    bg: '#babaab',
    color: '#2a26f9',
  },

  scoreGradient: nasstarScoreGradient,

  status: {
    textTransform: 'uppercase',
    fontWeight: fontWeights.semibold,

    bg: colors.gray[150],
    color: colors.white,

    success: {
      bg: colors.green[700],
      color: colors.white,
    },
    warning: {
      bg: colors.yellow[460],
      color: colors.white,
    },
    error: {
      bg: colors.red[450],
      color: colors.white,
    },
    critical: {
      bg: colors.red[650],
      color: colors.white,
    },
    processing: {
      bg: colors.blue[100],
      color: colors.white,
    },
    closed: {
      bg: colors.gray[150],
      color: colors.black,
    },
  },

  tooltip: {
    hover: colors.pink[500],
    bg: colors.black,
    color: colors.gray[150],
    size: '24px',
  },

  severity: severityNasstar,
  activeEDR: activeEDRNorm,

  // Inputs (inputs, select, switch, checkBox, radio, wysiwyg, datePicker)
  ...inputsNasstar,

  tabsNavigation: {
    activeTabBg: colors.white,
    activeTabColor: '#e03b92',
    inactiveTabColor: colors.black,
    inactiveTabHoverColor: colors.brown[400],
    boxShadow: '0px 0px 12px 1px rgba(0, 0, 0, 0.1)',
    borderBottom: '2px solid #f0f0f0',
    dropdown: {
      bg: inputsNasstar.select.cascader.backgroundColor,
      color: colors.black,
      bgHover: inputsNasstar.select.cascader.item.hoverBackgroundColor,
    },
  },
  vulnerability: {
    status: {
      confirmed: {
        backgroundColor: colors.black,
        textColor: colors.white,
        borderColor: colors.black,
      },
      potential: {
        backgroundColor: colors.yellow[560],
        textColor: colors.black,
        borderColor: colors.yellow[560],
      },
      suppressed: {
        backgroundColor: colors.brown[200],
        textColor: colors.white,
        borderColor: colors.brown[200],
      },
      unsuppressed: {
        backgroundColor: colors.white,
        textColor: colors.black,
        borderColor: colors.brown[200],
      },
    },
  },

  table: tableNasstar,

  pagination: {
    paddingBottom: '2px',
    iconColor: '#EEEDE8',

    page: {
      bg: colors.white,
      borderColor: '#EEEDE8',

      hover: {
        bg: colors.gray[100],
        borderColor: colors.gray[200],
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.15)`,
      },
      disabled: {
        bg: colors.white,
        borderColor: '#eeede8',
        color: '#00000040',
      },
      active: {
        bg: colors.pink[500],
        borderColor: colors.pink[500],
        color: colors.white,
      },
    },
  },

  button: buttonsNasstar.button,
  buttonSecondary: buttonsNasstar.buttonSecondary,
  buttonDisabled: buttonsNasstar.buttonDisabled,
  buttonDanger: buttonsNasstar.buttonDanger,
  buttonSimple: buttonsNasstar.buttonSimple,

  menu: {
    bg: colors.black,
    item: {
      color: colors.white,
      selected: {
        color: '#e03b92',
        bg: colors.gray[810],
      },
      hover: {
        bg: colors.blue[910],
        color: '#e03b92',
      },
      icon: {
        color: '#A6A6A6',
        hover: '#e03b92',
        selected: '#e03b92',
      },
    },
    adminTitle: {
      desktop: colors.gray[410],
      mobile: '#a69e9980',
    },
    submenu: {
      bg: colors.blue[910],
      item: {
        selectedIndicator: {
          color: '#e03b92',
        },
      },
      toggleIcon: {
        color: colors.gray[410],
      },
    },
    boxShadow: 'none',
    tooltip: {
      color: colors.white,
    },
  },

  snackBar: {
    bg: colors.white,
    color: colors.black,
  },

  login: loginNasstar,

  leftMenu: leftMenuNasstar,

  process: processNasstar,

  pieChart: pieChartNasstar,

  notFoundPage: {
    pageBackground: colors.black,
    backgroundImage: '',
    background: colors.black,
    textColor: colors.white,
    backLinkColor: colors.yellow[460],
    borderColor: colors.gray[120],
    fontWeight: fontWeights.semibold,
    fontSize: spacing['3.6'],
    lineHeight: spacing[5],
  },

  stressScore: {
    digits: {
      fontSize: '26px',
      fontWeight: fontWeights.bold,
    },
  },

  scrollbar: {
    bg: colors.gray[100],
    color: colors.black,
    height: '8px',
  },

  stepper: {
    borderColor: colors.gray[120],
    backgroundColor: colors.white,
    activeBorderColor: '#e03b92',
    activeBackgroundColor: '#f0f0f0',
  },

  alarmContent: {
    backgroundColor: colors.gray[100],
    borderColor: colors.gray[300],
    scrollbar: {
      bg: colors.gray[100],
      color: colors.black,
      height: '8px',
    },
  },
};

export const norm = {
  theme: 'norm',
  fontFamily: normFontFamily,
  lessFontFamily: `-apple-system, Strawford, Roboto, Arial, 'Noto Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,

  baseColors: baseNorm,

  bodyBackground: baseNorm.bodyBg,
  bodyBackgroundMobile: '#EFF6FD',

  primaryColor: baseNorm.black,
  secondaryColor: baseNorm.blue,
  errorColor: baseNorm.red,
  errorSecondaryColor: baseNorm.deepRed,
  successColor: baseNorm.green,
  inactiveColor: colors.gray[110],
  activeColor: baseNorm.blue,

  textColor: baseNorm.black,
  // codeFamily: `'Helvetica Now Display', Consolas, 'Liberation Mono', Menlo, Courier, monospace;`,

  labelFontWeight: fontWeights.medium,

  iconCheck: baseNorm.green,
  iconColor: baseNorm.grey,
  iconLock: colors.gray[150],
  iconDocument: '#9AB1FF',
  iconClipboard: baseNorm.blue,
  iconClipboardHover: '#003EDA',

  layout: {
    header: {
      bg: baseNorm.primaryBg,
      height: '80px',
    },
    body: {
      bg: 'transparent',
    },
    title: {
      fontWeight: fontWeights.black,
      fontSize: '24px',
      color: baseNorm.black,
    },
  },

  modal: {
    closeIcon: baseNorm.grey,
    borderColor: baseNorm.borderColor,
    bg: colors.white,
    overlayColor: 'rgba(35, 31, 32, 0.6)',
  },

  card: {
    border: baseNorm.borderColor,
    bg: baseNorm.primaryBg,
    boxShadow: 'rgba(0, 0, 0, 0.07)',
    footerBorderTopColor: baseNorm.borderColor,
    headBorderColor: baseNorm.borderColor,
    headAccessMessageBG: colors.gray[20],
    headUnderTitleColor: baseNorm.black,

    header: {
      toggleIconColor: baseNorm.blue,
    },

    gradientPieContainer: {
      bg: colors.white,
      border: colors.gray[110],
      boxShadow: 'rgba(0, 0, 0, 0.07)',
      iconDropShadow: colors.black,
      iconDropShadowSecond: colors.blackAlpha[200],
    },

    lightTextContainer: { color: baseNorm.grey },
  },

  // #region Aside
  menu: {
    bg: '#EFF6FD',
    item: {
      color: '#4c4a4c',
      selected: {
        color: '#4c4a4c',
        bg: '#D8E7FB',
      },
      hover: {
        color: '#4c4a4c',
        bg: '#D8E7FB',
      },
      icon: {
        color: '#757578',
        hover: 'inherit',
        selected: 'inherit',
      },
    },
    adminTitle: {
      desktop: '#4c4a4c',
      mobile: '#4c4a4c',
    },
    submenu: {
      bg: 'inherit',
      item: {
        selectedIndicator: {
          color: '#2C25DD',
        },
      },
      toggleIcon: {
        color: baseNorm.grey,
      },
    },
    boxShadow: 'none',
    tooltip: {
      color: colors.white,
    },
  },

  leftMenu: leftMenuNorm,
  // #endregion

  link: {
    fontWeight: fontWeights.bold,
    disabledColor: buttonsNorm.buttonDisabled.color,
    bg: baseNorm.grey,
    color: baseNorm.blue,
  },

  tooltip: {
    hover: '#4c4a4c',
    bg: '#2E2A2B',
    color: '#BFBDBD',
    size: '24px',
  },

  button: buttonsNorm.button,
  buttonSecondary: buttonsNorm.buttonSecondary,
  buttonDisabled: buttonsNorm.buttonDisabled,
  buttonDanger: buttonsNorm.buttonDanger,
  buttonSimple: buttonsNorm.buttonSimple,

  overlay: {
    open: 'rgba(0, 0, 0, 0.5)',
    close: 'rgba(0, 0, 0, 0)',
  },

  spinContainer: {
    bg: colors.whiteAlpha[600],
  },

  charts: {
    emptyChart: '#f9fbff',
    tooltip: {
      color: baseNorm.black,
      bg: colors.white,
    },
    axisLabel: '#707070',
    // people page
    culture: {
      lineColor: colors.blue[50],
      areaStyle: {
        internalColor: colors.white,
        externalColor: colors.blue[50],
      },
    },
    lineColor: baseNorm.blue,
    legend: {
      fontSize: '15px',
    },
  },

  // #region Dashboard
  process: processNorm,
  stressScore: {
    digits: {
      fontSize: '24px',
      fontWeight: fontWeights.black,
    },
  },
  // #endregion

  pieChart: pieChartNorm,
  severity: severityNorm,
  scoreGradient: normScoreGradient,
  activeEDR: activeEDRNorm,

  // Inputs (inputs, select, switch, checkBox, radio, wysiwyg, datePicker)
  ...inputsNorm,

  tabsNavigation: {
    activeTabBg: colors.white,
    activeTabColor: baseNorm.blue,
    inactiveTabColor: colors.black,
    inactiveTabHoverColor: baseNorm.blue,
    boxShadow: '0px 0px 12px 1px rgba(0, 0, 0, 0.1)',
    borderBottom: `1px solid ${baseNorm.borderColor}`,
    dropdown: {
      bg: inputsNorm.inputs.backgroundColor,
      color: colors.black,
      bgHover: inputsNorm.select.cascader.item.hoverBackgroundColor,
    },
  },

  snackBar: {
    bg: colors.white,
    color: baseNorm.black,
  },

  notifications: {
    drawer: {
      bg: colors.white,
      divider: colors.gray[20],
      closeIcon: baseNorm.grey,
      button: {
        color: baseNorm.blue,
      },
    },
    toggleIcon: {
      bg: colors.gray[100],
      bgMobile: colors.gray[300],
    },
    pulsingIcon: {
      boxShadow: `0 0 0 0 rgba(179, 0, 0, 0.7)`,
      bg: baseNorm.deepRed,
    },
    typeIconColor: baseNorm.blue,
  },

  scrollbar: {
    bg: colors.gray[100],
    color: baseNorm.grey,
    height: '8px',
  },

  login: loginNorm,

  divider: {
    color: colors.gray[20],
  },
  blur: {
    bg: colors.blackAlpha[600],
  },

  // CMDB page tags
  tagAssets: {
    bg: colors.gray[20],
    color: baseNorm.blue,
    borderColor: baseNorm.borderColor,
    iconColor: baseNorm.blue,
    limitMessage: {
      bg: '#fcefd4',
      color: '#72551C',
      borderColor: '#ede1c6',
      iconColor: '#f1ae28',
    },
  },

  status: {
    textTransform: 'none',
    fontWeight: fontWeights.bold,

    bg: baseNorm.grey,
    color: colors.black,

    success: {
      bg: baseNorm.green,
      color: colors.white,
    },
    warning: {
      bg: baseNorm.amber,
      color: colors.black,
    },
    error: {
      bg: baseNorm.red,
      color: colors.black,
    },
    critical: {
      bg: baseNorm.deepRed,
      color: colors.white,
    },
    processing: {
      bg: '#4EA3F1',
      color: colors.white,
    },
    closed: {
      bg: colors.gray[150],
      color: colors.black,
    },
  },

  alert: {
    error: {
      bg: baseNorm.red,
      color: baseNorm.deepRed,
      borderColor: baseNorm.deepRed,
    },

    banner: {
      bg: '#2E2A2B',
      color: colors.white,
      iconColor: baseNorm.red,
      linkColor: colors.white,
    },
  },

  table: tableNorm,

  pagination: {
    paddingBottom: 0,
    iconColor: baseNorm.grey,

    page: {
      bg: colors.white,
      borderColor: colors.gray[90],
      hover: {
        bg: colors.gray[20],
        borderColor: baseNorm.borderColor,
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.15)`,
      },
      disabled: {
        bg: 'rgba(35, 31, 32, 0.04)',
        borderColor: colors.gray[90],
        color: baseNorm.grey,
      },
      active: {
        bg: baseNorm.blue,
        borderColor: baseNorm.blue,
        color: colors.white,
      },
    },
  },

  vulnerability: {
    status: {
      confirmed: {
        backgroundColor: colors.black,
        textColor: colors.white,
        borderColor: colors.black,
      },
      potential: {
        backgroundColor: baseNorm.grey,
        textColor: colors.black,
        borderColor: baseNorm.grey,
      },
      suppressed: {
        backgroundColor: '#E5F0FD',
        textColor: colors.black,
        borderColor: '#E5F0FD',
      },
      unsuppressed: {
        backgroundColor: colors.white,
        textColor: colors.black,
        borderColor: baseNorm.borderColor,
      },
    },
  },

  notFoundPage: {
    pageBackground: colors.white,
    backgroundImage: '/assets/images/norm-not-found-page-rebranding.png',
    background: '#FFFFFF66',
    textColor: baseNorm.black,
    backLinkColor: baseNorm.blue,
    borderColor: '#FFFFFF66',
    fontWeight: fontWeights.normal,
    fontSize: spacing[4],
    lineHeight: spacing[6],
  },

  stepper: {
    borderColor: baseNorm.borderColor,
    backgroundColor: '#F0F0F0',
    activeBorderColor: baseNorm.blue,
    activeBackgroundColor: '#DCE6FF',
  },

  alarmContent: {
    backgroundColor: colors.gray[100],
    borderColor: colors.gray[300],
    scrollbar: {
      bg: colors.gray[100],
      color: baseNorm.grey,
      height: '8px',
    },
  },
};

export const darkNorm = {
  theme: 'darkNorm',
  fontFamily: normFontFamily,
  lessFontFamily: `-apple-system, Strawford, Roboto, Arial, 'Noto Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
  `,

  baseColors: baseDarkNorm,

  bodyBackground: baseDarkNorm.bodyBg,
  bodyBackgroundMobile: baseDarkNorm.bodyBg,

  primaryColor: colors.white,
  secondaryColor: baseDarkNorm.blue,
  errorColor: baseDarkNorm.red,
  errorSecondaryColor: baseDarkNorm.deepRed,
  successColor: baseDarkNorm.green,
  inactiveColor: baseDarkNorm.borderColor,
  activeColor: baseDarkNorm.lightBlue,

  textColor: colors.white,
  // codeFamily: `'Helvetica Now Display', Consolas, 'Liberation Mono', Menlo, Courier, monospace;`,

  labelFontWeight: fontWeights.medium,

  iconCheck: baseDarkNorm.green,
  iconColor: baseDarkNorm.grey,
  iconLock: '#5B5959',
  iconDocument: '#9AB1FF',
  iconClipboard: baseDarkNorm.lightBlue,
  iconClipboardHover: '#9AB1FF',

  layout: {
    header: {
      bg: baseDarkNorm.primaryBg,
      height: '80px',
    },
    body: {
      bg: 'transparent',
    },
    title: {
      fontWeight: fontWeights.black,
      fontSize: '24px',
      color: colors.white,
    },
  },

  modal: {
    closeIcon: baseDarkNorm.grey,
    borderColor: baseDarkNorm.borderColor,
    bg: baseDarkNorm.primaryBg,
    overlayColor: 'rgba(22, 23, 22, 0.8)',
  },

  card: {
    bg: baseDarkNorm.primaryBg,
    border: baseDarkNorm.borderColor,
    boxShadow: colors.blackAlpha[100],
    footerBorderTopColor: baseDarkNorm.divider,
    headBorderColor: baseDarkNorm.divider,
    headAccessMessageBG: baseDarkNorm.black,
    headUnderTitleColor: colors.white,

    header: {
      toggleIconColor: baseDarkNorm.lightBlue,
    },

    gradientPieContainer: {
      bg: baseDarkNorm.primaryBg,
      border: baseDarkNorm.borderColor,
      boxShadow: colors.blackAlpha[100],
      iconDropShadow: colors.black,
      iconDropShadowSecond: colors.blackAlpha[200],
    },

    lightTextContainer: { color: baseDarkNorm.grey },
  },

  // #region Aside
  menu: {
    bg: baseDarkNorm.primaryBg,
    item: {
      color: '#D7D5D5',
      selected: {
        color: '#D7D7CF',
        bg: baseDarkNorm.black,
      },
      hover: {
        color: '#D7D7CF',
        bg: baseDarkNorm.black,
      },
      icon: {
        color: '#8A8A8A',
        hover: '#8A8A8A',
        selected: '#8A8A8A',
      },
    },
    adminTitle: {
      desktop: '#D7D7CF',
      mobile: '#D7D7CF',
    },
    submenu: {
      bg: '#383637',
      item: {
        selectedIndicator: {
          color: baseDarkNorm.blue,
        },
      },
      toggleIcon: {
        color: '#5B5959',
      },
    },
    boxShadow: 'none',
    tooltip: {
      color: colors.white,
    },
  },

  leftMenu: leftMenuDark,
  // #endregion

  link: {
    fontWeight: fontWeights.bold,
    disabledColor: buttonsDarkNorm.buttonDisabled.color,
    bg: baseDarkNorm.grey,
    color: baseDarkNorm.lightBlue,
  },

  tooltip: {
    hover: colors.white,
    bg: '#4F4D4D',
    color: '#5B5959',
    size: '24px',
  },

  button: buttonsDarkNorm.button,
  buttonSecondary: buttonsDarkNorm.buttonSecondary,
  buttonDisabled: buttonsDarkNorm.buttonDisabled,
  buttonDanger: buttonsDarkNorm.buttonDanger,
  buttonSimple: buttonsDarkNorm.buttonSimple,

  overlay: {
    open: 'rgba(0, 0, 0, 0.5)',
    close: 'rgba(0, 0, 0, 0)',
  },

  spinContainer: {
    bg: 'rgba(35, 31, 32, 0.7)',
  },

  charts: {
    emptyChart: baseDarkNorm.black,
    tooltip: {
      color: colors.white,
      bg: '#323332',
    },
    axisLabel: colors.white,
    // people page
    culture: {
      lineColor: baseDarkNorm.grey,
      areaStyle: {
        internalColor: colors.white,
        externalColor: baseDarkNorm.black,
      },
    },
    lineColor: baseDarkNorm.blue,
    legend: {
      fontSize: '15px',
    },
  },

  status: {
    textTransform: 'none',
    fontWeight: fontWeights.bold,

    bg: baseDarkNorm.grey,
    color: colors.black,

    success: {
      bg: baseDarkNorm.green,
      color: colors.white,
    },
    warning: {
      bg: baseDarkNorm.amber,
      color: colors.black,
    },
    error: {
      bg: baseDarkNorm.red,
      color: colors.black,
    },
    critical: {
      bg: baseDarkNorm.deepRed,
      color: colors.white,
    },
    processing: {
      bg: baseDarkNorm.lightBlue,
      color: colors.white,
    },
    closed: {
      bg: colors.gray[150],
      color: colors.black,
    },
  },

  tabsNavigation: {
    activeTabBg: colors.black,
    activeTabColor: baseDarkNorm.lightBlue,
    inactiveTabColor: colors.white,
    inactiveTabHoverColor: baseDarkNorm.lightBlue,
    boxShadow: 'none',
    borderBottom: `1px solid ${baseDarkNorm.divider}`,
    dropdown: {
      bg: inputsNormDark.inputs.backgroundColor,
      color: colors.white,
      bgHover: inputsNormDark.select.cascader.item.hoverBackgroundColor,
    },
  },

  // Inputs (inputs, select, switch, checkBox, radio, wysiwyg, datePicker)
  ...inputsNormDark,

  snackBar: {
    bg: '#282627',
    color: colors.white,
  },

  notifications: {
    drawer: {
      bg: baseDarkNorm.secondaryBg,
      divider: baseDarkNorm.divider,
      closeIcon: baseDarkNorm.grey,
      button: {
        color: baseDarkNorm.lightBlue,
      },
    },
    toggleIcon: {
      bg: '#323332',
      bgMobile: '#323332',
    },
    pulsingIcon: {
      boxShadow: `0 0 0 0 rgba(179, 0, 0, 0.7)`,
      bg: baseDarkNorm.deepRed,
    },
    typeIconColor: baseDarkNorm.lightBlue,
  },

  tagAssets: {
    bg: 'transparent',
    color: baseDarkNorm.lightBlue,
    borderColor: baseDarkNorm.borderColor,
    iconColor: baseDarkNorm.lightBlue,
    limitMessage: {
      bg: '#4f3422',
      color: '#bfa167',
      borderColor: '#5a4030',
      iconColor: '#9b4e0c',
    },
  },

  // #region Dashboard
  process: processDark,
  stressScore: {
    digits: {
      fontSize: '24px',
      fontWeight: fontWeights.black,
    },
  },
  // #endregion

  pieChart: pieChartDarkNorm,
  scoreGradient: darkNormScoreGradient,
  severity: severityDarkNorm,
  activeEDR: activeEDRDarkNorm,

  table: tableDarkNorm,

  pagination: {
    paddingBottom: 0,
    iconColor: '#625D5A',

    page: {
      bg: '#383536',
      borderColor: baseDarkNorm.borderColor,
      hover: {
        bg: baseDarkNorm.black,
        borderColor: '#494b42',
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.15)`,
      },
      disabled: {
        bg: 'rgba(35, 31, 32, 0.1)',
        borderColor: '#383637',
        color: '#383637',
      },
      active: {
        bg: baseDarkNorm.blue,
        borderColor: baseDarkNorm.blue,
        color: colors.white,
      },
    },
  },

  login: loginNorm,

  blur: {
    bg: colors.blackAlpha[600],
  },

  divider: {
    color: baseDarkNorm.divider,
  },

  scrollbar: {
    bg: '#302c2d',
    color: baseDarkNorm.blue,
    height: '8px',
  },

  vulnerability: {
    status: {
      confirmed: {
        backgroundColor: '#4F4D4D',
        textColor: colors.white,
        borderColor: '#4F4D4D',
      },
      potential: {
        backgroundColor: '#8A8A8A',
        textColor: colors.black,
        borderColor: '#8A8A8A',
      },
      suppressed: {
        backgroundColor: '#E5F0FD',
        textColor: colors.black,
        borderColor: '#E5F0FD',
      },
      unsuppressed: {
        backgroundColor: colors.black,
        textColor: colors.white,
        borderColor: baseDarkNorm.borderColor,
      },
    },
  },

  alert: {
    error: {
      bg: baseDarkNorm.red,
      color: baseDarkNorm.deepRed,
      borderColor: baseDarkNorm.deepRed,
    },

    banner: {
      bg: '#4F4D4D',
      color: colors.white,
      iconColor: baseDarkNorm.red,
      linkColor: colors.white,
    },
  },

  notFoundPage: {
    pageBackground: colors.white,
    backgroundImage: '/assets/images/norm-not-found-page-rebranding.png',
    background: '#FFFFFF66',
    textColor: baseNorm.black,
    backLinkColor: baseNorm.blue,
    borderColor: '#FFFFFF66',
    fontWeight: fontWeights.normal,
    fontSize: spacing[4],
    lineHeight: spacing[6],
  },

  stepper: {
    borderColor: baseDarkNorm.primaryBg,
    backgroundColor: baseDarkNorm.secondaryBg,
    activeBorderColor: baseDarkNorm.primaryBg,
    activeBackgroundColor: baseNorm.blue,
  },

  alarmContent: {
    backgroundColor: '#383637',
    borderColor: '#4f4d4d',
    scrollbar: {
      bg: '#302c2d',
      color: baseDarkNorm.blue,
      height: '8px',
    },
  },
};

type CSSVars<T, K extends keyof T = keyof T> = K extends string
  ? T[K] extends infer InnerObject
    ?
        | `${K}`
        | (InnerObject extends Record<string, unknown> ? `${K}.${CSSVars<InnerObject>}` : never)
    : never
  : never;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type ThemePropertyRecord = Record<string, any>;

export type CSSVarsType = CSSVars<typeof nasstar>;

export const getCssVar = (varName: CSSVars<typeof nasstar>, wrapWithVarFunction = true) => {
  if (!wrapWithVarFunction) {
    return `${varName.replaceAll('.', '-')}`;
  }

  return `var(--norm-${varName.replaceAll('.', '-')})`;
};

const cssVarsObjToString = (map: Record<string, string>) =>
  `${Object.entries(map)
    .map(([varName, varValue]) => `${varName}: ${varValue}`)
    .join(';')};`;

export const createCSSVarsFromObject = (theme: typeof norm | typeof darkNorm | typeof nasstar) => {
  const processObject = (obj: ThemePropertyRecord, path: string) => {
    return Object.entries(obj).reduce<ThemePropertyRecord>((acc, [key, value]) => {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        return {
          ...acc,
          ...processObject(value, `${path}-${key}`),
        };
      }

      // eslint-disable-next-line no-param-reassign
      acc[`${path}-${key}`] = value;

      return acc;
    }, {});
  };

  return processObject(theme, '--norm');
};

export const createNormCSSVarsFromObject = () => cssVarsObjToString(createCSSVarsFromObject(norm));

export const createDarkCSSVarsFromObject = () =>
  cssVarsObjToString(createCSSVarsFromObject(darkNorm));

export const createNasstarCSSVarsFromObject = () =>
  cssVarsObjToString(createCSSVarsFromObject(nasstar));
