import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FilterType, OptionType } from 'shared/Table/types';
import { ValueType } from 'rc-cascader/lib/Cascader';
import { Filter } from 'services/api';
import { Icon } from 'shared/Icon';
import { icons } from 'styles/theme';
import classNames from 'classnames';
import { ConfigContext } from 'antd/lib/config-provider';
import { StyledLabel } from '../TableFiltersSelect/styled';
import { FilterGroupContainer, FilterGroupSelect } from './styled';

export type OnSelectChangeHandler = (
  field: string,
  value: string | string[],
  type: Filter.type,
  params?: Record<string, unknown>,
) => void;

export type TableFilterGroupSelectProps = FilterType & {
  getValue: (name: string) => undefined | string | string[];
  onSelectChange: OnSelectChangeHandler;
  options?: OptionType[];
  maxTagCount?: number;
  loading?: boolean; // Add this line to include the loading property
  placeholder?: string;
  multipleGroup?: boolean;
  modifyValuesBeforeOnSelect?: (
    onSelect: OnSelectChangeHandler,
    values: CascaderOptionsType,
  ) => string[];
  modifyDefaultValues?: (filter: null | Filter) => CascaderOptionsType;
  getSelectField: (name: string) => null | Filter;
};

export type CascaderOptionsType = [string?, string?][];

const getAllSelectedChildren = (options: OptionType[], key: string): CascaderOptionsType => {
  const items = (
    options.find((option) => option.value === key)?.children || []
  ).map(({ value }) => [key, value]) as CascaderOptionsType;

  return items.length === 0 ? [[key]] : items;
};

const getValue = (
  values: CascaderOptionsType,
  multipleGroup: boolean,
  options: OptionType[],
): CascaderOptionsType => {
  if (values.length > 0) {
    const [lastSelectedKey, lastSelectedValue] = values[values.length - 1] as [string, string?];

    if (!lastSelectedValue) {
      return getAllSelectedChildren(options, lastSelectedKey);
    }

    if (!multipleGroup) {
      return (values as CascaderOptionsType).filter(([group]) => group === lastSelectedKey);
    }
  }

  return values;
};

const removeIcon = (
  <svg
    viewBox='64 64 896 896'
    focusable='false'
    data-icon='close'
    width='1em'
    height='1em'
    fill='currentColor'
    aria-hidden='true'
  >
    <path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z' />
  </svg>
);

const MAX_TAG_COUNT = 2;

export const TableFilterGroupSelect = ({
  label,
  field,
  onSelectChange,
  loading,
  options = [] as OptionType[],
  placeholder = 'All',
  maxTagCount = MAX_TAG_COUNT,
  multipleGroup = false,
  filterType,
  modifyValuesBeforeOnSelect,
  modifyDefaultValues,
  getSelectField,
}: TableFilterGroupSelectProps) => {
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<CascaderOptionsType>([]);

  const { getPrefixCls } = React.useContext(ConfigContext);

  const prefixCls = getPrefixCls('select');
  const cascaderPrefixCls = getPrefixCls('cascader');

  useEffect(() => {
    const filterField = getSelectField(field);

    setValue(modifyDefaultValues ? modifyDefaultValues(filterField) : [] || []);
  }, [field, getSelectField, modifyDefaultValues]);

  const handleChange = useCallback(
    (values) => {
      const value = getValue(values, multipleGroup, options);

      setValue(value);

      if (modifyValuesBeforeOnSelect) {
        modifyValuesBeforeOnSelect(onSelectChange, value);

        return;
      }

      onSelectChange(field, value.length > 0 ? ((value as unknown) as string[]) : '', filterType);
    },
    [field, filterType, modifyValuesBeforeOnSelect, multipleGroup, onSelectChange, options],
  );

  const onClear = useCallback(() => {
    setValue([]);

    onSelectChange(field, '', filterType);
  }, [field, filterType, onSelectChange]);

  return (
    <FilterGroupContainer ref={selectContainerRef}>
      <StyledLabel htmlFor={`id-${label}`}>{label}</StyledLabel>
      <FilterGroupSelect
        checkable
        id={`id-${label}`}
        options={options}
        maxTagPlaceholder={(omittedValues) => `+${omittedValues.length}`}
        prefixCls={prefixCls}
        className={classNames({ cascaderPrefixCls: true, checked: value.length !== 0 })}
        placeholder={placeholder}
        // @ts-ignore
        value={(value as unknown) as ValueType | undefined}
        onChange={handleChange}
        expandTrigger='click' // Adjust expand trigger based on selection
        dropdownClassName={`${cascaderPrefixCls}-dropdown`}
        dropdownPrefixCls={cascaderPrefixCls}
        expandIcon={
          <Icon component={icons.arrow_angel_left} style={{ justifyContent: 'center' }} />
        }
        removeIcon={removeIcon}
        getPopupContainer={() => selectContainerRef.current as HTMLElement}
        maxTagCount={maxTagCount}
        showSearch
        suffixIcon={
          value.length === 0 ? (
            <Icon className='ant-select-suffix ant-select-icon' component={icons.arrow_drop_down} />
          ) : (
            <Icon
              className='clear-icon ant-select-icon'
              component={icons.select_clear_new}
              onClick={onClear}
            />
          )
        }
        allowClear={false}
        loading={loading}
      />
    </FilterGroupContainer>
  );
};
