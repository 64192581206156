import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { authApi, useAuth } from 'store';
import { ValidateTwoFARequest } from 'services/api';
import { Box } from 'shared';
import { Routes, TwoFASteps } from 'services/entities';
import { useTheme } from 'styled-components';
import { FirstStep, SecondStep, TwoFAValidation } from './steps';
import { checkLoginTokenExpired } from '../helpers';
import { TfaStepContent } from './styled';

export const TwoFASetup = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { token, twofa, isPending, requestError, isAuthorized } = useAuth();

  const submitCode = (data: ValidateTwoFARequest) => {
    if (!token || checkLoginTokenExpired(token, dispatch)) {
      return;
    }

    dispatch(authApi.twofaValidate(data));
  };

  useEffect(() => {
    if (!token || checkLoginTokenExpired(token, dispatch)) {
      return;
    }

    dispatch(authApi.twofaData({ token }));
  }, [dispatch, token]);

  if (twofa.step !== TwoFASteps.SETUP) {
    return <Redirect to={Routes.ROOT} />;
  }

  if (isAuthorized || twofa.isValid) {
    return <Redirect to={Routes.DASHBOARD} />;
  }

  return (
    <Box textAlign='center'>
      <Box mb={theme.spacing[8]}>
        <TfaStepContent>
          Once configured, we&apos;ll ask for both your password and a verification code on each
          login.
        </TfaStepContent>
      </Box>
      {twofa.data.base32 && (
        <>
          <FirstStep />
          <SecondStep data={twofa.data} />
          <TwoFAValidation
            isPending={isPending}
            onSubmit={submitCode}
            requestErrorMessage={requestError.message}
          />
        </>
      )}
    </Box>
  );
};
