import styled from 'styled-components';
import { breakpoints } from 'styles/theme/breakpoints';
import { Col, Divider as AntDivider, Form, Space } from 'antd';
import { Box, Card, Icon, Input } from 'shared';
import { ThemeProps } from 'services/interfaces';
import { getCssVar } from 'styles/theme';

export const LastUpdated = styled(Box)`
  color: ${getCssVar('process.dateColor')};
  font-size: 15px;
`;

export const IconContainer = styled.div`
  @media (max-width: ${breakpoints.lg}) {
    width: 30%;
  }

  > span {
    height: 100%;
    width: 100%;
  }

  .lockicon {
    filter: ${({
      theme,
    }: ThemeProps) => `drop-shadow(${theme.spacing.px} ${theme.spacing.px} ${theme.spacing[0]} ${theme.colors.black})
          drop-shadow(${theme.spacing['0.5']} ${theme.spacing['0.5']} ${theme.spacing[1]} ${theme.colors.blackAlpha[50]})`};
    height: 16px;
    left: 27px;
    position: absolute;
    top: 19%;
    width: 16px;

    @media (max-width: ${breakpoints.md}) {
      left: 20px;
    }
  }
`;

export const Divider = styled(AntDivider)`
  border-top: 2px solid ${getCssVar('divider.color')};
  margin: 0;
`;

export const DownloadContainer = styled(Box)<ThemeProps>`
  color: ${getCssVar('textColor')};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  div,
  span {
    cursor: pointer;
  }
`;

export const FormContainer = styled.div<ThemeProps>`
  margin-top: 24px;
  max-width: 348px;

  .ant-picker {
    width: 100%;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: ${getCssVar('errorColor')};
  }

  .ant-space {
    width: 100%;
  }

  .ant-form-item-label label {
    font-weight: ${getCssVar('process.labelInputsWeight')};
  }
`;

export const InputStyled = styled(Input)`
  height: 40px;
`;

export const ColumnName = styled.div<ThemeProps>`
  color: ${getCssVar('table.specialColumnTitleColor')};
`;

export const ColumnCertified = styled.div`
  opacity: 50%;
`;

export const BtnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  button {
    margin-left: 10px;
    margin-top: 10px;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-top: 10px;
  }
`;

export const SpaceStyled = styled(Space)`
  margin-bottom: 15px;
`;

export const MaxSize = styled.span`
  color: ${getCssVar('textColor')};
  font-weight: 700;
  opacity: 0.45;
`;

export const StyledTable = styled(Card)`
  .right-head-title {
    .ant-table-column-sorters {
      justify-content: flex-end;
    }
  }
`;

export const IconLeftArrow = styled(Icon)<ThemeProps>`
  color: ${getCssVar('link.color')};
  height: 8px;
  width: 16px;
`;

export const IconDownload = styled(Icon)`
  color: ${getCssVar('link.color')};
  height: 17px;
  margin-right: 5px;
  width: 14px;
`;

export const StyledCard = styled(Card)`
  min-width: 369px;
  padding-bottom: 3px;
  padding-top: 2px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-spin-spinning {
    margin: auto auto;
  }

  @media (max-width: 800px) {
    min-width: 345px;
  }
`;

export const CustomCol = styled(Col)`
  @media (max-width: 1870px) {
    max-width: 33% !important;
    flex: 0 0 33% !important;
  }
  @media (max-width: 1279px) {
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }

  @media (max-width: 800px) {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
`;

export const VendorName = styled(Box)`
  max-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ExpiryLabel = {
  hasDateButNotExpired: boolean;
};

export const ExpiryLabelBody = styled(Box)<ExpiryLabel>`
  min-width: ${({ hasDateButNotExpired }) => (hasDateButNotExpired ? '200px' : '120px')};
  text-align: end;
`;

export const ExpiryDate = styled(Form.Item)<ThemeProps>`
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-right: 0;
    color: ${getCssVar('inputs.label.requiredIconColor')};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    margin-bottom: 5px;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }
`;
