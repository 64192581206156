import { colors } from '../colors';
import { baseNorm } from './base';

export const leftMenu = {
  headerBgColor: baseNorm.bodyBg,
  logoTop: 'menu-logo-top-norm.svg',
  logoTopSmall: 'menu-logo-top-small-norm.svg',
  logoTopWidth: '168',
  logoTopWidthCollapsed: '28',
  logoTopHeight: '30',
  logoTopHeightCollapsed: '25',
  toggleIconColor: '#b9b9bc',
  toggleIconColorMobile: baseNorm.black,

  avatarColor: baseNorm.blue,
  userNameColor: colors.black,
  userAvatarColor: colors.white,
  logoutColor: baseNorm.grey,

  logoBottom: undefined,
  logoBottomCollapsed: undefined,

  drawerFooterLogo: undefined,

  footerBgColor: '#D3CFCC',

  themeSwitcher: {
    item: {
      bg: baseNorm.blue,
      selected: colors.white,
    },
    bg: 'rgba(0, 0, 0, 0.06)',
  },
};
