import styled from 'styled-components';
import AntdIcon from '@ant-design/icons';
import { Theme } from 'styles/theme/types';
import { FunctionComponent } from 'react';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type Props = {
  dimension?: string;
  width?: string;
  height?: string;
  margin?: string;
  verticalAlign?: string;
  theme?: Theme;
};

/* eslint-disable */
const getDimensions = ({ dimension, height, width }: Props) => {
  if (dimension) {
    return `
      height: ${dimension} !important;
      width: ${dimension} !important;
    `;
  }

  const heightString = height ? `${height} !important;` : '24px';
  const widthString = width ? `${width} !important;` : '24px';

  return `
    height: ${heightString};
    width: ${widthString};
  `;
};

export const Icon: FunctionComponent<Props & IconComponentProps> = styled(AntdIcon)<Props>`
  font-size: 24px;
  margin: ${(props) => props.margin};
  vertical-align: ${(props) => props.verticalAlign || 'middle'};

  ${getDimensions};

  ${({ color }) => color && `color: ${color};`}

  svg {
    ${({ dimension }) => {
      if (dimension) {
        return `
          height: ${dimension} !important;
          width: ${dimension} !important;
        `;
      }
    }};

    ${({ color }) => color && `fill: ${color} !important;`};
  }
`;
