import React from 'react';
import { useApp, useCEPlus, useLoading } from 'store';
import { Card } from 'shared';
import { useHistory } from 'react-router';
import { initialPagination } from 'app-constants';
import { useTheme } from 'styled-components';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { SegmentedPie } from 'shared/charts';
import { breakpoints } from 'styles/theme';
import { isEmptyObject } from 'services/helpers';
import { cyberEssentialPlusApi } from 'store/cyberEssentialPlus/thunks';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { Routes } from 'services/entities';
import { getQueryString } from 'services/api/core/request';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { Filter } from 'services/api';
import {
  getCustomDataHandler,
  lastYear,
  validateField,
  validateFieldValue,
  viewMoreConfig,
} from '../../helpers';
import { subTitleFormatterSum } from '../../../PatchManagement/helpers';
import { VendorChart } from '../../styled';
import {
  cePlusBackRoute,
  CustomPieChartTooltip,
  DEFAULT_CVSS_SCORE,
  HIDDEN_FILTER_EMPTY_FIELD,
  IFilterField,
  initialVulnerabilityOrderBy,
  tooltipTitles,
} from '../../constants';

export const TotalVulnerabilitiesInScope = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile, isMenuCollapsed } = useApp();

  const { totalVulnerabilitiesInScope, selectedTags } = useCEPlus();

  const loading = useLoading([cyberEssentialPlusApi.getTotalVulnerabilitiesInScope.typePrefix]);

  const totalVulnerabilitiesInScopeColorList = {
    vulnerabilities: theme.colorSet.baseColors.deepRed,
    fixedVulnerabilities: theme.colorSet.baseColors.red,
  };

  const getVulnerabilitiesParams = (vulnerabilityState: string, tagsList: string[]) => {
    const totalVulnerabilitiesInScopeFields: IFilterField[] = [
      HIDDEN_FILTER_EMPTY_FIELD as IFilterField,
      {
        name: 'tagsAssets',
        value: tagsList || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
    ];

    return {
      orderBy: initialVulnerabilityOrderBy,
      pagination: initialPagination,
      filter: {
        cvssScore: DEFAULT_CVSS_SCORE,
        fixedVulnerabilities: vulnerabilityState === 'fixedVulnerabilities',
        fixedVulnerabilitiesFrom: vulnerabilityState === 'fixedVulnerabilities' ? lastYear : null,
        fields: validateField(totalVulnerabilitiesInScopeFields),
      },
    };
  };

  const redirectToVulnerabilitiesList = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      history.push(
        `${Routes.VULNERABILITIES_LIST}${getQueryString(
          getVulnerabilitiesParams(params.name, selectedTags),
        )}`,
        {
          ...cePlusBackRoute,
          ignoreDefaultBehavior: true,
        },
      );
    },
    [history, selectedTags],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToVulnerabilitiesList(params),
    }),
    [redirectToVulnerabilitiesList],
  );

  const { fixedVulnerabilities = 0, vulnerabilities = 0 } = totalVulnerabilitiesInScope;
  const total = [fixedVulnerabilities + vulnerabilities || 0];

  return (
    <Card
      title='Total vulnerabilities in scope'
      showDivider={false}
      subTitle={<SubTitle boldText={subTitleFormatterSum(total, 1)} />}
      height={theme.sizes.full}
      tooltipTitle={tooltipTitles.totalVulnerabilitiesInScope}
      headStyles={
        !isMobile
          ? {
              mt: theme.sizes[1],
              titlePaddingRight: theme.sizes[2],
              headAlign: 'flex-start',
            }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start', maxWidthTitle: '80%' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getTotalVulnerabilitiesInScope.typePrefix]}
      viewMore={viewMoreConfig(selectedTags).totalVulnerabilitiesInScope}
    >
      {!isEmptyObject(totalVulnerabilitiesInScope) ? (
        <TotalCustomTooltipContainer>
          <VendorChart>
            <SegmentedPie
              values={totalVulnerabilitiesInScope || {}}
              colors={totalVulnerabilitiesInScopeColorList}
              direction={breakpoints.xxl ? 'column' : 'row'}
              getCustomDataHandler={getCustomDataHandler}
              entitiesName=''
              style={
                isMobile
                  ? { height: '160px', marginTop: `-${theme.spacing[0]}` }
                  : { height: '258px' }
              }
              tooltip={CustomPieChartTooltip(theme)}
              isMobile={isMobile}
              isMenuCollapsed={isMenuCollapsed}
              onEvents={onEvents}
              isTruncatedText
              isLoading={loading[cyberEssentialPlusApi.getTotalVulnerabilitiesInScope.typePrefix]}
              noLegend
            />
          </VendorChart>
        </TotalCustomTooltipContainer>
      ) : (
        emptyMessage(`No total vulnerabilities in scope`)
      )}
    </Card>
  );
};
