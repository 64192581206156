import { Button, InnerLayoutProps as InnerLayout } from 'shared';
import {
  DocumentSchema,
  DPLDocumentSchema,
  SoftwareDocumentSchema,
  BlobScanStatus,
} from 'services/api';
import { RequestStatus } from 'store/loading/slice';
import { ActionClickHandlersType } from 'services/interfaces';
import { showError, showMessage } from 'services/helpers';
import { ActionNames, emptyDataString } from 'app-constants';
import { AppDispatch, documentsApi, dplApi, MessageType, softwareApi } from 'store';
import { PopupConfigType } from 'shared/ActionPopup/types';
import prettyBytes from 'pretty-bytes';
import { ActionsPopupWithButton } from './components/ActionsPopupWithButton/ActionsPopupWithButton';
import { TitleTooltipContent } from './components/TitleTooltipContent';
import { deletePopupDetails } from './components/constants';
import { LoadingDots } from './components/LoadingDots';
import { DangerLabelContainer } from './components/styled';

export const getLayoutConfig = (showUploadPopup: () => void, withButton: boolean): InnerLayout => ({
  title: 'Documents',
  titleTooltip: TitleTooltipContent,
  customComponent: withButton ? (
    <Button type='secondary' onClick={showUploadPopup}>
      Upload file
    </Button>
  ) : null,
});

export type SoftwareAndDocumentRowType = {
  id?: string;
  isownedbysuperadmin?: boolean;
};

export const getActions = <T extends SoftwareAndDocumentRowType>(
  actionsClickHandlers: ActionClickHandlersType<T>,
  isFullAccess: boolean,
  isSuperAdmin?: boolean,
) => {
  return (row: T) => (
    <ActionsPopupWithButton
      actionsClickHandlers={actionsClickHandlers}
      row={row}
      isFullAccess={row.isownedbysuperadmin ? isFullAccess && !!isSuperAdmin : isFullAccess}
    />
  );
};

export const showPopup = (
  popupId: ActionNames,
  setPopupDetails: (config: PopupConfigType | null) => void,
  setIsVisiblePopup: (value: boolean) => void,
  row: DocumentSchema | SoftwareDocumentSchema | DPLDocumentSchema,
  dispatch: AppDispatch,
  customerId: string,
  api: typeof documentsApi | typeof softwareApi | typeof dplApi,
  onSuccess: () => void,
) => {
  switch (popupId) {
    case ActionNames.Delete:
      setPopupDetails({
        ...deletePopupDetails,
        title: `${deletePopupDetails.title} ${row.name}`,
        onApply: async () => {
          const result = await dispatch(api.deleteFile({ customerId, filename: row.name || '' }));

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          onSuccess();
          showMessage({ content: `File ${row.name} has been deleted` }, MessageType.Success);
        },
      });
      break;
    default:
      setPopupDetails(null);
  }

  setIsVisiblePopup(true);
};

export const normalizeFileSize = (fileSize: number) => {
  const normaliseSize = prettyBytes(fileSize, { binary: true }).toUpperCase();

  if (!normaliseSize) {
    return emptyDataString;
  }

  if (normaliseSize.includes('KIB')) {
    return normaliseSize.replace('KIB', 'KB');
  }

  if (normaliseSize.includes('MIB')) {
    return normaliseSize.replace('MIB', 'MB');
  }

  if (normaliseSize.includes('GIB')) {
    return normaliseSize.replace('GIB', 'GB');
  }

  return normaliseSize;
};

export const displayFileScanStatus = (scanStatus: BlobScanStatus) => {
  switch (scanStatus) {
    case BlobScanStatus.PENDING_SCAN:
    case BlobScanStatus.BEING_SCANNED:
      return <LoadingDots>{BlobScanStatus.BEING_SCANNED}</LoadingDots>;

    case BlobScanStatus.FILE_SCAN_ERROR:
      return <DangerLabelContainer>{BlobScanStatus.FILE_SCAN_ERROR}</DangerLabelContainer>;

    default:
      return null;
  }
};
