import { Tag, UcFirstLetter } from 'shared';
import { TagProps } from 'antd';
import { VulnerabilityStatus } from 'services/api';
import { getCssVar } from '../../styles/theme';

type CustomTagProps = TagProps & {
  title: string;
};

export const TagVulnStatus = ({ title, ...TagProps }: CustomTagProps) => {
  let textColor = getCssVar('vulnerability.status.confirmed.textColor');
  let bgColor = getCssVar('vulnerability.status.confirmed.backgroundColor');

  if (title === VulnerabilityStatus.POTENTIAL) {
    textColor = getCssVar('vulnerability.status.potential.textColor');
    bgColor = getCssVar('vulnerability.status.potential.backgroundColor');
  }

  return (
    <Tag color={bgColor} key={title} {...TagProps} title={title}>
      <span style={{ color: textColor }}>{UcFirstLetter(title)}</span>
    </Tag>
  );
};
