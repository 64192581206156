import { PermissionsLevel } from 'app-constants';
import { Button, InnerLayoutProps as InnerLayout } from 'shared';
import { DPLCategory } from 'services/api';
import { TitleTooltipContent } from './components/TitleTooltipContent';

export const getLayoutConfig = (
  showUploadPopup: () => void,
  permission: PermissionsLevel | null,
  categories: DPLCategory[],
  isNorm?: boolean,
): InnerLayout => ({
  title: 'Data protection library',
  titleTooltip: TitleTooltipContent,
  customComponent: isNorm && categories.length > 0 && (
    <Button
      type='secondary'
      onClick={showUploadPopup}
      disabled={permission !== PermissionsLevel.Full}
    >
      Upload file
    </Button>
  ),
});
