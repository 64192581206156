import { useTheme } from 'styled-components';
import { getCssVar } from 'styles/theme';
import { Spacer } from 'shared/TableFilters/styled';
import { Box, Icon } from 'shared';
import { useApp, useMaintenanceSettings } from 'store';
import { getSupportEmail, getSupportPhone } from '../../../../themes/helpers';
import { Preview } from '../Preview';
import { showSnackBarText } from '../../helpers';
import { ViewModeWrapper } from '../../style';

interface IProps {
  loading: boolean;
}

export const SnackBarViewMode = ({ loading }: IProps) => {
  const { themeName } = useApp();
  const theme = useTheme();
  const { maintenanceSettings } = useMaintenanceSettings();

  if (loading) {
    return null;
  }

  return (
    <Box>
      <ViewModeWrapper textAlign='center'>
        <span>Smartbloc</span> {maintenanceSettings?.snackbarText}
        <br />
        <Box>
          <Icon component={theme.icons.infoWarning} color={getCssVar('status.warning.bg')} />
          &nbsp; For any urgent issues please contact the support desk on{' '}
          {getSupportPhone(themeName)} or drop us an email at{' '}
          <a href={`mailto:${getSupportEmail(themeName)}`} target='_blank' rel='noreferrer'>
            {getSupportEmail(themeName)}
          </a>
          .<br />
        </Box>
        <Box> Apologies for any inconvenience.</Box>
      </ViewModeWrapper>
      <Spacer h='20' />
      <Preview onPress={() => showSnackBarText(maintenanceSettings?.snackbarText, themeName)} />
    </Box>
  );
};
