import { Form } from 'antd';
import styled from 'styled-components';
import { Button, Select } from 'shared';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../../styles/theme';

interface IProps {
  theme: Theme;
}

export const ButtonReset = styled(Button)<IProps>`
  margin-left: 10px;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const StyledSelect = styled(Select)`
  && {
    width: 400px;

    @media (max-width: 992px) {
      width: 100%;
    }
  }
`;

export const WrapperSelect = styled(Form.Item)<IProps>`
  &&& {
    .ant-form-item-required::before {
      color: ${({ theme }) => theme.colors.red[700]};
      margin: 0;
      padding: 0 0 5px 0;
    }

    .ant-form-item-required {
      font-weight: ${getCssVar('labelFontWeight')};
    }
  }
`;

export const StyledOption = styled.span<IProps>`
  font-size: ${({ theme }) => theme.sizes['3.5']};
  line-height: normal;
  opacity: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
