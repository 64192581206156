import { CertificateSchema } from 'services/api';
import { getCapitalizedString } from 'services/helpers';
import { ProcessListWrapper, TotalScorePieWrapper, ProcessText } from './styled';
import { ProcessList } from './ProcessList';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { CyberResilienceScoreDescriptionScoreMessage, Page } from '../styled';
import { asidePieSize, asidePieFontSize } from '../config';
import { BackgroundImage } from '../../components/BackgroundImage';

type Props = {
  certificates: Array<CertificateSchema>;
  processPermission: string;
  processScore: number | undefined | null;
  onChartLoaded: () => void;
  processRank: string;
};

export function PdfPage5({
  certificates,
  processPermission,
  processScore,
  processRank,
  onChartLoaded,
}: Props) {
  return (
    <Page>
      <BackgroundImage fileName='slide-5.jpg' />

      <ProcessText>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(processRank) }}
        />
        <ul>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
        </ul>
      </ProcessText>

      <TotalScorePieWrapper>
        <GradientPieReport
          score={processScore}
          onFinished={onChartLoaded}
          width={asidePieSize}
          height={asidePieSize}
          fontSize={asidePieFontSize}
        />
      </TotalScorePieWrapper>

      <ProcessListWrapper>
        <ProcessList
          certificates={certificates.filter(({ title }) => title !== 'IASME Governance Audited')}
          permission={processPermission}
        />
      </ProcessListWrapper>
    </Page>
  );
}
