import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Action, ThunkAction } from '@reduxjs/toolkit';
import { AppDispatch, AppState, useTagsManagement } from 'store';
import {
  CustomerId,
  Filter,
  Filters,
  GridRequestParams,
  OrderBy,
  Pagination,
  TagSchema,
} from 'services/api';
import { getParametersFromPath } from 'services/helpers';
import { getQueryString } from 'services/api/core/request';
import { initialPagination as defaultPagination } from 'app-constants';
import { Routes } from '../entities';
import { HistoryState } from '../interfaces';
import { tagAssetsField } from '../constants';

interface IProps {
  apiCall?: (params: GridRequestParams) => ThunkAction<void, AppState, unknown, Action>;
  dispatch: AppDispatch;
  isMobile: boolean;
  customerId: CustomerId;
  initialOrderBy: OrderBy;
  initialPagination?: Pagination;
  filter?: Filters;
  page?: string;
  historyState?: HistoryState;
  noRequest?: boolean;
  request?: (params: GridRequestParams) => void;
}

export type UpdateFilterParamsArgType =
  | GridRequestParams
  | ((prevParams: GridRequestParams) => GridRequestParams);

export const useFilter = ({
  apiCall,
  dispatch,
  isMobile,
  customerId,
  initialOrderBy,
  initialPagination = defaultPagination,
  filter,
  page,
  historyState,
  noRequest,
  request,
}: IProps) => {
  const history = useHistory();
  const { tagsList } = useTagsManagement();
  const defaultParams = {
    customerId,
    orderBy: initialOrderBy,
    pagination: initialPagination,
    filter,
  };

  const isInit = React.useRef(true);
  let searchParams = getParametersFromPath(history.location.search, customerId);

  if (searchParams?.tab) {
    searchParams = {
      ...defaultParams,
      ...searchParams,
    };
  }

  if (searchParams?.expiredSuppression) {
    let fields = searchParams?.filter?.fields || [];

    fields = [...fields, { name: 'expiredSuppression', value: 'true', type: Filter.type.VALUE }];
    searchParams = { ...searchParams, filter: { ...searchParams.filter, fields } };
  }

  const [params, setParams] = React.useState<GridRequestParams>(searchParams || defaultParams);

  useEffect(() => {
    const currentFilterFields = params?.filter?.fields;
    const selectedFilterTagsIndex = currentFilterFields?.findIndex(
      (field) => field.name === tagAssetsField,
    );

    if (
      !currentFilterFields ||
      selectedFilterTagsIndex === undefined ||
      selectedFilterTagsIndex < 0
    ) {
      return;
    }

    const selectedFilterTagsValue = currentFilterFields[selectedFilterTagsIndex]?.value as string[];

    const uniqueTagList = selectedFilterTagsValue?.reduce((acc: string[], currentTag: string) => {
      const hasTag = tagsList?.find((tag: TagSchema) => tag?.id === currentTag);

      if (!hasTag) {
        return acc;
      }

      return [...acc, currentTag];
    }, []);

    if (uniqueTagList?.length !== selectedFilterTagsValue.length) {
      currentFilterFields.splice(selectedFilterTagsIndex, 1);
      setParams((prevParams: GridRequestParams) => ({
        ...prevParams,
        filter: {
          ...prevParams.filter,
          fields: currentFilterFields,
        },
      }));
    }
  }, [params?.filter?.fields, tagsList]);

  useEffect(() => {
    if (isInit.current) {
      return;
    }

    setParams((prevParams: GridRequestParams) => ({
      ...prevParams,
      orderBy: initialOrderBy,
      pagination: initialPagination,
      customerId,
    }));
  }, [customerId, initialOrderBy, initialPagination]);

  const updateParams = React.useCallback((updatedParams: UpdateFilterParamsArgType) => {
    setParams(updatedParams);
  }, []);

  const makeRequest = React.useCallback(() => {
    if (request) {
      return request(params);
    }

    if (apiCall) {
      return dispatch(apiCall(params));
    }

    return dispatch;
  }, [params, dispatch, apiCall, request]);

  React.useEffect(() => {
    if (isInit.current) {
      if (!noRequest) {
        makeRequest();
      }

      isInit.current = false;

      return;
    }

    const { customerId, ...otherParams } = params;

    // save state on these pages, because you can redirect here from another pages
    if (
      page === 'CMDB' ||
      page === 'SUPPORT_REQUESTS' ||
      page === 'PATCH_MANAGEMENT_ASSETS' ||
      page === 'PATCH_MANAGEMENT_PATCHES'
    ) {
      history.push({
        pathname: Routes[page],
        search: getQueryString(otherParams),
        state: historyState,
      });
      makeRequest();

      return;
    }

    history.push(getQueryString(otherParams));

    if (!noRequest) {
      makeRequest();
    }
  }, [apiCall, params, dispatch, history, isMobile, historyState, page, noRequest, makeRequest]);

  return [params, updateParams, makeRequest] as const;
};
