import { useTheme } from 'styled-components';
import { StrengthBar, StrengthBarCell } from './styled';

type Props = {
  value: string;
  minPasswordLength: number;
};

export const getScore = (value: string) => {
  return (
    Number(/[A-Z]/.test(value)) +
    Number(/[~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)) +
    Number(/[0-9]/.test(value)) +
    Number(/[a-z]/.test(value))
  );
};

export const PasswordStrengthBar = ({ value, minPasswordLength }: Props) => {
  const { colorSet } = useTheme();
  const strength = getScore(value);

  const currentColors = colorSet.login.passwordStrengthColors.map((_, colorLevel) => {
    const isLengthLess = value && value.length < minPasswordLength;
    const isStrengthLess = strength <= colorLevel;

    if (isStrengthLess || isLengthLess) return colorSet.login.zeroStrengthColor;

    return colorSet.login.passwordStrengthColors[strength - 1];
  });

  return value ? (
    <StrengthBar>
      {currentColors.map((color: string, index: number) => (
        <StrengthBarCell key={index.toString()} bgColor={color} />
      ))}
    </StrengthBar>
  ) : (
    <></>
  );
};
