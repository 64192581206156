import { colors } from '../../colors';
import { baseDarkNorm } from '../base';
import { inputsBase } from './base';

export const inputs = {
  label: {
    textColor: colors.white,
    requiredIconColor: baseDarkNorm.deepRed,
  },
  backgroundColor: inputsBase.backgroundColor,
  backgroundColorDisabled: inputsBase.backgroundColorDisabled,
  textColor: colors.white,
  textColorDisabled: inputsBase.textColorDisabled,
  borderColor: inputsBase.borderColor,
  borderColorHover: inputsBase.borderColorHover,
  borderColorDisabled: inputsBase.borderColor,
  borderColorError: colors.red[400],
  borderColorErrorHover: '#B03A2D',
  errorMessageColor: inputsBase.errorColor,
  errorMessageColorHover: inputsBase.errorColor,
  prefixIconColor: inputsBase.iconColor,
  prefixIconBackground: inputsBase.backgroundColor,
};
