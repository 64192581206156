import React, { useMemo } from 'react';
import { useFilter } from 'services/hooks/useFilter';
import { EmptyTablePreview, InnerLayout, MobileTable, MobileTableCol } from 'shared';
import { useDispatch } from 'react-redux';
import { Gutter } from 'antd/lib/grid/row';
import { Row } from 'antd';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { CustomTable } from 'shared/Table/CustomTable';
import { useApp, useCustomer, useLoading, usePlatform, usePmPatches } from 'store';
import { patchesApi } from 'store/patchManagement';
import { useHistory } from 'react-router';
import { PatchListSchema } from 'services/api';
import { Routes } from 'services/entities';
import { backlinkRoutes } from 'app-constants';
import { PmPlatformFilter } from 'shared/PmPlatformsFilter';
import { useLocation } from 'react-router-dom';
import { HistoryState } from 'services/interfaces';
import { getLayoutConfig } from './helpers';
import { filterFields, initialOrderBy, searchSettings } from './constants';
import { getColumns } from './components/TableColumns';

const defaultPageSize = 50;
const defaultCurrent = 1;
const GUTTER_DIR = 32;
const GUTTER: [Gutter, Gutter] = [GUTTER_DIR, GUTTER_DIR];

export const PatchManagementPatches = () => {
  const dispatch = useDispatch();
  const platform = usePlatform();
  const history = useHistory();
  const { state: historyState, search } = useLocation<HistoryState>();
  const customer = useCustomer();
  const { data } = usePmPatches();
  const loading = useLoading([patchesApi.getPatches.typePrefix])[patchesApi.getPatches.typePrefix];
  const { isMobile } = useApp();

  const [params, updateParams] = useFilter({
    dispatch,
    isMobile,
    customerId: customer,
    initialOrderBy,
    noRequest: true,
    page: 'PATCH_MANAGEMENT_PATCHES',
    historyState,
  });

  const layoutConfig = getLayoutConfig(
    {
      customComponent: <PmPlatformFilter />,
    },
    historyState,
  );

  const columns = useMemo(() => {
    return getColumns(search);
  }, [search]);

  const handleTableClick = React.useCallback(
    (record: PatchListSchema) => {
      history.push(`${Routes.PATCH_MANAGEMENT_PATCHES}/${record.id}`, {
        backTo: {
          route: `${Routes.PATCH_MANAGEMENT_PATCHES}`,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[Routes.PATCH_MANAGEMENT_PATCHES]}`,
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [history, historyState?.backTo],
  );

  React.useEffect(() => {
    dispatch(
      patchesApi.getPatches({
        ...params,
        platform,
      }),
    );
  }, [params, platform, dispatch]);

  const emptyMessage = <EmptyTablePreview textContent='No patches yet' loading={loading} />;

  return (
    <InnerLayout {...layoutConfig}>
      {isMobile ? (
        <Row gutter={GUTTER}>
          <MobileTableCol flex='auto'>
            <MobileTable
              data={data}
              searchSettings={searchSettings}
              filterFields={filterFields}
              columns={getTableColumns(columns, params.orderBy)}
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              onRowClick={handleTableClick}
              isLoadingContent={loading}
              emptyMessage={emptyMessage}
            />
          </MobileTableCol>
        </Row>
      ) : (
        <CustomTable
          data={data}
          searchSettings={searchSettings}
          columns={getTableColumns(columns, params.orderBy)}
          params={params}
          setParams={updateParams}
          filterFields={filterFields}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          onRowClickHandle={handleTableClick}
          isLoadingContent={loading}
          emptyMessage={emptyMessage}
        />
      )}
    </InnerLayout>
  );
};
