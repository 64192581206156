import { colors } from '../../colors';
import { baseNorm } from '../base';
import { inputsBase } from './base';

export const select = {
  bg: inputsBase.backgroundColor,
  textColor: inputsBase.textColor,
  backgroundColor: inputsBase.backgroundColor,
  iconColor: inputsBase.iconColor,

  backgroundColorDisabled: inputsBase.backgroundColorDisabled,
  textColorDisabled: inputsBase.textColorDisabled,
  borderColorDisabled: inputsBase.borderColor,

  cascader: {
    backgroundColor: inputsBase.backgroundColor,
    item: {
      checkedBackgroundColor: colors.gray[20], // #F0F0F0
      checkedIconColor: inputsBase.activeColor,
      hoverBackgroundColor: inputsBase.backgroundColorDisabled,
    },
  },
  tag: {
    backgroundColor: inputsBase.borderColor,
    borderColor: baseNorm.borderColor,
    iconColor: inputsBase.iconColor,
    hoverIconColor: inputsBase.activeColor,
  },
  selector: {
    borderColor: inputsBase.borderColor,
    hoverBorderColor: inputsBase.borderColorHover,
    focusBorderColor: inputsBase.borderColorHover,
    checked: {
      borderColor: inputsBase.activeColor,
      backgroundColor: colors.gray[30],
      hoverBorderColor: colors.gray[70],
    },
  },
  dropDownActions: {
    backgroundColor: inputsBase.backgroundColor,
    backgroundColorHover: colors.gray[40],
    dangerColor: baseNorm.deepRed,
    iconColor: colors.gray[300],
    dividerColor: baseNorm.divider,
  },
};

// export const select = {
//   bg: colors.white,
//   textColor: colors.black,
//   backgroundColor: colors.white,
//   iconColor: colors.gray[410],
//   cascader: {
//     bg: colors.white,
//     bgHover: colors.gray[40],
//     backgroundColor: colors.white,
//     item: {
//       checkedBackgroundColor: colors.gray[20],
//       checkedIconColor: baseNorm.blue,
//       hoverBackgroundColor: '#0000000a',
//     },
//   },
//   tag: {
//     backgroundColor: colors.gray[90],
//     borderColor: baseNorm.borderColor,
//     iconColor: colors.gray[410],
//     hoverIconColor: baseNorm.blue,
//   },
//   selector: {
//     borderColor: colors.gray[90],
//     hoverBorderColor: colors.gray[150],
//     focusBorderColor: colors.gray[70],
//     checked: {
//       borderColor: baseNorm.blue,
//       backgroundColor: colors.gray[30],
//       hoverBorderColor: colors.gray[70],
//     },
//   },
//   dropDownActions: {
//     backgroundColor: colors.white,
//     backgroundColorHover: colors.gray[40],
//     dangerColor: colors.red[400],
//     iconColor: colors.gray[300],
//     dividerColor: colors.gray[70],
//   },
// };
