import { useCallback, useEffect, useMemo, useState } from 'react';
import { MonthlyReportResponse } from 'services/api';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { IDate } from 'services/interfaces';
import { Annotations } from 'shared/charts/GradientPie/types';
import { PdfBox } from './pages/styled';
import { CHARTS_COUNT, CHARTS_COUNT_WITHOUT_PEOPLE_SCORE } from './constants';
import {
  PageTitle,
  PdfPage10,
  PdfPage11,
  PdfPage12,
  PdfPage13,
  PdfPage14,
  PdfPage15,
  PdfPage16,
  PdfPage2,
  PdfPage3,
  PdfPage4,
  PdfPage5,
  PdfPage6,
  PdfPage7,
  PdfPage8,
  PdfPage9,
} from './pages';
import { getSortedDashboardScores } from './helpers';
import { serviceChanges } from './mocks/report';
import { Themes } from '../../../../../themes/types';

interface ReportProps {
  data: MonthlyReportResponse;
  processPermission: string;
  reportDate: IDate;
  theme: Theme;
  onChartsReady: () => void;
  scoreAnnotations?: Annotations;
  themeName?: Themes;
}

export const Report = ({
  data,
  reportDate,
  processPermission,
  onChartsReady,
  theme,
  scoreAnnotations,
  themeName = Themes.Norm,
}: ReportProps) => {
  const [loadedChartsCount, setLoadedChartsCount] = useState(0);

  const scores = useMemo(() => {
    const dashboardScores = data.dashboardScores || [];

    const checkingDashboardScores = getSortedDashboardScores([...dashboardScores])[
      dashboardScores.length - 1
    ];

    return {
      totalScore: checkingDashboardScores?.total || 0,
      peopleScore: checkingDashboardScores?.people,
      processScore: checkingDashboardScores?.process,
      technologyScore: checkingDashboardScores?.technology,
    };
  }, [data.dashboardScores]);

  const totalChartsCount = scores.peopleScore ? CHARTS_COUNT : CHARTS_COUNT_WITHOUT_PEOPLE_SCORE;

  useEffect(() => {
    if (loadedChartsCount === totalChartsCount) {
      onChartsReady();
      setLoadedChartsCount(-1);
    }
  }, [loadedChartsCount, onChartsReady, totalChartsCount]);

  const onChartLoadedHandler = useCallback(() => {
    setLoadedChartsCount((prev) => prev + 1);
  }, []);

  const { chart, totalChanges, totalCount } = useMemo(() => {
    return {
      chart: data?.vulnerabilityScanning?.vulnerabilitiesChanges?.chart || [],
      totalChanges: data?.vulnerabilityScanning?.vulnerabilitiesChanges?.totalChange || 0,
      totalCount: data?.vulnerabilityScanning?.vulnerabilitiesChanges?.count || 0,
    };
  }, [data]);

  const { technologyRank, peopleRank, processRank, totalRank } = useMemo(() => {
    return {
      totalRank: data.scoreRanks?.totalRank || '',
      technologyRank: data.scoreRanks?.technologyRank || '',
      peopleRank: data.scoreRanks?.peopleRank || '',
      processRank: data.scoreRanks?.processRank || '',
    };
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <PdfBox>
        <PageTitle reportDate={reportDate} />
        <PdfPage2 />
        <PdfPage3
          scoreAnnotations={scoreAnnotations}
          onChartLoaded={onChartLoadedHandler}
          totalRank={totalRank}
          technologyRank={technologyRank}
          peopleRank={peopleRank}
          processRank={processRank}
          {...scores}
        />
        <PdfPage4 data={data} onChartLoaded={onChartLoadedHandler} totalRank={totalRank} />
        <PdfPage5
          certificates={data.certificates || []}
          processPermission={processPermission}
          onChartLoaded={onChartLoadedHandler}
          processScore={scores.processScore}
          processRank={processRank}
        />
        <PdfPage6
          onChartLoaded={onChartLoadedHandler}
          peopleScore={scores.peopleScore}
          peopleRank={peopleRank}
          phishing={data.people?.phishing}
          trainingStatus={data.people?.trainingStatus}
          refresherStatistics={data.people?.refresherStatistics}
          theme={theme}
        />
        <PdfPage7
          technologyScore={scores.technologyScore}
          technologyRank={technologyRank}
          onChartLoaded={onChartLoadedHandler}
          chart={chart}
          totalChange={totalChanges}
          totalCount={totalCount}
          averageDaysToRemediate={data.vulnerabilityScanning?.avgTimeToRemediateVulns || 0}
          theme={theme}
          topVulnerabilities={data.vulnerabilityScanning?.topVulnerabilities}
          mostVulnerableHosts={data.vulnerabilityScanning?.topMostVulnerableHosts}
          patchVsConfiguration={data.vulnerabilityScanning?.patchVsConfiguration}
          suppressedVulnerabilities={data.vulnerabilityScanning?.suppressedVulnerabilities || 0}
        />
        <PdfPage8
          data={data}
          technologyRank={technologyRank}
          onChartLoaded={onChartLoadedHandler}
        />
        <PdfPage9 data={data} onChartLoaded={onChartLoadedHandler} reportDate={reportDate} />
        <PdfPage10 data={data} onChartLoaded={onChartLoadedHandler} />
        <PdfPage11 />
        <PdfPage12 />
        <PdfPage13 data={serviceChanges} />
        <PdfPage14 />
        <PdfPage15 themeName={themeName} data={data} reportDate={reportDate} />
        <PdfPage16 />
      </PdfBox>
    </ThemeProvider>
  );
};
