import React from 'react';
import { mapValues } from 'lodash';

export const iconsJSX = {
  start: (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='60' fill='none'>
      <mask id='b' fill='#fff'>
        <path d='M24 0H0l17 30L0 60h24V0Z' />
      </mask>
      <path
        fill='#D3CFCC'
        stroke='#B9A5A0'
        strokeWidth='4'
        d='M24 0H0l17 30L0 60h24V0Z'
        mask='url(#b)'
      />
    </svg>
  ),
  end: (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='60' fill='none'>
      <mask id='a' fill='#fff'>
        <path d='M-2 0h4l17 30L2 60h-5V0Z' />
      </mask>
      <path
        fill='#D3CFCC'
        stroke='#B9A5A0'
        strokeWidth='4'
        d='M-2 0h4l17 30L2 60h-5V0Z'
        mask='url(#a)'
      />
    </svg>
  ),
};

const jsxToFC = (jsx: JSX.Element) => () => jsx;
const stepperIcons = mapValues(iconsJSX, jsxToFC);

export { stepperIcons };
