import { colors } from '../../colors';
import { inputsBase } from './base';

export const switchStyles = {
  bg: {
    inactive: '#494B42',
    active: inputsBase.activeColor,
    hover: inputsBase.checkedBackgroundColor,
  },
  disabledOpacity: inputsBase.disabledOpacity,
};

export const checkBoxStyles = {
  borderColor: inputsBase.borderColor,
  active: {
    bg: inputsBase.activeColor,
    color: colors.white,
  },
  hover: inputsBase.hoverColor,
  disabledOpacity: inputsBase.disabledOpacity,
};

export const radioStyles = {
  color: colors.white,
  borderColor: inputsBase.borderColor,
  active: {
    bg: 'transparent',
    color: inputsBase.activeColor,
  },
  group: {
    bg: '#484648',
    borderColor: '#323332',
  },
  hover: inputsBase.hoverColor,
  disabledOpacity: inputsBase.disabledOpacity,
};
