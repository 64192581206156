import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { isFirefox } from 'react-device-detect';
import { RestorePasswordSteps, Routes } from 'services/entities';
import { getScore } from 'shared/inputs/Input/components/PasswordStrengthBar';
import * as Yup from 'yup';
import { SpinContainer } from 'shared/Card/components/styled';
import { AppDispatch, auth, authApi, MessageType, useAuth, useLoading } from 'store';
import { Form, Spin } from 'antd';
import { useFormik } from 'formik';
import { Box } from 'shared';
import { RequestStatus } from 'store/loading/slice';
import { showError, showMessage } from 'services/helpers';
import { useTheme } from 'styled-components';
import { CheckPasswordWrapper, StyledButton, InputLabel, PasswordDescription } from './styled';
import { FormInput } from '../../Login/components/styled';

const DEFAULT_MIN_PASSWORD_LENGTH = 6;
const DEFAULT_PASSWORD_COMPLEXITY = 2;

export const ResetForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const { resetPasswordInfo } = useAuth();
  const { code: resetLink } = useParams<Record<string, string>>();
  const history = useHistory();
  const loading = useLoading([
    authApi.resetPasswordConfirm.typePrefix,
    authApi.getResetPasswordInfo.typePrefix,
  ]);

  const loadingResetPassword = loading[authApi.resetPasswordConfirm.typePrefix];

  const validateComplexity = (value: string | undefined) => {
    return !(
      getScore(value || '') < (resetPasswordInfo?.passwordComplexity || DEFAULT_PASSWORD_COMPLEXITY)
    );
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required('Please enter your password')
      .min(
        resetPasswordInfo?.passwordLength || DEFAULT_MIN_PASSWORD_LENGTH,
        `Minimum permitted length for a password should be ${
          resetPasswordInfo?.passwordLength || DEFAULT_MIN_PASSWORD_LENGTH
        } characters`,
      )
      .test(
        'complexity',
        `Minimum number of different character types should be ${
          resetPasswordInfo?.passwordComplexity || DEFAULT_PASSWORD_COMPLEXITY
        } characters (lowercase letter, uppercase letter, number, special character)`,
        validateComplexity,
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      "This password doesn't match. Try again",
    ),
  });

  const onSubmit = async (values: { newPassword: string; confirmPassword: string }) => {
    const result = await dispatch(
      authApi.resetPasswordConfirm({
        resetLink, // FIXME update the flow accordingly swagger updates
        requestBody: { password: values.newPassword },
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      showError();

      return;
    }

    showMessage({ content: 'Password has been successfully reset' }, MessageType.Success);
  };

  React.useEffect(() => {
    dispatch(authApi.getResetPasswordInfo({ resetLink })).then((res) => {
      if (res.meta.requestStatus === RequestStatus.Rejected) {
        showMessage({ content: 'The link is not valid or expired' }, MessageType.Error);
        dispatch(auth.actions.setRestorePasswordStep(RestorePasswordSteps.INITIAL));
        history.push(Routes.FORGOT_PASSWORD);
      }
    });
  }, [dispatch, resetLink, history]);

  const { handleSubmit, values, errors, handleChange, isValid } = useFormik({
    validationSchema,
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      complexity: '',
    },
    onSubmit,
  });

  const isPasswordWeak = !!errors.newPassword;

  if (loading[authApi.getResetPasswordInfo.typePrefix]) {
    return (
      <Box h={theme.sizes[40]}>
        <SpinContainer>
          <Spin size='large' />
        </SpinContainer>
      </Box>
    );
  }

  return (
    <Form layout='vertical' onFinish={handleSubmit} className='auth-form'>
      <CheckPasswordWrapper isPasswordWeak={isPasswordWeak}>
        <FormInput
          type='password-check'
          name='newPassword'
          onChange={handleChange}
          label={<InputLabel>New password</InputLabel>}
          value={values.newPassword}
          validateStatus={errors.newPassword ? 'error' : 'success'}
          help={errors.newPassword || errors.complexity}
          minLength={resetPasswordInfo?.passwordLength || DEFAULT_MIN_PASSWORD_LENGTH}
        />
        {isPasswordWeak && (
          <Box mb={theme.spacing[4]}>
            <PasswordDescription>
              Password should contain minimum of{' '}
              {resetPasswordInfo?.passwordLength || DEFAULT_MIN_PASSWORD_LENGTH} case sensitive
              alphanumeric characters including symbols.
            </PasswordDescription>
            <Box mt={theme.spacing[1]} className='example'>
              <PasswordDescription>{'ex. !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'}</PasswordDescription>
            </Box>
          </Box>
        )}
      </CheckPasswordWrapper>

      <FormInput
        type='password'
        name='confirmPassword'
        label={<InputLabel>Confirm password</InputLabel>}
        value={values.confirmPassword}
        onChange={handleChange}
        validateStatus={errors.confirmPassword ? 'error' : 'success'}
        help={
          <Box textAlign='left' mt={theme.spacing[2]}>
            {errors.confirmPassword}
          </Box>
        }
        isFeedbackCentered
      />
      {isFirefox && loadingResetPassword && (
        <Box display='flex' justify='center'>
          <Spin />
        </Box>
      )}
      <StyledButton
        type='primary'
        htmlType='submit'
        disabled={!isValid || !values.confirmPassword || !values.newPassword}
        loading={loading[authApi.resetPasswordConfirm.typePrefix]}
        $isHidden={isFirefox && loadingResetPassword}
        block
      >
        Reset password
      </StyledButton>
    </Form>
  );
};
