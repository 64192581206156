import { UcFirstLetter } from 'shared';
import { ActiveServiceStatusEnum } from 'services/api';
import { TagValue } from 'shared/TagColoring/constants';
import { StatusTag } from '../styled';

type IconColoringProps = {
  status: string;
};

export const StatusColoring = ({ status }: IconColoringProps) => {
  const statusType = (status: string) => {
    switch (status) {
      case ActiveServiceStatusEnum.RISK:
        return TagValue.Error;
      default:
        return TagValue.Success;
    }
  };

  return (
    <StatusTag key={status} className={`ant-tag-${statusType(status)}`}>
      {UcFirstLetter(status)}
    </StatusTag>
  );
};
