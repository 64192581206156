import styled from 'styled-components';
import { breakpoints, getCssVar } from 'styles/theme';
import { Icon } from 'shared';

export const Message = styled.div`
  align-items: center;
  backdrop-filter: blur(15px);
  background: ${getCssVar('notFoundPage.background')};
  border: 8px solid ${getCssVar('notFoundPage.borderColor')};
  color: ${getCssVar('notFoundPage.textColor')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 650px;
  opacity: 0.9;
  padding: 32px 30px 34px;
  width: 100%;

  a {
    color: ${getCssVar('notFoundPage.backLinkColor')};
    text-decoration: none;
  }

  &.content-top {
    margin-bottom: 30vh;

    @media (max-width: ${breakpoints.sm}) {
      margin-bottom: 40vh;
    }
    @media (max-height: 850px) {
      margin-bottom: 43vh;
    }
    @media (max-height: ${breakpoints.sm}) {
      margin-bottom: 50px;
    }
  }
`;

export const MessageHeader = styled.div`
  color: ${getCssVar('notFoundPage.textColor')};
  font-size: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  line-height: 28px;
  max-width: 650px;
  text-align: center;
`;

export const MessageText = styled.div`
  color: ${getCssVar('notFoundPage.textColor')};
  font-size: 15px;
  font-weight: 400;
  margin-top: 18px;
  max-width: 660px;
  text-align: center;
`;

export const MessageFooter = styled.div`
  color: ${getCssVar('notFoundPage.textColor')};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin-top: 28px;
  padding-bottom: 8px;
  text-align: center;
  text-transform: uppercase;
`;

export const Logo = styled.img`
  margin-bottom: 80px;
`;

export const SmartBlocIcon = styled(Icon)`
  height: 50px;
  margin-right: 5px;
  width: 200px;

  svg {
    color: ${getCssVar('notFoundPage.textColor')};
  }

  @media (max-width: ${breakpoints.lg}) {
    color: ${getCssVar('login.sidebar.mobile.smartBlocColor')};
  }
`;
