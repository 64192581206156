import * as theme from 'styles/theme';
import {
  NASSTAR_SUPPORT_EMAIL,
  NASSTAR_SUPPORT_PHONE,
  NORM_SUPPORT_EMAIL,
  NORM_SUPPORT_PHONE,
} from 'app-constants';
import { IloginBackground, Themes } from './types';
import { NormRebranding } from './Norm/components/NormRebranding';
import { Nasstar } from './Nasstar/components/Nasstar';

export const getColorSet = (themeName?: Themes) => {
  switch (themeName) {
    case Themes.Norm:
      return theme.norm;
    case Themes.DarkNorm:
      return theme.darkNorm;
    case Themes.Nasstar:
      return theme.nasstar;
    default:
      return theme.nasstar;
  }
};

export const getLoginRightBackground = ({ themeName, width }: IloginBackground) => {
  switch (themeName) {
    case Themes.DarkNorm:
    case Themes.Norm:
      return <NormRebranding width={width} />;
    case Themes.Nasstar:
      return <Nasstar width={width} />;
    default:
      return <Nasstar width={width} />;
  }
};

export const getSupportEmail = (themeName?: Themes) => {
  switch (themeName) {
    case Themes.Norm:
    case Themes.DarkNorm:
      return NORM_SUPPORT_EMAIL;
    case Themes.Nasstar:
      return NASSTAR_SUPPORT_EMAIL;
    default:
      return NORM_SUPPORT_EMAIL;
  }
};

export const getSupportPhone = (themeName?: Themes) => {
  switch (themeName) {
    case Themes.Norm:
    case Themes.DarkNorm:
      return NORM_SUPPORT_PHONE;
    case Themes.Nasstar:
      return NASSTAR_SUPPORT_PHONE;
    default:
      return NORM_SUPPORT_PHONE;
  }
};
