import { startCase } from 'lodash';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { Colors } from 'shared/charts/types';
import { Severity } from 'services/constants';
import { getCurrentVulnerabilitiesBySeverityColors } from 'pages/VulnerabilityManagement/constants';
import { ReportChart } from './ReportChart';

export type Values = Record<
  string,
  { value: number; prevValue?: number; prevDiffValue?: number; prevDiffPercentage?: number }
>;

interface Props {
  onChartLoaded: () => void;
  theme: Theme;
  values: Values;
  height: string;
  width: string;
}

interface DataInterface {
  name: string;
  itemStyle: { color: keyof Colors };
  value: number;
  label: Record<string, unknown>;
  cursor: 'pointer' | 'default';
}

const getOption = (values: Values, colors: Colors, theme: Theme) => {
  const seriesNames: string[] = [];
  const data: DataInterface[] = [];

  // const maxValue = Math.max(...Object.values(values).map(({ value }) => value));

  // const MAX_Y_AXIS_STEPS = 5;
  // let axisStep = Math.floor(maxValue / MAX_Y_AXIS_STEPS);
  // axisStep = Math.floor(axisStep / 100) * 100;

  // const yAxisMax = Math.ceil(maxValue / axisStep) * axisStep;

  Object.entries(values).forEach(([name, { value }]) => {
    seriesNames.push(startCase(name));

    // let formatter = '{c}';

    // if (typeof prevDiffValue === 'number') {
    //   if (prevDiffValue > 0) {
    //     formatter = `{negative|+${prevDiffValue}} (${prevDiffPercentage}%)`;
    //   }

    //   if (prevDiffValue < 0) {
    //     formatter = `{positive|${prevDiffValue}} (${prevDiffPercentage}%)`;
    //   }
    // }

    data.push({
      name,
      itemStyle: { color: colors[name.toLowerCase()] },
      value,
      label: {
        show: true,
        position: 'top',
        color: 'blue',
        fontSize: 9,
        fontWeight: 500,
        // formatter,
        rich: {
          positive: {
            // color: '#43a047',
            fontSize: 9,
            fontFamily: theme.colorSet.lessFontFamily,
          },
          negative: {
            // color: '#9C2B23',
            fontSize: 9,
            fontFamily: theme.colorSet.lessFontFamily,
          },
        },
      },
      cursor: name !== Severity.Analysing ? 'pointer' : 'default',
    });
  });

  return {
    grid: {
      left: 0,
      top: 20,
      right: 0,
      bottom: 0,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: seriesNames,
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          interval: 0,
          fontSize: 9,
          fontFamily: theme.colorSet.lessFontFamily,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        // max: yAxisMax,
        axisLabel: {
          show: true,
          interval: 0,
          fontSize: 9,
          fontFamily: theme.colorSet.lessFontFamily,
        },
        // interval: AXIS_STEP,
        splitLine: {
          show: true,
          lineStyle: {
            color: 'lightgrey',
            width: 0.3,
          },
        },
      },
    ],
    series: [
      {
        data,
        type: 'bar',
        name: 'Total',
        barWidth: 30,
        label: {
          show: true,
          position: 'top',
          formatter({ data: { value, name } }: { data: { value: number; name: keyof Values } }) {
            const { prevDiffValue, prevDiffPercentage } = values[name];

            if (typeof prevDiffValue === 'number') {
              if (prevDiffValue > 0) {
                return `+${prevDiffValue} (${prevDiffPercentage}%)`;
              }

              if (prevDiffValue < 0) {
                return `${prevDiffValue} (${prevDiffPercentage}%)`;
              }
            }

            return `${value}`;
          },
        },
      },
    ],
  };
};

export const Wrapper = styled.div`
  > div {
    background: transparent;
    z-index: 0;
  }
`;

export function MultiColumnsReportChart({ onChartLoaded, theme, values, height, width }: Props) {
  return (
    <Wrapper>
      <ReportChart
        width={width}
        height={height}
        onFinished={onChartLoaded}
        option={getOption(values, getCurrentVulnerabilitiesBySeverityColors(theme), theme)}
      />
    </Wrapper>
  );
}
