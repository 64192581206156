import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppState, useAuth } from 'store';
import { PermissionsLevel } from 'app-constants/permissions';
import { Icon } from 'shared';
import {
  checkSuperAdminPermission,
  getPermissionLevel,
  getPermissionLevelToSupportGrid,
} from 'shared/AuthRouter/helpers';
import { Routes } from 'services/entities';
import { useTheme } from 'styled-components';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import {
  Menu,
  MenuItemGroup,
  MenuTooltipLink,
  MenuLogoFooter,
  MenuLogoFooterSpace,
  SubMenuStyled,
} from './styled';
import { calculateOpenKeys } from '../helpers';
import { menuItems, menuItemsAdmin } from './constants';
import { MenuItem, MenuItemChildren } from '../types';
import { createLinkHistoryState } from './helpers';

const OFFSET_X = 20;
const OFFSET_Y = 10;

export type MainMenuProps = {
  collapsed?: boolean;
  onClose?: () => void;
};

export const MainMenu = ({ collapsed, onClose }: MainMenuProps) => {
  const currentTheme = useTheme();
  const isMobile = useSelector(({ app: { isMobile } }: AppState) => isMobile);
  const location = useLocation();
  const { user, permissions } = useAuth();

  const [openKeys, setOpenKeys] = useState<string[]>(
    calculateOpenKeys(location.pathname as Routes),
  );

  useEffect(() => {
    setOpenKeys(collapsed ? [] : calculateOpenKeys(location.pathname as Routes));
  }, [collapsed, location.pathname]);

  const menuItemsWithPermissions = menuItems
    .filter((item: MenuItem) => {
      return (
        getPermissionLevel(item.path, permissions, () =>
          getPermissionLevelToSupportGrid(item.path, permissions),
        ) !== PermissionsLevel.NoAccess && checkSuperAdminPermission(item.path, user.isSuperAdmin)
      );
    })
    .reduce((acc: MenuItem[], item: MenuItem) => {
      if (!item.children) {
        return [...acc, item];
      }

      const children = item.children.filter((child: MenuItemChildren) => {
        return (
          getPermissionLevel(child.path, permissions) !== PermissionsLevel.NoAccess &&
          checkSuperAdminPermission(item.path, user.isSuperAdmin)
        );
      });

      if (!children.length) {
        return acc;
      }

      return [
        ...acc,
        {
          ...item,
          children,
        },
      ];
    }, []);

  const menuAdminItemsWithPermissions = menuItemsAdmin.filter((item: MenuItem) => {
    const permissionsLevel = getPermissionLevel(item.path, permissions);

    return (
      permissionsLevel &&
      permissionsLevel !== PermissionsLevel.NoAccess &&
      checkSuperAdminPermission(item.path, user.isSuperAdmin)
    );
  });

  const handleOpenChange = useCallback(
    (keys: React.Key[]) => {
      setOpenKeys(keys as string[]);
    },
    [setOpenKeys],
  );

  const renderAdminMenu = menuAdminItemsWithPermissions.length > 0;

  return (
    <>
      <Menu
        className='app-sidebar-menu'
        $currentTheme={currentTheme}
        mode={collapsed ? 'vertical' : 'inline'}
        defaultSelectedKeys={[Routes.DASHBOARD]}
        selectedKeys={Object.values(Routes).filter(
          (route: Routes) => route.split('/')[1] === location.pathname.split('/')[1],
        )}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        $collapsed={collapsed}
      >
        {menuItemsWithPermissions.map((item: MenuItem) => (
          <React.Fragment key={item.path}>
            {item?.children ? (
              <SubMenuStyled
                className='app-sidebar-menu-submenu'
                title={!collapsed && item.name}
                key={`sub-${item.path}`}
                icon={<Icon className='ant-menu-item-icon' component={item.icon} />}
                $collapsed={collapsed}
              >
                {item.children.map((itemInner) => (
                  <Menu.Item key={itemInner.path}>
                    <Link
                      style={{ paddingLeft: 16 }}
                      to={createLinkHistoryState(itemInner.path)}
                      onClick={onClose}
                    >
                      {itemInner.name}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenuStyled>
            ) : (
              <>
                {collapsed ? (
                  <Menu.Item key={item.path} icon={<Icon component={item.icon} />}>
                    <Tooltip
                      title={
                        <MenuTooltipLink to={createLinkHistoryState(item.path)} onClick={onClose}>
                          {item.name}
                        </MenuTooltipLink>
                      }
                      placement='right'
                      align={{ offset: [OFFSET_X, OFFSET_Y] }}
                    >
                      <Link to={createLinkHistoryState(item.path)} onClick={onClose} />
                    </Tooltip>
                  </Menu.Item>
                ) : (
                  <Menu.Item key={item.path} icon={<Icon component={item.icon} />}>
                    <Link to={createLinkHistoryState(item.path)} onClick={onClose}>
                      {item.name}
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </React.Fragment>
        ))}
        {renderAdminMenu && (
          <MenuItemGroup $collapsed={collapsed || false} key='adminMenu' title='Admin'>
            {menuAdminItemsWithPermissions.map((item: MenuItem) => (
              <React.Fragment key={item.path}>
                {collapsed ? (
                  <Menu.Item key={item.path} icon={<Icon component={item.icon} />}>
                    <Tooltip
                      title={
                        <MenuTooltipLink to={createLinkHistoryState(item.path)} onClick={onClose}>
                          {item.name}
                        </MenuTooltipLink>
                      }
                      align={{ offset: [OFFSET_X, OFFSET_Y] }}
                      placement='right'
                    >
                      <Link to={createLinkHistoryState(item.path)} onClick={onClose} />
                    </Tooltip>
                  </Menu.Item>
                ) : (
                  <Menu.Item key={item.path} icon={<Icon component={item.icon} />}>
                    <Link to={createLinkHistoryState(item.path)} onClick={onClose}>
                      {item.name}
                    </Link>
                  </Menu.Item>
                )}
              </React.Fragment>
            ))}
          </MenuItemGroup>
        )}
      </Menu>
      {currentTheme.colorSet.leftMenu.logoBottom && !isMobile && (
        <>
          <MenuLogoFooter
            className='app-side-bar-menu-logo-footer'
            $collapsed={collapsed}
            component={
              collapsed
                ? currentTheme.colorSet.leftMenu.logoBottomCollapsed
                : currentTheme.colorSet.leftMenu.logoBottom
            }
          />
          <MenuLogoFooterSpace />
        </>
      )}
    </>
  );
};
