import { colors } from '../colors';
import { fontWeights } from '../fontWeights';
import { sizes } from '../sizes';
import { baseNorm } from './base';

export const login = {
  logoDesktop: 'menu-logo-top-norm.svg',
  logoMobile: 'normcyber_logo_mobile.svg',
  logoWidth: '110px',
  logoHeight: '25px',
  alert: {
    error: {
      backgroundColor: '#b3000033', // '#512c27',
      textColor: '#FFFFFFCC',
      borderColor: '#644343',
    },
  },
  passwordStrengthColors: [baseNorm.red, baseNorm.amber, baseNorm.amber, baseNorm.green],
  zeroStrengthColor: '#707070',

  sidebar: {
    background: baseNorm.black,
    logoColor: colors.white,
    smartBlocColor: colors.white,
    mobile: {
      background: '#231f20d4',
      logoColor: colors.white,
      smartBlocColor: colors.white,
    },
  },
  formWrap: {
    background: colors.gray[550],
    borderRadius: sizes['3.6'],
    margin: '0 auto 20px',
    padding: '50px 35px',
    maxWidth: '400px',
    mobile: {
      background: colors.gray[550],
    },
  },
  formTitle: {
    color: colors.white,
    fontSize: sizes[7],
    fontWeight: fontWeights.medium,
    marginBottom: sizes['3.6'],
    mobile: {
      color: colors.white,
    },
  },
  formSubTitle: {
    color: colors.white,
    fontSize: sizes[4],
    margin: `${sizes[0]} ${sizes[0]} ${sizes['3.6']}`,
    mobile: {
      color: colors.white,
    },
  },
  formLabel: {
    color: colors.white,
    opacity: '1',
    mobile: {
      color: colors.white,
      opacity: '1',
    },
  },
  formInput: {
    backgroundColor: colors.gray[850],
    textColor: colors.white,
    borderColor: colors.gray[180],
    borderColorHover: colors.gray[500],
    borderColorError: '#EB644A',
    iconBackgroundColor: colors.gray[850],
    iconColor: colors.gray[210],
    errorMessageColor: '#EB644A',
  },
  link: {
    color: '#9AB1FF',
    hoverColor: '#9AB1FF',
    fontSize: sizes[4],
    fontWeight: fontWeights.bold,
    mobile: {
      color: '#9AB1FF',
      hoverColor: '#9AB1FF',
      fontSize: sizes[4],
      fontWeight: fontWeights.bold,
    },
  },
  tfa: {
    textColor: colors.white,
    linkColor: '#9AB1FF',
    linkColorHover: '#9AB1FF',
    copyButtonColor: '#9AB1FF',
    copyButtonHoverColor: '#9AB1FF',
    inputTextColor: colors.white,
    inputBackgroundColor: colors.gray[850],
    inputBorderColor: baseNorm.spanishGray,
    mobile: {
      textColor: colors.white,
    },
  },
};
