import styled from 'styled-components';
import { Table, AsideDescription, Badge } from '../styled';
import { smallFontSize } from '../config';

export const ServiceConsumption = styled(AsideDescription)`
  color: #fff;
  left: 90px;
  top: 160px;
`;

export const InfoContainer = styled.div`
  bottom: 0;
  left: 588px;
  position: absolute;
  top: 0;
  width: 430px;
`;

export const LicenseUsageTableWrapper = styled.div`
  position: absolute;

  top: 390px;
`;

export const LicenseUsageTitle = styled(Badge)`
  margin-bottom: 15px;
`;

export const LicenseUsageTable = styled(Table)`
  font-size: ${smallFontSize};

  tr > th,
  tr > td {
    padding: 5px 0px;
  }

  tr:first-child > td {
    padding-top: 10px;
  }
`;

export const ServiceHeaderCell = styled.th`
  width: 270px;
`;

export const UsedHeaderCell = styled.th`
  width: 80px;
`;

export const CurrentLimitHeaderCell = styled.th`
  width: 80px;
`;

export const ChartWrapper = styled.div`
  position: absolute;
  top: 62px;
  width: 100%;
`;

export const ChartTitleWrapper = styled.div`
  align-items: center;
  display: flex;

  font-weight: 700;

  margin-bottom: 15px;
`;

export const ChartTitle = styled(Badge)`
  margin-right: 5px;
`;

export const SubTitle = styled.div``;

export const EventsChart = styled.div`
  background-color: transparent;
  position: absolute;
  width: 100%;
`;
