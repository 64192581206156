import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useGetPermissions } from 'services/hooks';
import { useAllVulnerabilitiesTrend, useCustomerChange, useLoading } from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import {
  backlinkRoutes,
  initialPagination,
  PermissionOptions,
  PermissionsLevel,
} from 'app-constants';
import {
  VulnerabilityStatus,
  VulnerabilityTrendPointSchema,
  VulnerabilityTrendPointTypeSchema,
} from 'services/api';
import { Routes } from 'services/entities';
import { getSubTitleArrow } from 'services/helpers';
import { getQueryString } from 'services/api/core/request';
import { getOption as getLineOption } from 'pages/VulnerabilityManagement/components/AllVulnerabilities/helpers';
import { emptyValuesAllVulnerabilities } from 'pages/VulnerabilityManagement/constants';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { Line } from 'shared/charts';
import { RadioButtonsDays } from 'shared/RadioButtonsWithRangePicker/constants';
import { getDefaultDateRangeValue } from 'shared/RadioButtonsWithRangePicker/helpers';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import {
  confirmedVulnerabilitiesStatusFilter,
  initialOrderBy,
  tabsKeys as vulnerabilitiesListTabs,
} from 'pages/VulnerabilitiesList/constants';
import { EmptyValuesAllVulnerabilities } from 'pages/VulnerabilityManagement/types';
import { ScrollContainer, ShowMoreButton } from 'shared';
import { useTheme } from 'styled-components';
import { TooltipTitles, viewMoreConfig } from '../../constants';
import { StyledCard } from './styled';
// import { StyledCard, StyledSeeAllVulnsLink } from './styled';

interface VulnerabilityManagementCardProps {
  customerId: string;
  isMobile: boolean;
}

export const VulnerabilityManagementCard = ({
  customerId,
  isMobile,
}: VulnerabilityManagementCardProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const allVulnerabilitiesTrend = useAllVulnerabilitiesTrend();
  const loading = useLoading([vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix]);

  const customerChange = useCustomerChange([
    vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix,
  ]);
  const isShowEmptyChartAllVulnerabilitiesTrend =
    customerChange[vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix];

  const permissions = useGetPermissions([
    PermissionOptions.VulnerabilityManagement,
    PermissionOptions.SupportRequests,
  ]);
  const history = useHistory();
  const eventsDate = React.useMemo(
    () => ({
      startDate: moment()
        .subtract(RadioButtonsDays.SevenDays - 1, 'days')
        .startOf('day')
        .valueOf(),
      endDate: moment().valueOf(),
    }),
    [],
  );

  const redirectToVulnerabilitiesList = useCallback(() => {
    const vulnerabilitiesParams = {
      orderBy: initialOrderBy,
      pagination: initialPagination,
      tab: vulnerabilitiesListTabs.active,
      filter: confirmedVulnerabilitiesStatusFilter,
    };

    return history.push(`${Routes.VULNERABILITIES_LIST}${getQueryString(vulnerabilitiesParams)}`, {
      backTo: {
        route: Routes.DASHBOARD,
        title: `Back to ${backlinkRoutes[Routes.DASHBOARD]}`,
      },
    });
  }, [history]);

  React.useEffect(() => {
    dispatch(
      vulnerabilitiesApi.getAllVulnerabilitiesTrend({
        customerId,
        startDate: getDefaultDateRangeValue().startDate,
        endDate: getDefaultDateRangeValue().endDate,
        pointType: VulnerabilityTrendPointTypeSchema.DAY,
        vulnerabilityStatus: [VulnerabilityStatus.CONFIRMED],
      }),
    );
  }, [dispatch, customerId, eventsDate]);

  return (
    <StyledCard
      title='Vulnerability management'
      tooltipTitle={TooltipTitles.VulnerabilityManagement}
      bodyPadding={theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[7]} ${theme.sizes[8]} ${theme.sizes['5.5']}`
          : `${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[2]} ${theme.sizes[4]}`
      }
      headStyles={{ headAlign: 'flex-start', maxWidthTitle: isMobile ? '190px' : '250px' }}
      subTitle={
        <SubTitle
          boldText={
            allVulnerabilitiesTrend.count ? allVulnerabilitiesTrend.count.toLocaleString('ru') : ''
          }
          isLocked={permissions.vulnerabilityManagement === PermissionsLevel.NoAccess}
          lightText={
            allVulnerabilitiesTrend.percentageComparison
              ? `${allVulnerabilitiesTrend.percentageComparison.toFixed(2)}%`
              : ''
          }
          period='last month'
          arrow={getSubTitleArrow(allVulnerabilitiesTrend.percentageComparison)}
          paddingRight={theme.sizes[4]}
        />
      }
      showDivider={false}
      getAccessMessage={
        permissions.vulnerabilityManagement === PermissionsLevel.NoAccess &&
        permissions.supportRequests === PermissionsLevel.Full
          ? {
              text: 'Order Vulnerability Scanning',
              href: `${Routes.SERVICE_REQUEST}?type=purchase&product=Vulnerability%20Scanning`,
              layout: 'column',
            }
          : undefined
      }
      viewMore={
        permissions.vulnerabilityManagement === PermissionsLevel.NoAccess
          ? undefined
          : viewMoreConfig.vulnerabilityManagement
      }
      showLoader={loading[vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix]}
      headAction={
        permissions.vulnerabilityManagement === PermissionsLevel.NoAccess ? null : (
          <ShowMoreButton onClick={redirectToVulnerabilitiesList} pt={theme.sizes['2.5']}>
            See all vulnerabilities
          </ShowMoreButton>
        )
      }
    >
      <ScrollContainer width='600px' className='default-cursor-chart'>
        <TotalCustomTooltipContainer>
          <RenderChartWrapper<VulnerabilityTrendPointSchema, EmptyValuesAllVulnerabilities>
            values={
              permissions.vulnerabilityManagement === PermissionsLevel.NoAccess
                ? emptyValuesAllVulnerabilities
                : allVulnerabilitiesTrend?.trend?.points
            }
            emptyValues={emptyValuesAllVulnerabilities}
            emptyText='No vulnerabilities management data yet'
            isShowEmptyChart={isShowEmptyChartAllVulnerabilitiesTrend}
          >
            <Line
              values={emptyValuesAllVulnerabilities}
              getOption={getLineOption}
              isMobile={isMobile}
              height={{
                grid: '290px',
                chart: !allVulnerabilitiesTrend?.trend?.points ? '350px' : '330px',
              }}
            />
          </RenderChartWrapper>
        </TotalCustomTooltipContainer>
      </ScrollContainer>
    </StyledCard>
  );
};
