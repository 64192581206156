import styled from 'styled-components';
import { Descriptions as AntdDescriptions } from 'antd';
import { Box, Card, Icon } from 'shared';
import { Theme } from 'styles/theme/types';
import { CardFooter } from 'shared/Card/components/styled';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
  ws?: string;
};

export const MainCardContentHeader = styled.span<Props>`
  font-size: 18px;
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
`;

export const MainCardContent = styled.div<Props>`
  font-size: 15px;
  margin: ${({ theme }: Props) => `${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[0]}`};
  white-space: ${({ ws }: Props) => ws};

  width: ${({ theme }: Props) => theme.sizes.full};
  word-wrap: break-word;
`;

export const MainCardBlock = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }: Props) => `${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[6]}`};

  &:last-child {
    margin-bottom: ${({ theme }: Props) => theme.sizes['0.5']};
  }
`;

export const ClipboardIcon = styled(Icon)<Props>`
  color: ${getCssVar('iconClipboard')} !important;
  cursor: pointer;
  height: ${({ theme }: Props) => theme.sizes.full};
  transition: 300ms;
  width: ${({ theme }: Props) => theme.sizes.full};

  &:hover {
    color: ${getCssVar('iconClipboardHover')} !important;
  }
`;

export const CveContainer = styled.div<Props>`
  font-size: ${({ theme }: Props) => theme.sizes[4]};
  max-height: 220px;
  overflow-y: auto;

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    margin-left: ${({ theme }: Props) => theme.sizes[2]};
    padding: ${({ theme }: Props) => `${theme.sizes[4]} ${theme.sizes[0]}`};
  }
`;

export const Descriptions = styled(AntdDescriptions)<Props>`
  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    padding-top: ${({ theme }: Props) => theme.sizes[4]};
  }

  .ant-descriptions-row {
    .ant-descriptions-item {
      padding: ${({ theme }: Props) => `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[7]}`};

      .ant-descriptions-item-label {
        width: ${({ theme }: Props) => theme.sizes[36]};
        align-items: center;
        font-weight: ${({ theme }: Props) => theme.fontWeights.medium};
        color: ${({ theme }: Props) => theme.colors.blue[900]};

        &:after {
          display: none;
        }
      }

      .anticon {
        color: ${getCssVar('activeColor')};
      }
    }

    &:last-child {
      .ant-descriptions-item {
        padding-bottom: ${({ theme }: Props) => theme.sizes[0]};

        @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
          padding-bottom: ${({ theme }: Props) => theme.sizes[4]};
        }
      }
    }
  }
`;

export const CardStyled = styled(Card)<Props>`
  ${CardFooter} {
    @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
      padding-top: ${({ theme }: Props) => theme.sizes[1]};
    }
  }
`;

export const ItemContainer = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;

  > div > span {
    flex: 0;
    margin-left: ${({ theme }: Props) => theme.sizes['2.5']};
  }
`;

export const LinkStyled = styled.a<Props>`
  font-size: 15px;
`;

export const HeaderActionWrap = styled.div<Props>`
  align-items: center;
  display: inline-flex;

  justify-content: space-between;
`;

export const AffectedHostsWrap = styled(Box)<Props>`
  align-items: center;
  display: flex;

  button.ant-btn-link {
    font-size: 15px;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }
`;
