import { colors } from '../../colors';
import { inputsBase } from './base';

export const datePicker = {
  checked: {
    bg: inputsBase.activeColor,
    borderColor: inputsBase.activeColor,
    inputBg: colors.gray[30],
  },
  bg: inputsBase.backgroundColor,
  textColor: inputsBase.textColor,
  iconColor: inputsBase.iconColor,
  clearIconColor: colors.gray[150],
  borderColor: inputsBase.borderColor,
  hoverBorderColor: inputsBase.borderColorHover,
  rangeHoverBorderColor: inputsBase.activeColor,
  color: colors.green[200],
  pickerPanelBg: colors.gray[100],
  pickerPanelBorder: colors.gray[300],
  pickerPanelTextColor: colors.brown[500],
  innerBg: colors.gray[90],
  cellRangeBg: colors.gray[50],
  disabledRangeBG: colors.gray[50],
  nextPrevIconColor: inputsBase.iconColor,
  nextPrevIconColorHover: inputsBase.activeColor,
};
