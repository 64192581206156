import React from 'react';
import { useFilter } from 'services/hooks/useFilter';
import { InnerLayout, MobileTable, MobileTableCol } from 'shared';
import { useDispatch } from 'react-redux';
import { Gutter } from 'antd/lib/grid/row';
import { Row } from 'antd';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { CustomTable } from 'shared/Table/CustomTable';
import { auditApi, useApp, useAudit, useAuth, useCustomer } from 'store';
import { emptyMessage as setEmptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { getLayoutConfig } from './helpers';
import { getFilterFields, initialOrderBy, searchSettings } from './constant';
import { GetUserColumns, columns } from './components/TableColumns';

const defaultPageSize = 50;
const defaultCurrent = 1;
const GUTTER_DIR = 32;
const GUTTER: [Gutter, Gutter] = [GUTTER_DIR, GUTTER_DIR];

export const AuditLog = () => {
  const layoutConfig = getLayoutConfig();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const customer = useCustomer();
  const { isMobile } = useApp();
  const { data, isPending, actionTypes } = useAudit();
  const isNormUser = user.isSuperAdmin || false;

  const [params, updateParams] = useFilter({
    apiCall: auditApi.getAuditLogs,
    dispatch,
    isMobile,
    customerId: customer,
    initialOrderBy,
    page: 'AuditLog',
  });

  React.useEffect(() => {
    dispatch(auditApi.getAuditActionTypes({ customerId: customer }));
  }, [dispatch, customer]);

  const filterFields = React.useMemo(() => {
    return getFilterFields(actionTypes);
  }, [actionTypes]);

  const emptyMessage = setEmptyMessage('No audit logs yet');

  const tableColumns = isNormUser
    ? getTableColumns(columns, params.orderBy)
    : getTableColumns(GetUserColumns(), params.orderBy);

  return (
    <InnerLayout {...layoutConfig}>
      {isMobile ? (
        <Row gutter={GUTTER}>
          <MobileTableCol flex='auto'>
            <MobileTable
              data={data}
              searchSettings={searchSettings}
              filterFields={filterFields}
              columns={tableColumns}
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              isLoadingContent={isPending}
              emptyMessage={emptyMessage}
            />
          </MobileTableCol>
        </Row>
      ) : (
        <CustomTable
          data={data}
          searchSettings={searchSettings}
          columns={tableColumns}
          params={params}
          setParams={updateParams}
          filterFields={filterFields}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          isLoadingContent={isPending}
          emptyMessage={emptyMessage}
        />
      )}
    </InnerLayout>
  );
};
