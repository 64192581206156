import { createGlobalStyle, css } from 'styled-components';
import {
  breakpoints,
  colors,
  createDarkCSSVarsFromObject,
  createNasstarCSSVarsFromObject,
  createNormCSSVarsFromObject,
  getCssVar,
} from 'styles/theme/';
import React from 'react';
import { CheckBoxStyles } from 'shared';
import { useApp } from '../../store';
import { Themes } from '../../themes/types';
// import { ThemeProps } from '../../services/interfaces';

const antCssVarsOverride = css`
  body {
    background-color: ${getCssVar('bodyBackground')};
    font-family: ${getCssVar('lessFontFamily')};
  }

  --ant-error-color: #ff4d4f;
  --ant-error-color-active: #d9363e;
  --ant-error-color-deprecated-bg: #fff2f0;
  --ant-error-color-deprecated-border: #ffccc7;
  --ant-error-color-hover: #ff7875;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);
  --ant-info-color: #1890ff;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #91d5ff;
  --ant-primary-1: #e6f7ff;
  --ant-primary-2: #bae7ff;
  --ant-primary-3: #91d5ff;
  --ant-primary-4: #69c0ff;
  --ant-primary-5: #40a9ff;
  --ant-primary-6: #1890ff;
  --ant-primary-7: #096dd9;
  --ant-primary-color: ${getCssVar('primaryColor')};
  --ant-primary-color-active: ${getCssVar('primaryColor')};
  --ant-primary-color-active-deprecated-d-02: #dcf4ff;
  --ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --ant-primary-color-deprecated-l-20: #7ec1ff;
  --ant-primary-color-deprecated-l-35: #cbe6ff;
  --ant-primary-color-deprecated-t-20: #46a6ff;
  --ant-primary-color-deprecated-t-50: #8cc8ff;
  --ant-primary-color-hover: ${getCssVar('primaryColor')};
  --ant-primary-color-outline: ${getCssVar('primaryColor')};
  --ant-success-color: #52c41a;
  --ant-success-color-active: #389e0d;
  --ant-success-color-deprecated-bg: #f6ffed;
  --ant-success-color-deprecated-border: #b7eb8f;
  --ant-success-color-hover: #73d13d;
  --ant-success-color-outline: rgba(82, 196, 26, 0.2);
  --ant-warning-color: #faad14;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-deprecated-bg: #fffbe6;
  --ant-warning-color-deprecated-border: #ffe58f;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);
`;

const GlobalCSSVarsDark = createGlobalStyle`
  :root {
    ${createDarkCSSVarsFromObject()}
    ${antCssVarsOverride}
  }
`;

const GlobalCSSVarsLight = createGlobalStyle`
  :root {
    ${createNormCSSVarsFromObject()}
    ${antCssVarsOverride}
  }
`;

const GlobalCSSVarsNasstar = createGlobalStyle`
  :root {
    ${createNasstarCSSVarsFromObject()}
    ${antCssVarsOverride}
  }
`;

const ThemeMap = {
  [Themes.DarkNorm]: GlobalCSSVarsDark,
  [Themes.Norm]: GlobalCSSVarsLight,
  [Themes.Nasstar]: GlobalCSSVarsNasstar,
};

export const CSSVarsProvider = () => {
  const { themeName } = useApp();

  return React.createElement(ThemeMap[themeName || Themes.Norm]);
};

export const GlobalStyles = createGlobalStyle`
  * {
    font-family:${getCssVar('fontFamily')};

     &::selection {
           color: ${getCssVar('textColor')};
           background-color: #a6a6a680;
        }
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  /* modal overlay */
  .ant-modal-mask {
    background-color: ${getCssVar('modal.overlayColor')};
  }

  /* used with lock icons to set color separatly for each theme */
  .icon-lock {
    color: ${getCssVar('iconLock')};
  }

  /* checkbox */
  .ant-checkbox-wrapper {
    ${CheckBoxStyles};
  }

  /* datePicker */
  .ant-form-item-has-error .ant-input-number:not([disabled]):hover, .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    background-color: ${getCssVar('datePicker.bg')};
  }

  .ant-picker-panel-container {
    background-color: ${getCssVar('datePicker.bg')};
  }

  .ant-picker-panel {
    border-color: ${getCssVar('divider.color')};

    .ant-picker-footer {
      border-color: ${getCssVar('divider.color')};
    }
  }

  .ant-picker-dropdown.ant-picker-dropdown-range .ant-picker-range-arrow {
    display: none;
  }


  /* svg icon clear */
  .ant-picker-clear {
    background-color: ${getCssVar('datePicker.bg')};

    svg {
      background-color: ${getCssVar('datePicker.checked.inputBg')};
      fill: ${getCssVar('datePicker.clearIconColor')};
      width: 1.15em;
      height: 1.15em;
    }
  }

  .ant-picker-header {
    color: ${getCssVar('textColor')};
    border-color: ${getCssVar('divider.color')};

    button {
      color: ${getCssVar('datePicker.textColor')};

      &:hover {
        color: ${getCssVar('primaryColor')};
      }

      .ant-picker-super-prev-icon,
      .ant-picker-super-next-icon,
      .ant-picker-prev-icon,
      .ant-picker-next-icon {
        color: ${getCssVar('datePicker.nextPrevIconColor')};

        &:hover {
          color: ${getCssVar('datePicker.nextPrevIconColorHover')};
        }
      }
    }
  }

  .ant-picker-content th {
    color: ${getCssVar('textColor')};
  }

  .ant-picker-cell {
    color: ${getCssVar('textColor')};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color: ${getCssVar('datePicker.innerBg')};
  }

  .ant-picker-cell-disabled::before {
    background-color: ${getCssVar('datePicker.disabledRangeBG')};
  }

  .ant-picker-cell-disabled .ant-picker-cell-inner {
    color: ${getCssVar('textColor')};
    opacity: 0.3;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: ${getCssVar('datePicker.cellRangeBg')};
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: ${getCssVar('datePicker.cellRangeBg')};
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${getCssVar('datePicker.innerBg')};
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: ${getCssVar('datePicker.innerBg')};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-color: ${getCssVar('datePicker.rangeHoverBorderColor')};
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-color: ${getCssVar('datePicker.rangeHoverBorderColor')};
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-color: ${getCssVar('datePicker.rangeHoverBorderColor')};
  }

  .ant-form-item-has-error .ant-input-number, .ant-form-item-has-error .ant-picker {
    background-color: ${getCssVar('select.bg')};
  }

  .ant-picker-dropdown {
    .ant-tag {
      &.ant-tag-blue {
        border-color: ${getCssVar('datePicker.pickerPanelBorder')};
        background-color: ${getCssVar('datePicker.pickerPanelBg')} !important;
        color: ${getCssVar('datePicker.pickerPanelTextColor')} !important;
        border-width: 1px;
      }
    }

    .ant-picker-panel-container {
      .ant-picker-panel {
        border-bottom-width: 1px;
      }

      .ant-picker-header {
        border-bottom-width: 1px;
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${getCssVar('datePicker.checked.bg')};
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 2px solid ${getCssVar('datePicker.checked.borderColor')};
  }

  /* menu */
  .ant-dropdown-menu {
    background-color: ${getCssVar('select.dropDownActions.backgroundColor')};

    .ant-dropdown-menu-item {
      color: ${getCssVar('select.textColor')};
      a {
        color: ${getCssVar('select.textColor')};
      }
      &:hover {
        background-color: ${getCssVar('select.dropDownActions.backgroundColorHover')};
      }
    }
  }

  .ant-menu-sub .ant-menu-item-selected .ant-menu-title-content a:hover {
    color: ${getCssVar('menu.item.selected.color')};
  }

  .ant-menu-submenu-popup > .ant-menu {
    background-color: ${getCssVar('menu.item.selected.bg')};

    a {
      color: ${getCssVar('menu.item.selected.color')};
    }
  }

  /* snackbar style */
  .ant-message-notice {
    color: ${getCssVar('snackBar.color')};

    .ant-message-notice-content {
      background-color: ${getCssVar('snackBar.bg')};
    }
  }

  .ant-progress-text {
    color: ${getCssVar('textColor')};
  }

  /* table */

  .ant-table {
    background-color: ${getCssVar('table.bg')};
  }

  .ant-empty-description {
    color: ${getCssVar('table.tr.color')};
  }

  .ant-table-placeholder > td {
    color: ${getCssVar('table.tr.color')};
  }

  .ant-table-cell-scrollbar {
    box-shadow: 0 2px transparent;
  }

  .ant-table-tbody > tr > td {
    border-bottom-color: ${getCssVar('divider.color')};
  }

  .ant-table-row:hover {
    background-color: ${getCssVar('table.tr.hover.bg')} !important;

    > td {
       background-color: transparent !important;
    }
  }

  .ant-table-row-expand-icon {
    color: ${getCssVar('table.expandIcon')};
    background-color: ${getCssVar('inputs.backgroundColor')};
    border-color: ${getCssVar('inputs.borderColor')};
  }

  /* description item style */
  .ant-descriptions-item-content {
    color: ${getCssVar('textColor')};
  }

  .text-primary {
    color: ${getCssVar('textColor')};
  }

  .ant-menu {
    background-color: transparent;
  }

  /* upload card */
  .ant-card-bordered {
    background-color: ${getCssVar('select.bg')};
    color: ${getCssVar('textColor')};
    border-color: ${getCssVar('baseColors.borderColor')};
  }


  /* drawer */
  .ant-drawer-content {
    background-color: ${getCssVar('table.filters.mobile.bg')};

    .ant-drawer-body .ant-row label {
      color: ${getCssVar('textColor')};
    }
  }


  /* typography */

  .ant-typography {
    h1,
    h1& {
      letter-spacing: 0.01em;
      line-height: 1.17;
      font-weight: 700;
    }

    h2,
    h2& {
      letter-spacing: 0.01em;
      line-height: 1.1;
      font-weight: 700;
    }

    h3,
    h3& {
      letter-spacing: 0.03em;
      line-height: 1;
      font-weight: 700;
    }

    h4,
    h4& {
      line-height: 1.25;
      font-weight: 700;
    }
  }

  b {
    font-weight: 800;
  }

  strong {
    font-weight: 400;
  }

  .caption {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
  }

  /* tooltip */

  .tooltipInfo {
    margin-left: 6px;
    color: ${getCssVar('tooltip.color')};
    cursor: pointer;

    width: ${getCssVar('tooltip.size')};
    height: ${getCssVar('tooltip.size')};

    > svg {
      width: ${getCssVar('tooltip.size')};
      height: ${getCssVar('tooltip.size')};
    }

    &:hover,
    &:focus {
      color: ${getCssVar('tooltip.hover')};
    }
  }

  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 3px;
  }

  .ant-tooltip-inner {
    border-radius: 4px;
    padding: 10px;
    background-color: ${getCssVar('tooltip.bg')};

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ant-tooltip-arrow-content {
    background-color: ${getCssVar('tooltip.bg')};
  }

  /* Select */

  .ant-select-selection-search {
    color: ${getCssVar('textColor')}
  }

  .ant-select {
    color: ${getCssVar('select.textColor')};
    &.checked .ant-select-selector {
      background-color: ${getCssVar('select.selector.checked.backgroundColor')};
      border: 2px solid ${getCssVar('select.selector.checked.borderColor')};

      &:hover {
        border: 2px solid ${getCssVar('select.selector.checked.hoverBorderColor')};
      }
    }
  }
  .ant-select:not(.ant-select-customize-input) {
    .anticon svg {
        color: ${getCssVar('select.iconColor')};
    }
    &.ant-select-focused .ant-select-selector {
      border: 2px solid ${getCssVar('select.selector.hoverBorderColor')} !important;
    }

    .ant-select-selector {
      background-color: ${getCssVar('select.backgroundColor')};
      border: 2px solid ${getCssVar('select.selector.borderColor')};
      box-shadow: none !important;

      .ant-select-focused & {
        border: 2px solid ${getCssVar('select.selector.borderColor')};
        box-shadow: none !important;
      }

      &:hover {
        border: 2px solid ${getCssVar('select.selector.hoverBorderColor')};
      }
    }
  }
  .ant-select-dropdown {
    background-color: ${getCssVar('select.backgroundColor')};

    .ant-select-item {
      color: ${getCssVar('select.textColor')};

      &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: ${getCssVar('select.textColor')};
        background-color: ${getCssVar('select.cascader.item.checkedBackgroundColor')};
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
      color: ${getCssVar('activeColor')} !important;
    }
  }
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({ theme }) => theme.colorSet.inputs.backgroundColor};
    border-color: ${({ theme }) => theme.colorSet.inputs.borderColorError} !important;

    &:hover {
      border-color: ${({ theme }) => theme.colorSet.inputs.borderColorErrorHover} !important;
    }
  }
  .ant-form-item-explain-error {
    color: ${({ theme }) => theme.colorSet.inputs.errorMessageColor};
  }

  /* input */

  .ant-form {
    .ant-form-item-label, .ant-form-item-label label {
      font-weight: ${({ theme }) => theme.colorSet.labelFontWeight} !important;
      color: ${({ theme }) => theme.colorSet.textColor} !important;
    }
  }

  .ant-select-item {
    line-height: 28px;
  }

  .anticon svg {
    display: block;
  }

  .ant-dropdown-trigger {
    .anticon-down {
      width: auto;
      height: auto;
    }
  }
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: ${({ theme }) => theme.colorSet.inputs.backgroundColor};
    border-color: ${({ theme }) => theme.colorSet.inputs.borderColorError};
  }

  /* status */

  .status {
    display: flex;
    align-items: center;
  }

  .ant-message-notice.black-notification {
    .anticon {
      font-size: 20px;
    }

    .ant-message-custom-content {
      display: flex;
      align-items: center;
    }

    .ant-message-notice-content {
      color: ${colors.white};
      background-color: ${colors.gray[800]};
      border-radius: 2px;
      box-shadow: 0px 8px 20px rgba(${colors.blackAlpha[200]});

      p {
        text-align: left;
        padding: 0;
        margin: 0;
      }
    }
  }

  /* Message */

  .ant-message-notice .ant-message-custom-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* left-menu */

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .ant-menu-item {
    .ant-menu-title-content {
      font-weight: 700;
    }
  }

  .ant-menu-submenu {
    font-weight: 700;
  }

  .ant-menu-sub {
    .ant-menu-title-content {
      font-weight: 500;
    }

    .ant-menu-item:first-child {
      @media (max-width: ${breakpoints.lg}) {
        margin-top: 8px;
      }
    }

    .ant-menu-item:last-child {
      @media (max-width: ${breakpoints.lg}) {
        margin-bottom: 8px;
      }
    }

  }

  /* icon upload */

  svg.icon_upload_centering {
    display: inline-block !important;
  }

  /* description */

  .ant-descriptions-item-label {
    font-weight: ${({ theme }) => theme.colorSet.labelFontWeight} !important;
    color: ${({ theme }) => theme.colorSet.textColor} !important;
  }

  .vulnerability-tooltip {
    transform: translateY(20px) !important;
    padding-bottom: 5px;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      background-color: ${({ theme }) => theme.colorSet.charts.tooltip.bg};
      border-color: ${({ theme }) => theme.colorSet.charts.tooltip.bg};
      color: ${({ theme }) => theme.colorSet.charts.tooltip.color};
      font: 14px / 21px sans-serif;

      ul {
        padding-left: 0;
        list-style-type: none;
        max-width: 250px;
        margin-bottom: 0;

        > li {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  /* tabs (dropdown-menu) */

  .ant-tabs-dropdown-menu {
    background-color: ${getCssVar('tabsNavigation.dropdown.bg')};

    .ant-tabs-dropdown-menu-item {
      color: ${getCssVar('tabsNavigation.dropdown.color')};

      &:hover {
        background-color: ${getCssVar('tabsNavigation.dropdown.bgHover')};
      }
    }
  }

  .ant-tooltip.alarm-description-tootlip .ant-tooltip-content {
    width: 450px;
    max-height: 200px;
    overflow: auto;
    border-radius: 5px;

    @media (max-width: ${breakpoints.smMax}) {
        width: 100%;
      }
  }
`;
