import styled from 'styled-components';
import { Typography } from 'antd';
import { Button } from 'shared';
import { getCssVar, breakpoints } from 'styles/theme';

type Props = {
  isPasswordWeak: boolean;
};

type ButtonProps = {
  $isHidden?: boolean;
};

export const CheckPasswordWrapper = styled.div<Props>`
  --color: ${({ theme }) => theme.colors.blue[900]};
  --margin: ${({ theme }) => theme.spacing[2]};

  .ant-form-item {
    &.ant-form-item-has-error {
      margin-bottom: 0;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      line-height: 21px;
      ${({ isPasswordWeak }) =>
        isPasswordWeak ? 'margin-top: var(--margin);' : 'margin-bottom: var(--margin);'}
      ${({ isPasswordWeak }) => isPasswordWeak && 'color: var(--color);'}
    }
  }

  div.example {
    margin-bottom: var(--margin);
    opacity: 0.5;
  }
`;

export const StyledButton = styled(Button)<ButtonProps>`
  display: ${({ $isHidden }) => $isHidden && 'none'};
`;

export const InputLabel = styled.span`
  color: ${getCssVar('login.formLabel.color')};
  opacity: ${getCssVar('login.formLabel.opacity')};

  @media (max-width: ${breakpoints.lg}) {
    color: ${getCssVar('login.formLabel.mobile.color')};
    opacity: ${getCssVar('login.formLabel.mobile.opacity')};
  }
`;

export const PasswordDescription = styled(Typography.Paragraph)`
  color: ${getCssVar('login.formTitle.color')};

  @media (max-width: ${breakpoints.lg}) {
    color: ${getCssVar('login.formTitle.mobile.color')};
  }
`;
