import { Tooltip } from 'antd';
import { Icon } from 'shared';
import { icons } from 'styles/theme';

interface IProps {
  labelText: string;
  tooltipText: string;
}

export const LabelForm = ({ labelText, tooltipText }: IProps) => {
  return (
    <>
      {labelText}
      <Tooltip title={tooltipText}>
        <Icon component={icons.info_outline} className='tooltipInfo' />
      </Tooltip>
    </>
  );
};
