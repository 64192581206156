import { Tag } from 'shared';
import { getConnectionColor, getConnectionStatus } from 'pages/ServicesIntegrations/helpers';

type StatusTagProps = {
  isConnected?: boolean;
  style?: React.CSSProperties;
};

export const StatusTag = ({ isConnected = false, style }: StatusTagProps) => {
  const status = getConnectionStatus(isConnected);
  const color = getConnectionColor(isConnected);

  return (
    <Tag title={status} color={color} style={style}>
      {status}
    </Tag>
  );
};
