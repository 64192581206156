import styled from 'styled-components';
import { getCssVar } from 'styles/theme';

export const DetailsStatusWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.sizes[4]};
  justify-content: space-between;
  width: 100%;
`;

export const DetailsItemWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  padding-bottom: ${({ theme }) => theme.sizes[7]};

  &:first-child {
    padding-bottom: ${({ theme }) => theme.sizes[6]};

    .details-item-label {
      @media (max-width: 430px) {
        display: none;
      }
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const DetailsItemLabel = styled.div`
  align-items: center;
  color: ${getCssVar('textColor')};
  font-weight: bold;
  width: ${({ theme }) => theme.sizes[36]};

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: ${({ theme }) => theme.sizes[28]};
  }
`;

export const DetailsItemValue = styled.div`
  flex-basis: 0%;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FormWrapper = styled.div`
  max-width: 700px;
  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing['2.5']} 0px`};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.spacing[4]} 0px 0px`};
  }
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing[6]};

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-direction: column;
  }
`;

export const FormButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const ConnectionStatus = styled.div<{ isConnected: boolean | null }>`
  color: ${({ isConnected }) => getCssVar(isConnected ? 'successColor' : 'errorColor')};
  font-size: ${({ theme }) => theme.sizes['4']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
`;
