import styled from 'styled-components';
import { Box, Tag } from 'shared';
import { getCssVar } from 'styles/theme';
import { ThemeProps } from 'services/interfaces';

export const TotalCell = styled(Box)`
  background-color: ${getCssVar('bodyBackground')};
  display: flex;
  justify-content: start;
  padding: 8px 12px;

  div {
    margin-right: 10px;

    span {
      font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
    }
  }
`;

export const StatusTag = styled(Tag)`
  margin-left: 0;
`;
