import { CertificatesContainer, StyledCard } from 'pages/Dashboard/styled';
import { useApp } from 'store';
import { Icon } from 'shared';
import { useGetPermission } from 'services/hooks';
import { TooltipTitles, viewMoreConfig } from 'pages/Dashboard/constants';
import { getScore } from 'services/helpers';
import { CertificateStatusSchemaWithDownloadName } from 'services/api';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { useTheme } from 'styled-components';
import { ProcessItem } from './ProcessItem';
import { HelpMessage } from './HelpMessage';
import { staticCertificates } from '../constants';

type ProcessProps = {
  layout: 'horizontal' | 'vertical';
  isLoading?: boolean;
  certificates?: CertificateStatusSchemaWithDownloadName[];
  onSelfSertify: (withForm?: boolean | undefined, title?: string | undefined) => void;
  openFormWithPreparedData: (title: string, date?: number, vendorName?: string) => void;
  score?: number;
  scoreLoading?: boolean;
};

export const Process = ({
  layout,
  isLoading,
  certificates,
  onSelfSertify,
  openFormWithPreparedData,
  score,
  scoreLoading,
}: ProcessProps) => {
  const theme = useTheme();

  const { isMobile } = useApp();

  const permission = useGetPermission(PermissionOptions.Process);
  const hasPermissionToBuyCertificate = permission === PermissionsLevel.Full;

  return (
    <>
      <StyledCard
        title='Process'
        valueMini={getScore(score)}
        isValueMiniLoading={scoreLoading}
        layout={layout}
        titlePlacement={isMobile ? 'left' : 'inside'}
        isHiddable={isMobile || undefined}
        viewMore={permission === PermissionsLevel.NoAccess ? undefined : viewMoreConfig.process}
        tooltipTitle={TooltipTitles.Process}
        headStyles={
          !isMobile
            ? { pb: `${theme.spacing[4]}` }
            : { pb: `${theme.spacing[0]}`, titlePaddingRight: `${theme.spacing[0]}` }
        }
        bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
        cardPadding={
          !isMobile
            ? `${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[2]} ${theme.spacing[6]}`
            : `${theme.spacing[1]} ${theme.spacing[4]} ${theme.spacing[1]} ${theme.spacing[4]}`
        }
        showDivider={!isMobile}
        showLoader={isLoading}
        height='100%'
      >
        <CertificatesContainer isMobile={isMobile}>
          {(permission === PermissionsLevel.NoAccess ? staticCertificates : certificates)?.map(
            (certificate: CertificateStatusSchemaWithDownloadName) => {
              return (
                <ProcessItem
                  {...certificate}
                  key={certificate.title}
                  onSelfSertify={onSelfSertify}
                  openFormWithPreparedData={openFormWithPreparedData}
                  isLocked={permission === PermissionsLevel.NoAccess}
                  hasPermissionToBuyCertificate={hasPermissionToBuyCertificate}
                />
              );
            },
          )}
        </CertificatesContainer>
      </StyledCard>
      {permission === PermissionsLevel.NoAccess && (
        <HelpMessage
          icon={<Icon component={theme.icons.danger} />}
          title='Purchase products to see detailed security analytics and get actionable insights'
        />
      )}
    </>
  );
};
