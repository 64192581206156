import { fontWeights } from '../fontWeights';
import { colors } from '../colors';
import { baseDarkNorm } from './base';

const buttonShadow = '0px 4px 8px 0px rgba(35, 31, 32, 0.15)';

export const button = {
  fontSize: '16px',
  fontWeight: `${fontWeights.bold}`,
  textTransform: 'none',
  backgroundColor: baseDarkNorm.blue,
  color: colors.white,
  border: 'none',
  borderRadius: '0px',

  hover: {
    backgroundColor: baseDarkNorm.blueHover,
    color: colors.white,
    transform: 'none',
    boxShadow: 'none',
  },

  active: {
    backgroundColor: baseDarkNorm.blueActive,
    color: colors.white,
  },
};

export const buttonDisabled = {
  backgroundColor: '#323332',
  color: '#646263',
};

export const buttonSecondary = {
  backgroundColor: `transparent`,
  color: colors.white,
  border: `2px solid #bdbcbc`,
  borderRadius: '0px',

  hover: {
    backgroundColor: `transparent`,
    color: colors.white,
    borderColor: `#bdbcbc`,
    boxShadow: buttonShadow,
  },

  active: {
    backgroundColor: `#4f4d4d`,
    color: colors.white,
    borderColor: `#bdbcbc`,
  },

  disabled: {
    backgroundColor: 'transparent',
    color: buttonDisabled.color,
    borderColor: buttonDisabled.backgroundColor,
  },
};

export const buttonDanger = {
  backgroundColor: baseDarkNorm.deepRed,
  color: colors.white,

  hover: {
    backgroundColor: baseDarkNorm.deepRedHover,
    color: colors.white,
    transform: 'none',
    boxShadow: 'none',
  },

  active: {
    backgroundColor: baseDarkNorm.deepRedActive,
    color: colors.white,
  },

  disabled: buttonDisabled,
};

// secondary ghost buttons
export const buttonSimple = {
  borderColor: `#646263`,
  color: colors.white,

  hover: {
    backgroundColor: `#242524`,
    color: colors.white,
    borderColor: `#646263`,
    boxShadow: buttonShadow,
  },

  active: {
    backgroundColor: `#4f4d4d`,
    borderColor: `#646263`,
    color: colors.white,
  },

  disabled: {
    backgroundColor: `transparent`,
    color: buttonDisabled.color,
    borderColor: buttonDisabled.backgroundColor,
  },
};
