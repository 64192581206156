import { createRef, useCallback, useMemo, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { getOptions } from 'shared/charts/GradientPie/helpers';
import { Annotations } from 'shared/charts/GradientPie/types';
import { Icon } from 'shared';
import { icons, sizes } from 'styles/theme';
import { useTheme } from 'styled-components';
import { EChartToImage } from '.';
import { CenteredIcon } from '../../pages/PdfPage3/styled';
import { totalPieScoreSize } from '../../pages/config';

type GradientPieReportContainerProps = {
  score?: number | null;
  width?: string;
  height?: string;
  fontSize?: number;
  onFinished: () => void;
  scoreAnnotations?: Annotations;
  titleFontSize?: number;
  fontFamily?: string;
};

const FONT_SIZE_DEFAULT = 14;

export const GradientPieReport = ({
  score,
  height = '60px',
  width = '60px',
  fontSize = FONT_SIZE_DEFAULT,
  onFinished,
  scoreAnnotations,
  titleFontSize,
  fontFamily = 'Strawford, Arial, sans-serif',
}: GradientPieReportContainerProps) => {
  const theme = useTheme();
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = createRef<ReactECharts>();

  const option = useMemo(() => {
    return getOptions(
      score ?? null,
      fontSize,
      { ...theme, colorSet: { ...theme.colorSet, textColor: '#292933' } },
      false,
      scoreAnnotations,
      titleFontSize,
      fontFamily,
    );
  }, [fontFamily, fontSize, score, scoreAnnotations, theme, titleFontSize]);

  const onChartReadyHandler = useCallback((instance) => {
    instance.on('finished', () => {
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      <EChartToImage
        chartRef={ref}
        height={height}
        width={width}
        isLoaded={isLoaded}
        onFinished={onFinished}
      >
        <ReactECharts
          style={{ height }}
          ref={ref}
          onChartReady={onChartReadyHandler}
          opts={{ renderer: 'canvas' }}
          option={option}
        />
      </EChartToImage>
      {(score === undefined || score === null) && (
        <CenteredIcon>
          <Icon
            height={sizes.min}
            width={sizes.min}
            component={
              parseInt(width, 10) < parseInt(totalPieScoreSize, 10)
                ? icons.medium_lock
                : icons.big_lock
            }
          />
        </CenteredIcon>
      )}
    </>
  );
};
