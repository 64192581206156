import { SelectValue } from 'antd/es/select';
import { FormikProps } from 'formik';
import { InnerLayoutProps as InnerLayout, BackLink } from 'shared';
import { HistoryState } from 'services/interfaces';
import { Routes } from 'services/entities';
import { backlinkRoutes } from 'app-constants/routes';
import { optionsImpact, optionsPriority, PageTitles, ServiceRequestType } from './constants';
import { IInputFields, ISelectFields, IFormikValues, ProductsSelectOptions } from './types';

export const getLayoutConfig = (
  historyState: HistoryState | undefined,
  isServiceRequest?: boolean,
  requestType?: string | null,
  pathname?: string,
): InnerLayout => {
  const serviceRequestTitle =
    requestType === ServiceRequestType.Purchase ? PageTitles.Purchase : PageTitles.Service;

  return {
    title: isServiceRequest ? serviceRequestTitle : PageTitles.Incident,
    backLink: (
      <BackLink
        historyState={historyState?.backTo?.route !== pathname ? historyState : undefined}
        defaultRoute={Routes.SUPPORT_REQUESTS}
        defaultTitle={backlinkRoutes[Routes.SUPPORT_REQUESTS]}
      />
    ),
  };
};

export const getFormFieldsConfig = (
  isServiceRequest: boolean | undefined,
  formik: FormikProps<IFormikValues>,
  certificateName?: string | null,
): Record<string, IInputFields> => ({
  summary: {
    name: 'summary',
    label: 'Summary',
    placeholder: 'Please provide a summary of your request',
    type: 'textarea',
    width: '712px',
    height: '64px',
    value: certificateName ? `Request to purchase ${certificateName}` : formik.values.summary,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    validateStatus: formik.errors.summary && formik.touched.summary ? 'error' : 'success',
    help: formik.errors.summary && formik.touched.summary ? formik.errors.summary : undefined,
    required: true,
  },

  description: {
    name: 'description',
    label: 'Description',
    placeholder: 'Please provide a description of your request',

    type: 'textarea',
    width: '712px',
    height: '100px',
    value: formik.values.description,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    validateStatus: formik.errors.description && formik.touched.description ? 'error' : 'success',
    help:
      formik.errors.description && formik.touched.description
        ? formik.errors.description
        : undefined,
    required: true,
  },
});

export const getSelectsConfig = (
  formik: FormikProps<IFormikValues>,
  customerProductsList: ProductsSelectOptions[],
  productsLoading?: boolean,
): Record<string, ISelectFields> => ({
  product: {
    value: formik.values.product,
    options: customerProductsList,
    onChange: (value: SelectValue) => {
      formik.setFieldValue('product', value);
    },
    loading: productsLoading,
  },
  priority: {
    value: formik.values.priority,
    options: optionsPriority,
    onChange: (value: SelectValue) => {
      formik.setFieldValue('priority', value);
    },
  },

  impact: {
    value: formik.values.impact,
    options: optionsImpact,
    onChange: (value: SelectValue) => {
      formik.setFieldValue('impact', value);
    },
  },
});
