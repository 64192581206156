import styled from 'styled-components';
import { Modal } from 'shared/Modal/Modal';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

type Props = {
  theme: Theme;
};

export const Popup = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      display: flex;
      justify-content: center;
      border: none;

      .ant-modal-title {
        color: ${getCssVar('textColor')};
        font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
      }
    }

    .ant-modal-body {
      padding: 0;

      .ant-menu-item {
        align-items: center;
        display: flex;
        height: 48px;
        justify-content: start;
        padding: 0 12px;
      }

      .ant-menu-item-selected {
        background-color: ${({ theme }: Props) => `${theme.colors.brown[100]}${theme.opacity[12]}`};
        color: currentColor;
      }
    }
  }
`;
