import styled from 'styled-components';
import { AsideDescription, AsidePieWrapper } from '../styled';

export const PeopleText = styled(AsideDescription)`
  max-width: 250px;
  word-wrap: break-word;
`;

export const ChartsContainer = styled.div`
  margin-left: 561px;
  margin-right: 50px;
`;

const ChartWrapper = styled.div`
  left: 0;

  position: relative;
  width: 100%;
`;

export const CurrentTrainingStatus = styled(ChartWrapper)`
  top: 102px;
`;

export const CurrentRefresherTests = styled(ChartWrapper)`
  top: 182px;
`;

export const PhishingLastMonth = styled(ChartWrapper)`
  top: 255px;
`;

export const TotalScorePieWrapper = styled(AsidePieWrapper)``;
