import { SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { RangePickerProps } from 'antd/lib/date-picker';
import { InputType, Filter } from 'services/api';
import { FilterType } from '../../../Table/types';

import { TableFiltersSelect } from '../TableFiltersSelect/TableFiltersSelect';
import { TableFiltersRange } from '../TableFiltersRange/TableFiltersRange';
import { TableFilterRadio } from '../TableFilterRadio';
import { TableFilterGroupSelect } from '../TableFilterGroupSelect/TableFilterGroupSelect';

export type IFilterFactoryProps = SelectProps<SelectValue> &
  RangePickerProps & {
    getValue: (name: string) => string[] | string | undefined;
    maxTagCount?: number;
    onFilterChange: (
      field: string,
      value: string[] | string,
      filterType: Filter.type,
      params?: Record<string, unknown>,
    ) => void;
    filterField?: FilterType;
    getSelectField: (name: string) => null | Filter;
  };

export const FilterFactory = ({
  filterField,
  getValue,
  onFilterChange,
  getSelectField,
}: IFilterFactoryProps) => {
  switch (filterField?.inputType) {
    case InputType.SELECTOR:
      return (
        <TableFiltersSelect
          value={getValue(filterField.field)}
          onSelectChange={onFilterChange}
          {...filterField}
        />
      );
    case InputType.DATE:
      return (
        <TableFiltersRange
          onDateChange={onFilterChange}
          dateValue={getValue(filterField.field || filterField.label.toLowerCase())}
          {...filterField}
        />
      );
    case InputType.RADIO:
      return (
        <TableFilterRadio
          onFilterChange={onFilterChange}
          value={getValue(filterField.field)}
          {...filterField}
        />
      );
    case InputType.GROUP_SELECTOR:
      return (
        <TableFilterGroupSelect
          getValue={getValue}
          onSelectChange={onFilterChange}
          getSelectField={getSelectField}
          {...filterField}
        />
      );
    default:
      return null;
  }
};
