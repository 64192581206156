import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { Box } from 'shared';
import { auth, useAuth } from 'store';
import { ValidateTwoFARequest } from 'services/api';
import { useTheme } from 'styled-components';
import { TwoFAStep } from '../TwoFAStep';
import {
  InputTwoFA,
  TwoFAWrapper,
  TfaStepTitle,
  TfaStepContent,
  InvalidCodeMessage,
} from '../styled';

type Props = {
  isPending: boolean;
  onSubmit: (requestBody: ValidateTwoFARequest) => void;
  requestErrorMessage?: string;
};

export const TwoFAValidation = ({ isPending, onSubmit, requestErrorMessage }: Props) => {
  const theme = useTheme();
  const [code, setCode] = useState('');
  const { token } = useAuth();
  const { user } = useAuth();
  const is2FASetted = user ? user.twoFA : false;
  const inputEl = useRef<Input>(null);
  const title = is2FASetted ? 'Verify code' : 'Step 3. Verify code';
  const TITLE_LEVEL_3 = 3;
  const TITLE_LEVEL_5 = 5;
  const titleLevel = is2FASetted ? TITLE_LEVEL_3 : TITLE_LEVEL_5;

  const dispatch = useDispatch();

  const handleCodeChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const MAX_LENGTH = 6;
    const isInRange = value.length <= MAX_LENGTH;
    const isFull = value.length === MAX_LENGTH;

    if (isInRange) {
      dispatch(auth.actions.setError(''));
      setCode(value);
    }

    if (isFull && token) {
      const requestBody = { code: value, token };

      onSubmit(requestBody);
    }
  };

  useEffect(() => {
    dispatch(auth.actions.setError(''));
  }, [dispatch]);

  useEffect(() => {
    if (inputEl.current && !!requestErrorMessage) {
      inputEl.current.focus();
    }
  }, [requestErrorMessage]);

  return (
    <TwoFAStep
      title={<TfaStepTitle level={titleLevel}>{title}</TfaStepTitle>}
      description={
        <TwoFAWrapper>
          <TfaStepContent>
            Enter the <strong>6-digit</strong> code generated by your authentication app
          </TfaStepContent>
        </TwoFAWrapper>
      }
      isLast={!is2FASetted}
    >
      <Box mt={theme.spacing[4]}>
        <InputTwoFA
          ref={inputEl}
          value={code}
          autoFocus
          $isRequestError={!!requestErrorMessage}
          onChange={handleCodeChange}
          suffix={
            isPending ? (
              <LoadingOutlined style={{ color: theme.colorSet.login.tfa.inputTextColor }} />
            ) : (
              <span />
            )
          }
          disabled={isPending}
        />
      </Box>
      {!!requestErrorMessage && <InvalidCodeMessage>{requestErrorMessage}</InvalidCodeMessage>}
    </TwoFAStep>
  );
};
