import { inputsBase } from './base';

export const wysiwyg = {
  placeholderColor: inputsBase.textColorDisabled,
  bg: inputsBase.backgroundColor,
  borderColor: inputsBase.borderColor,
  toolbarButtonBg: '#8A8A8A',
  toolbarButtonBgHover: '#4F4D4D',
  view: {
    table: {
      borderColor: inputsBase.borderColor,
      cellPadding: '5px 12px',
    },
  },
};
