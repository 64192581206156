import { Box } from 'shared';
import { useTheme } from 'styled-components';
import { getCssVar } from 'styles/theme';

type HelpMessageProps = {
  title: string;
  icon?: JSX.Element;
};

export const HelpMessage = ({ title, icon }: HelpMessageProps) => {
  const { sizes, fontWeights, spacing } = useTheme();

  return (
    <Box
      w={sizes.full}
      d='flex'
      justify='center'
      alignItems='center'
      p={spacing[3]}
      backgroundColor={getCssVar('process.helpMessageBg')}
      zIndex={4}
    >
      {icon}
      <Box
        color={getCssVar('process.helpMessageColor')}
        ml={sizes[3]}
        fontWeight={fontWeights.medium}
      >
        {title}
      </Box>
    </Box>
  );
};
