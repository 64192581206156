import React from 'react';
import {
  useSoftware,
  softwareApi,
  useApp,
  useLoading,
  MessageType,
  AppDispatch,
  useCustomer,
  useAuth,
  useProcess,
  processApi,
  software,
} from 'store';
import {
  ActionNames,
  defaultCurrent,
  defaultPageSize,
  loadingNotificationMessage,
  PermissionOptions,
  PermissionsLevel,
} from 'app-constants';
import { ActionClickHandlersType } from 'services/interfaces';
import {
  InnerLayout,
  MobileTable,
  ActionPopup,
  MobileTableWrapper,
  EmptyTablePreview,
  Button,
  Icon,
  Box,
} from 'shared';
import { useHistory } from 'react-router';
import { icons } from 'styles/theme';
import { getQueryString } from 'services/api/core/request';
import { useFilter } from 'services/hooks/useFilter';
import { downloadDocument } from 'services/helpers/downloadDocument';
import { useGetPermission } from 'services/hooks';
import {
  CustomerProductsListSchema,
  GridRequestParams,
  SoftwareDocumentSchema,
} from 'services/api';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { CustomTable } from 'shared/Table/CustomTable';
import { deletePopupDetails, initialOrderBy } from 'pages/Documents/components/constants';
import { showMessage } from 'services/helpers';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { getActions, showPopup } from 'pages/Documents/helpers';
import { useDispatch } from 'react-redux';
import { getLayoutConfig, getFiltersFields } from './helpers';
import { getColumns } from './components/TableColumns';
import { searchSettings } from './components/constants';
import { Upload } from './components/Upload';

export const Software = () => {
  const { tableContent, downloading } = useSoftware();
  const { customerProductsList } = useProcess();
  const customerId = useCustomer();
  const { user } = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const { isMobile } = useApp();
  const loading = useLoading([
    softwareApi.fetchTableContent.typePrefix,
    softwareApi.deleteFile.typePrefix,
    processApi.getProducts.typePrefix,
  ]);

  const isInit = React.useRef(true);
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isActionPopupVisible, setIsActionPopupVisible] = React.useState(false);
  const [activePopupDetails, setActivePopupDetails] = React.useState<PopupConfigType | null>(null);
  const [dataSource, setDataSource] = React.useState<SoftwareDocumentSchema[] | undefined>();

  const [params, updateParams] = useFilter({
    apiCall: softwareApi.fetchTableContent,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
  });

  const [isShowTable, setIsShowTable] = React.useState(true);

  const permission = useGetPermission(PermissionOptions.ManageSoftware);

  const layoutConfig = getLayoutConfig(
    setIsModalVisible,
    isShowTable && permission === PermissionsLevel.Full && !!user.isSuperAdmin,
  );

  const fetchTableContent = React.useCallback(() => {
    if (isInit.current) {
      dispatch(softwareApi.fetchTableContent({ ...params }));

      isInit.current = false;

      return;
    }

    const { customerId, ...otherParams } = params;

    history.push(getQueryString(otherParams));
    dispatch(softwareApi.fetchTableContent({ ...params }));
  }, [dispatch, params, history]);

  const downloadFile = React.useCallback(
    async (filename: string) => {
      await downloadDocument({
        dispatch,
        customer: customerId,
        filename,
        api: softwareApi,
        slice: software,
      });
      setIsActionPopupVisible(false);
    },
    [dispatch, customerId],
  );

  const onClickActionButton = React.useCallback(
    (row: SoftwareDocumentSchema, popupId: ActionNames) => {
      showPopup(
        popupId,
        setActivePopupDetails,
        setIsActionPopupVisible,
        row,
        dispatch,
        customerId,
        softwareApi,
        () => {
          if (params.pagination.page !== 1 && dataSource?.length === 1) {
            return updateParams({
              ...params,
              pagination: {
                ...params.pagination,
                page: params.pagination.page && params.pagination.page - 1,
              },
            });
          }

          return fetchTableContent();
        },
      );
    },
    [
      setIsActionPopupVisible,
      setActivePopupDetails,
      dispatch,
      params,
      fetchTableContent,
      dataSource,
      customerId,
      updateParams,
    ],
  );

  const columns = React.useMemo(
    () => getColumns(downloadFile, onClickActionButton, downloading, isMobile, permission),
    [downloadFile, onClickActionButton, downloading, isMobile, permission],
  );

  React.useEffect(() => {
    if (tableContent && tableContent.count !== undefined && !params.filter) {
      setIsShowTable(tableContent.count > 0 || loading[softwareApi.fetchTableContent.typePrefix]);
    }
  }, [tableContent, params.filter, loading]);

  React.useEffect(() => {
    if (
      loading[softwareApi.fetchTableContent.typePrefix] ||
      loading[processApi.getProducts.typePrefix]
    ) {
      return;
    }

    if (Object.entries(loading).some((action: [string, boolean]) => action[1])) {
      showMessage(loadingNotificationMessage, MessageType.Loading);
    }
  }, [loading]);

  React.useEffect(() => {
    if (tableContent) {
      setDataSource(tableContent.rows as SoftwareDocumentSchema[]);
    }
  }, [tableContent]);

  React.useEffect(() => {
    dispatch(processApi.getProducts({ customerId }));
  }, [dispatch, customerId]);

  const actionsClickHandlers: ActionClickHandlersType<SoftwareDocumentSchema> = {
    [ActionNames.Delete]: onClickActionButton,
    [ActionNames.Download]: (row: SoftwareDocumentSchema) => downloadFile(row.name || ''),
  };

  const emptyMessage = (
    <EmptyTablePreview
      icon='empty_table'
      alignCenter
      textContent={
        <Box>
          No download files available yet <br />
          Here will be displayed all documents that you will be able to download.
        </Box>
      }
      actionButton={
        <Button
          type='link'
          onClick={() => setIsModalVisible(true)}
          disabled={permission !== PermissionsLevel.Full}
          icon={<Icon component={icons.file_upload} style={{ marginRight: 5 }} />}
        >
          Upload file
        </Button>
      }
    />
  );

  const productsSelectOptions = customerProductsList?.map(
    (productName: CustomerProductsListSchema) => ({
      label: productName,
      value: productName,
    }),
  );

  return (
    <InnerLayout {...layoutConfig}>
      {!isMobile ? (
        <CustomTable<SoftwareDocumentSchema, GridRequestParams>
          data={{ ...tableContent, rows: dataSource }}
          columns={getTableColumns(columns, params.orderBy)}
          params={params}
          setParams={updateParams}
          searchSettings={searchSettings}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          filterFields={getFiltersFields(productsSelectOptions)}
          isLoadingContent={loading[softwareApi.fetchTableContent.typePrefix]}
          emptyMessage={emptyMessage}
        />
      ) : (
        <MobileTableWrapper>
          <MobileTable<SoftwareDocumentSchema, GridRequestParams>
            data={{ ...tableContent, rows: dataSource }}
            columns={getTableColumns(columns, params.orderBy)}
            params={params}
            setParams={updateParams}
            initialOrderBy={initialOrderBy}
            searchSettings={searchSettings}
            filterFields={getFiltersFields(productsSelectOptions)}
            isLoadingContent={loading[softwareApi.fetchTableContent.typePrefix]}
            getActions={getActions<SoftwareDocumentSchema>(
              actionsClickHandlers,
              permission === PermissionsLevel.Full,
            )}
            emptyMessage={emptyMessage}
          />
        </MobileTableWrapper>
      )}

      <Upload
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        user={user}
        fetchTableContent={fetchTableContent}
        customerId={customerId}
        permission={permission}
        loading={loading[processApi.getProducts.typePrefix]}
        productsSelectOptions={productsSelectOptions}
      />

      {isActionPopupVisible && (
        <ActionPopup
          isVisible={isActionPopupVisible}
          popupDetails={activePopupDetails || deletePopupDetails}
          setIsVisiblePopup={setIsActionPopupVisible}
        />
      )}
    </InnerLayout>
  );
};
