import styled from 'styled-components';
import { breakpoints, getCssVar } from 'styles/theme';
import { Theme } from 'styles/theme/types';
import { Box } from 'shared';
import { Link } from 'shared/layouts/Outer/components';

export const Title = styled.div`
  padding-top: 5px;
`;

type PieProps = {
  isMobile: boolean;
  isDirectionRow?: boolean;
  isOpen?: boolean;
  customValueTop?: string;
  customValueRight?: string;
  isProcessPieChart?: boolean;
  theme: Theme;
};

const valueMiniTop =
  (!breakpoints.xl && breakpoints.lg) || (!breakpoints.xl && breakpoints.md) ? '-6%' : '-10%';

export const GradientPieContainer = styled.div<PieProps>`
  background-color: ${getCssVar('card.gradientPieContainer.bg')};
  border: 2px solid ${getCssVar('card.gradientPieContainer.border')};
  border-radius: ${({ theme }) => theme.radius.full};
  box-shadow: 0px 4px 12px ${getCssVar('card.gradientPieContainer.boxShadow')};
  font-size: ${({ theme, isDirectionRow }) => (isDirectionRow ? theme.sizes[5] : theme.sizes[10])};
  height: ${({ theme, isDirectionRow }) => (!isDirectionRow ? theme.sizes[40] : theme.sizes[20])};
  position: ${({ isDirectionRow }) => (isDirectionRow ? 'absolute' : 'static')};
  right: ${({ theme, customValueRight }) => customValueRight || theme.spacing[12]};
  top: ${({ isOpen, isMobile, customValueTop }) =>
    isOpen && isMobile && !customValueTop ? '-25%' : customValueTop || valueMiniTop};
  width: ${({ theme, isDirectionRow }) => (!isDirectionRow ? theme.sizes[40] : theme.sizes[20])};
  z-index: 2;

  .anticon {
    left: ${({ isDirectionRow }) => (isDirectionRow ? '40%' : '42%')};
    position: absolute;
    top: ${({ isDirectionRow }) => (isDirectionRow ? '35%' : '39%')};

    > svg {
      filter: ${({ theme }: { theme: Theme }) => `drop-shadow(${theme.spacing.px} ${
        theme.spacing.px
      } ${theme.spacing[0]} ${getCssVar('card.gradientPieContainer.iconDropShadow')})
          drop-shadow(${theme.spacing[1]} ${theme.spacing[1]} ${theme.spacing[2]} ${getCssVar(
        'card.gradientPieContainer.iconDropShadowSecond',
      )})`};
    }
  }

  @media (max-width: ${breakpoints.xl}) {
    top: ${({ isProcessPieChart }) => isProcessPieChart && '-5%'};
  }
`;

export const SpinContainer = styled.div<{ zIndex?: number }>`
  align-items: center;
  background-color: ${getCssVar('spinContainer.bg')};
  bottom: ${({ theme }: { theme: Theme }) => theme.sizes[0]};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: ${({ theme }: { theme: Theme }) => theme.sizes[0]};
  margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[0]};
  position: absolute;
  right: ${({ theme }: { theme: Theme }) => theme.sizes[0]};
  top: ${({ theme }: { theme: Theme }) => theme.sizes[0]};
  z-index: ${({ zIndex }) => zIndex || 2};
`;

export const CardBody = styled(Box)``;
export const CardFooter = styled(Box)``;
export const CardTitle = styled(Box)`
  color: ${({ theme }) => theme.colorSet.textColor};
`;
export const LinkForPurchase = styled(Link)`
  && {
    margin-left: 0;
    padding-top: 0;
  }
`;

export const AccessMessageStyled = styled(Box)``;
export const Collapsed = styled.span`
  font-size: 14px;
  line-height: 16px;
`;
