import styled from 'styled-components';

export const StyledList = styled.div``;

export const ListTitle = styled.p`
  border-bottom: 2px solid #d7d7cf;
  font-weight: ${({ theme }) => theme.colorSet.labelFontWeight};
  margin: ${({ theme }) => `${theme.spacing['2.5']} ${theme.spacing[0]}`};

  padding-bottom: 15px;
`;
