import { Modal } from 'shared/Modal/Modal';
import styled from 'styled-components';
import { breakpoints, getCssVar } from 'styles/theme';
import { ThemeProps } from 'services/interfaces';

export const UploadPopupStyled = styled(Modal)`
  .ant-space {
    width: 100%;

    .label {
      opacity: 0.45;
      font-weight: 700;
    }
  }

  .ant-modal-header {
    font-weight: 700;
    margin: 0;
    border-width: 1px;
  }

  .ant-modal-footer {
    border-width: 1px;
  }

  .mt {
    margin: 20px 0 8px 0;
  }

  .select {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}) {
    top: ${({ theme }: ThemeProps) => theme.spacing['10.1']};
  }
`;

export const UploadPopupSubTitle = styled.h3<ThemeProps>`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: ThemeProps) => theme.spacing[4]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
`;
