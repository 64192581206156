import React from 'react';
import { useApp, useCEPlus, useLoading } from 'store';
import { Card } from 'shared';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { SegmentedPie } from 'shared/charts';
import { isEmptyObject } from 'services/helpers';
import { cyberEssentialPlusApi } from 'store/cyberEssentialPlus/thunks';
import { getQueryString } from 'services/api/core/request';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { Routes } from 'services/entities';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { Filter } from 'services/api';
import { subTitleFormatterSum } from '../../../PatchManagement/helpers';
import { VendorChart } from '../../styled';
import {
  cePlusBackRoute,
  CustomPieChartTooltip,
  IFilterField,
  tooltipTitles,
} from '../../constants';
import {
  getCustomDataHandler,
  getTotalAssetsParams,
  lastYear,
  validateField,
  validateFieldValue,
  viewMoreConfig,
} from '../../helpers';

export const TotalAssetsInScope = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile, isMenuCollapsed } = useApp();
  const { totalAssetsInScope, selectedTags } = useCEPlus();

  const loading = useLoading([cyberEssentialPlusApi.getTotalAssetsInScope.typePrefix]);

  const totalAssetsInScopeColorList = {
    vulnerabilities: theme.colorSet.baseColors.blue,
    fixedVulnerabilities: theme.colorSet.baseColors.grey,
  };

  const redirectToVulnerabilitiesList = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      const tagsAssetsFields: IFilterField = {
        name: 'tagsAssets',
        value: selectedTags || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      };

      const totalAssetsInScopeFields = {
        name: 'countVulnerabilitiesWithBaseScoreMoreThen7',
        value: 'true',
        type: Filter.type.VALUE,
      };

      const fixedTotalAssetsInScopeFields = {
        name: 'countFixedVulnerabilitiesWithBaseScoreMoreThen7',
        value: 'true',
        type: Filter.type.VALUE,
      };

      const totalAssetsInScopeQueryParams = {
        fields: validateField([totalAssetsInScopeFields, tagsAssetsFields]),
      };

      const fixedTotalAssetsInScopeQueryParams = {
        fields: validateField([fixedTotalAssetsInScopeFields, tagsAssetsFields]),
      };

      if (params.name === 'vulnerabilities') {
        history.push(
          `${Routes.CMDB}${getQueryString(getTotalAssetsParams(totalAssetsInScopeQueryParams))}`,
          {
            ...cePlusBackRoute,
            ignoreDefaultBehavior: true,
          },
        );
      }

      if (params.name === 'fixedVulnerabilities') {
        history.push(
          `${Routes.CMDB}${getQueryString(
            getTotalAssetsParams(fixedTotalAssetsInScopeQueryParams, {
              fixedVulnerabilities: true,
              fixedVulnerabilitiesFrom: lastYear,
            }),
          )}`,
          {
            ...cePlusBackRoute,
            ignoreDefaultBehavior: true,
          },
        );
      }
    },
    [history, selectedTags],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToVulnerabilitiesList(params),
    }),
    [redirectToVulnerabilitiesList],
  );

  const { fixedVulnerabilities = 0, vulnerabilities = 0 } = totalAssetsInScope;
  const total = [fixedVulnerabilities + vulnerabilities || 0];

  return (
    <Card
      title='Total assets in scope'
      showDivider={false}
      subTitle={<SubTitle boldText={subTitleFormatterSum(total, 1)} />}
      height={theme.sizes.full}
      tooltipTitle={tooltipTitles.totalAssetsInScope}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getTotalAssetsInScope.typePrefix]}
      viewMore={viewMoreConfig(selectedTags).totalAssetsInScope}
    >
      {!isEmptyObject(totalAssetsInScope) ? (
        <TotalCustomTooltipContainer>
          <VendorChart>
            <SegmentedPie
              values={totalAssetsInScope || {}}
              colors={totalAssetsInScopeColorList}
              getCustomDataHandler={getCustomDataHandler}
              entitiesName=''
              style={
                isMobile
                  ? { height: '160px', marginTop: `-${theme.spacing[0]}` }
                  : { height: '258px' }
              }
              isMobile={isMobile}
              isMenuCollapsed={isMenuCollapsed}
              onEvents={onEvents}
              tooltip={CustomPieChartTooltip(theme)}
              isLoading={loading[cyberEssentialPlusApi.getTotalAssetsInScope.typePrefix]}
              noLegend
            />
          </VendorChart>
        </TotalCustomTooltipContainer>
      ) : (
        emptyMessage(`No total assets in scope`)
      )}
    </Card>
  );
};
