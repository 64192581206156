import { Radio } from 'antd';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

export const RadioGroup = styled(Radio.Group)`
  margin-top: 3px;

  .ant-radio-button-wrapper {
    background-color: ${getCssVar('radio.group.bg')};
    border-color: ${getCssVar('radio.group.borderColor')};
    color: ${getCssVar('textColor')};

    &:hover {
      color: ${getCssVar('primaryColor')};
    }
  }

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 16px;

    .ant-radio-button-wrapper {
      flex: 0 0 auto;
    }
  }
`;

export const RadioButton = styled(Radio.Button)`
  height: 37px;
  line-height: 33px;

  &:not(:first-child)::before {
    background-color: transparent;
  }

  &.ant-radio-button-wrapper-checked {
    background: ${getCssVar('radio.active.color')};
    border-color: ${getCssVar('radio.active.color')} !important;
    box-shadow: none !important;
    color: ${getCssVar('radio.color')};

    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.medium};

    &:hover {
      color: ${getCssVar('radio.color')};
      font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.medium};
    }

    &:before {
      display: none;
    }
  }
`;
