import React, { useCallback, useMemo } from 'react';
import { Col, RadioChangeEvent, Space, Spin } from 'antd';
import { DocumentType, SoftwareDocumentSchema } from 'services/api';
import { SelectValue } from 'antd/lib/select';
import { DocumentStyled } from 'pages/Documents/components/Document/styled';
import { normalizeFileSize } from 'pages/Documents/helpers';
import { POPUP_WIDTH } from 'app-constants';
import { getCssVar, icons } from 'styles/theme';
import { Radio, RadioGroup } from 'shared';
import { Button, Icon, Select } from '..';
import { allowedExtensionsString, emptyFile, maxFileSizeLabel } from './constants';
import { SelectType, UploadDocumentOption } from './types';
import { UploadPopupStyled, UploadPopupSubTitle } from './styled';

type DocumentTypeDefault = DocumentType | SoftwareDocumentSchema.documenttype;

export type UploadPopupProps<T extends DocumentTypeDefault> = {
  visible: boolean;
  handleOk: (name: string, type: T, file: File, service?: { [x: string]: SelectValue }) => void;
  setVisible: (state: boolean) => void;
  radioButtons: UploadDocumentOption<T>[];
  selections?: SelectType[];
  className?: string;
  initialSelections: { [x: string]: SelectValue };
  noExtensions?: boolean;
  loadingServiceType?: boolean;
  uploadFileSize?: number;
};

export const UploadPopup = <T extends DocumentTypeDefault>({
  handleOk,
  visible,
  setVisible,
  className,
  radioButtons,
  selections,
  initialSelections,
  noExtensions,
  loadingServiceType,
  uploadFileSize,
}: UploadPopupProps<T>) => {
  const [isFileAttached, setIsFileAttached] = React.useState(false);
  const [radio, setRadio] = React.useState<T>(radioButtons[0].value);
  const [selectionValues, setSelectionValues] = React.useState(initialSelections);
  const [attachedFile, setAttachedFile] = React.useState<File>(emptyFile);
  const inputRef = React.createRef<HTMLInputElement>();

  const handleRadio = useCallback(
    (event: RadioChangeEvent) => {
      setRadio(event.target.value);
    },
    [setRadio],
  );

  const filteredSelections = useMemo(() => {
    return selections?.reduce((acc: SelectType[], e: SelectType) => {
      if (!e.radioButtonRely || radio === e.radioButtonRely) {
        return [...acc, e];
      }

      return acc;
    }, []);
  }, [radio, selections]);

  const clearAttachedFile = useCallback(() => {
    setIsFileAttached(false);
    const input = inputRef.current;

    if (input) {
      input.value = '';
    }
  }, [setIsFileAttached, inputRef]);

  const clean = useCallback(() => {
    clearAttachedFile();
    setRadio(radioButtons[0].value);
    setSelectionValues(initialSelections);
  }, [clearAttachedFile, setRadio, setSelectionValues, radioButtons, initialSelections]);

  const handleCancel = useCallback(() => {
    clean();
    setVisible(false);
  }, [setVisible, clean]);

  const handleUploadButton = useCallback(() => {
    handleOk(attachedFile.name, radio, attachedFile, selectionValues);
    handleCancel();
  }, [attachedFile, radio, handleOk, handleCancel, selectionValues]);

  const handleSelect = useCallback(
    (value: SelectValue, key: string) => {
      setSelectionValues({ ...selectionValues, [key]: value });
    },
    [setSelectionValues, selectionValues],
  );

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const filesUploaded = event.currentTarget.files;

      if (filesUploaded) {
        setAttachedFile(filesUploaded[0]);
        setIsFileAttached(true);
      }
    },
    [setIsFileAttached, setAttachedFile],
  );

  const handleAttachButton = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  return (
    <UploadPopupStyled
      className='action-modal'
      title='Upload file'
      visible={visible}
      width={POPUP_WIDTH}
      onOk={handleUploadButton}
      onCancel={handleCancel}
      footer={[
        <Button type='secondary' key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          disabled={
            !isFileAttached ||
            (!!filteredSelections?.length &&
              Object.values(selectionValues).some((value: SelectValue) => !value)) ||
            (uploadFileSize ? attachedFile.size > uploadFileSize : false)
          }
          onClick={handleUploadButton}
        >
          Upload file
        </Button>,
      ]}
    >
      <Col className={className}>
        <Space direction='vertical' size={4}>
          <UploadPopupSubTitle className='header'>Document</UploadPopupSubTitle>
          {!isFileAttached ? (
            <Button
              onClick={handleAttachButton}
              type='link'
              icon={<Icon component={icons.attach_file} />}
            >
              Choose file
            </Button>
          ) : (
            <DocumentStyled file={attachedFile} clear={clearAttachedFile} />
          )}
          {!isFileAttached && (
            <span>
              {!noExtensions &&
                (radioButtons.find((option: { value: string }) => option.value === radio)
                  ?.uploadLabel ||
                  'Pdf, docx, xlsx, image (jpg, jpeg), zip files can be uploaded.')}
            </span>
          )}
          {!isFileAttached && (
            <span className='label'>
              Max size: {uploadFileSize ? normalizeFileSize(uploadFileSize) : maxFileSizeLabel}
            </span>
          )}
        </Space>
        <input
          hidden
          type='file'
          onChange={handleChange}
          ref={inputRef}
          accept={
            !noExtensions
              ? radioButtons.find((option: { value: string }) => option.value === radio)?.ext ||
                allowedExtensionsString
              : undefined
          }
        />
        <UploadPopupSubTitle className='header mt'>Document type</UploadPopupSubTitle>
        <RadioGroup value={radio} onChange={handleRadio}>
          <Space direction='vertical'>
            {radioButtons.map((e: { value: T; label: string }) => (
              <Radio key={e.value} value={e.value}>
                {e.label}
              </Radio>
            ))}
          </Space>
        </RadioGroup>

        {filteredSelections?.map((selection: SelectType) => {
          return (
            <Col key={selection.key}>
              <h3 style={{ color: getCssVar('textColor') }} className='header mt'>
                {selection.label}
              </h3>
              <Select
                options={selection.options}
                className='select'
                placeholder={selection.placeholder}
                onChange={(value: SelectValue) => handleSelect(value, selection.key)}
                value={selectionValues[selection.key]}
                suffixIcon={
                  loadingServiceType ? <Spin /> : <Icon component={icons.arrow_drop_down} />
                }
                disabled={!selection.options.length}
              />
            </Col>
          );
        })}
      </Col>
    </UploadPopupStyled>
  );
};
