import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getCssVar } from 'styles/theme';
import { ThemeProps } from 'services/interfaces';

export const StyledLink = styled(Link)<ThemeProps>`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${getCssVar('link.color')};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }) => theme.spacing[4]};
  font-weight: ${getCssVar('link.fontWeight')} !important;
  margin-bottom: ${({ theme }) => theme.spacing['2.5']};

  .anticon {
    position: relative;
    margin-right: 6px;

    svg {
      fill: ${({ color }) => color || getCssVar('link.color')};
    }

    &:after {
      background: ${getCssVar('link.bg')};
      border-radius: ${({ theme }) => theme.radius.full};
      content: '';
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.25s ease;
    }
  }

  :hover {
    text-decoration: underline;

    .anticon {
      &:after {
        height: 32px;
        opacity: 0.24;
        width: 32px;
      }
    }
  }
`;
