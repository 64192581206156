import { colors } from '../colors';
import { fontWeights } from '../fontWeights';
import { sizes } from '../sizes';

export const login = {
  logoDesktop: 'logo-nasstar.svg',
  logoMobile: 'logo-nasstar-mobile.svg',
  logoWidth: '190px',
  logoHeight: '29px',

  alert: {
    error: {
      backgroundColor: 'rgb(248, 215, 218)',
      textColor: 'rgb(156, 43, 35)',
      borderColor: 'rgb(248, 215, 218)',
    },
  },

  passwordStrengthColors: [
    colors.red[450],
    colors.yellow[460],
    colors.yellow[460],
    colors.green[700],
  ],
  zeroStrengthColor: colors.gray[100],

  sidebar: {
    background: colors.white,
    logoColor: colors.black,
    smartBlocColor: colors.black,
    mobile: {
      background: colors.transparent,
      logoColor: colors.white,
      smartBlocColor: colors.white,
    },
  },
  formWrap: {
    background: colors.white,
    borderRadius: '0',
    margin: '0 auto 20px',
    padding: '20px 35px',
    maxWidth: '400px',
    mobile: {
      background: colors.transparent,
    },
  },
  formTitle: {
    color: colors.black,
    fontSize: '26px',
    fontWeight: fontWeights.bold,
    marginBottom: sizes['2.5'],
    mobile: {
      color: colors.white,
    },
  },
  formSubTitle: {
    color: colors.black,
    fontSize: sizes['3.6'],
    margin: '0 0 15px',
    mobile: {
      color: colors.white,
    },
  },
  formLabel: {
    color: colors.black,
    opacity: '0.5',
    mobile: {
      color: colors.white,
      opacity: '1',
    },
  },
  formInput: {
    backgroundColor: colors.white,
    textColor: colors.black,
    borderColor: colors.gray[80],
    borderColorHover: colors.gray[200],
    borderColorError: 'rgb(235, 74, 112)',
    iconBackgroundColor: colors.white,
    iconColor: colors.gray[300],
    errorMessageColor: 'rgb(156, 35, 63)',
  },
  link: {
    color: colors.blue[550],
    hoverColor: colors.blue[550],
    fontSize: sizes[4],
    fontWeight: fontWeights.bold,
    mobile: {
      color: colors.yellow[460],
      hoverColor: colors.yellow[460],
      fontSize: sizes[4],
      fontWeight: fontWeights.bold,
    },
  },
  tfa: {
    textColor: colors.black,
    linkColor: colors.yellow[400],
    linkColorHover: colors.yellow[400],
    copyButtonColor: colors.blue[550],
    copyButtonHoverColor: colors.blue[550],
    inputTextColor: colors.black,
    inputBackgroundColor: colors.white,
    inputBorderColor: colors.blue[550],
    mobile: {
      textColor: colors.white,
    },
  },
};
