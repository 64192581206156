import { colors } from '../../colors';
import { baseDarkNorm } from '../base';
import { inputsBase } from './base';

export const select = {
  bg: inputsBase.backgroundColor,
  backgroundColor: inputsBase.backgroundColor,
  iconColor: inputsBase.iconColor,
  textColor: colors.white,

  backgroundColorDisabled: inputsBase.backgroundColorDisabled,
  textColorDisabled: inputsBase.textColorDisabled,
  borderColorDisabled: inputsBase.borderColor,

  cascader: {
    backgroundColor: inputsBase.backgroundColor,
    item: {
      checkedBackgroundColor: inputsBase.checkedBackgroundColor,
      checkedIconColor: inputsBase.activeColor,
      hoverBackgroundColor: 'rgba(186, 186, 171, 0.12)',
    },
  },
  tag: {
    backgroundColor: '#494B42',
    borderColor: '#323332',
    iconColor: inputsBase.iconColor,
    hoverIconColor: colors.white,
  },
  selector: {
    borderColor: inputsBase.borderColor,
    hoverBorderColor: inputsBase.borderColorHover,
    focusBorderColor: inputsBase.borderColorHover,
    checked: {
      borderColor: inputsBase.activeColor,
      backgroundColor: inputsBase.checkedBackgroundColor,
      hoverBorderColor: inputsBase.borderColorHover,
    },
  },
  dropDownActions: {
    backgroundColor: baseDarkNorm.black,
    backgroundColorHover: 'rgba(186, 186, 171, 0.12)',
    dangerColor: baseDarkNorm.deepRed,
    iconColor: '#8A8A8A',
    dividerColor: baseDarkNorm.divider,
  },
};
