import styled from 'styled-components';
import { getCssVar } from 'styles/theme';
import { Descriptions } from 'pages/CmdbHostDetails/components/AssetDetails/styled';
import { Theme } from 'styles/theme/types';

type Props = {
  theme: Theme;
  ws?: string;
};

export const StepperText = styled.div`
  align-items: center;
  color: ${getCssVar('textColor')};
  display: flex;
  font-size: 15px;
  font-weight: 400;

  height: 100%;
  justify-content: center;
  left: 0;

  line-height: 21px;
  position: absolute;
  top: -2px;

  width: 100%;
`;

export const DescriptionItem = styled(Descriptions.Item)`
  .ant-descriptions-item-content {
    display: block;
  }
`;
export const CommonDetailsBlock = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }: Props) => `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[10]}`};
`;

export const CommonDetailsHeader = styled.span<Props>`
  font-size: ${({ theme }: Props) => theme.sizes[4]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
`;

export const CommonDetailsMain = styled.div<Props>`
  overflow: hidden;
  width: 100%;
`;

export const CommonDetailsContent = styled.div<Props>`
  font-size: 15px;
  margin: ${({ theme }: Props) => `${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[0]}`};
  overflow: auto hidden;

  white-space: normal;
  width: ${({ theme }: Props) => theme.sizes.full};
  word-wrap: break-word;

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
    ::-webkit-scrollbar {
      height: 16px;
      bottom: 10px;
      width: 50px;
    }

    ::-webkit-scrollbar-track {
      background: ${getCssVar('alarmContent.scrollbar.bg')};
      border-radius: 8px;
      margin-right: 0px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${getCssVar('alarmContent.scrollbar.color')};
      border-radius: 10px;
    }
  }

  table {
    border: 1px solid ${({ theme }: Props) => theme.colorSet.alarmContent.borderColor};
    margin-bottom: ${({ theme }: Props) => theme.sizes[4]};
    th {
      background-color: ${({ theme }: Props) => theme.colorSet.alarmContent.backgroundColor};
      padding: 5px;
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }: Props) => theme.colorSet.alarmContent.borderColor};
      }
    }
    tr {
      border-bottom: 1px solid ${({ theme }: Props) => theme.colorSet.alarmContent.borderColor};
    }
    td {
      padding: 5px;
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }: Props) => theme.colorSet.alarmContent.borderColor};
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }: Props) => theme.colorSet.textColor};
  }
`;
