import styled from 'styled-components';
import { AsideDescription } from '../styled';

export const PenetrationTestingText = styled(AsideDescription)`
  left: 539px;

  position: absolute;

  top: 213px;

  transform: translateX(-50%);

  .title {
    font-weight: 700;
    margin-bottom: 5px;
  }

  ul {
    margin-top: 5px;
  }
`;
