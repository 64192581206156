import styled from 'styled-components';
import { Modal } from 'shared/Modal/Modal';
import { Theme } from 'styles/theme/types';
import { Button } from '../../../shared';
import { getCssVar } from '../../../styles/theme';

type Props = {
  theme: Theme;
};

export const Popup = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      display: flex;
      justify-content: center;
      border: none;
      border-radius: 4px;

      .ant-modal-title {
        color: ${getCssVar('textColor')};
        font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
        font-size: 16px;
      }
    }

    .ant-modal-body {
      padding: 0;

      .ant-menu-item {
        display: flex;
        justify-content: start;
        align-items: center;
        height: 48px;
        padding: 0 12px;
      }

      .ant-menu-item-selected {
        background-color: ${({ theme }: Props) => `${theme.colors.brown[100]}${theme.opacity[12]}`};
        color: currentColor;
      }
    }
  }
`;

export const SaveEditButton = styled(Button)`
  border-radius: 0;
  max-width: 40px;
  width: 100%;

  &:hover {
    box-shadow: none;
    transform: none;
  }

  &.ant-btn:not(.ant-btn-primary):not(.secondary):not(.ant-btn-link) {
    background-color: ${getCssVar('primaryColor')} !important;
    border: 2px solid ${getCssVar('primaryColor')} !important;
    border-radius: initial;

    &:disabled {
      background-color: gainsboro;

      &:hover {
        background-color: gainsboro;
      }
    }
  }
`;
