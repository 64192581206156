import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { RequestStatus } from 'store/loading/slice';
import { Col, Dropdown, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { adminUsersApi, AppDispatch, useAdminUsers, useApp, useAuth, useCustomer } from 'store';
import { useGetPermission } from 'services/hooks';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { ActionPopup, Box, Button, Icon, InnerLayout } from 'shared';
import { ActionType, PopupConfigType } from 'shared/ActionPopup/types';
import { getCssVar, icons } from 'styles/theme';
import { filterActionsByStatus, showPopup } from 'pages/Users/helpers';
import { getCustomMenu, getLayoutConfig } from './helpers';
import { popupConfig } from '../../constants';
import { Permissions } from './Permissions/Permissions';
import { Details } from './Details/Details';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const FULL_WIDTH_COLS = 32;

export const UserDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { userDetails } = useAdminUsers();
  const ref = useRef(false);
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { isMobile } = useApp();
  const GUTTER: [Gutter, Gutter] = !isMobile ? [H_GUTTER, V_GUTTER] : [H_GUTTER, V_GUTTER_MOBILE];
  const customer = useCustomer();
  const { user } = useAuth();
  const [disabledActions, setDisabledActions] = React.useState(false);
  const [layoutConfig, setLayoutConfig] = React.useState({});
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const { state: historyState } = useLocation<HistoryState>();
  const isFullAccess = useGetPermission(PermissionOptions.AdminUsers) === PermissionsLevel.Full;

  const getUserDetailsAndPermissions = React.useCallback(async () => {
    if (!ref.current) {
      const result = await dispatch(
        adminUsersApi.userDetails({ customerId: customer, userId: id }),
      );

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        history.push(Routes.NOT_FOUND);
      }

      dispatch(adminUsersApi.userPermissions({ customerId: customer, userId: id }));
      ref.current = true;

      return;
    }

    history.push(Routes.USERS);
  }, [history, customer, dispatch, id]);

  const goBackToUsersPage = React.useCallback(() => {
    history.push(historyState?.backTo?.route);
  }, [history, historyState?.backTo?.route]);

  const handleClickAction = React.useCallback(
    (popupId: string) => {
      if (!userDetails.name || !userDetails.email) {
        return;
      }

      const userData = { id, name: userDetails.name, email: userDetails.email };

      showPopup({
        popupId,
        setPopupDetails,
        setIsVisiblePopup,
        userData,
        dispatch,
        customerId: customer,
        onSuccess:
          popupId !== popupConfig.delete.id ? getUserDetailsAndPermissions : goBackToUsersPage,
        user,
      });
    },
    [customer, dispatch, id, userDetails, getUserDetailsAndPermissions, goBackToUsersPage, user],
  );

  const handleDisabledActions = React.useCallback((disabled: boolean) => {
    setDisabledActions(disabled);
  }, []);

  const renderActionsInButtonsRow = React.useCallback(
    (actions: ActionType[]) => {
      return actions.map(({ name, icon, popupId }, index: number) => {
        let color = getCssVar('link.color');

        if (name === 'Delete' && !disabledActions) {
          color = getCssVar('errorSecondaryColor');
        }

        if (disabledActions) {
          color = getCssVar('inactiveColor');
        }

        return (
          <Box key={name} ml={index !== 0 ? '12px' : '0'}>
            <Button
              type='link'
              color={color}
              key={name}
              icon={<Icon component={icon} />}
              onClick={() => handleClickAction(popupId)}
              disabled={disabledActions}
            >
              {name}
            </Button>
          </Box>
        );
      });
    },
    [disabledActions, handleClickAction],
  );

  const renderActions: () => JSX.Element | JSX.Element[] = React.useCallback(() => {
    const actionsArray = filterActionsByStatus(userDetails.status);

    if (!isMobile) {
      return renderActionsInButtonsRow(actionsArray);
    }

    const firstActionsArrayPart = actionsArray.slice(0, 2);
    const secondActionsArrayPart = actionsArray.slice(2);

    return (
      <Row justify='end'>
        {renderActionsInButtonsRow(firstActionsArrayPart)}
        <Dropdown
          overlay={getCustomMenu(secondActionsArrayPart, handleClickAction)}
          trigger={['click']}
          disabled={disabledActions}
        >
          <Button type='link' icon={<Icon component={icons.more_vert} />} />
        </Dropdown>
      </Row>
    );
  }, [disabledActions, handleClickAction, userDetails, isMobile, renderActionsInButtonsRow]);

  React.useEffect(() => {
    getUserDetailsAndPermissions();
  }, [getUserDetailsAndPermissions]);

  React.useEffect(() => {
    if (userDetails && userDetails.id === id) {
      setLayoutConfig(getLayoutConfig(userDetails, renderActions, historyState, isFullAccess));
    }
  }, [userDetails, renderActions, id, historyState, isFullAccess]);

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={GUTTER}>
        <Col span={FULL_WIDTH_COLS} xs={24} xxl={24}>
          <Details data={userDetails} isMobile={isMobile} />
        </Col>

        <Col span={FULL_WIDTH_COLS} xs={24} xxl={24}>
          <Permissions
            setDisabledActions={handleDisabledActions}
            customerId={user.customer}
            currentCustomerId={userDetails.customer || ''}
            userId={id}
            email={userDetails.email}
            isMobile={isMobile}
          />
        </Col>
      </Row>

      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
        />
      )}
    </InnerLayout>
  );
};
