import React from 'react';
import { Modal } from 'shared/Modal/Modal';
import moment, { Moment } from 'moment';
import { Button, DatePicker } from 'shared';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { PopupBodyContainer } from '../styled/styled';
import { FormItem } from '../../styled';

// const MIN_REPORT_DATE = '05/01/2022';

interface PopUpProps {
  closePopup: () => void;
  isVisible: boolean;
  handleOk: (startDate: number, endDate: number) => void;
  loading?: boolean;
  isMobile?: boolean;
}

const validationSchema = Yup.object({
  date: Yup.string().trim().required('Select month is a required field'),
});

export const ReportModal = ({ closePopup, isVisible, loading, handleOk, isMobile }: PopUpProps) => {
  const initialValues = {
    date: '',
  };
  const { handleSubmit, handleBlur, setFieldValue, errors, touched, setFieldTouched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: FormikValues) => {
      const startDate = moment(values.date).clone().startOf('month').valueOf();
      const endDate = moment(values.date).clone().endOf('month').valueOf();

      handleOk(startDate, endDate);
    },
  });

  const onPickDate = (value: Moment | null) => {
    setFieldValue('date', value?.valueOf());
  };

  return (
    <Modal
      title='Generate and download report'
      visible={isVisible}
      width={720}
      onCancel={() => {
        closePopup();
      }}
      footer={[
        <Button
          type='secondary'
          key='back'
          onClick={() => {
            closePopup();
          }}
        >
          Cancel
        </Button>,
        <Button
          key='enter'
          type='primary'
          disabled={!!Object.values(errors).length}
          loading={loading}
          onClick={() => {
            if (!Object.values(errors).length) {
              handleSubmit();
            }

            setFieldTouched('date');
          }}
        >
          Download file
        </Button>,
      ]}
    >
      <PopupBodyContainer>
        <FormItem
          validateStatus={errors.date && touched.date ? 'error' : 'success'}
          help={touched.date && errors.date}
        >
          <DatePicker
            name='date'
            onChange={onPickDate}
            onBlur={handleBlur}
            inputReadOnly={isMobile}
            picker='month'
            mode='month'
            disabledDate={(current: Moment) => current && current > moment().date(0)}
          />
        </FormItem>
      </PopupBodyContainer>
    </Modal>
  );
};
