import { Pagination as AntdPagination } from 'antd';
import styled, { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

const PAGE_HEIGHT = '40px';

const PageInactiveStyles = css`
  background-color: ${getCssVar('pagination.page.bg')};
  border-color: ${getCssVar('pagination.page.borderColor')};
  color: ${getCssVar('textColor')};

  a {
    color: ${getCssVar('textColor')};
  }
`;

const PageHoverStyles = css`
  background: ${getCssVar('pagination.page.hover.bg')} !important;
  border-color: ${getCssVar('pagination.page.hover.borderColor')};
  box-shadow: ${getCssVar('pagination.page.hover.boxShadow')};

  a {
    color: ${getCssVar('textColor')};
  }
`;

const BaseOverideStyles = css`
  .ant-pagination-total-text {
    flex: 1 1;
    font-size: 14px;
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.normal};
    height: auto;
    margin-right: auto;
  }

  .ant-select-selector,
  .ant-pagination-item-link,
  .ant-pagination-item,
  input {
    border-radius: ${({ theme }: { theme: Theme }) => theme.radius.sm};
  }

  .ant-select-selector,
  .ant-pagination-options-quick-jumper,
  input {
    height: ${PAGE_HEIGHT} !important;
    line-height: ${PAGE_HEIGHT} !important;
  }

  /* Page selector dropdown block */
  .ant-pagination-options {
    .ant-select-selector {
      ${PageInactiveStyles};

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        align-content: center;
        line-height: 1;
      }

      &:hover {
        border-color: ${getCssVar('pagination.page.hover.borderColor')};
      }
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: ${getCssVar('pagination.page.hover.borderColor')};
        box-shadow: none;
      }

      .ant-select-selection-item {
        color: ${getCssVar('textColor')};
      }
    }

    /* Goto page block */
    .ant-pagination-options-quick-jumper {
      input {
        box-shadow: none;
        margin: 0 8px;

        ${PageInactiveStyles};

        &:focus,
        &-focused,
        &:hover {
          border-color: ${getCssVar('pagination.page.hover.borderColor')};
        }
      }
    }
  }
`;

export const Pagination = styled(AntdPagination)`
  &.ant-pagination {
    ${BaseOverideStyles};

    align-items: center;
    color: ${getCssVar('textColor')};
    display: flex;
    margin: ${({ theme }: { theme: Theme }) => `${theme.sizes['2.5']} ${theme.sizes[0]}`};

    .ant-pagination-item-link,
    .ant-pagination-item {
      font-family: ${getCssVar('fontFamily')} !important;
      font-weight: ${getCssVar('labelFontWeight')};

      ${PageInactiveStyles};

      /* button arrow */
      span {
        padding-bottom: ${getCssVar('pagination.paddingBottom')};
        color: ${getCssVar('pagination.iconColor')};
      }

      &:disabled {
        background-color: ${getCssVar('pagination.page.disabled.bg')};
        border-color: ${getCssVar('pagination.page.disabled.borderColor')};
        color: ${getCssVar('pagination.page.disabled.color')};

        span,
        a {
          color: ${getCssVar('pagination.page.disabled.color')};
        }
      }
    }

    .ant-pagination-item:hover,
    .ant-pagination-item:focus {
      ${PageHoverStyles};
    }

    .ant-pagination-item-link:hover,
    .ant-pagination-item-link:focus {
      :not(:disabled) {
        ${PageHoverStyles};
      }
    }

    .ant-pagination-item-active {
      &,
      a,
      &:hover,
      &:focus {
        background: ${getCssVar('pagination.page.active.bg')};
        border-color: ${getCssVar('pagination.page.active.bg')};
        color: ${getCssVar('pagination.page.active.color')} !important;
      }
    }
  }
`;

export const MobilePaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }: { theme: Theme }) => `${theme.spacing[2]} 0 ${theme.spacing[1]}`};
`;

export const PerPageContainer = styled.div`
  align-items: center;
  display: flex;

  > span {
    font-size: 14px;
    margin-right: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
  }

  .ant-select {
    min-width: 64px;

    .ant-select-arrow {
      margin-top: -6px;
      padding-left: 11px !important;
    }

    .ant-select-selector {
      padding: 0 11px 0 12px !important;

      .ant-select-selection-item {
        padding-right: 4px;
      }
    }
  }

  .ant-form-item {
    margin: 0 !important;
  }
`;

export const GoToContainer = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[3]};

  > span {
    font-size: 14px;
    margin-right: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
    white-space: nowrap;
  }

  .ant-form-item {
    margin: 0 !important;

    .ant-input {
      padding: 7px 12px;
    }
  }

  .ant-form-item-control-input-content {
    width: 60px;
  }
`;
