import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'antd';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Card, InnerLayout } from 'shared';
import {
  AppDispatch,
  AppState,
  managedDetectionsResponseApi,
  useCustomer,
  useLoading,
} from 'store';
import { useHistory, useParams } from 'react-router';
import { useGetPermissions, useRowGap } from 'services/hooks';
import { Routes } from 'services/entities';
import { RequestStatus } from 'store/loading/slice';
import { useLocation } from 'react-router-dom';
import { HistoryState } from 'services/interfaces';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { getLayoutConfig } from './helpers';
import {
  CommonData,
  DescriptionTitle,
  DeviceData,
  EmailData,
  FileData,
  NetworkData,
  SourceData,
} from './constants';
import { DescriptionItems } from './components/DescriptionItems';
import { AlarmStepper } from './components/AlarmStepper';
import { CommonDetailsItem } from './components/CommonDetailsItem';

export const AlarmDetails = () => {
  const theme = useTheme();
  const permissions = useGetPermissions([PermissionOptions.CMDB]);
  const { id: alarmId } = useParams<{ id: string }>();
  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();
  const { gapNumber } = useRowGap();
  const history = useHistory();
  const { state: historyState } = useLocation<HistoryState>();
  const loading = useLoading([managedDetectionsResponseApi.getAlarmDetails.typePrefix]);
  const isLoaded = loading[managedDetectionsResponseApi.getAlarmDetails.typePrefix];
  const hasCMDBPermission = permissions.cmdb !== PermissionsLevel.NoAccess;

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(
      managedDetectionsResponseApi.getAlarmDetails({
        customerId,
        alarmId,
      }),
    ).then((result) => {
      if (result.meta.requestStatus === RequestStatus.Rejected) {
        history.push(Routes.THREAT_DETECTION);
      }
    });
  }, [customerId, dispatch, alarmId, history]);

  const alarmDetails = useSelector(({ mdr: { alarmDetails } }: AppState) => alarmDetails);
  const layoutConfig = getLayoutConfig(alarmDetails.name, historyState, alarmDetails.risk);

  const stepperItems = useMemo(() => {
    const categories = (alarmDetails.tactic ? alarmDetails.tactic.split(',') : []).map((item) =>
      item.toLowerCase(),
    );

    return Object.entries(alarmDetails?.mitreTechniquesUrls || {}).map(([value, link]) => ({
      value,
      link: categories.length > 0 ? link : undefined,
      isActive: categories.includes(value.toLowerCase()),
    }));
  }, [alarmDetails?.tactic, alarmDetails?.mitreTechniquesUrls]);

  const backLink = {
    route: `${Routes.ALARM_DETAILS.replace(':id', alarmId)}`,
    title: `Back to ${alarmDetails.name}`,
  };

  return (
    <InnerLayout {...layoutConfig}>
      <AlarmStepper items={stepperItems} />
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={32} xxl={16}>
          <Card
            height={theme.sizes.full}
            cardPadding={`${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes[5]}`}
            showLoader={isLoaded}
          >
            <Row gutter={32}>
              <Col span={32} xs={24} xl={24}>
                <CommonDetailsItem
                  title={CommonData.description}
                  data={alarmDetails?.description}
                />
                <CommonDetailsItem
                  title={CommonData.enrichmentData}
                  data={alarmDetails?.enrichmentData}
                />
                <CommonDetailsItem
                  title={CommonData.eventIOCDetails}
                  data={alarmDetails?.eventIOCDetails}
                />
              </Col>
              <Col span={32} xs={24} xl={12}>
                <DescriptionItems
                  listOfFields={FileData}
                  title={DescriptionTitle.File_Data}
                  data={alarmDetails?.fileData || {}}
                />
                <DescriptionItems
                  listOfFields={EmailData}
                  title={DescriptionTitle.Email_Data}
                  data={alarmDetails?.emailData || {}}
                />
              </Col>
              <Col span={32} xs={24} xl={12}>
                <DescriptionItems
                  listOfFields={NetworkData}
                  title={DescriptionTitle.Network_Data}
                  data={alarmDetails?.networkData || {}}
                />
                <DescriptionItems
                  listOfFields={DeviceData}
                  title={DescriptionTitle.Device_Data}
                  data={alarmDetails?.deviceData || {}}
                  backLink={backLink}
                  hasPermission={hasCMDBPermission}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xxl={8}>
          <Card
            height={theme.sizes.md}
            cardPadding={`${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes[5]}`}
            showLoader={isLoaded}
          >
            <DescriptionItems
              listOfFields={SourceData}
              title=''
              data={alarmDetails}
              backLink={backLink}
            />
          </Card>
        </Col>
      </Row>
    </InnerLayout>
  );
};
