import ReactECharts from 'echarts-for-react';
import { IDate } from 'services/interfaces';
import { AverageConfidenceResponse } from 'services/api';
import { IEvents } from 'pages/ThreatDetection/types';
import { useTheme } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { Values } from '../types';
import { EChartsEventParams, IEChartsOption } from '../interfaces';

type ValuesType = Values | IEvents[] | AverageConfidenceResponse;

type Props<T extends ValuesType> = {
  values: T;
  getOption: (
    params: { values: T; date?: IDate },
    theme: Theme,
    isMobile?: boolean,
    gridHeight?: number,
    onEvents?: boolean,
  ) => IEChartsOption;
  date?: IDate;
  isMobile?: boolean;
  height?: number;
  gridHeight?: number;
  onEvents?: Record<string, (params: EChartsEventParams) => void>;
  onLoad?: () => void;
};

export const Area = <T extends ValuesType>({
  values,
  getOption,
  date,
  isMobile,
  height,
  gridHeight,
  onEvents,
  onLoad,
}: Props<T>) => {
  const theme = useTheme();

  return (
    <ReactECharts
      option={getOption({ values, date }, theme, isMobile, gridHeight, Boolean(onEvents))}
      style={{ height: '100%' }}
      opts={{ height, renderer: 'canvas' }}
      onEvents={onEvents}
      onChartReady={onLoad}
      notMerge
    />
  );
};
