import { Routes } from 'services/entities';
import {
  Filter,
  HostSource,
  SupportRequestsStatus,
  SupportRequestsType,
  VulnerabilityDetails,
} from 'services/api';
import { getQueryString } from 'services/api/core/request';
import {
  initialOrderBy as phishingOrderBy,
  TrainingProgress,
} from 'pages/PeoplePhishing/constants';
import { initialPagination } from 'app-constants';
import { initialOrderBy as cmdbOrderBy } from 'pages/Cmdb/constants';
import { initialOrderBy as supportRequestOrderBy } from 'pages/SupportRequests/constants';
import { Annotations } from 'shared/charts/GradientPie/types';

export enum TooltipTitles {
  TotalStress = 'The current status of your overall cyber risk, combining all elements of Process, People and Technology',
  Process = 'Establish your security baseline and demonstrate to external stakeholders your organisation’s commitment to information security by completing and maintaining these industry recognised accreditations.',
  People = 'Ensure your people are well prepared to recognise a potential cyber attack, through ongoing education and continuous testing.',
  Phishing = 'Summary of all phishing simulation emails sent over the past 30 days and the actions taken by your staff.',
  Technology = 'Technology Controls should be proactive and reactive. Minimising technical vulnerabilities combined with actively monitoring your entire technology estate will ensure you are prepared to respond to any potential malicious activity',
  TrainingStatus = 'The current status of your organisation’s learning progress.',
  ThreatDetection = 'Total number of events within your technology environment that are  being monitored and analysed by our SOC',
  VulnerabilityManagement = 'Total technical vulnerabilities discovered across your technology environment',
  BoostScores = 'Boost your Cyber Resilience Score by telling us about the additional controls that you have in place.',
}

export const getScoreAnnotations = (customerName: string): Annotations => ({
  low: {
    title: 'Urgent attention\nrequired',
    description: 'Act now to improve your Cyber Resilience',
  },
  medium: {
    title: 'Still some work\nto do',
    description: `There's still some work to do before you're Cyber Resilient`,
  },
  high: {
    title: 'Getting there',
    description: `You're on your way to becoming Cyber Resilient!`,
  },
  very_high: {
    title: `You're in good\nshape`,
    description: `${customerName} are in good shape, keep up the good work!`,
  },
});

export const viewMoreConfig = {
  people: {
    route: Routes.PEOPLE,
  },
  dataProtection: {
    route: Routes.CMDB,
  },
  latestReports: {
    route: Routes.DOCUMENTS,
  },
  process: {
    route: Routes.PROCESS,
  },
  vulnerabilityManagement: {
    route: Routes.VULNERABILITY_MANAGEMENT,
  },
  mdr: {
    route: Routes.THREAT_DETECTION,
  },
  latestOpenRequests: {
    route: Routes.SUPPORT_REQUESTS,
  },
};

export enum TotalStressScoreLabels {
  IsolatedDevices = 'Isolated devices',
  CriticalHosts = 'Hosts with urgent vulnerabilities',
  HighHosts = 'Hosts with critical vulnerabilities',
  SecurityIncidents = 'Security incidents',
  IncompleteTraining = 'Incomplete training',
  CertificationsDue = 'Certifications due',
}

export const totalStressScoreRedirects = {
  isolatedDevices: `${Routes.CMDB}${getQueryString({
    pagination: initialPagination,
    orderBy: cmdbOrderBy,
    filter: {
      fields: [
        {
          name: 'assetSource',
          value: [
            HostSource.EDR_AGENT_FORTI_EDR_,
            HostSource.EDR_AGENT_TRELLIX_,
            HostSource.EDR_AGENT_SENTINEL_ONE_,
          ],
          type: Filter.type.MULTIPLE,
        },
        { name: 'deviceStatus', value: ['Isolated'], type: Filter.type.MULTIPLE },
      ],
    },
  })}`,
  criticalHosts: `${Routes.CMDB}${getQueryString({
    pagination: initialPagination,
    orderBy: cmdbOrderBy,
    filter: {
      fields: [
        {
          name: 'vulnerabilitySeverity',
          value: [VulnerabilityDetails.severity._5],
          type: Filter.type.MULTIPLE,
        },
      ],
    },
  })}`,
  highHosts: `${Routes.CMDB}${getQueryString({
    pagination: initialPagination,
    orderBy: cmdbOrderBy,
    filter: {
      fields: [
        {
          name: 'vulnerabilitySeverity',
          value: [VulnerabilityDetails.severity._4],
          type: Filter.type.MULTIPLE,
        },
      ],
    },
  })}`,
  securityIncidents: `${Routes.SUPPORT_REQUESTS}${getQueryString({
    pagination: initialPagination,
    orderBy: supportRequestOrderBy,
    filter: {
      fields: [
        {
          name: 'status',
          value: SupportRequestsStatus.ACTIVE,
          type: Filter.type.VALUE,
        },
        {
          name: 'type',
          value: SupportRequestsType.SECURITY_INCIDENT,
          type: Filter.type.VALUE,
        },
      ],
    },
  })}`,
  incompleteTraining: `${Routes.PEOPLE_PHISHING}${getQueryString({
    pagination: initialPagination,
    orderBy: phishingOrderBy,
    filter: {
      fields: [
        {
          name: 'trainingProgressStatus',
          value: [TrainingProgress.InProgress, TrainingProgress.NotStarted],
          type: Filter.type.MULTIPLE,
        },
      ],
    },
  })}`,
  certificationsDue: Routes.PROCESS,
};

export const topColResponsiveProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 12,
};

export const PeopleStatisticsInitialState = {
  training: null,
  phishing: null,
};

export const THIRTEEN_MONTHS = 13;
