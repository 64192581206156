import { Tag, UcFirstLetter } from 'shared';
import { TagProps } from 'antd';
import { CMDBSeverity } from 'services/constants';
import { getCssVar } from 'styles/theme';

type CustomTagProps = TagProps & {
  title: string;
};

export const StatusTagColoring = ({ title, ...TagProps }: CustomTagProps) => {
  let textColor = getCssVar('status.error.color');

  const tagType = (title: string) => {
    switch (title) {
      case CMDBSeverity.High:
        return getCssVar('severity.high');
      case CMDBSeverity.Medium:
        return getCssVar('severity.meduim');
      case CMDBSeverity.Low:
        textColor = getCssVar('status.success.color');

        return getCssVar('severity.low');
      default:
        return getCssVar('severity.high');
    }
  };

  return (
    <Tag color={tagType(title)} key={title} {...TagProps} title={title}>
      <div style={{ color: textColor }}>{UcFirstLetter(title)}</div>
    </Tag>
  );
};
