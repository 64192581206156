import { Icon, Box } from 'shared';
import { icons } from 'styles/theme';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { Spin } from 'antd';
import { StyledOption, StyledSelect } from './styled';
import { ISelectFields, OptionType } from '../types';

export const Select = ({ options, value, onChange, disabled, loading }: ISelectFields) => {
  const { Option } = StyledSelect;

  const items = options.map((item: OptionType) => {
    return (
      <Option key={item.label} value={item.value}>
        <Box d='flex' flexDirection='column'>
          <span>{item.label}</span>
          {!item.type ? (
            <StyledOption>{item.subLabel}</StyledOption>
          ) : (
            <>
              {item.label === 'High' && (
                <StyledOption>
                  Critical functionality or network access interrupted, <br />
                  degraded or unusable, affecting business and high <br />
                  number of staff
                </StyledOption>
              )}
              {item.label === 'Medium' && (
                <StyledOption>
                  Non-critical function or procedure, unusable or hard to <br />
                  use having an operational impact
                </StyledOption>
              )}
              {item.label === 'Low' && (
                <StyledOption>Issue with limited business impact</StyledOption>
              )}
            </>
          )}
        </Box>
      </Option>
    );
  });

  const suffixIcon = loading ? (
    <Spin />
  ) : (
    <Icon className='ant-select-suffix' component={icons.arrow_drop_down} />
  );

  return (
    <>
      <StyledSelect
        showSearch
        suffixIcon={suffixIcon}
        value={value}
        onChange={onChange}
        disabled={disabled}
        filterOption={(inputValue: string, option: OptionData | OptionGroupData | undefined) =>
          (option?.key as string)?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      >
        {items}
      </StyledSelect>
    </>
  );
};
