import React from 'react';
import { BackLink, Box, InnerLayoutProps as InnerLayout } from 'shared';
import { Routes } from 'services/entities';
import { backlinkRoutes, emptyDataString } from 'app-constants';
import moment from 'moment';
import { getCapitalizedString, stripCssRules, stripTags } from 'services/helpers';
import { HistoryState } from 'services/interfaces';
import { HostLink, RelatedIncident } from 'services/api';
import { Link } from 'react-router-dom';
import { ReadMoreText } from 'shared/ReadMoreText/ReadMoreText';
import { TagColoring } from 'pages/Alarms/components/TagColoring';
import { StatusColoring } from '../ThreatDetection/components/MonitoredServices/components/StatusColoring';
import { TBackLink } from './types';
import { IconColoring } from '../ThreatDetection/components/RecentAlarms/IconColoring';
import { DescriptionStyled } from '../SupportRequestDetails/styled';
import { CmdbTabKeys } from '../CmdbHostDetails/constants';

export const getLayoutConfig = (
  title = '',
  historyState?: HistoryState,
  status = '',
): InnerLayout => ({
  title,
  backLink: (
    <BackLink
      historyState={historyState}
      defaultRoute={Routes.ALARMS}
      defaultTitle={backlinkRoutes[Routes.ALARMS]}
    />
  ),
  status: status && <TagColoring title={status} style={{ marginLeft: '16px' }} />,
});

export const getDateFromTimestamp = (timestamp = 0) => {
  return moment(timestamp).format('DD-MMM-YYYY');
};

export const commonDataMapper = {
  updated: (value?: number) => getDateFromTimestamp(value) || '-',
  created: (value?: number) => getDateFromTimestamp(value) || '-',
  description: (value: string) => <ReadMoreText text={stripTags(value) || '-'} />,
  enrichmentData: (value: string) => <ReadMoreText text={stripTags(value) || '-'} />,
  eventIOCDetails: (value: string) => (
    <ReadMoreText
      text={
        stripCssRules(stripTags(value))
          .replaceAll('Show more', '')
          .replaceAll('Show less', '')
          .trim() || '-'
      }
    />
  ),
  resolved: (value?: number) => (value ? getDateFromTimestamp(value) : '-'),
  serviceStatus: (value: string) => (value ? <StatusColoring status={value} /> : '-'),
  risk: (value: string) => <TagColoring title={getCapitalizedString(value)} /> || '-',
  mitreAttackId: (record: { id: string; url: string }) => {
    if (!record?.url && !record?.id) {
      return '-';
    }

    const ids = record.id.split(',');

    return ids.map((itemId, index) => (
      <>
        <a
          href={`${record.url}${itemId.replaceAll('.', '/')}`}
          key={itemId}
          target='_blank'
          rel='noreferrer'
        >
          {itemId}
        </a>
        {index !== ids.length - 1 && ', '}
      </>
    ));
  },
  host: (record: HostLink, backLink?: TBackLink, hasPermission?: boolean) => {
    return record?.name && record?.id && backLink && hasPermission ? (
      <Link
        to={{
          pathname: `${Routes.CMDB}/${record.id}`,
          state: {
            backTo: !backLink ? backlinkRoutes[Routes.ALARMS] : backLink,
            activeTabKey: CmdbTabKeys.ALARMS,
            data: record,
          },
        }}
      >
        {record?.name}
      </Link>
    ) : (
      record?.name
    );
  },
  status: (status?: string) => {
    return (
      <Box display='flex' alignItems='center'>
        {status === null || status === undefined ? (
          emptyDataString
        ) : (
          <>
            <IconColoring status={status} />
            {status}
          </>
        )}
      </Box>
    );
  },
  relatedIncident: (record: RelatedIncident, backLink?: TBackLink) =>
    record?.incidentNumber && record?.incidentId && backLink ? (
      <Link
        to={{
          pathname: `${Routes.SUPPORT_REQUESTS}/${record.incidentId}`,
          state: {
            backTo: !backLink ? backlinkRoutes[Routes.ALARMS] : backLink,
          },
        }}
      >
        {record?.incidentNumber}
      </Link>
    ) : (
      record?.incidentNumber
    ),
  emailBody: (description?: string) =>
    description ? (
      <DescriptionStyled
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    ) : (
      '-'
    ),
};
