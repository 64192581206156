import styled from 'styled-components';
import { Select as SharedSelect } from 'shared';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

type Props = {
  theme: Theme;
  value?: string | string[];
  maxTagCount?: number;
};

export const SelectWithLabelContainer = styled.div`
  margin-bottom: 8px;
  padding: 0 6px;
  width: 282px;

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    min-width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    label {
      font-weight: ${({ theme }: Props) => theme.fontWeights.medium};
    }
  }
`;

export const Select = styled(SharedSelect)`
  width: 270px;

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    width: ${({ theme }: Props) => theme.sizes.full};
  }

  .ant-select-selector {
    height: 40px;
  }

  .ant-select-selection-overflow-item {
    max-width: ${({ value, maxTagCount }: Props) => {
      if (!value || value.length < 2) {
        return '85%';
      }

      const count = maxTagCount && value.length > maxTagCount ? maxTagCount : value.length;

      return `calc(80% / ${count})`;
    }};
  }

  .ant-select-selection-item {
    color: ${getCssVar('textColor')};
  }

  &.checked {
    .ant-select-selector {
      border-color: ${getCssVar('select.selector.checked.borderColor')};
      background-color: ${getCssVar('select.selector.checked.backgroundColor')};
    }

    .ant-select-clear {
      background-color: ${getCssVar('select.selector.checked.backgroundColor')};
    }

    &:hover {
      .ant-select-selector {
        border: 2px solid ${getCssVar('select.selector.checked.hoverBorderColor')};
      }
    }
  }

  .clear-icon {
    align-items: center;
    color: ${getCssVar('select.iconColor')};
    display: flex;
    justify-content: center;
  }
`;

export const StyledLabel = styled.label`
  color: ${getCssVar('textColor')};
  font-weight: ${getCssVar('labelFontWeight')};
`;
