import styled from 'styled-components';
import { Modal } from 'shared/Modal/Modal';
import { Theme } from 'styles/theme/types';
import { breakpoints, fontWeights, colors, getCssVar, spacing } from 'styles/theme';

type Props = {
  theme: Theme;
};

export const Popup = styled(Modal)<Props>`
  max-width: 100vw;

  .ant-modal {
    .ant-menu {
      background-color: ${colors.white};
    }

    .ant-menu-item {
      .ant-menu-title-content {
        font-weight: ${fontWeights.normal};
      }
    }
  }

  .ant-modal-close-x {
    color: ${getCssVar('modal.closeIcon')};
  }

  .ant-modal-content {
    background-color: ${getCssVar('modal.bg')};
    color: ${getCssVar('textColor')};

    .ant-modal-body,
    .ant-modal-header,
    .ant-modal-footer {
      border-width: 1px;
      border-color: ${getCssVar('modal.borderColor')};
      background-color: ${getCssVar('modal.bg')};
      color: ${getCssVar('textColor')};
      padding: 20px 32px;

      @media (max-width: ${breakpoints.md}) {
        padding: 20px;
      }
    }

    .ant-modal-header {
      padding-right: 60px !important;

      .ant-modal-title {
        font-weight: ${fontWeights.bold};
        font-size: ${spacing[5]};
        color: ${getCssVar('textColor')};
      }
    }

    .ant-modal-body {
      padding-bottom: 5px !important;

      .ant-menu {
        border: transparent;
        background-color: ${getCssVar('modal.bg')};
        color: ${getCssVar('textColor')};

        .ant-menu-item-divider {
          border-color: ${getCssVar('divider.color')};
        }
      }

      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }

    p {
      white-space: pre;
    }
  }
`;
