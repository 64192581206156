import React from 'react';
import { Box, Button, Icon } from 'shared';
import { Popup } from 'shared/ActionPopup/styled';
import { Tooltip } from 'antd';
import { useTheme } from 'styled-components';
import { useApp } from 'store';
import { ActiveEDRAgentsGraph } from './ActiveEDRAgentsGraph/ActiveEDRAgents';

export const GraphPopup = ({ isVisible, setIsVisible }) => {
  const onCancel = React.useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const { icons } = useTheme();
  const { isMobile } = useApp();

  return (
    <Popup
      className='actions-modal'
      title={
        <Box display='flex' alignItems='center'>
          Active EDR agents{' '}
          <Tooltip
            title='Number of Active EDR agents installed within your environment.'
            placement={isMobile ? 'top' : 'right'}
            trigger={isMobile ? 'click' : 'hover'}
          >
            <Icon component={icons.info_outline} className='tooltipInfo' />
          </Tooltip>
        </Box>
      }
      visible={isVisible}
      onCancel={onCancel}
      width='1000px'
      footer={[
        <Button type='secondary' key='back' onClick={() => setIsVisible(false)}>
          Close
        </Button>,
      ]}
    >
      <ActiveEDRAgentsGraph />
    </Popup>
  );
};
