import React from 'react';
import { MobileTable } from 'shared';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router';
import { Empty } from 'antd';
import { defaultPageSize, defaultCurrent, initialPagination } from 'app-constants';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { CustomTable } from 'shared/Table/CustomTable';
import { GridRequestParams, VulnerabilityDetails } from 'services/api';
import {
  useApp,
  useAssetDetails,
  useCustomer,
  useAssetDetailsPotentialVulnerabilities,
  useLoading,
} from 'store';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { isEmpty } from 'lodash';
import { columns } from './TableColumns';
import {
  filterFields,
  confirmedVulnerabilitiesFilter,
  initialOrderBy,
  searchSettings,
  potentialVulnerabilitiesFilter,
} from './constants';
import { getFiltersFields } from './helpers';

export const SuppressedVulnerabilitiesTable = () => {
  const customerId = useCustomer();
  const { state } = useLocation<HistoryState>();
  const { isMobile } = useApp();
  const { id } = useParams<{
    id: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [params, setParams] = React.useState<GridRequestParams>({
    customerId,
    orderBy: initialOrderBy,
    pagination: initialPagination,
    filter: { ...confirmedVulnerabilitiesFilter },
  });
  const {
    detailsSuppressedVulnerabilitiesListByHost,
    detailsAdditionalInformation,
  } = useAssetDetails();
  const loading = useLoading([
    assetDetailsApi.getDetailsSuppressedVulnerabilitiesListByHost.typePrefix,
  ]);
  const emptyMessage = (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No vulnerabilities present' />
  );
  const isPotentialVulnerabilitiesActive = useAssetDetailsPotentialVulnerabilities();

  const updateParams = React.useCallback(
    (updatedParams: GridRequestParams | ((prevParams: GridRequestParams) => GridRequestParams)) => {
      setParams(updatedParams);
    },
    [],
  );

  const redirectToVulnerabilityDetails = React.useCallback(
    (record: VulnerabilityDetails) => {
      const searchParam = record.id ? '?type=asset' : '';

      history.push(`${Routes.VULNERABILITIES_LIST}/${record.id || record.qid}${searchParam}`, {
        backTo: {
          route: `${Routes.CMDB}/${id}`,
          search: history.location.search,
          title: `Back to `,
          id: detailsAdditionalInformation.hostname,
        },
        rootBackTo: state?.rootBackTo,
      });
    },
    [history, id, detailsAdditionalInformation, state],
  );

  React.useEffect(() => {
    if (isEmpty(params.filter)) {
      params.filter = { ...potentialVulnerabilitiesFilter };
    }

    if (params.filter?.fields) {
      params.filter.fields = getFiltersFields(
        params.filter.fields,
        potentialVulnerabilitiesFilter.fields[0],
      );
    }

    dispatch(
      assetDetailsApi.getDetailsSuppressedVulnerabilitiesListByHost({
        ...params,
        hostId: id,
        customerId,
      }),
    );
  }, [dispatch, customerId, id, params, isPotentialVulnerabilitiesActive]);

  if (isMobile) {
    return (
      <MobileTable<VulnerabilityDetails, GridRequestParams>
        data={detailsSuppressedVulnerabilitiesListByHost}
        searchSettings={searchSettings}
        params={params}
        setParams={updateParams}
        initialOrderBy={initialOrderBy}
        columns={getTableColumns(columns, params.orderBy)}
        filterFields={filterFields}
        onRowClick={redirectToVulnerabilityDetails}
        scroll='1000px'
        isLoadingContent={
          loading[assetDetailsApi.getDetailsSuppressedVulnerabilitiesListByHost.typePrefix]
        }
        emptyMessage={emptyMessage}
      />
    );
  }

  return (
    <CustomTable<VulnerabilityDetails, GridRequestParams>
      columns={getTableColumns(columns, params.orderBy)}
      data={detailsSuppressedVulnerabilitiesListByHost}
      params={params}
      setParams={updateParams}
      filterFields={filterFields}
      searchSettings={searchSettings}
      defaultPageSize={defaultPageSize}
      defaultCurrent={defaultCurrent}
      maxFilterFieldsLength={1}
      onRowClickHandle={redirectToVulnerabilityDetails}
      isLoadingContent={
        loading[assetDetailsApi.getDetailsSuppressedVulnerabilitiesListByHost.typePrefix]
      }
      emptyMessage={emptyMessage}
    />
  );
};
