import { ServiceChangesTable } from './styled';
import { BackgroundImage } from '../../components/BackgroundImage';
import { Page } from '../styled';

export interface Data {
  id: number | string;
  name: string;
  status: string;
  eta: string;
}

interface Props {
  data: Data[];
}

export function PdfPage13({ data }: Props) {
  return (
    <Page>
      <BackgroundImage fileName='slide-13.jpg' useFilter={false} />

      <ServiceChangesTable>
        {data.map(({ id, name, status, eta }) => (
          <div className='row' key={id}>
            <div className='column'>{name}</div>
            <div className='column'>{status}</div>
            <div className='column'>{eta}</div>
          </div>
        ))}
      </ServiceChangesTable>
    </Page>
  );
}
