import React from 'react';
import { useApp, useLoading, usePatchManagement, usePlatform } from 'store';
import { Card } from 'shared';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { patchDashboardApi } from 'store/patchManagement/dashboard/thunks';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { SegmentedPie } from 'shared/charts';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { Routes } from 'services/entities';
import { getQueryString } from 'services/api/core/request';
import { initialPagination } from 'app-constants';
import { Filter, PatchStatus } from 'services/api';
import { breakpoints } from 'styles/theme';
import { isEmptyObject } from 'services/helpers';
import { initialOrderByAssets, PBBackRoute, TooltipTitles, viewMoreConfig } from '../../constants';
import { AssetsChart } from '../../styled';
import { getLegendColors, getLegendValues } from '../../helpers';

export const AssetsMissingPatchesByPlatform = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const history = useHistory();
  const platform = usePlatform();
  const {
    metrics: { patchesByPlatform },
  } = usePatchManagement();
  const loading = useLoading([patchDashboardApi.getMissingPatchesByPlatform.typePrefix]);

  const assetsMissingPatchesColorList = [
    theme.colorSet.baseColors.blue,
    theme.colorSet.baseColors.amber,
    theme.colorSet.baseColors.deepRed,
    theme.colorSet.baseColors.green,
    theme.colorSet.baseColors.red,
    theme.colorSet.baseColors.grey,
  ];

  const limit = assetsMissingPatchesColorList.length - 1;

  const otherRedirectValue = React.useMemo(
    () => patchesByPlatform?.chart?.slice(limit).map((item) => item?.platform),
    [limit, patchesByPlatform?.chart],
  );

  const getPatchesParams = (value: string[]) => ({
    orderBy: initialOrderByAssets,
    pagination: initialPagination,
    filter: {
      fields: [
        {
          name: 'patchStatus',
          value: [PatchStatus.MISSING],
          type: Filter.type.MULTIPLE,
        },
        {
          name: 'operatingSystem',
          value,
          type: Filter.type.MULTIPLE,
        },
      ],
    },
  });

  const redirectToAssetsList = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      let value = [params.name];

      if (params.name === 'Other') {
        value = otherRedirectValue as string[];
      }

      history.push(
        `${Routes.PATCH_MANAGEMENT_ASSETS}${getQueryString(getPatchesParams(value))}`,
        PBBackRoute,
      );
    },
    [history, otherRedirectValue],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToAssetsList(params),
    }),
    [redirectToAssetsList],
  );

  const legendValues = getLegendValues(
    patchesByPlatform?.chart,
    'platform',
    assetsMissingPatchesColorList.length - 1,
  );

  return (
    <Card
      title='Assets missing patches by platform'
      showDivider={false}
      subTitle={<SubTitle boldText={patchesByPlatform?.count || 0} customText='Total' />}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles(platform).AssetsMissingPatchesByPlatform}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[patchDashboardApi.getMissingPatchesByPlatform.typePrefix]}
      viewMore={viewMoreConfig(false, true).assetsWithState}
    >
      {!isEmptyObject(legendValues) ? (
        <AssetsChart>
          <SegmentedPie
            values={legendValues || {}}
            colors={getLegendColors(legendValues, assetsMissingPatchesColorList)}
            direction={breakpoints.xxl ? 'column' : 'row'}
            entitiesName=''
            style={
              isMobile
                ? { height: '160px', marginTop: `-${theme.spacing[0]}` }
                : { height: '258px', width: '350px' }
            }
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
            onEvents={onEvents}
            isTruncatedText
          />
        </AssetsChart>
      ) : (
        emptyMessage(`No assets missing patches by ${platform} yet`)
      )}
    </Card>
  );
};
