import { colors } from '../colors';
import { baseNasstar } from './base';

export const tableNasstar = {
  bg: baseNasstar.primaryBg,
  expandIcon: colors.black,
  borderBottom: '#e0e3e8',

  specialColumnTitleColor: baseNasstar.blue,

  th: {
    color: colors.gray[400],
    iconColor: baseNasstar.pink,
  },
  tr: {
    hover: {
      bg: '#e0e3e833',
    },
    expanded: {
      bg: '#d7d7cf',
    },
    selected: {
      bg: colors.blueAlpha[200],
    },
    bg: colors.white,
    color: '#29292980',
    darkBg: colors.gray[100],
  },
  tableItem: {
    color: colors.blue[900],
  },
  filters: {
    bg: colors.gray[110],
    moreFiltersIcon: colors.red[300],
    hiddenFilterIcon: colors.red[700],

    mobile: {
      bg: colors.black,
      color: colors.white,

      title: colors.white,
      resetIconColor: colors.yellow[460],
      closeIconColor: colors.gray[150],

      search: {
        bg: colors.gray[110],
      },
      sort: {
        item: {
          activeBg: colors.gray[80],
          inactiveBg: colors.white,
        },
      },
    },
  },
};
