import { colors } from '../../colors';
import { baseDarkNorm } from '../base';

export const inputsBase = {
  backgroundColor: '#383536',
  backgroundColorDisabled: '#383637',
  textColor: colors.white,
  textColorDisabled: baseDarkNorm.grey,
  borderColor: baseDarkNorm.borderColor,
  borderColorHover: '#8A8A8A',
  borderColorDisabled: baseDarkNorm.borderColor,
  iconColor: '#625D5A',
  activeColor: baseDarkNorm.blue,
  disabledOpacity: 0.4,
  hoverColor: 'rgba(35, 31, 32, 0.1)',
  errorColor: '#FAA696',
  checkedBackgroundColor: '#323332',
};
