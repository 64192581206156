import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { InnerLayout } from 'shared';
import { PatchStatus } from 'services/api';
import { useRowGap } from 'services/hooks';
import { AppDispatch, useApp, useCustomer, usePlatform } from 'store';
import { getLayoutConfig } from './helpers';
import { SetOfMetrics } from './components/SetOfMetrics';
import { MissingPatchesOnPlatform } from './components/MissingPatchesOnPlatform/MissingPatchesOnPlatform';
import { patchDashboardApi } from '../../store/patchManagement/dashboard/thunks';
import { AssetsMissingPatchesByPlatform } from './components/AssetsMissingPatchesByPlatform';
import { PatchesByStatus } from './components/PathcesByStatus/PatchesByStatus';
import { PatchGroupedBy } from './constants';

export const PatchManagement = () => {
  const platform = usePlatform();
  const dispatch: AppDispatch = useDispatch();
  const customerId = useCustomer();
  const { gapNumber } = useRowGap();
  const isMobile = useApp();

  const isPendingReboot = true;

  const layoutConfig = getLayoutConfig(!isMobile);

  useEffect(() => {
    dispatch(
      patchDashboardApi.getAssetsAwaitingRebootCount({ customerId, platform, isPendingReboot }),
    );
    dispatch(patchDashboardApi.getAllAssets({ customerId, platform }));
    dispatch(patchDashboardApi.getMissingPatchesByPlatform({ customerId, platform }));
    dispatch(patchDashboardApi.getPatchesByOSStatus({ customerId, platform }));
    dispatch(
      patchDashboardApi.getPatchesByStatus({
        patchStatus: [PatchStatus.MISSING],
        customerId,
        platform,
      }),
    );
    dispatch(
      patchDashboardApi.getPatchesGroupedBy({
        groupBy: [PatchGroupedBy.VENDOR, PatchGroupedBy.CATEGORY],
        customerId,
        patchStatus: [PatchStatus.MISSING],
        platform,
      }),
    );
  }, [dispatch, customerId, platform, isPendingReboot]);

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} xs={24} md={24} xxl={24}>
          <SetOfMetrics />
        </Col>

        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={12}>
          <PatchesByStatus />
        </Col>
        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={12}>
          <AssetsMissingPatchesByPlatform />
        </Col>

        <Col span={gapNumber} xs={24} md={24} xxl={24}>
          <MissingPatchesOnPlatform />
        </Col>
      </Row>
    </InnerLayout>
  );
};
