import React from 'react';
import { Form, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { MessageType, notifications, securitySettingsApi, useNotifications } from 'store';
import { Button, Icon } from 'shared';
import { SecuritySettingsResponse } from 'services/api';
import { getCapitalizedString, showMessage } from 'services/helpers';
import { icons } from 'styles/theme';
import { options } from '../data';
import { LabelTitles, TooltipText, formFields } from '../constants';
import { InputLabel, Select } from '../styled';
import { Label, Tooltip, Option, Fields } from '../types';

interface IProps {
  hideEditMode: () => void;
  settings: SecuritySettingsResponse;
  customerId: string;
}

export const SettingsEditMode = ({ hideEditMode, settings, customerId }: IProps) => {
  const [showNotification, setShowNotification] = React.useState(false);
  const { message, type } = useNotifications();
  const {
    userAccountExpiry,
    passwordExpiry,
    passwordMinLength,
    passwordComplexity,
    passwordReUsePrevention,
    passwordLock,
  } = settings;

  const dispatch = useDispatch();

  const onSubmitForm = async (values: SecuritySettingsResponse) => {
    await dispatch(
      securitySettingsApi.updateSecuritySettings({
        requestBody: values,
        customerId,
      }),
    );
    await dispatch(
      notifications.actions.createNotifications({
        message: 'Success',
        type: MessageType.Success,
      }),
    );
    await dispatch(securitySettingsApi.securitySettingsContent({ customerId }));
    await setShowNotification(true);
    hideEditMode();
  };

  React.useEffect(() => {
    if (message && type && showNotification) {
      showMessage({ content: message }, type);
    }

    setShowNotification(false);
  }, [message, type, showNotification]);

  const initialValues = {
    userAccountExpiry,
    passwordExpiry,
    passwordMinLength,
    passwordComplexity,
    passwordReUsePrevention,
    passwordLock,
  };

  const icon = <Icon component={icons.info_outline} />;

  return (
    <Form layout='vertical' onFinish={onSubmitForm} initialValues={initialValues}>
      {formFields.map((field: Fields) => (
        <InputLabel
          key={field}
          name={field}
          label={LabelTitles[getCapitalizedString(field, true) as Label]}
          required
          tooltip={{
            title: TooltipText[getCapitalizedString(field, true) as Tooltip],
            icon,
          }}
        >
          <Select
            options={options[field as Option]}
            showArrow
            suffixIcon={<Icon className='ant-select-suffix' component={icons.arrow_drop_down} />}
          />
        </InputLabel>
      ))}
      <Space>
        <Button type='primary' htmlType='submit'>
          Save
        </Button>
        <Button type='secondary' onClick={hideEditMode}>
          Cancel
        </Button>
      </Space>
    </Form>
  );
};
