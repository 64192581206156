import { useTheme } from 'styled-components';
import { useApp, useMaintenanceSettings } from 'store';
import { PATH_TO_ICONS } from 'app-constants';
import { getSupportEmail, getSupportPhone } from 'themes/helpers';
import { PageContainer, BlockContainer, Logo } from 'pages/NotFound/styled';
import { getCssVar, icons } from 'styles/theme';
import { Message, MessageFooter, MessageHeader, MessageText, SmartBlocIcon } from './style';

export const MaintenancePageViewMode = () => {
  const theme = useTheme();
  const { themeName } = useApp();
  const { maintenanceSettings, maintenancePageMessageViewMode } = useMaintenanceSettings();

  return (
    <PageContainer>
      <BlockContainer>
        <Message>
          <MessageHeader>
            <SmartBlocIcon component={icons.smartBlocNew} />
            {maintenancePageMessageViewMode || maintenanceSettings?.maintenancePageMessage}
          </MessageHeader>

          <MessageText>
            For any urgent issues please contact the support desk on{' '}
            <a href={`tel:${getSupportPhone(themeName)}`}>{getSupportPhone(themeName)}</a> or drop
            us an email at{' '}
            <a href={`mailto:${getSupportEmail(themeName)}`}>{getSupportEmail(themeName)}</a>
          </MessageText>

          <MessageFooter>Apologies for any inconvenience</MessageFooter>
        </Message>
        <Logo
          src={`${PATH_TO_ICONS}/${theme.colorSet.login.logoDesktop}`}
          width={getCssVar('login.logoWidth')}
          height={getCssVar('login.logoHeight')}
          alt='logo'
        />
      </BlockContainer>
    </PageContainer>
  );
};
