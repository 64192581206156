import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState, useApp, usePlatform } from 'store';
import { Box, Card, Icon } from 'shared';
import { chunk } from 'lodash';
import { useTheme } from 'styled-components';
import { icons } from 'styles/theme';
import {
  getBodyPadding,
  getCardPadding,
  getHeadStyles,
} from '../../VulnerabilityManagement/components/AllVulnerabilities/helpers';
import { PBBackRoute, setOfMetrics } from '../constants';
import {
  MetricElement,
  MetricIcon,
  MetricSection,
  MetricTitle,
  MetricTooltip,
  MetricValue,
  MetricWrapper,
} from '../styled';

export const SetOfMetrics = () => {
  const theme = useTheme();
  const platform = usePlatform();
  const { isMobile, isMenuCollapsed } = useApp();
  const history = useHistory();
  const { metrics } = useSelector((state: AppState) => state.patchManagementDashboard);
  const { assetsAwaitingReboot, allAssets, patchesByOSStatus, patchesByStatus } = metrics;

  const goTo = (route: string | undefined): void => {
    if (!route) {
      return;
    }

    history.push(route, PBBackRoute);
  };

  const border = `2px solid ${theme.colorSet.divider.color}`;

  const setOfMetricsList = setOfMetrics(
    platform,
    patchesByOSStatus.failedCount,
    patchesByOSStatus?.failedPatches,
    patchesByStatus.count,
    allAssets.count,
    assetsAwaitingReboot.count,
  );

  const CHUNK_SEPARATE_SIZE = 2;

  const chunkMetricList = chunk(setOfMetricsList, CHUNK_SEPARATE_SIZE);

  return (
    <Card
      showDivider={false}
      width='100%'
      headStyles={getHeadStyles(isMobile, theme)}
      cardPadding={getCardPadding(isMobile, theme)}
      bodyPadding={getBodyPadding(isMobile, theme)}
      height={theme.sizes.full}
    >
      <Box d='flex' flexDirection={isMobile ? 'column' : 'row'} justify='space-between'>
        {chunkMetricList.map((metrics, index) => {
          const lastIndex = chunkMetricList.length - 1 === index;

          return (
            <Fragment key={index}>
              <MetricSection>
                {metrics.map((params) => {
                  const { title, value, cursor, route, tooltip } = params;

                  return (
                    <MetricElement key={title} $collapsed={isMenuCollapsed}>
                      <MetricWrapper cursor={cursor}>
                        <MetricValue>{value}</MetricValue>
                        <Box display='flex' alignItems='center' justifyContent='flex-start'>
                          <MetricTitle onClick={() => goTo(route)}>{title}</MetricTitle>
                          {tooltip && (
                            <MetricTooltip
                              title={tooltip}
                              placement={isMobile ? 'bottomLeft' : 'right'}
                            >
                              <Icon
                                component={icons.info_outline}
                                className='tooltipInfo'
                                style={{ verticalAlign: 'middle' }}
                              />
                            </MetricTooltip>
                          )}
                        </Box>
                      </MetricWrapper>
                      {route && (
                        <MetricIcon
                          component={theme.icons.arrow_right}
                          $collapsed={isMenuCollapsed}
                        />
                      )}
                    </MetricElement>
                  );
                })}
              </MetricSection>
              {!lastIndex && !isMobile && <MetricWrapper ml='10px' borderRight={border} />}
            </Fragment>
          );
        })}
      </Box>
    </Card>
  );
};
