import { minLengthToSearch } from 'app-constants';
import { OrderBy, UserSchema, Filter } from 'services/api';
import { OptionType } from 'shared/Table/types';
import { ISimpleInputFields } from 'shared/inputs/Input/types';
import { icons } from 'styles/theme';
import { ActionType } from 'shared/ActionPopup/types';
import { InputType } from '../../services/api/models/InputType';

export const searchPlaceholder = 'Search by full name, email';
export const searchFields = ['name', 'email'];
export const actionsLength = 4;

export const searchSettings = {
  fields: searchFields,
  min: minLengthToSearch,
  placeholder: searchPlaceholder,
};

export const popupConfig = {
  resetPassword: {
    id: 'resetPassword',
    title: 'Reset password',
    text: 'User will receive an email with reset password link',
    onApplyTitle: 'Reset password',
  },
  reset2FA: {
    id: 'reset2FA',
    title: 'Reset 2FA',
    text: 'User will be required to set new 2FA on the next login. This action cannot be undone',
    onApplyTitle: 'Reset 2FA',
  },
  lock: {
    id: 'lock',
    title: 'Lock user',
    text:
      'Locking this user will prevent them from accessing Smartbloc. You can unlock the user later',
    onApplyTitle: 'Lock user',
    isShowInput: true,
  },
  unlock: {
    id: 'unlock',
    title: 'Unlock user',
    text: 'User will be granted access to Smartbloc',
    onApplyTitle: 'Unlock user',
  },
  delete: {
    id: 'delete',
    title: 'Delete user',
    text: 'This action cannot be undone',
    onApplyTitle: 'Delete user',
    danger: true,
    isShowInput: true,
  },
};

export enum TableTitles {
  Name = 'Full name',
  Email = 'Email',
  Status = 'Status',
  Type = 'Type',
  Comments = 'Comments',
  Updated = 'Updated',
  Actions = 'Actions',
}

export enum ColumnKey {
  Name = 'name',
  Email = 'email',
  Status = 'status',
  Type = 'type',
  Comments = 'comments',
  Updated = 'updatedDate',
  Actions = 'actions',
}

export const optionsStatus: OptionType[] = [
  { value: UserSchema.status.ACTIVE, label: UserSchema.status.ACTIVE },
  { value: UserSchema.status.AWAITING_FIRST_LOGIN, label: UserSchema.status.AWAITING_FIRST_LOGIN },
  { value: UserSchema.status.ACCOUNT_LOCKED, label: UserSchema.status.ACCOUNT_LOCKED },
];

export const optionsType: OptionType[] = [
  { value: UserSchema.type.ADMINISTRATOR, label: UserSchema.type.ADMINISTRATOR },
  { value: UserSchema.type.USER, label: UserSchema.type.USER },
  { value: UserSchema.type.AUTHORISED_CONTACT, label: UserSchema.type.AUTHORISED_CONTACT },
];

export const filterFields = [
  {
    field: 'status',
    options: optionsStatus,
    label: 'Status',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'type',
    options: optionsType,
    label: 'Type',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];

export const initialOrderBy = {
  field: 'name',
  type: OrderBy.type.ASC,
};

export enum ActionTypeName {
  ResetPassword = 'Reset password',
  Reset2FA = 'Reset 2FA',
  Lock = 'Lock',
  Unlock = 'Unlock',
  Delete = 'Delete',
}

export const actions: ActionType[] = [
  {
    name: ActionTypeName.ResetPassword,
    icon: icons.vpn_key,
    popupId: popupConfig.resetPassword.id,
  },
  {
    name: ActionTypeName.Reset2FA,
    icon: icons.replay_circle,
    popupId: popupConfig.reset2FA.id,
  },
  { name: ActionTypeName.Lock, icon: icons.lock_filled, popupId: popupConfig.lock.id },
  { name: ActionTypeName.Unlock, icon: icons.unlock, popupId: popupConfig.unlock.id },
  {
    name: ActionTypeName.Delete,
    icon: icons.delete,
    popupId: popupConfig.delete.id,
    isDanger: true,
  },
];
export enum FieldNames {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
}

export const formErrorMessages: Record<FieldNames, Record<string, string>> = {
  [FieldNames.FirstName]: {
    isRequired: 'First name is required',
  },
  [FieldNames.LastName]: {
    isRequired: 'Last name is required',
  },
  [FieldNames.Email]: {
    isRequired: 'Email is required',
    isValid: 'Email is not valid',
  },
  [FieldNames.Phone]: {
    isRequired: 'Phone number is required',
    isValid: 'Phone number is not valid',
  },
};

export const phoneRegExp = /^[0-9 ()+-]{0,20}$/;

export const phoneMinLength = 7;

export const createUserFields: ISimpleInputFields[] = [
  {
    label: 'First name',
    name: FieldNames.FirstName,
    type: 'text',
    placeholder: '',
    value: '',
    maxLength: 50,
  },
  {
    label: 'Last name',
    name: FieldNames.LastName,
    type: 'text',
    placeholder: '',
    value: '',
    maxLength: 50,
  },
  {
    label: 'Email',
    name: FieldNames.Email,
    type: 'text',
    placeholder: '',
    value: '',
    maxLength: 100,
  },
  {
    label: 'Phone number',
    name: FieldNames.Phone,
    type: 'text',
    placeholder: '',
    value: '',
    maxLength: 20,
  },
];
