import { PatchVendorSeverity } from 'services/api';
import { baseNorm } from './base';

export const pieChart = {
  CurrentTrainingStatusSuccess: baseNorm.green,
  CurrentTrainingStatusStarted: baseNorm.amber,
  CurrentTrainingStatusNotStarted: baseNorm.red,

  CurrentRefresherTestsPassed: baseNorm.green,
  CurrentRefresherTestsIgnored: baseNorm.red,
  CurrentRefresherTestsFailed: baseNorm.black,

  PhishingLast30DaysOpened: baseNorm.amber,
  PhishingLast30DaysNoActions: baseNorm.green,
  PhishingLast30DaysClicked: baseNorm.red,
  PhishingLast30DaysProvidedCredentials: baseNorm.black,

  ActiveAlarms: baseNorm.blue,
  UnActiveAlarms: baseNorm.red,

  PatchesBySeverity: {
    [PatchVendorSeverity._5]: baseNorm.red,
    [PatchVendorSeverity._4]: baseNorm.amber,
    [PatchVendorSeverity._3]: baseNorm.green,
    [PatchVendorSeverity._2]: baseNorm.blue,
    [PatchVendorSeverity._1]: baseNorm.grey,
  },
  Radius: ['66%', '94%'],
  RestSectorOpacity: '0',
};
