import React, { useCallback } from 'react';
import { EmptyTablePreview, MobileTable } from 'shared';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { defaultCurrent, defaultPageSize } from 'app-constants';
import { Routes } from 'services/entities';
import { CustomTable } from 'shared/Table/CustomTable';
import { AlarmSchema, Filter, GridRequestParams } from 'services/api';
import { alarmsApi, useAlarms, useApp, useAssetDetails, useCustomer, useLoading } from 'store';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { useFilter, UpdateFilterParamsArgType } from 'services/hooks/useFilter';
import { useLocation } from 'react-router-dom';
import { HistoryState } from 'services/interfaces';
import { filterFields, initialOrderBy, searchSettings } from './constants';
import { CmdbTabKeys } from '../../constants';
import { columns } from './TableColumns';

export const Alarms = () => {
  const { data } = useAlarms();
  const customerId = useCustomer();
  const loading = useLoading([alarmsApi.alarmsTableContent.typePrefix]);
  const { isMobile } = useApp();
  const history = useHistory();
  const { state: historyState } = useLocation<HistoryState>();
  const { id: hostId } = useParams<{ id: string }>();
  const { detailsAdditionalInformation } = useAssetDetails();
  const dispatch = useDispatch();

  const computerName = historyState?.data?.name;
  const destHostName = computerName || detailsAdditionalInformation.hostname;

  const destHostNameFieldFilter = {
    name: 'destHostName',
    value: destHostName || '',
    type: Filter.type.VALUE,
  };

  const [params, updateParams] = useFilter({
    apiCall: alarmsApi.alarmsTableContent,
    dispatch,
    isMobile,
    filter: {
      fields: [destHostNameFieldFilter],
    },
    customerId,
    initialOrderBy,
  });

  const updateFilterParamsHandler = React.useCallback(
    (newParams: UpdateFilterParamsArgType): void => {
      updateParams((prevParams) => {
        let newParamsData: GridRequestParams;

        if (typeof newParams === 'function') {
          newParamsData = newParams(prevParams);
        } else {
          newParamsData = { ...newParams };
        }

        if (!newParamsData.filter?.fields?.length) {
          newParamsData.filter = {
            fields: [destHostNameFieldFilter],
          };
        } else {
          const index = newParamsData.filter.fields.findIndex(
            (field) => field.name === 'destHostName',
          );

          if (index === -1) {
            newParamsData.filter.fields.push(destHostNameFieldFilter);
          } else {
            newParamsData.filter.fields[index] = destHostNameFieldFilter;
          }
        }

        return newParamsData;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [destHostName],
  );

  const emptyMessage = (
    <EmptyTablePreview
      textContent='No alarms yet'
      loading={loading[alarmsApi.alarmsTableContent.typePrefix]}
    />
  );

  const onRowClickHandler = useCallback(
    (record: AlarmSchema) => {
      history.push(Routes.ALARM_DETAILS.replace(':id', record.id as string), {
        backTo: {
          route: `${Routes.CMDB}/${hostId}`,
          search: history.location.search,
          title: `Back to `,
          id: detailsAdditionalInformation.hostname,
        },
        activeTabKey: CmdbTabKeys.ALARMS,
      });
    },
    [detailsAdditionalInformation.hostname, history, hostId],
  );

  if (isMobile) {
    return (
      <MobileTable<AlarmSchema, GridRequestParams>
        data={data}
        searchSettings={searchSettings}
        columns={getTableColumns(columns, params.orderBy)}
        filterFields={filterFields}
        params={params}
        setParams={updateFilterParamsHandler}
        initialOrderBy={initialOrderBy}
        isLoadingContent={loading[alarmsApi.alarmsTableContent.typePrefix]}
        emptyMessage={emptyMessage}
        onRowClick={onRowClickHandler}
      />
    );
  }

  return (
    <CustomTable<AlarmSchema, GridRequestParams>
      data={data}
      columns={getTableColumns(columns, params.orderBy)}
      params={params}
      setParams={updateFilterParamsHandler}
      searchSettings={searchSettings}
      defaultPageSize={defaultPageSize}
      defaultCurrent={defaultCurrent}
      filterFields={filterFields}
      isLoadingContent={loading[alarmsApi.alarmsTableContent.typePrefix]}
      emptyMessage={emptyMessage}
      onRowClickHandle={onRowClickHandler}
    />
  );
};
