import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  AssetInventoryService,
  OSTypes,
  Timestamp,
  VulnerabilityStatus,
} from 'services/api';

const getOperatingSystemDistribution = createAsyncThunk(
  'ce-plus/operating-system-distribution/get',
  async (requestBody: { customerId: string; tagIds?: string[] }) => {
    try {
      return await AssetInventoryService.getOperatingSystemDistribution(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTopClientOperatingSystem = createAsyncThunk(
  'ce-plus/top-operating-system-client/get',
  async (requestBody: {
    customerId: string;
    osType: OSTypes;
    limit: number;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getTopOperatingSystem(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTopServerOperatingSystem = createAsyncThunk(
  'ce-plus/top-operating-system-server/get',
  async (requestBody: {
    customerId: string;
    osType: OSTypes;
    limit: number;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getTopOperatingSystem(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTopOS = createAsyncThunk(
  'ce-plus/top-os-versions/get',
  async (requestBody: { customerId: string; operatingSystem?: string[]; tagIds?: string[] }) => {
    try {
      return await AssetInventoryService.getOperatingSystemVersions(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTopHardwareManufacturer = createAsyncThunk(
  'ce-plus/top-hardware-manufacturer/get',
  async (requestBody: { customerId: string; limit: number; tagIds?: string[] }) => {
    try {
      return await AssetInventoryService.getTopHardwareManufacturer(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTopClientHardware = createAsyncThunk(
  'ce-plus/top-client-hardware/get',
  async (requestBody: { customerId: string; limit: number; tagIds?: string[] }) => {
    try {
      return await AssetInventoryService.getTopHardware(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTopServerHardware = createAsyncThunk(
  'ce-plus/top-server-hardware/get',
  async (requestBody: {
    customerId: string;
    isServer?: boolean;
    limit: number;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getTopHardware(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getVendorPatchAvailable = createAsyncThunk(
  'ce-plus/vendor-patch-available/get',
  async (requestBody: {
    customerId: string;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getVendorPatchAvailableDetectionsTotals(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTotalVulnerabilitiesInScope = createAsyncThunk(
  'ce-plus/total-vulnerabilities-in-scope/get',
  async (requestBody: {
    customerId: string;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getTotalDetectionsInScope(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTotalAssetsInScope = createAsyncThunk(
  'ce-plus/total-assets-in-scope/get',
  async (requestBody: {
    customerId: string;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getTotalAssetsInScope(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getLastWeekDetections = createAsyncThunk(
  'ce-plus/last-week-detections/get',
  async (requestBody: {
    customerId: string;
    from: Timestamp;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getDetectionsWithDateFilter(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getPenultimateWeekDetections = createAsyncThunk(
  'ce-plus/penultimate-week-detections/get',
  async (requestBody: {
    customerId: string;
    from: Timestamp;
    to: Timestamp;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getDetectionsWithDateFilter(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getDetectionsAfterTwoWeeks = createAsyncThunk(
  'ce-plus/more-then-2-week-detections/get',
  async (requestBody: {
    customerId: string;
    to: Timestamp;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: string[];
  }) => {
    try {
      return await AssetInventoryService.getDetectionsWithDateFilter(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const cyberEssentialPlusApi = {
  getOperatingSystemDistribution,
  getTopClientOperatingSystem,
  getTopServerOperatingSystem,
  getTopHardwareManufacturer,
  getTopClientHardware,
  getTopServerHardware,
  getTopOS,
  getTotalVulnerabilitiesInScope,
  getVendorPatchAvailable,
  getTotalAssetsInScope,
  getLastWeekDetections,
  getPenultimateWeekDetections,
  getDetectionsAfterTwoWeeks,
};
