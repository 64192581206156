import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const Secondary = css<Props>`
  background-color: ${getCssVar('buttonSecondary.backgroundColor')};
  border: ${getCssVar('buttonSecondary.border')};
  border-radius: ${getCssVar('button.borderRadius')};
  color: ${getCssVar('textColor')};

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    &:not(:disabled) {
      background-color: ${getCssVar('buttonSecondary.hover.backgroundColor')};
      border: ${getCssVar('buttonSecondary.border')};
      border-color: ${getCssVar('buttonSecondary.border')};
      box-shadow: ${getCssVar('buttonSecondary.hover.boxShadow')};
      color: ${getCssVar('buttonSecondary.hover.color')};
    }
  }

  &:focus,
  &.focus {
    &:not(:disabled) {
      box-shadow: none;
      transform: none;
    }
  }

  &:active,
  &.active {
    &:not(:disabled) {
      background-color: ${getCssVar('buttonSecondary.active.backgroundColor')};
      color: ${getCssVar('buttonSecondary.active.color')};
    }
  }

  &:not(:disabled) {
    border-color: ${getCssVar('buttonSecondary.border')};
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${getCssVar('buttonSecondary.disabled.backgroundColor')};
    border-color: ${getCssVar('buttonSecondary.disabled.borderColor')};
    box-shadow: none;
    color: ${getCssVar('buttonSecondary.disabled.color')};

    svg > path {
      fill: ${getCssVar('buttonSecondary.disabled.color')};
    }
  }
`;
