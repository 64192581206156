import { Menu } from 'antd';
import styled from 'styled-components';
import { getCssVar } from 'styles/theme';

export const ActionsDDL = styled(Menu)`
  background-color: ${getCssVar('select.dropDownActions.backgroundColor')};
  border-radius: 4px;

  li {
    width: 180px;

    [class*='menu-title-content'] {
      color: ${getCssVar('select.textColor')};
    }

    [class*='menu-item-icon'] {
      color: ${getCssVar('select.dropDownActions.iconColor')};
    }

    &[class*='menu-item-danger'] {
      [class*='menu-title-content'] {
        color: ${getCssVar('select.dropDownActions.dangerColor')};
      }

      [class*='menu-item-icon'] {
        color: ${getCssVar('select.dropDownActions.dangerColor')};
      }
    }

    &:hover {
      background-color: ${getCssVar('select.dropDownActions.backgroundColorHover')};
    }
  }
  [class*='menu-item-divider'] {
    background-color: ${getCssVar('select.dropDownActions.dividerColor')};
  }
`;
