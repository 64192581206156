import { baseDarkNorm } from './base';

export const severityDarkNorm = {
  critical: baseDarkNorm.deepRed,
  high: baseDarkNorm.red,
  meduim: baseDarkNorm.amber,
  low: baseDarkNorm.green,
  informational: baseDarkNorm.grey,
  analysis: baseDarkNorm.grey,
};
