import React, { PropsWithChildren, ReactElement } from 'react';
import { ContentWrapper } from '../Inner/components';
import { Layout as LayoutWrapper } from '../Inner/components/styled';

type LayoutProps = {
  header?: ReactElement;
  sidebar?: ReactElement;
};

export function Layout({
  sidebar,
  header,
  children,
}: PropsWithChildren<LayoutProps>): React.ReactElement {
  return (
    <LayoutWrapper className='app-layout-wrapper'>
      {sidebar}
      <ContentWrapper>
        {header}
        {children}
      </ContentWrapper>
    </LayoutWrapper>
  );
}
