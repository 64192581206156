import { Tag as AntdTag } from 'antd';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { breakpoints, getCssVar } from 'styles/theme';
import { TagColoring } from '../TagColoring/TagColoring';

interface IProps {
  theme: Theme;
  title?: string;
}

export const Tag = styled(AntdTag)<IProps>`
  background: ${getCssVar('status.bg')};
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${getCssVar('status.color')};
  font-weight: ${getCssVar('status.fontWeight')};
  line-height: 17px;
  margin-left: 16px;
  min-width: 64px;

  padding: 0.5px 2px;
  text-align: center;

  text-transform: ${getCssVar('status.textTransform')};

  @media (max-width: ${breakpoints.lg}) {
    margin-top: 5px;
  }

  &.ant-tag-success {
    background: ${getCssVar('status.success.bg')};
    border-color: ${getCssVar('status.success.bg')};
    color: ${getCssVar('status.success.color')};
  }

  &.ant-tag-warning {
    background: ${getCssVar('status.warning.bg')};
    border-color: ${getCssVar('status.warning.bg')};
    color: ${getCssVar('status.warning.color')};
  }

  &.ant-tag-error {
    background: ${getCssVar('status.error.bg')};
    border-color: ${getCssVar('status.error.bg')};
    color: ${getCssVar('status.error.color')};
  }

  &.ant-tag-critical {
    background: ${getCssVar('status.critical.bg')};
    border-color: ${getCssVar('status.critical.bg')};
    color: ${getCssVar('status.critical.color')};
  }

  &.ant-tag-processing {
    background: ${getCssVar('status.processing.bg')};
    border-color: ${getCssVar('status.processing.bg')};
    color: ${getCssVar('status.processing.color')};
  }

  &.ant-tag-default {
    background: ${getCssVar('status.bg')};
    border-color: ${getCssVar('status.bg')};
    color: ${getCssVar('status.color')};
  }
  &.ant-tag-suppression-suppressed,
  &.ant-tag-suppression-reviewed {
    background: ${({ theme }) => theme.colorSet.vulnerability.status.suppressed.backgroundColor};
    border-color: ${({ theme }) => theme.colorSet.vulnerability.status.suppressed.borderColor};
    color: ${({ theme }) => theme.colorSet.vulnerability.status.suppressed.textColor};
    padding: 0.5px 5px;
  }
  &.ant-tag-suppression-unsuppressed {
    background: ${({ theme }) => theme.colorSet.vulnerability.status.unsuppressed.backgroundColor};
    border-color: ${({ theme }) => theme.colorSet.vulnerability.status.unsuppressed.borderColor};
    color: ${({ theme }) => theme.colorSet.vulnerability.status.unsuppressed.textColor};
    padding: 0.5px 5px;
  }
`;

export const HeaderTagColoring = styled(TagColoring)<IProps>`
  margin-left: 8px;
`;
