import styled from 'styled-components';
import { Menu as AntdMenu } from 'antd';
import { getCssVar } from 'styles/theme';
import { Theme } from '../../styles/theme/types';

export const Menu = styled(AntdMenu)`
  color: ${getCssVar('textColor')};

  .ant-dropdown-menu-item {
    padding: 10px 15px;
  }

  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-active.ant-dropdown-menu-item-danger {
    background-color: ${({ theme }: { theme: Theme }) =>
      `${theme.colors.green[200]}${theme.opacity[12]}`};
  }

  .ant-dropdown-menu-item-danger {
    color: ${getCssVar('errorSecondaryColor')};
  }

  .ant-dropdown-menu-item-active.ant-dropdown-menu-item-danger {
    color: ${getCssVar('errorSecondaryColor')};
  }

  .anticon {
    margin-top: -2px;

    svg {
      fill: ${getCssVar('inactiveColor')};
    }
  }
`;

export const CustomMenuItem = styled(Menu.Item)`
  align-items: start;
  display: flex;
  min-height: 100% !important;
  min-width: 255px !important;
  padding: 0 !important;
`;
