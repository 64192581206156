import { Col } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { useApp, usePlatform } from 'store';
import { Card } from 'shared';
import { useTheme } from 'styled-components';
import { ByVendors } from './ByVendors';
import { ByCategory } from './ByCategory/ByCategory';
import { TooltipTitles } from '../../constants';
import { StyledRow } from '../../../Dashboard/components/Technology/styled';

const ColResponsiveProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 12,
};

const DESKTOP_GUTTER = 20;
const MOBILE_GUTTER = 16;

export const MissingPatchesOnPlatform = () => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const platform = usePlatform();
  const gutter: [Gutter, Gutter] = !isMobile
    ? [DESKTOP_GUTTER, DESKTOP_GUTTER]
    : [MOBILE_GUTTER, MOBILE_GUTTER];

  return (
    <Card
      title={`Missing patches on ${platform}`}
      tooltipTitle={TooltipTitles(platform).MissingPatchesOnWindows}
      headStyles={
        !isMobile
          ? { pb: theme.spacing[4], titlePaddingRight: theme.spacing['2.5'] }
          : { pb: theme.spacing[0], titlePaddingRight: theme.spacing[0] }
      }
      bodyPadding={theme.spacing[0]}
      cardPadding={
        !isMobile
          ? `${theme.spacing[6]} ${theme.spacing['5.5']} ${theme.spacing[8]}`
          : `${theme.spacing[5]} ${theme.spacing[5]} ${theme.spacing[5]} ${theme.spacing[5]}`
      }
      showDivider={false}
    >
      <StyledRow gutter={gutter}>
        <Col {...ColResponsiveProps}>
          <ByVendors />
        </Col>
        <Col {...ColResponsiveProps}>
          <ByCategory />
        </Col>
      </StyledRow>
    </Card>
  );
};
