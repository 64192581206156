import { Dropdown } from 'antd';
import React from 'react';
import { Icon, ActionsDDL } from 'shared';
import { useTheme } from 'styled-components';
import { deleteDropdownItems, DeleteDropdownLabels } from '../constants';

type DeleteDropdownProps = {
  children: JSX.Element | JSX.Element[];
  placement?: 'bottomLeft' | 'bottomCenter';
  disabled?: boolean;
  onClick: (soft: boolean) => void;
};

type DropdownItem = {
  key: string;
  label: string;
  isDanger?: boolean;
};

export const DeleteDropdown = ({ children, onClick, placement, disabled }: DeleteDropdownProps) => {
  const theme = useTheme();
  const handleItemClick = ({ key }: { key: React.Key }) => {
    onClick(key === DeleteDropdownLabels.DeleteInternally);
  };

  const dropdownOptions = (
    <ActionsDDL onClick={handleItemClick}>
      {deleteDropdownItems.map((item: DropdownItem) => {
        return (
          <ActionsDDL.Item
            key={item.key}
            danger={item.isDanger}
            icon={<Icon component={theme.icons.delete} />}
          >
            {item.label}
          </ActionsDDL.Item>
        );
      })}
    </ActionsDDL>
  );

  return (
    <Dropdown
      overlay={dropdownOptions}
      trigger={['click']}
      disabled={disabled}
      placement={placement || 'bottomLeft'}
    >
      {children}
    </Dropdown>
  );
};
