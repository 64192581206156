import moment from 'moment';
import { IDate } from 'services/interfaces';
import { sevenDaysTime } from 'app-constants';
import { HostSource } from 'services/api';
import { IParamsList } from '../../pages/People/components/PhishingByMonth/helpers';

export interface ICustomChartValues {
  [key: string]: number;
}

export interface ICustomPieChartTooltipProps {
  tooltip?: {
    trigger?: string;
    formatter?: (params: CEPlusEntryType) => string;
    axisPointer?: {
      type?: string;
    };
  };
}

type TotalEntryType = {
  collectedDateTime?: number;
  count?: number;
  date?: number;
  value?: number;
  totalUsers?: number;
  percentage?: number;
};

export type CEPlusEntryType = {
  color: string;
  seriesName: string;
  name: string;
  data: {
    name: string;
    value: number;
    result: number;
  };
};

type CriticalHighEntryType = {
  high?: number;
  critical?: number;
  date?: number;
};

type EdrAgentEntryType = {
  [HostSource.EDR_AGENT_TRELLIX_]?: number;
  [HostSource.EDR_AGENT_FORTI_EDR_]?: number;
  [HostSource.EDR_AGENT_DEFENDER_]?: number;
  [HostSource.EDR_AGENT_SENTINEL_ONE_]?: number;
  date?: number;
};

const getFormat = (dateRange: IDate) => {
  const period = dateRange.endDate - dateRange.startDate;

  if (period <= sevenDaysTime) {
    return 'HH:mm, DD-MMM-YYYY';
  }

  return 'dddd, DD-MMM-YYYY';
};

export const totalTooltipFormatter = <T extends TotalEntryType>(entry: T, dateRange: IDate) => {
  const date = entry.collectedDateTime || entry.date;
  const showDateLabel = moment(date).format(getFormat(dateRange));

  return `
    <div class="wrapper">
      <div class="title">${showDateLabel}</div>
      <div>
        <span class="circle"></span>
        <span>Total<span>
        <span class="value">${(entry.count || entry.value || 0).toLocaleString()}<span>
      </div>
    </div>
  `;
};

export const criticalHighTooltipFormatter = <T extends CriticalHighEntryType>(
  entry: T,
  dateRange: IDate,
) => {
  const { date } = entry;
  const showDateLabel = moment(date).format(getFormat(dateRange));

  return `
    <div class="wrapper">
      <div class="title">${showDateLabel}</div>
      <div>
        <span class="circle high"></span>
        <span>Critical<span>
        <span class="value">${(entry.high || 0).toLocaleString()}<span>
      </div>
      <div>
        <span class="circle critical"></span>
        <span>Urgent<span>
        <span class="value">${(entry.critical || 0).toLocaleString()}<span>
      </div>
    </div>
  `;
};

export const activeEDRAgentsFormatter = <T extends EdrAgentEntryType>(
  entry: T,
  dateRange: IDate,
) => {
  const { date } = entry;
  const showDateLabel = moment(date).format(getFormat(dateRange));
  const trellixEntry = entry[HostSource.EDR_AGENT_TRELLIX_];
  const fortiEntry = entry[HostSource.EDR_AGENT_FORTI_EDR_];
  const defenderEntry = entry[HostSource.EDR_AGENT_DEFENDER_];
  const sentinelEntry = entry[HostSource.EDR_AGENT_SENTINEL_ONE_];

  return `
  <div class="wrapper">
    <div class="title">${showDateLabel}</div>
    ${
      trellixEntry != null
        ? `
      <div>
        <span class="circle trellix"></span>
        <span>${HostSource.EDR_AGENT_TRELLIX_}</span>
        <span class="value">${trellixEntry.toLocaleString()}</span>
      </div>`
        : ''
    }
    ${
      fortiEntry != null
        ? ` <div>
      <span class="circle forti"></span>
      <span>${HostSource.EDR_AGENT_FORTI_EDR_}</span>
      <span class="value">${fortiEntry.toLocaleString()}</span>
    </div>`
        : ''
    }
    ${
      defenderEntry != null
        ? ` <div>
      <span class="circle defender"></span>
      <span>${HostSource.EDR_AGENT_DEFENDER_}</span>
      <span class="value">${defenderEntry.toLocaleString()}</span>
    </div>`
        : ''
    }
    ${
      sentinelEntry != null
        ? ` <div>
      <span class="circle sentinelOne"></span>
      <span>${HostSource.EDR_AGENT_SENTINEL_ONE_}</span>
      <span class="value">${sentinelEntry.toLocaleString()}</span>
    </div>`
        : ''
    }
  </div>
`;
};

export const averageConfidenceTooltipFormatter = <T extends TotalEntryType>(
  entry: T,
  name: string,
) => {
  return `
    <div class="wrapper">
      <div class="title">${name}</div>
      <div>
        <span class="circle"></span>
        <span class="single-value">${entry.value}</span>
      </div>
      ${
        entry.totalUsers && entry.percentage
          ? `<div>
        <span class="circle"></span>
        <span>Users</span>
        <span class="value">${entry.totalUsers} (${entry.percentage.toFixed(2)}%)</span>
      </div>`
          : ''
      }
    </div>
  `;
};

export const phishingByMonthTooltipFormatter = (paramsList: IParamsList[], name: string) => {
  return `
    <div class="wrapper">
      <div class="title">${name}</div>
      ${paramsList
        .map(({ className, label, value, count }: IParamsList) => {
          return `
             <div>
                <span class="circle ${className}"></span>
                <span>${label}</span>
                <span class="value">${(value || 0).toLocaleString()}% (${count})</span>
              </div>
        `;
        })
        .join(' ')}
    </div>
  `;
};

const renderCultureTooltipItem = (name: string, value: number, percentage: number) => `<div>
<span class="circle"></span>
<span>${name}</span>
<span class="value">${`${(percentage || 0).toLocaleString()}% (${value})`}</span>
</div>`;

export const cultureTooltipFormatter = (
  tooltipData: { name: string; value: number; percentage: number }[],
  name: string,
) => {
  return `
  <div class="wrapper">
    <div class="title">${name}</div>
    ${tooltipData
      .map((entry: { name: string; value: number; percentage: number }) =>
        renderCultureTooltipItem(entry.name, entry.value, entry.percentage),
      )
      .join('')}
  </div>
`;
};

export const cePlusTooltips = (entry: CEPlusEntryType) => {
  return `
    <div class="wrapper">
      <div class="title">${entry?.data?.name || entry.name}</div>
      <div class="flex">
      <span>
        <span class="circle" style="background: ${entry.color}"></span>
        <span class="value">${entry?.data?.value}</span>
      </div>
    </div>
  `;
};

export const cePlusPieChartsTooltips = (entry: CEPlusEntryType) => {
  const itemValue = entry?.name === 'vulnerabilities' ? entry?.data?.value : entry?.data?.result;

  return `
    <div class="wrapper">
      <div class="flex">
      <span>
        <span class="circle" style="background: ${entry.color}"></span>
        <span class="value">${itemValue}</span>
      </div>
    </div>
  `;
};
