import React, { useCallback, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { PATH_TO_ICONS } from 'app-constants';
import { icons } from 'styles/theme';
import { MainMenu, StyledSider } from 'shared/layouts/Inner/components';
import { app, AppState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '../Icon';
import { useSiderWidth } from './hooks/useSiderWidth';

export function DesktopSidebar() {
  const theme = useContext<Theme>(ThemeContext);
  const collapsed = useSelector(({ app: { isMenuCollapsed } }: AppState) => isMenuCollapsed);
  const siderRef = useRef<HTMLDivElement>(null);
  const [collapsedWidth, width] = useSiderWidth(siderRef);
  const dispatch = useDispatch();

  const logo = React.useMemo(
    () => ({
      small: `${PATH_TO_ICONS}/${theme.colorSet.leftMenu.logoTopSmall}`,
      regular: `${PATH_TO_ICONS}/${theme.colorSet.leftMenu.logoTop}`,
    }),
    [theme.colorSet.leftMenu.logoTop, theme.colorSet.leftMenu.logoTopSmall],
  );

  const toggle = useCallback(() => {
    dispatch(app.actions.setIsMenuCollapsed(!collapsed));
  }, [dispatch, collapsed]);

  return (
    <StyledSider
      ref={siderRef}
      breakpoint='xl'
      collapsedWidth={collapsedWidth}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={width}
    >
      <div className='app-sidebar-logo'>
        <Link to='/'>
          <img className='logo__image' src={collapsed ? logo.small : logo.regular} alt='logo' />
        </Link>

        <Icon
          component={collapsed ? icons.double_arrow : icons.double_arrow_left}
          className='trigger'
          onClick={toggle}
          color={theme.colorSet.leftMenu.toggleIconColor}
        />
      </div>

      <MainMenu collapsed={collapsed} />
    </StyledSider>
  );
}
