import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const Danger = css<Props>`
  &.ant-btn-dangerous.ant-btn-primary {
    background-color: ${getCssVar('buttonDanger.backgroundColor')};
    border: ${getCssVar('button.border')};
    border-radius: ${getCssVar('button.borderRadius')};
    color: ${getCssVar('buttonDanger.color')};

    &:hover,
    &.hover,
    &:focus,
    &.focus {
      &:not(:disabled) {
        background-color: ${getCssVar('buttonDanger.hover.backgroundColor')};
        border-color: ${getCssVar('buttonDanger.hover.backgroundColor')};
        color: ${getCssVar('buttonDanger.hover.color')};
        box-shadow: ${getCssVar('buttonDanger.hover.boxShadow')};
        transform: ${getCssVar('buttonDanger.hover.transform')};
      }
    }

    &:focus,
    &.focus {
      &:not(:disabled) {
        box-shadow: none;
        transform: none;
      }
    }

    &:active,
    &.active {
      &:not(:disabled) {
        background-color: ${getCssVar('buttonDanger.active.backgroundColor')} !important;
        border-color: ${getCssVar('buttonDanger.active.backgroundColor')} !important;
        color: ${getCssVar('buttonDanger.active.color')};
      }
    }

    &:disabled,
    &:disabled:hover {
      background-color: ${getCssVar('buttonDanger.disabled.backgroundColor')};
      border-color: ${getCssVar('buttonDanger.disabled.backgroundColor')};
      color: ${getCssVar('buttonDanger.disabled.color')};

      svg > path {
        fill: ${getCssVar('buttonDanger.disabled.color')};
      }
    }
  }
`;
