import {
  ANDROID_AUTHY_URL,
  ANDROID_GOOGLE_AUTHENTICATOR_URL,
  IOS_AUTHY_URL,
  IOS_GOOGLE_AUTHENTICATOR_URL,
} from 'app-constants';
import { TwoFAStep } from '../TwoFAStep';
import { TfaStepTitle, TfaStepContent } from '../styled';

export const FirstStep = () => (
  <TwoFAStep
    title={<TfaStepTitle level={5}>Step 1. Get the app</TfaStepTitle>}
    description={
      <TfaStepContent>
        Download and install Google Authenticator (
        <a rel='noreferrer' href={IOS_GOOGLE_AUTHENTICATOR_URL} target='_blank'>
          iOS&nbsp;
        </a>
        or&nbsp;
        <a rel='noreferrer' href={ANDROID_GOOGLE_AUTHENTICATOR_URL} target='_blank'>
          Android
        </a>
        )
        <br />
        or Authy (
        <a rel='noreferrer' href={IOS_AUTHY_URL} target='_blank'>
          iOS&nbsp;
        </a>
        or&nbsp;
        <a rel='noreferrer' href={ANDROID_AUTHY_URL} target='_blank'>
          Android
        </a>
        ).
      </TfaStepContent>
    }
  />
);
