import { isFirefox } from 'react-device-detect';
import { useFormik } from 'formik';
import { Form, Spin } from 'antd';
import { Box } from 'shared';
import * as Yup from 'yup';
import { authApi, useLoading } from 'store';
import { useDispatch } from 'react-redux';
import { StyledButton, InputLabel } from './styled';
import { FormInput } from '../../Login/components/styled';

export const InitialForm = () => {
  const dispatch = useDispatch();
  const loading = useLoading([authApi.resetPasswordInit.typePrefix]);
  const loadingAuth = loading[authApi.resetPasswordInit.typePrefix];

  const { handleSubmit, initialValues, values, handleChange, errors } = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required('Email is a required field')
        .email('Email must be a valid email'),
    }),
    onSubmit: async (values) => dispatch(authApi.resetPasswordInit(values)),
  });

  return (
    <Form
      layout='vertical'
      onFinish={handleSubmit}
      initialValues={initialValues}
      className='auth-form'
    >
      <FormInput
        name='email'
        label={<InputLabel>Email</InputLabel>}
        value={values.email}
        onChange={handleChange}
        validateStatus={errors.email ? 'error' : 'success'}
        help={errors.email}
        isFeedbackCentered
      />
      {isFirefox && loadingAuth && (
        <Box display='flex' justify='center'>
          <Spin />
        </Box>
      )}
      <StyledButton
        type='primary'
        htmlType='submit'
        loading={loadingAuth}
        block
        $isHidden={isFirefox && loadingAuth}
      >
        Send me instructions
      </StyledButton>
    </Form>
  );
};
