/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CmdbDataResponse } from '../models/CmdbDataResponse';
import type { CMDBDetailAdditionalInformation } from '../models/CMDBDetailAdditionalInformation';
import type { CMDBReportType } from '../models/CMDBReportType';
import type { CustomerCMDBSources } from '../models/CustomerCMDBSources';
import type { CustomerId } from '../models/CustomerId';
import type { CvssScore } from '../models/CvssScore';
import type { Filters } from '../models/Filters';
import type { HostAssetDetails } from '../models/HostAssetDetails';
import type { HostAssetOwnerSchema } from '../models/HostAssetOwnerSchema';
import type { HostId } from '../models/HostId';
import type { HostInstalledSoftware } from '../models/HostInstalledSoftware';
import type { HostNetworkInterfaces } from '../models/HostNetworkInterfaces';
import type { HostSchema } from '../models/HostSchema';
import type { HostsCountBySeverityResponse } from '../models/HostsCountBySeverityResponse';
import type { ImportTagsResponse } from '../models/ImportTagsResponse';
import type { IsolatedDevicesTrendResponse } from '../models/IsolatedDevicesTrendResponse';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { SeverityLevel } from '../models/SeverityLevel';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { SuppressedVulnerabilitiesByHost } from '../models/SuppressedVulnerabilitiesByHost';
import type { Timestamp } from '../models/Timestamp';
import type { UpdateTagSchema } from '../models/UpdateTagSchema';
import type { VulnerabilitiesByHost } from '../models/VulnerabilitiesByHost';
import type { VulnerabilitiesBySeverity } from '../models/VulnerabilitiesBySeverity';
import type { VulnerabilitiesCountByStatusResponse } from '../models/VulnerabilitiesCountByStatusResponse';
import type { VulnerabilityStatus } from '../models/VulnerabilityStatus';
import type { VulnerabilityTrendPointTypeSchema } from '../models/VulnerabilityTrendPointTypeSchema';
import { request as __request } from '../core/request';

export class CmdbService {
  /**
   * CMDB data
   * get CMDB data
   * @returns CmdbDataResponse Formated Cmdb Data
   * @throws ApiError
   */
  public static async getCmdb({
    customerId,
    orderBy,
    pagination,
    filter,
    agedHosts,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters & {
      patchableFixedVulnsWithBaseScoreMoreThen7?: boolean | null;
      /**
       * The To First Found timestamp filter for fixed vulnerabilities
       */
      fixedVulnerabilitiesFrom?: Timestamp | null;
      /**
       * The From First Found timestamp filter for host vulnerability
       */
      vulnerabilitiesFrom?: Timestamp | null;
      /**
       * The To First Found timestamp filter for host vulnerability
       */
      vulnerabilitiesTo?: Timestamp | null;
      /**
       * Host vulnerability cvss score
       */
      vulnerabilitiesCvssScore?: CvssScore | null;
    };
    /** Include only Aged hosts **/
    agedHosts?: boolean;
  }): Promise<CmdbDataResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
        agedHosts: agedHosts,
      },
      errors: {
        400: `An error message when getting cmdb data`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerabilities by severity level
   * Vulnerabilities by severity level
   * @returns VulnerabilitiesBySeverity Vulnerabilities by severity level
   * @throws ApiError
   */
  public static async getDetailsVulnerabilitiesBySeverityLevel({
    customerId,
    hostId,
    status,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
    /** Vulnerability status (type) **/
    status?: VulnerabilityStatus;
  }): Promise<VulnerabilitiesBySeverity> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/${hostId}/details/vulnerabilities/by-severity`,
      query: {
        status: status,
      },
      errors: {
        400: `An error message when getting cmdb vulnerabilities by severity level`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerabilities count by status
   * Vulnerabilities count by status
   * @returns VulnerabilitiesCountByStatusResponse Vulnerabilities count by status
   * @throws ApiError
   */
  public static async getAssetVulnerabilitiesCountByStatus({
    customerId,
    hostId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
  }): Promise<VulnerabilitiesCountByStatusResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/${hostId}/details/vulnerabilities/count-by-status`,
      errors: {
        400: `An error message when getting cmdb vulnerabilities by severity level`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerabilities list by host
   * Vulnerabilities list by host
   * @returns VulnerabilitiesByHost Vulnerabilities list by host
   * @throws ApiError
   */
  public static async getDetailsVulnerabilitiesListByHost({
    customerId,
    hostId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<VulnerabilitiesByHost> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/${hostId}/details/vulnerabilities`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when getting cmdb vulnerabilities`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Suppressed vulnerabilities list by host
   * Suppressed vulnerabilities list by host
   * @returns SuppressedVulnerabilitiesByHost Vulnerabilities list by host
   * @throws ApiError
   */
  public static async getDetailsSuppressedVulnerabilitiesListByHost({
    customerId,
    hostId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<SuppressedVulnerabilitiesByHost> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/${hostId}/details/suppressed-vulnerabilities`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when getting cmdb vulnerabilities`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Additional information
   * Additional information
   * @returns CMDBDetailAdditionalInformation Additional information
   * @throws ApiError
   */
  public static async getDetailsAdditionalInformation({
    customerId,
    hostId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
  }): Promise<CMDBDetailAdditionalInformation> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/${hostId}/details/additional-information`,
      errors: {
        400: `An error message when getting cmdb additional information`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Sets the asset owner
   * Sets the asset owner
   * @returns HostSchema Host information
   * @throws ApiError
   */
  public static async setsAssetOwner({
    customerId,
    hostId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
    /** Sets the asset owner **/
    requestBody?: HostAssetOwnerSchema;
  }): Promise<HostSchema> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/cmdb/${hostId}/details/set-asset-owner`,
      body: requestBody,
      errors: {
        400: `An error message when setting cmdb asset owner`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get asset details
   * Get asset details
   * @returns HostAssetDetails Asset details
   * @throws ApiError
   */
  public static async getAssetDetails({
    customerId,
    hostId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
  }): Promise<HostAssetDetails> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/${hostId}/details/asset-details`,
      errors: {
        400: `An error message when getting cmdb asset details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Get interface details
   * Get interface details
   * @returns HostNetworkInterfaces Interface details
   * @throws ApiError
   */
  public static async getInterfaceDetails({
    customerId,
    hostId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
  }): Promise<HostNetworkInterfaces> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/${hostId}/details/interface-details`,
      errors: {
        400: `An error message when getting cmdb interfce details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Get installed software
   * Get installed software
   * @returns HostInstalledSoftware Installed software
   * @throws ApiError
   */
  public static async getInstalledSoftware({
    customerId,
    hostId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<HostInstalledSoftware> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/${hostId}/details/installed-software`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when getting cmdb installed software`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Export CMDB data
   * Export CMDB data
   * @returns any Export CMDB in csv file
   * @throws ApiError
   */
  public static async exportCmdb({
    customerId,
    orderBy,
    filter,
    reportType,
    agedHosts,
    connectionId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** The filter parameter **/
    filter?: Filters & {
      patchableFixedVulnsWithBaseScoreMoreThen7?: boolean | null;
      /**
       * The To First Found timestamp filter for fixed vulnerabilities
       */
      fixedVulnerabilitiesFrom?: Timestamp | null;
    };
    reportType?: CMDBReportType;
    /** Include only Aged hosts **/
    agedHosts?: boolean;
    /** Connection id to communicate with Messaging service (Web PubSub) **/
    connectionId?: string;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/export`,
      query: {
        orderBy: orderBy,
        filter: filter,
        reportType: reportType,
        agedHosts: agedHosts,
        connectionId: connectionId,
      },
      errors: {
        400: `An error message when exporting cmdb data`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CMDB data. Isolated devices trend data
   * get isolated devices
   * @returns IsolatedDevicesTrendResponse Isolated devices trend
   * @throws ApiError
   */
  public static async getIsolatedDevicesTrend({
    customerId,
    startDate,
    endDate,
    pointType,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate?: Timestamp;
    /** The end date parameter **/
    endDate?: Timestamp;
    /** Chart point type **/
    pointType?: VulnerabilityTrendPointTypeSchema;
  }): Promise<IsolatedDevicesTrendResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/isolated-devices-trend`,
      query: {
        startDate: startDate,
        endDate: endDate,
        pointType: pointType,
      },
      errors: {
        400: `An error message when getting isolated devices`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete host by id
   * Delete host by id
   * @returns SuccessResponse Delete host by id
   * @throws ApiError
   */
  public static async deleteHost({
    customerId,
    hostId,
    soft,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
    /** Soft deletion flag **/
    soft?: boolean;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/cmdb/${hostId}/`,
      query: {
        soft: soft,
      },
      errors: {
        400: `An error message when deleting host`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CMDB data. Hosts count by criticalities
   * get hosts count by criticalities
   * @returns HostsCountBySeverityResponse Hosts count Trends by criticalities
   * @throws ApiError
   */
  public static async getHostsTrendsByCriticalities({
    customerId,
    criticalities,
    startDate,
    endDate,
    pointType,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Array of criticalities values **/
    criticalities: Array<SeverityLevel>;
    /** The start date parameter **/
    startDate?: Timestamp;
    /** The end date parameter **/
    endDate?: Timestamp;
    /** Chart point type **/
    pointType?: VulnerabilityTrendPointTypeSchema;
  }): Promise<HostsCountBySeverityResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/hosts-trends-by-criticalities`,
      query: {
        criticalities: criticalities,
        startDate: startDate,
        endDate: endDate,
        pointType: pointType,
      },
      errors: {
        400: `An error message when getting count by vulnerability criticalities`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Set tag
   * Set tag
   * @returns any Tag information
   * @throws ApiError
   */
  public static async setTag({
    customerId,
    hostId,
    tagId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
    /** tag id **/
    tagId: string;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/cmdb/${hostId}/details/set-tag/${tagId}`,
      errors: {
        400: `An error message when setting cmdb tag`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Remove tag
   * Remove tag
   * @returns UpdateTagSchema Tag information
   * @throws ApiError
   */
  public static async removeTag({
    customerId,
    hostId,
    tagId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Host id **/
    hostId: HostId;
    /** tag id **/
    tagId: string;
  }): Promise<UpdateTagSchema> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/cmdb/${hostId}/details/remove-tag/${tagId}`,
      errors: {
        400: `An error message when removing cmdb tag`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Import (upload) csv file with tags
   * Import (upload) csv file with tags to set tags to hosts
   * @returns ImportTagsResponse Import tags into CMDB success response
   * @throws ApiError
   */
  public static async importTags({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Import csv file **/
    requestBody?: any;
  }): Promise<ImportTagsResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/cmdb/import-tags`,
      body: requestBody,
      errors: {
        400: `An error message when import document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get customer sources
   * Get customer sources
   * @returns CustomerCMDBSources Customer sources
   * @throws ApiError
   */
  public static async getCustomerHostsSources({
    customerId,
    agedHosts,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Include only Aged hosts **/
    agedHosts?: boolean;
  }): Promise<CustomerCMDBSources> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cmdb/sources`,
      query: {
        agedHosts: agedHosts,
      },
      errors: {
        400: `An error message when getting customer sources`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }
}
