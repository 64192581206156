import React, { useEffect, useMemo } from 'react';
import { useFilter } from 'services/hooks/useFilter';
import { Button, EmptyTablePreview, InnerLayout, MobileTable, MobileTableCol } from 'shared';
import { useDispatch } from 'react-redux';
import { Gutter } from 'antd/lib/grid/row';
import { Col, Row } from 'antd';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { CustomTable } from 'shared/Table/CustomTable';
import { AppDispatch, useApp, useCustomer, useLoading, useTagsManagement } from 'store';
import { useLocation } from 'react-router-dom';
import { getLayoutConfig } from './helpers';
import { ColumnKey, initialOrderBy, searchSettings } from './constant';
import { getColumns } from './components/TableColumns';
import { useModal } from '../../services/hooks/useModal';
import { useGetPermission } from '../../services/hooks';
import { PermissionOptions, PermissionsLevel } from '../../app-constants';
import { GridRequestParams, TagSchema, UserSchema } from '../../services/api';
import { CreateModalMode } from '../Users/types';
import { CreateTagPopup } from './components/CreateTagPopup';
import { tagsManagementApi } from '../../store/tagsManagement/thunks';
import { MobileTagActions } from './components/MobileTagActions';
import { HistoryState } from '../../services/interfaces';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const FULL_WIDTH_COLS = 32;
const defaultPageSize = 50;
const defaultCurrent = 1;

export const TagsManagement = () => {
  const modal = useModal();
  const isFullAccess = useGetPermission(PermissionOptions.CMDB) === PermissionsLevel.Full;
  const customerId = useCustomer();
  const { tagsGrid, editableTag } = useTagsManagement();
  const loading = useLoading([tagsManagementApi.getTagsGrid.typePrefix]);
  const { isMobile } = useApp();
  const { state: historyState, search } = useLocation<HistoryState>();
  const GUTTER: [Gutter, Gutter] = !isMobile
    ? [H_GUTTER, V_GUTTER]
    : [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

  const dispatch: AppDispatch = useDispatch();

  const [params, updateParams] = useFilter({
    apiCall: tagsManagementApi.getTagsGrid,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
    historyState,
  });

  useEffect(() => {
    dispatch(tagsManagementApi.getTagsList(customerId));
  }, [customerId, dispatch]);

  const layoutConfig = getLayoutConfig(modal.showModal, isFullAccess);

  const getUsersTableContent = React.useCallback(() => {
    dispatch(tagsManagementApi.getTagsGrid(params));
  }, [dispatch, params]);

  const getActions = React.useCallback(
    (tagsRow: TagSchema) => {
      return (
        <MobileTagActions
          editableTag={editableTag}
          tagsRow={tagsRow}
          onSuccess={getUsersTableContent}
        />
      );
    },
    [editableTag, getUsersTableContent],
  );

  const emptyMessage = (
    <EmptyTablePreview
      textContent='No Tags yet'
      loading={loading[tagsManagementApi.getTagsGrid.typePrefix]}
      withoutMarginTop
      actionButton={
        isFullAccess ? (
          <Button type='primary' onClick={() => modal.showModal()}>
            Create tag
          </Button>
        ) : undefined
      }
    />
  );

  const columnsWithoutActions = useMemo(() => {
    return getTableColumns(
      getColumns(getUsersTableContent, editableTag, isFullAccess, isMobile, search),
      params.orderBy,
    ).filter((column) => column.key !== ColumnKey.Actions);
  }, [editableTag, getUsersTableContent, isFullAccess, isMobile, params.orderBy, search]);

  return (
    <InnerLayout {...layoutConfig}>
      {isMobile ? (
        <Row gutter={GUTTER}>
          <MobileTableCol span={FULL_WIDTH_COLS} flex='auto'>
            <MobileTable<UserSchema, GridRequestParams>
              data={tagsGrid}
              searchSettings={searchSettings}
              columns={columnsWithoutActions}
              noFilters
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              isLoadingContent={loading[tagsManagementApi.getTagsGrid.typePrefix]}
              getActions={isFullAccess ? getActions : undefined}
              emptyMessage={emptyMessage}
            />
          </MobileTableCol>
        </Row>
      ) : (
        <Row gutter={GUTTER}>
          <Col span={FULL_WIDTH_COLS} xs={24} xxl={24}>
            <CustomTable<UserSchema, GridRequestParams>
              data={tagsGrid}
              columns={
                isFullAccess
                  ? getTableColumns(
                      getColumns(getUsersTableContent, editableTag, isFullAccess, false, search),
                      params.orderBy,
                    )
                  : columnsWithoutActions
              }
              params={params}
              setParams={updateParams}
              searchSettings={searchSettings}
              defaultPageSize={defaultPageSize}
              defaultCurrent={defaultCurrent}
              filterFields={[]}
              isLoadingContent={loading[tagsManagementApi.getTagsGrid.typePrefix]}
              emptyMessage={emptyMessage}
            />
          </Col>
        </Row>
      )}
      {modal.isModalShown && (
        <CreateTagPopup
          isVisible={modal.isModalShown}
          mode={CreateModalMode.Create}
          setIsVisible={(value: boolean) => (value ? modal.showModal() : modal.hideModal())}
          onSuccess={getUsersTableContent}
        />
      )}
    </InnerLayout>
  );
};
