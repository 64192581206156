import moment from 'moment';
import { formatDate } from 'services/helpers';
import { IDate } from 'services/interfaces';
import { activeEDRAgentsFormatter } from 'shared/charts/formatters';
import { IEChartsOption, LabelFormatterParams, Series } from 'shared/charts/interfaces';
import { RadioButtonsDays } from 'shared/RadioButtonsWithRangePicker/constants';
import { EdrTrendBySource, EdrTrendPointSchema, Filter, HostSource, OrderBy } from 'services/api';
import { Theme } from 'styles/theme/types';
import { OptionParams } from 'shared/charts';
import { Values } from 'shared/charts/types';
import { initialPagination } from 'app-constants';
import { getData } from '../../../../helpers';

const rightGridForMobile = 38;
const rightGridForDesktop = 10;

export const activeEDRChartColors = (theme: Theme) => ({
  [HostSource.EDR_AGENT_TRELLIX_]: theme.colorSet.activeEDR[HostSource.EDR_AGENT_TRELLIX_],
  [HostSource.EDR_AGENT_FORTI_EDR_]: theme.colorSet.activeEDR[HostSource.EDR_AGENT_FORTI_EDR_],
  [HostSource.EDR_AGENT_DEFENDER_]: theme.colorSet.activeEDR[HostSource.EDR_AGENT_DEFENDER_],
  [HostSource.EDR_AGENT_SENTINEL_ONE_]:
    theme.colorSet.activeEDR[HostSource.EDR_AGENT_SENTINEL_ONE_],
});

const getMultiLineSeries = (entries: { [x: string]: EdrTrendPointSchema[] }, theme: Theme) => {
  const baseSettings = {
    type: 'line',
  };
  const colors = activeEDRChartColors(theme);
  const series: Series[] = [];
  let data: number[] = [];

  Object.entries(entries).map((entry: [string, EdrTrendPointSchema[]]) => {
    const [seriesData, xAxisData] = getData(entry[1]);

    series.push({
      ...baseSettings,
      name: entry[0],
      data: seriesData,
      color: colors[entry[0] as keyof typeof colors],
      showSymbol: seriesData.length <= 1 && seriesData[0] !== 0,
      symbolSize: 6,
    });

    if (xAxisData.length) {
      data = xAxisData;
    }

    return entry;
  });

  return { series, data };
};

export const getOption = ({
  values,
  theme,
  date,
  height = '320px',
  isMobile,
}: OptionParams<{ [x: string]: EdrTrendPointSchema[] }>): IEChartsOption => {
  const dateRange: IDate = date || {
    startDate: moment().subtract(RadioButtonsDays.OneMonth, 'days').startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
  };

  const { series, data } = getMultiLineSeries(values, theme);

  return {
    legend: { show: false },
    grid: {
      top: 30,
      bottom: 20,
      right: !isMobile ? rightGridForDesktop : rightGridForMobile,
      left: 45,
      height,
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, '10%'],
      data: data.map((value: number) => formatDate(`${value}`, dateRange, true)),
      axisLabel: {
        color: theme.colorSet.charts.axisLabel,
        fontFamily: theme.colorSet.lessFontFamily,
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      axisLabel: {
        color: theme.colorSet.charts.axisLabel,
        fontFamily: theme.colorSet.lessFontFamily,
      },
    },
    tooltip: {
      trigger: 'axis',
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
        fontFamily: theme.colorSet.lessFontFamily,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
      formatter: (params: LabelFormatterParams[]) => {
        const entries = params.reduce((acc, param) => {
          const { seriesName, dataIndex } = param;
          const source = Object.values(HostSource).find((source) => source === seriesName);

          if (source !== undefined && values[source] && values[source][dataIndex]) {
            const { count = 0, collectedDateTime = 0 } = values[source][dataIndex];

            return { ...acc, [seriesName]: count, date: collectedDateTime };
          }

          return acc;
        }, {});

        return activeEDRAgentsFormatter(entries, dateRange);
      },
    },
    series,
  };
};

export const getLegendValues = (trends: EdrTrendBySource[]): Values => {
  return trends
    .filter((trend = { points: [] }) => trend?.points && trend.points.length > 0)
    .reduce((acc, { source = '' }) => ({ ...acc, [source]: 0 }), {}) as Values;
};

export const getSourcesByTrends = (trends: EdrTrendBySource[]): HostSource[] => {
  return trends
    .filter((trend) => trend?.points && trend.points.length > 0)
    .reduce((acc: string[], { source = '' }) => {
      return [...acc, source];
    }, []) as HostSource[];
};

export const getEDRAgentsParams = (value: HostSource[]) => {
  return {
    orderBy: {
      field: 'lastCheckedIn',
      type: OrderBy.type.ASC,
    },
    pagination: initialPagination,
    filter: {
      fields: [
        {
          name: 'assetSource',
          value,
          type: Filter.type.MULTIPLE,
        },
      ],
    },
  };
};
