import moment from 'moment';
import { startCase } from 'lodash';
import { dateFormat } from 'app-constants';
import { Box, Icon } from 'shared';
import { getIconName, getStatusColor } from 'pages/Process/helpers';
import { icons } from 'styles/theme/icons';
import { CertificateName, CertificateStatusSchema } from 'services/api';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Routes } from 'services/entities';
import { CertificateHeadProp } from '../../types';
import { ABOUT_TO_EXPIRE_MONTHS } from '../../constants';
import { IconContainer, LastUpdated } from '../../styled';
import { Tag } from './Tag';

export const Head = ({ title, lastUpdated, status, expirationDate }: CertificateHeadProp) => {
  const theme = useTheme();
  const history = useHistory();
  const currentDate = moment();
  const expiration = expirationDate ? moment(expirationDate) : null;
  const updated = moment(lastUpdated);
  const isCertificateSoonExpired =
    expiration && expiration.diff(currentDate, 'month') < ABOUT_TO_EXPIRE_MONTHS;

  const getStatusText = () => {
    if (expiration?.isBefore(currentDate)) {
      return 'Expired';
    }

    return startCase(status?.toLowerCase());
  };

  const isStatusUndefined = status !== `${CertificateStatusSchema}`;

  const updatedTime = updated?.format(dateFormat);

  const canRedirectToCEPlus = title === CertificateName.CYBER_ESSENTIALS_PLUS;

  const redirectToCEPlus = () => {
    return history.push(`${Routes.CE_PLUS}`);
  };

  return (
    <Box cursor={canRedirectToCEPlus ? 'pointer' : 'default'} onClick={redirectToCEPlus}>
      {status && (
        <Tag
          text={getStatusText()}
          color={getStatusColor(expiration, isCertificateSoonExpired, status, theme)}
        />
      )}

      <Box textAlign='right' fontSize={theme.sizes[4]} visibility={status ? 'visible' : 'hidden'}>
        <LastUpdated visibility={isStatusUndefined ? 'hidden' : 'visible'} marginRight='-15px'>
          <Box visibility={lastUpdated ? 'visible' : 'hidden'}>Last updated {updatedTime}</Box>
        </LastUpdated>
      </Box>

      <Box d='flex' align='center' minH='80px'>
        <IconContainer>
          {!status && <Icon component={icons.small_lock} className='lockicon' />}
          <Icon component={icons[getIconName(title, status)]} />
        </IconContainer>

        <Box
          fontWeight={theme.fontWeights.bold}
          fontSize={theme.sizes[5]}
          lineHeight={theme.sizes[5]}
          m={theme.spacing[4]}
        >
          {title}
        </Box>
      </Box>
    </Box>
  );
};
