import { startCase } from 'lodash';

export const getCapitalizedString = (value: string, withoutToLowerCase?: boolean) =>
  withoutToLowerCase
    ? `${value.charAt(0).toUpperCase()}${value.slice(1)}`
    : `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;

export const trimSpaces = (string: string) => startCase(string).replace(/\s/g, '');

export const replaceSpace = (value: string) => {
  return value.replace(/\s/g, '');
};

export const getStringWithoutTags = (message: string) =>
  message
    .replace('</p><p>', ' ')
    .replace(/<\/?[^>]+(>|$)|&nbsp;/gm, '')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .trim();

export const MAX_CHARS_IN_SHORTED_TEXT = 210;

export const getShortenedText = (
  text: string,
  max = MAX_CHARS_IN_SHORTED_TEXT,
  separator = '...',
) => {
  return text.length > max ? `${text.substring(0, max)}${separator}` : text;
};

export const stripTags = (text: string) => {
  const div = document.createElement('div');

  div.innerHTML = text;

  return div.textContent || div.innerText;
};

export const stripCssRules = (text: string) => {
  return text.replaceAll(/(\.[a-zA-Z\-\s*]+\{[^}]*})(\s+)/g, '').trim();
};

export const getPercentage = (value: number, total: number) => {
  const ONE_HUNDRED = 100;

  return Math.round((Number(value) / Number(total)) * ONE_HUNDRED);
};

export const removeStyleAttribute = (inputString: string): string => {
  return inputString.replaceAll(/style="[^"]*"/g, '');
};

export const removeShowMoreAttribute = (inputString: string): string => {
  return inputString.replaceAll('Show more', '').replaceAll('Show less', '').trim();
};

export const formatNumberWithCommas = (num: number): string => {
  const regEx = /\B(?=(\d{3})+(?!\d))/g;

  return num.toString().replace(regEx, ',');
};
