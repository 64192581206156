import moment from 'moment';
import { IDate } from 'services/interfaces';
import { LogoPlaceholder, PageTitleDate } from './styled';
import { BackgroundImage } from '../../components/BackgroundImage';
import { Page } from '../styled';

type Props = { reportDate: IDate };

export function PageTitle({ reportDate }: Props) {
  return (
    <Page>
      <BackgroundImage fileName='slide-1.jpg' />

      <PageTitleDate>
        <p>{moment(reportDate.startDate).format('MMMM YYYY')}</p>
      </PageTitleDate>
      <LogoPlaceholder>Company Logo</LogoPlaceholder>
    </Page>
  );
}
