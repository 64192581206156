import { Tooltip } from 'antd';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { getFormattedDate, getCapitalizedString, getRenderValue } from 'services/helpers';
import { Box, TagColoring } from 'shared';
import { ColumnsType } from 'antd/lib/table';
import { VulnerabilitySchema } from 'services/api';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { ColumnKey, VulnerabilitiesTitle } from '../constants';
import { renderCvssColumn } from '../helpers';

export const columns: ColumnsType<VulnerabilitySchema> = [
  {
    key: ColumnKey.Title,
    title: VulnerabilitiesTitle.Vulnerability,
    dataIndex: ColumnKey.Title,
    width: '40%',
    render: (title: string) => <>{getRenderValue(title, NO_VALUE_LABEL)}</>,
  },
  {
    key: ColumnKey.AffectedHosts,
    title: VulnerabilitiesTitle.AffectedHosts,
    dataIndex: ColumnKey.AffectedHosts,
    sorter: true,
    showSorterTooltip: false,
    className: 'center-head-title',
    align: 'center',
    render: (hosts: string) => <>{getRenderValue(hosts)}</>,
  },
  {
    key: ColumnKey.Category,
    title: VulnerabilitiesTitle.Category,
    dataIndex: ColumnKey.Category,
    render: (category: string) => <>{getRenderValue(category)}</>,
  },
  {
    key: ColumnKey.Severity,
    title: VulnerabilitiesTitle.Severity,
    dataIndex: ColumnKey.Severity,
    render: (severity: string) => (
      <>
        {!severity ? (
          emptyDataString
        ) : (
          <TagColoring title={getCapitalizedString(getSeverityLevelName(severity))} />
        )}
      </>
    ),
    sorter: true,
    showSorterTooltip: false,
  },
  {
    key: ColumnKey.CVSS,
    title: (
      <Tooltip placement='right' title='Base CVSS score'>
        {VulnerabilitiesTitle.CVSS}
      </Tooltip>
    ),
    dataIndex: ColumnKey.CVSS,
    render: renderCvssColumn,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    key: ColumnKey.Updated,
    title: VulnerabilitiesTitle.Updated,
    dataIndex: ColumnKey.Updated,
    render: (date: string) => (
      <Box w='160px'>
        <Box>{getFormattedDate(date)}</Box>
      </Box>
    ),
    sorter: true,
    showSorterTooltip: false,
    width: 160,
    align: 'right',
    className: 'right-head-title',
  },
];
