import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Box, InnerLayout, Tabs as CustomTabs } from 'shared';
import { downloadCSV } from 'services/helpers/downloadCSV';
import { showError, showMessage, excludeAgedHostTag } from 'services/helpers';
import { CMDBReportType, TagSchema, CmdbService } from 'services/api';
import { HistoryState } from 'services/interfaces';
import { useGetPermission, useSearchParams } from 'services/hooks';
import {
  dateFormatHours,
  errorDefaultNotificationMessage,
  loadingNotificationMessage,
  PermissionOptions,
  PermissionsLevel,
} from 'app-constants';
import {
  app,
  AppDispatch,
  cmdb,
  cmdbApi,
  MessageType,
  useApp,
  useCmdb,
  useCMDBPotentialVulnerabilities,
  useCustomer,
  useLoading,
  useTagsManagement,
} from 'store';
import { RequestStatus } from 'store/loading/slice';
import { getFiltersFields } from 'pages/CmdbHostDetails/components/Vulnerabilities/helpers';
import { assetDetails } from 'store/assetDetails/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { downloadZip } from 'services/helpers/downloadZip';
import { useMessagingClient, useFileDownloadMessagingProgress } from 'services/messaging';
import { OptionType } from 'shared/Table/types';
import { useFilter } from 'services/hooks/useFilter';
import { tagsManagementApi } from 'store/tagsManagement/thunks';
import { Tabs, Tooltip } from 'antd';
import { getLayoutConfig } from './helpers';
import {
  allowedUploadOnlyCSV,
  confirmedVulnerabilitiesFilter,
  getFilterFields,
  initialOrderBy,
  potentialVulnerabilitiesFilter,
  defaultAssetSourcesOptions,
  CMDB_TABS,
  activeTabTooltip,
  agedHostsTabTooltip,
} from './constants';
import { PopupWithProgress } from './components/PopupWithProgress';
import { TabContent } from './components/TabContent';

export const Cmdb = () => {
  const { data } = useCmdb();
  const { tagsList } = useTagsManagement();
  const { potentialVulnerabilitiesExists } = data;
  const dispatch: AppDispatch = useDispatch();
  const customerId = useCustomer();
  const loading = useLoading([
    cmdbApi.fetchData.typePrefix,
    cmdbApi.getCSVReport.typePrefix,
    cmdbApi.deleteHosts.typePrefix,
    tagsManagementApi.getTagsList.typePrefix,
    cmdbApi.uploadTags.typePrefix,
  ]);
  const isToggleButtonLoading = Object.values(loading).some((item) => item);
  const { isMobile } = useApp();
  const { state: historyState, pathname } = useLocation<HistoryState>();
  const isPotentialVulnerabilitiesActive = useCMDBPotentialVulnerabilities();
  const inputRef = React.createRef<HTMLInputElement>();
  const isFullAccess = useGetPermission(PermissionOptions.CMDB) === PermissionsLevel.Full;

  const { tab } = useSearchParams();
  const isAgedHostsTab = CMDB_TABS.agedHosts.key === tab;

  const newVulnerabilityStatusFilter = isPotentialVulnerabilitiesActive
    ? potentialVulnerabilitiesFilter
    : confirmedVulnerabilitiesFilter;

  const request = useCallback(
    (params) => {
      const filter = {
        ...params.filter,
        fields: getFiltersFields(
          params?.filter?.fields || [],
          newVulnerabilityStatusFilter.fields[0],
        ),
      };

      dispatch(
        cmdbApi.fetchData({
          ...params,
          filter,
          agedHosts: params.tab === CMDB_TABS.agedHosts.key,
        }),
      );
    },
    [dispatch, newVulnerabilityStatusFilter.fields],
  );

  const [params, updateParams] = useFilter({
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
    filter: isPotentialVulnerabilitiesActive
      ? { ...potentialVulnerabilitiesFilter }
      : { ...confirmedVulnerabilitiesFilter },
    page: 'CMDB',
    historyState,
    request,
  });

  const onSwitchToggleButton = useCallback(
    (isPotentialVulnsSwitched: boolean) => {
      dispatch(cmdb.actions.setShowPotentialVulnerabilities(isPotentialVulnsSwitched));
      dispatch(assetDetails.actions.setShowPotentialVulnerabilities(isPotentialVulnsSwitched));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!loading[cmdbApi.deleteHosts.typePrefix]) {
      dispatch(app.actions.setBlured(false));

      return;
    }

    showMessage(loadingNotificationMessage, MessageType.Loading);
    dispatch(app.actions.setBlured(true));
  }, [loading, dispatch, params]);

  useEffect(() => {
    dispatch(tagsManagementApi.getTagsList(customerId));
  }, [customerId, dispatch]);

  const [customerAssetSourcesOptions, setCustomerAssetSourcesOptions] = useState<OptionType[]>(
    defaultAssetSourcesOptions,
  );

  // get customer host sources
  useEffect(() => {
    CmdbService.getCustomerHostsSources({ customerId, agedHosts: isAgedHostsTab })
      .then((sources) => {
        setCustomerAssetSourcesOptions(sources as OptionType[]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching customer CMDB sources', error);

        setCustomerAssetSourcesOptions(defaultAssetSourcesOptions);
      });
  }, [customerId, isAgedHostsTab]);

  const messagingClient = useMessagingClient();

  const {
    progress,
    setProgress,
    progressMessageHandler,
    initialProgressState,
  } = useFileDownloadMessagingProgress();

  const onDownloadCSV = async (reportType: CMDBReportType = CMDBReportType.SUMMARY) => {
    const hostsTypes = isAgedHostsTab ? 'CMDB AGED HOSTS' : 'CMDB';
    const filename = `${hostsTypes}  ${moment().utc().format(dateFormatHours)} UTC`;
    const isDetailedReport = reportType.toLowerCase() === CMDBReportType.DETAILED;

    const getReport = async (connectionId = '') => {
      return dispatch(
        cmdbApi.getCSVReport({
          ...params,
          filter: {
            ...params.filter,
            fields: getFiltersFields(
              params?.filter?.fields || [],
              newVulnerabilityStatusFilter.fields[0],
            ),
          },
          reportType,
          agedHosts: isAgedHostsTab,
          connectionId,
        }),
      ).unwrap();
    };

    try {
      setProgress({ ...initialProgressState, isProcessing: true });

      if (isDetailedReport) {
        const { connectionInfo } = await messagingClient?.listen(progressMessageHandler);
        const result = await getReport(connectionInfo.connectionId);

        if (result instanceof Blob) {
          downloadZip(result, filename);
        }
      } else {
        const result = await getReport();

        downloadCSV(result, filename);
      }
    } catch (error) {
      showError();
    } finally {
      messagingClient.stop();

      setProgress(initialProgressState);
    }
  };

  const handleAttachButton = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  const onUploadTags = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const filesUploaded = event.currentTarget.files;

      if (!filesUploaded) {
        return;
      }

      if (filesUploaded[0].type !== allowedUploadOnlyCSV) {
        showMessage({ content: 'CSV (only) files can be uploaded.' }, MessageType.Error);

        return;
      }

      const reader = new FileReader();

      reader.readAsArrayBuffer(filesUploaded[0] as File);
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const blob = new Blob([e?.target?.result as ArrayBuffer], {
          type: 'application/octet-stream',
        });

        const result = (await dispatch(
          cmdbApi.uploadTags({ customerId, requestBody: blob }),
        )) as PayloadAction<
          { notFoundHosts?: string[]; incorrectTags?: string[]; hostsMaxTagsReached?: string[] },
          string,
          { requestStatus: string },
          { message: string }
        >;

        if (result.meta.requestStatus === RequestStatus.Rejected) {
          showMessage(
            { content: result.error.message || errorDefaultNotificationMessage },
            MessageType.Error,
          );

          return;
        }

        const notFoundHosts = result.payload?.notFoundHosts as string[];
        const incorrectTags = result.payload?.incorrectTags as string[];
        const hostsMaxTagsReached = result.payload?.hostsMaxTagsReached as string[];

        const content: string[] = [
          'File processing completed successfully, however, there are some unprocessed data described below.',
        ];

        if (notFoundHosts.length) {
          content.push(`The following hosts have not been found: ${notFoundHosts.join(', ')}`);
        }

        if (incorrectTags.length) {
          content.push(`The following tags have invalid names: ${incorrectTags.join(', ')}`);
        }

        if (hostsMaxTagsReached.length) {
          content.push(
            `The maximum number of tags has been reached for: ${hostsMaxTagsReached.join(', ')}`,
          );
        }

        if (content.length === 1) {
          showMessage(
            { content: `File successfully uploaded, without any warnings.` },
            MessageType.Success,
          );
        } else {
          showMessage(
            {
              style: {
                margin: 'auto',
                maxWidth: '750px',
              },
              content: (
                <>
                  {content.map((warning) => (
                    <Box key={warning}>{warning}</Box>
                  ))}
                </>
              ),
            },
            MessageType.Warning,
          );
        }

        request(params);
      };

      const input = inputRef.current;

      if (input) {
        input.value = '';
      }
    },
    [customerId, dispatch, inputRef, params, request],
  );

  const layoutConfig = getLayoutConfig(
    onDownloadCSV,
    onSwitchToggleButton,
    loading[cmdbApi.getCSVReport.typePrefix],
    historyState,
    pathname,
    isPotentialVulnerabilitiesActive,
    isToggleButtonLoading,
    potentialVulnerabilitiesExists,
    isMobile,
    onUploadTags,
    handleAttachButton,
    inputRef,
    loading[cmdbApi.uploadTags.typePrefix],
    isFullAccess,
  );

  const filterFields = useMemo(() => {
    const selectTagOptions = tagsList?.filter(excludeAgedHostTag).map((item: TagSchema) => ({
      label: item?.name,
      value: item?.id,
    })) as OptionType[];

    return getFilterFields(customerAssetSourcesOptions, selectTagOptions, isAgedHostsTab);
  }, [tagsList, isAgedHostsTab, customerAssetSourcesOptions]);

  return (
    <InnerLayout {...layoutConfig}>
      <CustomTabs
        activeKey={tab || CMDB_TABS.active.key}
        onChange={(activeTab) => {
          updateParams((prevParams) => ({
            ...prevParams,
            tab: activeTab,
          }));
        }}
      >
        <Tabs.TabPane
          tab={<Tooltip title={activeTabTooltip}>{CMDB_TABS.active.name}</Tooltip>}
          key={CMDB_TABS.active.key}
        >
          <TabContent
            data={data}
            filterFields={filterFields}
            params={params}
            setParams={updateParams}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={<Tooltip title={agedHostsTabTooltip}>{CMDB_TABS.agedHosts.name}</Tooltip>}
          key={CMDB_TABS.agedHosts.key}
        >
          <TabContent
            data={data}
            filterFields={filterFields}
            params={params}
            setParams={updateParams}
          />
        </Tabs.TabPane>
      </CustomTabs>

      <PopupWithProgress
        visible={progress.isProcessing}
        percent={progress.percent}
        disableCancel={!messagingClient.isConnected}
        handleCancel={() => {
          messagingClient.stop();
          setProgress(initialProgressState);
        }}
      />
    </InnerLayout>
  );
};
