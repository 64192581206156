import React from 'react';
import { useApp, useCEPlus, useCustomerChange, useLoading } from 'store';
import { Card, ScrollContainer, ShowMoreButton } from 'shared';
import { BarChart } from 'shared/charts';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { LinkContainer } from 'shared/Link/LinkContainer';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { Routes } from 'services/entities';
import { numberFormatter } from 'services/helpers';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { getQueryString } from 'services/api/core/request';
import { cyberEssentialPlusApi } from 'store/cyberEssentialPlus/thunks';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import {
  cePlusBackRoute,
  ITEM_CHART_HEIGHT,
  MAX_EVENTS_COUNT,
  MIN_CHART_HEIGHT,
  tooltipTitles,
} from '../../constants';
import { getTopHardwareParams, viewMoreConfig } from '../../helpers';
import { ChartContainer, HardwareWrapper } from '../../styled';
import { getOption } from '../../barChartHelper';

export const Top5ClientHardware = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile } = useApp();
  const { topClientHardware } = useCEPlus();
  const { selectedTags } = useCEPlus();
  const loading = useLoading([cyberEssentialPlusApi.getTopClientHardware.typePrefix]);

  const colors = [
    theme.colorSet.baseColors.green,
    theme.colorSet.baseColors.grey,
    theme.colorSet.baseColors.blue,
    theme.colorSet.baseColors.red,
    theme.colorSet.baseColors.amber,
  ];

  const barChartValue = topClientHardware?.chart || [];

  const customerChange = useCustomerChange([cyberEssentialPlusApi.getTopClientHardware.typePrefix]);
  const isShowEmptyChart = customerChange[cyberEssentialPlusApi.getTopClientHardware.typePrefix];

  const isScrollable = barChartValue.length > MAX_EVENTS_COUNT;

  const heightOfChart = !isScrollable
    ? MIN_CHART_HEIGHT
    : MIN_CHART_HEIGHT + ITEM_CHART_HEIGHT * (barChartValue.length - MAX_EVENTS_COUNT);

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => {
        if (!params.name) {
          return null;
        }

        const getParamByName = topClientHardware?.chart?.find((data) => data.title === params.name);

        return history.push(
          `${Routes.CMDB}${getQueryString(
            getTopHardwareParams(
              getParamByName?.manufacturer,
              getParamByName?.product,
              false,
              selectedTags,
            ),
          )}`,
          cePlusBackRoute,
        );
      },
    }),
    [history, selectedTags, topClientHardware?.chart],
  );

  return (
    <Card
      title='Top 5 client hardware'
      tooltipTitle={tooltipTitles.topClientHardware}
      subTitle={
        <SubTitle boldText={numberFormatter(topClientHardware.count || 0, 1)} customText='Total' />
      }
      showDivider={false}
      height={theme.sizes.full}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getTopClientHardware.typePrefix]}
    >
      <HardwareWrapper>
        <ScrollContainer width='600px'>
          <ChartContainer isScrollable={isScrollable}>
            <TotalCustomTooltipContainer>
              <RenderChartWrapper
                values={topClientHardware?.chart || []}
                emptyText='No top client hardware yet'
                isShowEmptyChart={isShowEmptyChart}
              >
                <BarChart
                  values={topClientHardware?.chart || []}
                  isMobile={isMobile}
                  getOption={getOption}
                  height={heightOfChart}
                  onEvents={onEvents}
                  color={colors}
                  isLoading={loading[cyberEssentialPlusApi.getTopClientHardware.typePrefix]}
                  isAsyncAnimation
                />
              </RenderChartWrapper>
            </TotalCustomTooltipContainer>
          </ChartContainer>
        </ScrollContainer>

        <LinkContainer
          borderTop={!isMobile ? '' : `${theme.sizes.px} solid ${theme.colors.blue[50]}`}
          hasBorder
        >
          <ShowMoreButton
            to={{
              pathname: viewMoreConfig().CMDBClients.route,
              search: viewMoreConfig().CMDBClients.search,
              state: cePlusBackRoute,
            }}
          >
            See all client hardware
          </ShowMoreButton>
        </LinkContainer>
      </HardwareWrapper>
    </Card>
  );
};
