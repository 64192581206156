import { UcFirstLetter } from 'shared';
import { TagProps } from 'antd';
import { TagValue } from 'shared/TagColoring/constants';
import { StatusTag } from 'pages/ThreatDetection/components/MonitoredServices/styled';
import { PriorityLabel } from 'pages/CmdbHostDetails/components/AssetOwner/constants';

type CustomTagProps = TagProps & {
  title: string;
};

export const TagColoring = ({ title, ...TagProps }: CustomTagProps) => {
  const tagType = (title: string) => {
    switch (title) {
      case PriorityLabel.Low:
        return TagValue.Success;
      case PriorityLabel.Medium:
        return TagValue.Warning;
      case PriorityLabel.High:
        return TagValue.Error;

      default:
        return TagValue.Success;
    }
  };

  return (
    <StatusTag color={tagType(title)} key={title} {...TagProps}>
      {UcFirstLetter(title)}
    </StatusTag>
  );
};
