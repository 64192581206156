import { Icon } from 'shared';
import { getCssVar, icons } from 'styles/theme';
import { UserSchema } from 'services/api';

type IconColoringProps = {
  status: string;
};

export const IconColoring = ({ status }: IconColoringProps) => {
  const statusType = (status: string) => {
    switch (status) {
      case UserSchema.status.ACTIVE:
        return getCssVar('status.success.bg');
      case UserSchema.status.ACCOUNT_LOCKED:
        return getCssVar('status.error.bg');
      default:
        return getCssVar('status.bg');
    }
  };

  return (
    <Icon
      component={icons.fiber_manual_record}
      color={statusType(status)}
      dimension='10px'
      margin='0 8px 4px 0'
    />
  );
};
