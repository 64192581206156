export const mainFontSize = '13px';
export const smallFontSize = '11px';

export const totalPieScoreSize = '100px';
export const totalPieFontSize = 25;
export const titleFontSize = 8;

// Pages 5,6,7,8
export const asidePieSize = '110px';
export const asidePieFontSize = 30;
