import moment from 'moment';
import { startCase } from 'lodash';
import { IDate } from 'services/interfaces';
import { OptionParams } from 'shared/charts';
import { IEChartsOption, Series } from 'shared/charts/interfaces';
import { RadioButtonsDays } from 'shared/RadioButtonsWithRangePicker/constants';
import { formatDate } from 'services/helpers';
import { VulnerabilityTrendPointSchema } from 'services/api';
import { getData } from 'pages/VulnerabilityManagement/helpers';
import { criticalHighTooltipFormatter } from 'shared/charts/formatters';
import { Theme } from 'styles/theme/types';

const rightGridForMobile = 38;
const rightGridForDesktop = 32;

const getMultiLineSeries = (
  entries: { [x: string]: VulnerabilityTrendPointSchema[] },
  theme: Theme,
) => {
  const baseSettings = {
    type: 'line',
  };
  const colors = {
    critical: theme.colorSet.severity.high,
    urgent: theme.colorSet.severity.critical,
  };
  const series: Series[] = [];
  let data: number[] = [];

  Object.entries(entries).map((entry: [string, VulnerabilityTrendPointSchema[]]) => {
    const [seriesData, xAxisData] = getData(entry[1]);

    series.push({
      ...baseSettings,
      name: startCase(entry[0]),
      data: seriesData,
      color: colors[entry[0] as keyof typeof colors],
      showSymbol: seriesData.length <= 1 && seriesData[0] !== 0,
      symbolSize: 6,
    });
    data = xAxisData;

    return entry;
  });

  return { series, data };
};

export const getOption = ({
  values,
  theme,
  date,
  height,
  isMobile,
}: OptionParams<{ [x: string]: VulnerabilityTrendPointSchema[] }>): IEChartsOption => {
  const dateRange: IDate = date || {
    startDate: moment().subtract(RadioButtonsDays.OneMonth, 'days').startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
  };
  const { series, data } = getMultiLineSeries(values, theme);

  return {
    grid: {
      left: 18,
      top: 35,
      right: !isMobile ? rightGridForDesktop : rightGridForMobile,
      bottom: 10,
      containLabel: true,
      height: height || '260px',
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, '10%'],
      data: data.map((value: number) => formatDate(`${value}`, dateRange, true)),
      axisLabel: {
        color: theme.colorSet.charts.axisLabel,
        fontFamily: theme.colorSet.lessFontFamily,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: theme.colorSet.charts.axisLabel,
        fontFamily: theme.colorSet.lessFontFamily,
      },
      min: 0,
    },
    series,
    tooltip: {
      trigger: 'axis',
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
        fontFamily: theme.colorSet.lessFontFamily,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
      formatter: (params: Record<string, number>[]) => {
        return criticalHighTooltipFormatter(
          {
            high: values.critical[params[0].dataIndex].value,
            critical: values.urgent[params[1].dataIndex].value,
            date: values.critical[params[0].dataIndex].date,
          },
          dateRange,
        );
      },
    },
  };
};
