import styled from 'styled-components';
import { Box, Table } from 'shared';
import { breakpoints, getCssVar } from 'styles/theme';
import { ThemeProps } from 'services/interfaces';
import { Theme } from 'styles/theme/types';
import { StyledSelect } from 'shared/layouts/Inner/components/CustomersSelect/styled';

export const ChartContaner = styled.div`
  margin-top: 25px;
  width: 100%;
`;

export const TablePercentStyled = styled.span<ThemeProps>`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  opacity: 0.4;
`;

export const TableValueStyled = styled.span<ThemeProps>`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const TableWithSmallFields = styled(Table)<ThemeProps>`
  margin-top: 5px;

  .ant-table-column-sorters {
    justify-content: center;
  }

  .ant-table-thead {
    .ant-table-cell,
    .ant-table-cell:first-child,
    .ant-table-cell:last-child {
      padding: ${({ theme }) => `${theme.sizes[2]} ${theme.sizes[3]}`};
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: ${({ theme }) => `${theme.sizes[2]} ${theme.sizes['1.5']}`};
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell,
      .ant-table-cell:first-child,
      .ant-table-cell:last-child {
        padding: ${({ theme }) => `${theme.sizes[2]} ${theme.sizes[3]}`};
        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          padding: ${({ theme }) => `${theme.sizes[2]} ${theme.sizes['1.5']}`};
        }
      }
    }
  }
`;

export const TableWithSmallFieldsStyled = styled(TableWithSmallFields)<ThemeProps>`
  margin-top: 5px;

  .table-row-dark {
    background: ${getCssVar('table.tr.darkBg')};
    font-weight: ${({ theme }) => theme.fontWeights.medium};

    &:hover {
      cursor: default !important;
    }
  }

  .ant-row-space-between {
    justify-content: flex-end;
  }

  td {
    span:first-child {
      margin-right: 26px;
    }
  }

  .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-tbody .ant-table-row > td:last-child,
  .ant-table-tbody .ant-table-row > td:first-child,
  .ant-table-container table > thead > tr:first-child th:last-child {
    padding: 8px 12px;
    @media (max-width: ${breakpoints.md}) {
      padding: 12px 6px;
    }
  }
`;

type LinkContainerProps = {
  theme: Theme;
  hasBorder?: boolean;
};

export const LinkContainer = styled(Box)<LinkContainerProps>`
  font-size: ${({ theme }: LinkContainerProps) => theme.sizes[4]};
  font-weight: ${({ theme }: LinkContainerProps) => theme.fontWeights.bold};
  margin-top: ${({ theme }: LinkContainerProps) => theme.sizes['4.5']};
  padding: ${({ theme }: LinkContainerProps) => `${theme.sizes[0]} ${theme.sizes['2.5']}`};
  @media (max-width: ${({ theme }: LinkContainerProps) => theme.breakpoints.md}) {
    padding: ${({ theme, hasBorder }: LinkContainerProps) =>
      hasBorder ? `${theme.sizes['4.5']} ${theme.sizes[0]} ${theme.sizes[0]}` : theme.sizes[0]};
  }

  .anticon {
    margin-left: ${({ theme }: LinkContainerProps) => theme.sizes['1.5']};
    transition: all 0.1s ease-out;
  }

  &:not(:disabled) {
    &:hover,
    &.hover,
    &:focus,
    &:active,
    &.active {
      color: ${getCssVar('activeColor')};

      .anticon {
        transform: translateX(2px);

        &:after {
          opacity: 0;
        }
      }
    }
  }
`;

export const ScrollContainer = styled.div<ThemeProps>`
  width: 100%;

  &.default-cursor-chart .echarts-for-react > * {
    cursor: default !important;
  }

  @media (max-width: ${breakpoints.md}) {
    overflow-x: auto;
    .echarts-for-react,
    .ant-radio-group {
      min-width: 600px;
    }
  }

  ::-webkit-scrollbar {
    height: ${getCssVar('scrollbar.height')};
    bottom: 10px;
    width: 50px;
  }

  ::-webkit-scrollbar-track {
    background: ${getCssVar('scrollbar.bg')};
    border-radius: 10px;
    margin-right: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${getCssVar('scrollbar.color')};
    border-radius: 10px;
  }
`;

export const FilterWrapper = styled(Box)<ThemeProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 270px;

  &.exclude-vulns-select .ant-select-selection-overflow-item {
    max-width: calc(90% / 2);
  }
`;

export const FilterLabel = styled(Box)<ThemeProps>`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: ThemeProps) => theme.sizes['4.2']};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
  margin-bottom: 8px;
  margin-right: 10px;
`;

export const FilterTags = styled(StyledSelect)<ThemeProps>`
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.normal};
  top: 6px;
`;

export const FilterVulnerabilitiesWrapper = styled(Box)<ThemeProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const StyledTitle = styled.span<ThemeProps>`
  display: inline-block;
  margin-bottom: 20px;
`;
