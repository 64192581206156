import { getTimeSinceDate } from 'services/helpers';
import { DateOfSending, Email as StyledEmail } from '../styled';

interface IProps {
  email: string;
  createdTime: string;
}

export const Email = ({ email, createdTime }: IProps) => {
  return (
    <StyledEmail>
      {email}
      {createdTime && <DateOfSending>{getTimeSinceDate(createdTime)}</DateOfSending>}
    </StyledEmail>
  );
};
