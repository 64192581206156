import styled from 'styled-components';
import { Theme } from '../styles/theme/types';
import { getCssVar } from '../styles/theme';

type ScrollProps = {
  theme: Theme;
  width: string;
  $isScrollTop?: boolean;
  margin?: string;
};

export const ScrollContainer = styled.div<ScrollProps>`
  .echarts-for-react {
    height: 100% !important;
  }

  &.default-cursor-chart .echarts-for-react > * {
    cursor: default !important;
  }

  @media (max-width: ${({ theme }: ScrollProps) => theme.breakpoints.md}) {
    overflow-x: auto;
    transform: ${({ $isScrollTop }: ScrollProps) => (!$isScrollTop ? '' : 'scaleY(-1)')};
    flex-direction: column;
    margin: ${({ margin, theme }: ScrollProps) => margin || theme.sizes[0]};

    ::-webkit-scrollbar {
      height: ${getCssVar('scrollbar.height')};
    }

    .ant-table-wrapper {
      transform: scaleY(-1);
      min-width: ${({ width }: ScrollProps) => width};
      padding-right: ${({ theme }: ScrollProps) => theme.sizes[8]};
    }

    .ant-table {
      min-width: ${({ width }: ScrollProps) => width};
    }

    .echarts-for-react {
      min-width: ${({ width }: ScrollProps) => width};
      margin-bottom: ${({ theme }: ScrollProps) => theme.sizes[4]};
    }

    ::-webkit-scrollbar-track {
      background: ${getCssVar('scrollbar.bg')};
      border-radius: ${({ theme }: ScrollProps) => theme.radius.xl};
      margin-right: 20px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${getCssVar('scrollbar.color')};
      border-radius: ${({ theme }: ScrollProps) => theme.radius.xl};
    }

    .ant-radio-group {
      .ant-radio-button-wrapper {
        padding: 0 15px !important;
        font-size: 14px;
      }
    }

    > div {
      .ant-form {
        order: -1;

        .ant-row {
          margin-bottom: ${({ theme }: ScrollProps) => theme.sizes[4]};
        }
      }
    }
  }
`;
