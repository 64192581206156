import { AverageConfidenceResponse, AverageConfidenceSchema, Filter } from 'services/api';
import { IDate } from 'services/interfaces';
import { initialPagination } from 'app-constants';
import { averageConfidenceTooltipFormatter } from 'shared/charts/formatters';
import { IEChartsOption } from 'shared/charts/interfaces';
import { sizes } from 'styles/theme';
import { AverageConfidenceKeys, AverageConfidenceLabels } from 'pages/People/constants';
import { initialOrderBy } from 'pages/PeoplePhishing/constants';
import { getTrainingProgressStatusFilterValue } from 'pages/People/helpers';
import { Theme } from 'styles/theme/types';

const bottomGridDesktop = 0;
const bottomGridMobile = 20;
const heightGridDesktop = 260;
const heightGridMobile = 240;

const getXAxisLabel = (key: string) => {
  switch (key) {
    case AverageConfidenceKeys.InitialAssessments:
      return AverageConfidenceLabels.InitialAssessments;
    case AverageConfidenceKeys.StartedCourse:
      return AverageConfidenceLabels.StartedCourse;
    case AverageConfidenceKeys.CompletedCourse:
      return AverageConfidenceLabels.CompletedCourse;
    case AverageConfidenceKeys.LatestRefresher:
      return AverageConfidenceLabels.LatestRefresher;
    default:
      return '';
  }
};

export const getOption = (
  params: { values: AverageConfidenceResponse; date?: IDate },
  theme: Theme,
  isMobile?: boolean,
): IEChartsOption => {
  const { totalUsers, ...otherValues } = params.values;
  const entries = otherValues ? Object.entries(otherValues) : [];

  return {
    grid: {
      top: 20,
      left: 30,
      right: 10,
      bottom: !isMobile ? bottomGridDesktop : bottomGridMobile,
      height: !isMobile ? heightGridDesktop : heightGridMobile,
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: true,
      data: entries.map((entry: [string, number | AverageConfidenceSchema]) =>
        getXAxisLabel(entry[0]),
      ),
      scale: true,
      axisLabel: {
        show: true,
        interval: 0,
        fontSize: sizes['3.4'],
        fontFamily: theme.colorSet.lessFontFamily,
        color: theme.colorSet.charts.axisLabel,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        fontSize: sizes['3.4'],
        fontFamily: theme.colorSet.lessFontFamily,
        color: theme.colorSet.charts.axisLabel,
      },
      min: 0,
    },
    tooltip: {
      trigger: 'axis',
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
        fontFamily: theme.colorSet.lessFontFamily,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
      formatter: (params) => averageConfidenceTooltipFormatter(params[0].data, params[0].name),
    },
    series: [
      {
        data: entries.map((entry) => {
          if (typeof entry[1] === 'number') {
            return { value: entry[1].toFixed(2), cursor: 'default' };
          }

          return {
            value: entry[1].confidence?.toFixed(2),
            percentage: entry[1].percentage,
            totalUsers: entry[1].count,
            cursor: 'pointer',
          };
        }),
        type: 'line',
        color: theme.colorSet.charts.lineColor,
        areaStyle: { opacity: 0.1 },
        symbolSize: 6,
        itemStyle: { borderWidth: 2 },
        emphasis: {
          scale: true,
          lineStyle: { width: 2 },
        },
      },
    ],
  };
};

export const getRedirectParams = (key: string) => {
  const result = getTrainingProgressStatusFilterValue(key);
  const value = Array.isArray(result) ? [...result] : [result];

  return {
    orderBy: initialOrderBy,
    pagination: initialPagination,
    filter: {
      fields: [
        {
          name: 'trainingProgressStatus',
          value,
          type: Filter.type.MULTIPLE,
        },
      ],
    },
  };
};
