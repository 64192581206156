import { InnerLayoutProps as InnerLayout, Button } from 'shared';
import { Filter } from 'services/api';
import { TitleTooltipContent } from './components/TitleTooltipContent';
import { ProductsSelectOptions } from '../SupportRequestForm/types';
import { selectOptionOther, softwareDocTypeOptions } from './components/constants';
import { InputType } from '../../services/api/models/InputType';

export const getLayoutConfig = (
  setIsModalVisible: (val: boolean) => void,
  withButton: boolean,
): InnerLayout => ({
  title: 'Software',
  titleTooltip: TitleTooltipContent,
  customComponent: withButton ? (
    <Button type='secondary' onClick={() => setIsModalVisible(true)}>
      Upload file
    </Button>
  ) : null,
});

export const getSelections = (productsSelectOptions: ProductsSelectOptions[]) => [
  {
    label: 'Service type',
    options: productsSelectOptions
      ? [selectOptionOther, ...productsSelectOptions]
      : [selectOptionOther],
    placeholder: 'Select service',
    inputType: InputType.SELECTOR,
    key: 'service',
  },
];
export const getFiltersFields = (productsSelectOptions?: ProductsSelectOptions[]) => [
  {
    field: 'servicetype',
    options: productsSelectOptions
      ? [selectOptionOther, ...productsSelectOptions]
      : [selectOptionOther],
    label: 'Service type',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'documenttype',
    options: softwareDocTypeOptions,
    label: 'Document type',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];
