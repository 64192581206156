import { MonthlyReportResponse } from 'services/api';
import moment from 'moment/moment';
import { Themes } from 'themes/types';
import { IDate } from 'services/interfaces';
import { getSupportEmail, getSupportPhone } from 'themes/helpers';
import {
  MoreInformationText,
  DescriptionText,
  Wrapper,
  YourContactsWrapper,
  Field,
  FieldBlue,
  FieldWrapper,
  Section,
} from './styled';
import { BackgroundImage } from '../../components/BackgroundImage';
import { Page } from '../styled';

type Props = { themeName: Themes; data: MonthlyReportResponse; reportDate: IDate };

export function PdfPage15({ themeName, data: { contactDetails }, reportDate }: Props) {
  const focalAnalyst = contactDetails?.focalAnalyst;
  const accountManager = contactDetails?.accountManager;

  const startDate = moment(reportDate.startDate);
  const endDate = moment(reportDate.endDate + 1);

  return (
    <Page>
      <BackgroundImage fileName='slide-15.jpg' useFilter={false} />

      <Wrapper>
        <DescriptionText>
          This report covers the areas of your organisation that norm has visibility of. The data
          used to compile this report was collected between the {startDate.format('Do of MMMM')} and
          the {endDate.format('Do of MMMM YYYY')}.
        </DescriptionText>

        <YourContactsWrapper>
          <div className='title'>Your contacts</div>

          <Section>
            <p>
              For any incidents, service requests, or general queries, your first point of call
              should be the Norm service desk
            </p>

            <FieldWrapper>
              <FieldBlue>Tier 1 Support</FieldBlue>
              <Field>{getSupportEmail(themeName)}</Field>
              <Field>{getSupportPhone(themeName)}</Field>
            </FieldWrapper>
          </Section>

          <Section>
            <p>
              The norm team assigned to your organisation is listed below, and each has their own
              understanding of your organisation relative to their business role:
            </p>

            <FieldWrapper>
              <FieldBlue>Customer Experience</FieldBlue>
              <Field>customer.experience@normcyber.com</Field>
              <Field>+44(0) 20 3855 6215</Field>
            </FieldWrapper>

            <FieldWrapper>
              <FieldBlue>Your Focal Analyst</FieldBlue>
              <Field>{focalAnalyst?.email}</Field>
              <Field>{focalAnalyst?.phone}</Field>
            </FieldWrapper>

            <FieldWrapper>
              <FieldBlue>Your Account Manager</FieldBlue>
              <Field>{accountManager?.email}</Field>
              <Field>{accountManager?.phone}</Field>
            </FieldWrapper>
          </Section>
        </YourContactsWrapper>

        <MoreInformationText>
          More information about your norm contacts as well as their roles and responsibilities can
          be found in your customer handbook. Should you need to escalate a query, the norm
          escalation schedules are available in the same handbook, or on request from any of the
          contacts listed above.
        </MoreInformationText>
      </Wrapper>
    </Page>
  );
}
