import styled from 'styled-components';
import { breakpoints, getCssVar } from 'styles/theme';

export const AppWrapper = styled.div`
  background-color: ${getCssVar('bodyBackground')};
  min-height: 100vh;

  @media (max-width: ${breakpoints.lg}) {
    background-color: ${getCssVar('bodyBackgroundMobile')};
  }
`;
