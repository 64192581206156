import React from 'react';
import { useDispatch } from 'react-redux';
import { cybersafetyApi, useApp, useCulture, useCustomer, useLoading } from 'store';
import { isEmptyObject } from 'services/helpers';
import { Card } from 'shared';
import { Radar } from 'shared/charts/Radar/Radar';
import { Legend } from 'shared/charts';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { useTheme } from 'styled-components';
import {
  CultureContainer,
  PercentageContainer,
  PercentageItem,
  PercentageItemsContainer,
} from './styled';
import { ScrollContainer } from '../styled';
import { getOption } from './helpers';
import { radiuses, TooltipTitles } from '../../constants';

export const Culture = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const customerId = useCustomer();
  const dispatch = useDispatch();
  const culture = useCulture();
  const loading = useLoading([cybersafetyApi.getCulture.typePrefix]);

  const radarValuesLegend = {
    yourOrganisation: 0,
  };

  const radarColors = {
    yourOrganisation: theme.colorSet.charts.lineColor,
  };

  React.useEffect(() => {
    dispatch(
      cybersafetyApi.getCulture({
        customerId,
      }),
    );
  }, [dispatch, customerId]);

  return (
    <Card
      title='Culture'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles.Culture}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2] }
          : { titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? theme.sizes[6]
          : `${theme.sizes[5]} ${theme.sizes[0]} ${theme.sizes[5]} ${theme.sizes[5]}`
      }
      showLoader={loading[cybersafetyApi.getCulture.typePrefix]}
    >
      {culture && !isEmptyObject(culture) ? (
        <CultureContainer>
          <ScrollContainer>
            <PercentageContainer>
              <Radar
                values={culture}
                getOption={getOption}
                style={
                  isMobile
                    ? { height: '240px', marginBottom: theme.spacing[4] }
                    : { height: '300px' }
                }
                radius={isMobile ? radiuses.small : radiuses.normal}
                isMobile={isMobile}
                isLoading={loading[cybersafetyApi.getCulture.typePrefix]}
              />

              <PercentageItemsContainer>
                <PercentageItem>100%</PercentageItem>
                <PercentageItem>75%</PercentageItem>
                <PercentageItem>50%</PercentageItem>
                <PercentageItem>25%</PercentageItem>
                <PercentageItem>0</PercentageItem>
              </PercentageItemsContainer>
            </PercentageContainer>
          </ScrollContainer>

          <Legend
            values={radarValuesLegend}
            colors={radarColors}
            noValues
            direction='row'
            marginTop={isMobile ? theme.sizes[4] : undefined}
            cursor='default'
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
          />
        </CultureContainer>
      ) : (
        emptyMessage('No culture data yet')
      )}
    </Card>
  );
};
