import { useCallback, useMemo, useState } from 'react';
import { useApp, useRequestLoading, cmdbApi, useAuth, AppDispatch, useCustomer } from 'store';
import { Row } from 'antd';
import {
  EmptyTablePreview,
  MobileTable,
  MobileTableCol,
  CustomTable,
  Box,
  ActionPopup,
} from 'shared';
import { Props as TableProps } from 'shared/Table/CustomTable';
import { GridRequestParams, HostSchema } from 'services/api';
import { Gutter } from 'antd/lib/grid/row';
import { useGetPermission } from 'services/hooks';
import {
  defaultCurrent,
  defaultPageSize,
  PermissionOptions,
  PermissionsLevel,
  backlinkRoutes,
} from 'app-constants';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { useDispatch } from 'react-redux';
import { PopupConfigType } from 'shared/ActionPopup/types';
import {
  searchSettings,
  initialOrderBy,
  popupConfig as staticPopupConfig,
  ManageTagDropdownLabels,
} from '../constants';
import { getColumns } from './TableColumns';
import { MobileActions } from './MobileActions';
import { showDeletePopup } from '../helpers';

const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const MOBILE_GUTTER: [Gutter, Gutter] = [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

const EmptyMessage = ({ loading }) => (
  <EmptyTablePreview
    textContent={
      <Box>
        No hosts discovered yet
        <br />
        Here will be displayed your hosts within your environment once discovered.
      </Box>
    }
    loading={loading}
    alignCenter
  />
);

type TabContentProps = Pick<
  TableProps<HostSchema, GridRequestParams>,
  'data' | 'params' | 'setParams' | 'filterFields'
>;

export const TabContent = ({ data, filterFields, params, setParams }: TabContentProps) => {
  const history = useHistory();
  const { state: historyState } = useLocation<HistoryState>();
  const { user } = useAuth();
  const { isMobile } = useApp();
  const permission = useGetPermission(PermissionOptions.CMDB);
  const isLoading = useRequestLoading(cmdbApi.fetchData.typePrefix);
  const dispatch: AppDispatch = useDispatch();
  const customerId = useCustomer();

  const [popupConfig, setPopupConfig] = useState<PopupConfigType>(staticPopupConfig.delete);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedHosts, setSelectedHosts] = useState<HostSchema[]>([]);

  const handleTableClick = useCallback(
    (record: HostSchema) => {
      history.push(`${Routes.CMDB}/${record.id}`, {
        backTo: {
          route: Routes.CMDB,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[Routes.CMDB]}`,
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [history, historyState],
  );

  const updateHostsList = useCallback(() => {
    dispatch(
      cmdbApi.fetchData({
        ...params,
        agedHosts: false,
      }),
    );
  }, [dispatch, params]);

  const columns = useMemo(() => {
    return getColumns(
      (row: HostSchema, soft?: boolean) => {
        showDeletePopup({
          setPopupConfig,
          setIsPopupVisible,
          hostData: [{ id: row.id, hostName: row.hostname }],
          dispatch,
          customerId,
          onSuccess: updateHostsList,
          isNormUser: user.isSuperAdmin,
          soft,
        });
      },
      permission,
      isMobile,
      user.isSuperAdmin,
    );
  }, [permission, isMobile, user.isSuperAdmin, dispatch, customerId, updateHostsList]);

  const tableColumns = getTableColumns(columns, params.orderBy);

  const getActions = useCallback(
    (row: HostSchema) => (
      <MobileActions
        onAction={(soft: boolean) =>
          showDeletePopup({
            setPopupConfig,
            setIsPopupVisible,
            hostData: [{ id: row.id, hostName: row.hostname }],
            dispatch,
            customerId,
            onSuccess: updateHostsList,
            isNormUser: user.isSuperAdmin,
            soft,
          })
        }
      />
    ),
    [setIsPopupVisible, customerId, user.isSuperAdmin, updateHostsList, dispatch],
  );

  const onSelectedChanged = useCallback((_: React.Key[], selectedRows: HostSchema[]) => {
    setSelectedHosts(selectedRows);
  }, []);

  const handleSelectedDelete = useCallback(
    (soft: boolean, action?: string, tagId?: string) => {
      if (action && tagId) {
        if (action === ManageTagDropdownLabels.AddTags) {
          selectedHosts.forEach(({ id }: HostSchema) => {
            if (id) {
              dispatch(cmdbApi.setTag({ customerId, hostId: id.toString(), tagId }));
            }
          });
        }

        if (action === ManageTagDropdownLabels.DeleteTags) {
          selectedHosts.forEach(({ id }: HostSchema) => {
            if (id) {
              dispatch(cmdbApi.removeTag({ customerId, hostId: id.toString(), tagId }));
            }
          });
        }

        return;
      }

      showDeletePopup({
        setPopupConfig,
        setIsPopupVisible,
        hostData: selectedHosts.map((host: HostSchema) => ({
          id: host.id,
          hostName: host.hostname,
        })),
        dispatch,
        customerId,
        onSuccess: updateHostsList,
        isNormUser: user.isSuperAdmin,
        soft,
      });
    },
    [customerId, dispatch, selectedHosts, user.isSuperAdmin, updateHostsList],
  );

  return (
    <>
      {isMobile && (
        <Row gutter={MOBILE_GUTTER}>
          <MobileTableCol span={32} flex='auto'>
            <MobileTable<HostSchema, GridRequestParams>
              data={data}
              searchSettings={searchSettings}
              columns={tableColumns}
              filterFields={filterFields}
              onRowClick={handleTableClick}
              params={params}
              setParams={setParams}
              isLoadingContent={isLoading}
              initialOrderBy={initialOrderBy}
              getActions={permission !== PermissionsLevel.Full ? undefined : getActions}
              emptyMessage={EmptyMessage}
            />
          </MobileTableCol>
        </Row>
      )}

      {!isMobile && (
        <CustomTable<HostSchema, GridRequestParams>
          columns={tableColumns}
          data={data}
          params={params}
          setParams={setParams}
          filterFields={filterFields}
          searchSettings={searchSettings}
          rowSelection={{
            onChange: onSelectedChanged,
          }}
          onMultyDeletionSubmit={
            permission === PermissionsLevel.Full ? handleSelectedDelete : undefined
          }
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          isLoadingContent={isLoading}
          onRowClickHandle={handleTableClick}
          emptyMessage={EmptyMessage}
        />
      )}

      {isPopupVisible && (
        <ActionPopup
          isVisible={isPopupVisible}
          setIsVisiblePopup={setIsPopupVisible}
          popupDetails={popupConfig}
        />
      )}
    </>
  );
};
