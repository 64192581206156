import React from 'react';
import { useApp, useCEPlus, useLoading } from 'store';
import { Card } from 'shared';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { SegmentedPie } from 'shared/charts';
import { Routes } from 'services/entities';
import { isEmptyObject } from 'services/helpers';
import { cyberEssentialPlusApi } from 'store/cyberEssentialPlus/thunks';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { getQueryString } from 'services/api/core/request';
import { Filter } from 'services/api';
import { initialPagination } from 'app-constants';
import { getLegendColors, getLegendValues } from '../../../PatchManagement/helpers';
import { cePlusBackRoute, IFilterField, initialCMDBOrderBy, tooltipTitles } from '../../constants';
import { validateField, validateFieldValue, viewMoreConfig } from '../../helpers';

export const OperatingSystemDistribution = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const history = useHistory();
  const { getOperatingSystemDistribution } = useCEPlus();
  const { selectedTags } = useCEPlus();
  const loading = useLoading([cyberEssentialPlusApi.getOperatingSystemDistribution.typePrefix]);

  const assetsMissingPatchesColorList = [
    theme.colorSet.baseColors.blue,
    theme.colorSet.baseColors.amber,
    theme.colorSet.baseColors.green,
    theme.colorSet.baseColors.red,
    theme.colorSet.baseColors.deepRed,
    theme.colorSet.baseColors.grey,
    theme.colorSet.baseColors.blue,
    theme.colorSet.baseColors.amber,
    theme.colorSet.baseColors.green,
    theme.colorSet.baseColors.red,
    theme.colorSet.baseColors.deepRed,
    theme.colorSet.baseColors.grey,
  ];
  const getCMDBParams = (value: string, tagsAssets?: string[]) => {
    const filterFields: IFilterField[] = [
      {
        name: 'platform',
        value,
        type: Filter.type.VALUE,
      },
      {
        name: 'tagsAssets',
        value: tagsAssets || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
    ];

    return {
      orderBy: initialCMDBOrderBy,
      pagination: initialPagination,
      filter: {
        fields: validateField(filterFields),
      },
    };
  };

  const redirectToAssetsList = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      history.push(
        `${Routes.CMDB}${getQueryString(getCMDBParams(params.name, selectedTags))}`,
        cePlusBackRoute,
      );
    },
    [history, selectedTags],
  );

  const totalClickHandler = () => {
    history.push(viewMoreConfig(selectedTags).cmdbDistribution);
  };

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToAssetsList(params),
    }),
    [redirectToAssetsList],
  );

  const legendValues = getLegendValues(getOperatingSystemDistribution?.chart);

  return (
    <Card
      title='Operating system distribution'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={tooltipTitles.operatingSystemDistribution}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getOperatingSystemDistribution.typePrefix]}
      viewMore={viewMoreConfig(selectedTags).cmdbDistribution}
    >
      {!isEmptyObject(legendValues) ? (
        <SegmentedPie
          values={legendValues}
          colors={getLegendColors(legendValues, assetsMissingPatchesColorList)}
          direction='row'
          entitiesName=' '
          style={
            isMobile ? { height: '160px', marginTop: `-${theme.spacing[0]}` } : { height: '360px' }
          }
          isMobile={isMobile}
          isMenuCollapsed={isMenuCollapsed}
          onEvents={onEvents}
          totalClickHandler={totalClickHandler}
          isLoading={loading[cyberEssentialPlusApi.getOperatingSystemDistribution.typePrefix]}
        />
      ) : (
        emptyMessage(`No operating distribution yet`)
      )}
    </Card>
  );
};
