export const dateFormat = 'DD-MMM-YYYY';
export const dateFormatHours = 'DD-MMM-YYYY HH:mm';

export const oneMonth = 1;
export const threeMonths = 3;
export const oneDayTime = 86400000;
export const sevenDaysTime = 604800000;
export const oneMonthTime = 2678400000;
export const fourMonthsTime = 10713600000;
export const thirtyDays = 30;

export const NO_VALUE_LABEL = '—';
export const emptyDataString = 'N/A';
export const MAX_PERCENT = 100;

const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;

export const GUTTER: [number, number] = [H_GUTTER_MOBILE, V_GUTTER_MOBILE];
export const POPUP_WIDTH = 720;

export const PATH_TO_FONTS = `${process.env.PUBLIC_URL}/assets/fonts`;
export const PATH_TO_ICONS = `${process.env.PUBLIC_URL}/assets/icons`;
export const PATH_TO_IMAGES = `${process.env.PUBLIC_URL}/assets/images`;
export const PATH_TO_MONTHLY_REPORT_IMAGES = `${window.location.protocol}//${window.location.host}/assets/images/monthly-report`;
// export const PATH_TO_MONTHLY_REPORT_IMAGES = `https://wapp-normaliser-dev-002.azurewebsites.net/assets/images/monthly-report`;

export const NASSTAR_SUPPORT_EMAIL = 'support@nasstar.com';
export const NORM_SUPPORT_EMAIL = 'support@normcyber.com';
export const NASSTAR_SUPPORT_PHONE = '+44(0) 33 0313 0340';
export const NORM_SUPPORT_PHONE = '+44(0) 20 3855 6225';

export const SUPPRESSION_REVIEW_MAX_DAYS = 90;
