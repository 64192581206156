import { useCallback } from 'react';
import moment from 'moment';
import { Box, Button, ShowMoreButton } from 'shared';
import { CertificateName, CertificateStatusSchema } from 'services/api';
import { getCssVar, icons } from 'styles/theme';
import { useTheme } from 'styled-components';
import { ABOUT_TO_EXPIRE_MONTHS } from '../../constants';
import { CertificateFooterProp } from '../../types';
import { IconDownload } from '../../styled';
import { isCertificateCertified, isStatusInProgress } from '../../helpers';

export const Footer = ({
  onDownload,
  onSelfSertify,
  onReorder,
  status,
  expirationDate,
  openFormWithPreparedData,
  title,
  certificateName,
  downloading,
  id,
  downloadName,
  vendorName,
  isDashboard,
  hasPermissionToBuyCertificate,
  setClickOn,
  clickOn,
}: CertificateFooterProp) => {
  const theme = useTheme();

  const currentDate = moment();
  const expiration = moment(expirationDate);

  const isCertificateSoonExpire =
    expiration && expiration.diff(currentDate, 'month') < ABOUT_TO_EXPIRE_MONTHS;
  const isStatusExpired = expiration.isBefore(currentDate);
  const isISOCertificate = title === CertificateName.ISO27001;
  const isCertificateSelfCertified = status === CertificateStatusSchema.CERTIFIED && vendorName;
  const isStatusFailed = status === CertificateStatusSchema.FAILED;

  const isRenewalShown =
    hasPermissionToBuyCertificate &&
    ((id && !isStatusInProgress(status) && (isCertificateSoonExpire || isStatusExpired)) ||
      isStatusFailed);

  const isDefaultEmptyCertificate = !status && !id && !vendorName && !expirationDate;

  const isSelfCertifiedAndSoonExpired =
    isCertificateSelfCertified && (isCertificateSoonExpire || isStatusExpired);

  const isOrderShown =
    (isSelfCertifiedAndSoonExpired || isDefaultEmptyCertificate) &&
    !isISOCertificate &&
    hasPermissionToBuyCertificate;

  const onUpdateHandler = useCallback(() => {
    if (expirationDate) {
      openFormWithPreparedData(title || '', expirationDate, vendorName);
    }
  }, [expirationDate, openFormWithPreparedData, title, vendorName]);

  const onDownloadCertificate = () => {
    setClickOn?.('card');
    onDownload?.(certificateName || '');
  };

  const isUpdateShown =
    !id &&
    (isCertificateSoonExpire || isStatusExpired) &&
    (!isStatusInProgress(status) || (isStatusInProgress(status) && isStatusExpired)) &&
    hasPermissionToBuyCertificate;

  return (
    <Box
      d='flex'
      color={getCssVar('activeColor')}
      fontSize={theme.sizes[4]}
      fontWeight={theme.colorSet.link.fontWeight}
      justify='space-between'
      height='40px'
      gap={theme.spacing[3]}
    >
      {isCertificateCertified(status) && downloadName && (
        <Button
          onClick={onDownloadCertificate}
          loading={downloading === certificateName && clickOn === 'card'}
          className='btn-table-action'
          type='link'
          icon={<IconDownload component={icons.download_small} />}
        >
          Download
        </Button>
      )}

      {isRenewalShown &&
        (!isStatusInProgress(status) || isStatusExpired) &&
        !isCertificateSelfCertified && (
          <ShowMoreButton onClick={onReorder}>
            {isStatusFailed ? 'Re-order' : 'Renewal'}
          </ShowMoreButton>
        )}

      {!status && hasPermissionToBuyCertificate && (
        <Box onClick={() => onSelfSertify(true, `${title} Self-certification`)}>
          {isDashboard ? (
            <Button type='primary'>Self-certify</Button>
          ) : (
            <ShowMoreButton>Self-certify</ShowMoreButton>
          )}
        </Box>
      )}

      {isUpdateShown && <ShowMoreButton onClick={onUpdateHandler}>Update</ShowMoreButton>}

      {isOrderShown &&
        (isDashboard ? (
          <Button type='primary' onClick={onReorder}>
            Order
          </Button>
        ) : (
          <ShowMoreButton onClick={onReorder}>Order</ShowMoreButton>
        ))}
    </Box>
  );
};
