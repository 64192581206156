import { useMemo } from 'react';
import {
  PhishingLastThirtyDaysResponse,
  RefresherRatesResponse,
  TrainingStatusResponse,
} from 'services/api';
import { pieChart } from 'styles/theme/Norm/pieChart';
import { Values } from 'shared/charts/types';
import { Theme } from 'styles/theme/types';
import { getCapitalizedString, isEmptyObject } from 'services/helpers';
import { getChartValues } from 'pages/People/components/PhishingLastThirtyDays/helpers';
import {
  CurrentRefresherTests,
  CurrentTrainingStatus,
  PeopleText,
  PhishingLastMonth,
  TotalScorePieWrapper,
  ChartsContainer,
} from './styled';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { emptyCurrentTrainingStatusObject, emptyRefresherStatistics } from '../../../../constants';
import { CyberResilienceScoreDescriptionScoreMessage, Page } from '../styled';
import { asidePieSize, asidePieFontSize } from '../config';
import { BarReportChartWithLegend } from '../../components/Charts/BarReportChartWithLegend';
import { BackgroundImage } from '../../components/BackgroundImage';

type Props = {
  onChartLoaded: () => void;
  peopleScore: number | undefined | null;
  peopleRank: string;
  phishing?: PhishingLastThirtyDaysResponse;
  trainingStatus?: TrainingStatusResponse;
  refresherStatistics?: RefresherRatesResponse;
  theme: Theme;
};

const trainingColors = {
  completed: pieChart.CurrentTrainingStatusSuccess,
  started: pieChart.CurrentTrainingStatusStarted,
  notStarted: pieChart.CurrentTrainingStatusNotStarted,
};

const phishingLastThirtyDaysColors = {
  noActions: pieChart.PhishingLast30DaysNoActions,
  opened: pieChart.PhishingLast30DaysOpened,
  clicked: pieChart.PhishingLast30DaysClicked,
  providedCredentialsOrDownloadedAnAttachment: pieChart.PhishingLast30DaysProvidedCredentials,
};

const refreshingTestsColors = {
  passed: pieChart.CurrentTrainingStatusSuccess,
  ignored: pieChart.CurrentRefresherTestsIgnored,
  failed: pieChart.CurrentRefresherTestsFailed,
};

export function PdfPage6({
  onChartLoaded,
  peopleScore,
  peopleRank,
  theme,
  phishing = {},
  trainingStatus = {},
  refresherStatistics = {},
}: Props) {
  const trainingStatusValues = useMemo(
    () => (!isEmptyObject(trainingStatus) ? trainingStatus : emptyCurrentTrainingStatusObject),
    [trainingStatus],
  );

  const phishingValues = useMemo(
    () =>
      !isEmptyObject(phishing.details)
        ? (getChartValues(phishing) as Values)
        : emptyCurrentTrainingStatusObject,
    [phishing],
  );

  const refresherStatisticsValues = useMemo(
    () => (!isEmptyObject(refresherStatistics) ? refresherStatistics : emptyRefresherStatistics),
    [refresherStatistics],
  );

  return (
    <Page>
      <BackgroundImage fileName='slide-6.jpg' />

      <PeopleText>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(peopleRank) }}
        />
        <ul>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga.
          </li>
        </ul>
      </PeopleText>

      <TotalScorePieWrapper>
        <GradientPieReport
          score={peopleScore}
          onFinished={onChartLoaded}
          width={asidePieSize}
          height={asidePieSize}
          fontSize={asidePieFontSize}
        />
      </TotalScorePieWrapper>

      {peopleScore && peopleScore >= 0 && (
        <ChartsContainer>
          <CurrentTrainingStatus>
            <BarReportChartWithLegend
              values={trainingStatusValues}
              theme={theme}
              colors={trainingColors}
              onChartLoaded={onChartLoaded}
              title='Total users'
            />
          </CurrentTrainingStatus>

          <CurrentRefresherTests>
            <BarReportChartWithLegend
              values={refresherStatisticsValues}
              theme={theme}
              colors={refreshingTestsColors}
              onChartLoaded={onChartLoaded}
              title='Total tests'
            />
          </CurrentRefresherTests>

          <PhishingLastMonth>
            <BarReportChartWithLegend
              values={phishingValues}
              theme={theme}
              colors={phishingLastThirtyDaysColors}
              onChartLoaded={onChartLoaded}
              title='Total emails'
            />
          </PhishingLastMonth>
        </ChartsContainer>
      )}
    </Page>
  );
}
