import { PenetrationTestingText } from './styled';
import { BackgroundImage } from '../../components/BackgroundImage';
import { Page } from '../styled';

export function PdfPage11() {
  return (
    <Page>
      <BackgroundImage fileName='slide-11.jpg' useFilter={false} />

      <PenetrationTestingText>
        <div className='title'>Summary of Penetration Test Results</div>

        <ul>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.
          </li>
        </ul>
      </PenetrationTestingText>
    </Page>
  );
}
