import moment from 'moment';
import { formatUpdatedOrCreatedTime } from 'services/helpers';
import { Icon } from 'shared';
import { icons } from 'styles/theme/icons';
import { useTheme } from 'styled-components';
import { BoostScoreItemContainer } from '../styled/styled';
import { BoostScoreDate, BoostScoreSubTitle, BoostScoreTitle, StyledButton } from './styled';

interface BoostScoreItemProps {
  title: string;
  subTitle?: string;
  date?: number;
  onClick: () => void;
}

export const BoostScoreItem = ({ date, title, subTitle, onClick }: BoostScoreItemProps) => {
  const theme = useTheme();

  const isDateExpired = moment().valueOf() > moment(date).valueOf();

  const getColor = () => {
    if (!date) {
      return theme.colorSet.baseColors.grey;
    }

    if (isDateExpired) {
      return theme.colorSet.baseColors.red;
    }

    return theme.colorSet.baseColors.green;
  };

  return (
    <BoostScoreItemContainer color={getColor()} isExpired={isDateExpired}>
      <span />
      <div>
        <BoostScoreTitle>{title}</BoostScoreTitle>
        {(subTitle || date) && (
          <div>
            <BoostScoreSubTitle>{subTitle}</BoostScoreSubTitle>
            <div className='circle' />
            {date && <BoostScoreDate>{formatUpdatedOrCreatedTime(date, true)}</BoostScoreDate>}
          </div>
        )}
        {onClick && (
          <StyledButton type='link' onClick={onClick}>
            {date ? 'Update' : 'Set up'}
            <Icon component={icons.arrow_right_alt} />
          </StyledButton>
        )}
      </div>
    </BoostScoreItemContainer>
  );
};
