import { Link } from 'react-router-dom';
import { Spacer } from 'shared/TableFilters/styled';
import { Routes } from 'services/entities';
import { useMaintenanceSettings } from 'store';
import { Preview } from '../Preview';
import { ViewModeWrapper } from '../../style';

interface IProps {
  loading: boolean;
}

export const MaintenanceViewMode = ({ loading }: IProps) => {
  const { maintenanceSettings } = useMaintenanceSettings();

  if (loading) {
    return null;
  }

  return (
    <ViewModeWrapper>
      <span>Smartbloc</span> {maintenanceSettings?.maintenancePageMessage}
      <Spacer h='30' />
      <Link
        to={{
          pathname: Routes.MAINTENANCE_PAGE_VIEW_MODE,
        }}
        target='_blank'
      >
        <Preview />
      </Link>
    </ViewModeWrapper>
  );
};
