import { Icon } from 'shared';
import { StatusLevel } from 'services/constants';
import { getCssVar, icons } from 'styles/theme';
import { SupportRequestsStatus } from 'services/api';

type IconColoringProps = {
  status: string;
};

export const IconColoring = ({ status }: IconColoringProps) => {
  const statusType = (status: string) => {
    switch (status) {
      case StatusLevel.Open:
      case SupportRequestsStatus.WITH_CUSTOMER:
        return getCssVar('status.warning.bg');
      case StatusLevel.Resolved:
        return getCssVar('status.success.bg');
      default:
        return getCssVar('status.closed.bg');
    }
  };

  return (
    <Icon
      component={icons.fiber_manual_record}
      color={statusType(status)}
      dimension='9px'
      margin='0 8px 2px 0'
    />
  );
};
