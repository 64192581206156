import moment from 'moment';
import { formatData } from 'pages/ThreatDetection/helpers';
import { IEvents } from 'pages/ThreatDetection/types';
import { formatDate, numberFormatter } from 'services/helpers';
import { IDate } from 'services/interfaces';
import { totalTooltipFormatter } from 'shared/charts/formatters';
import { IEChartsOption, LabelFormatterParams } from 'shared/charts/interfaces';
import { RadioButtonsDays } from 'shared/RadioButtonsWithRangePicker/constants';
import { Theme } from 'styles/theme/types';

const rightGridForMobile = 38;
const rightGridForDesktop = 18;
const defaultGridHeight = 355;

export const getOption = (
  params: { values: IEvents[]; date?: IDate },
  theme: Theme,
  isMobile?: boolean,
  gridHeight?: number,
  hasClickHandler?: boolean,
): IEChartsOption => {
  const date: IDate = params.date
    ? params.date
    : {
        startDate: moment().subtract(RadioButtonsDays.OneMonth, 'days').startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf(),
      };
  const entries = formatData(params.values, date);
  const markPointLableMaxLength = 5;
  const isEmpty =
    entries?.length <= 1 && entries[0]?.count === 0 && entries[0]?.collectedDateTime === 0;

  return {
    grid: {
      top: 40,
      bottom: 10,
      right: !isMobile ? rightGridForDesktop : rightGridForMobile,
      left: 55,
      height: gridHeight || defaultGridHeight,
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, '10%'],
      data: isEmpty
        ? []
        : entries.map((events: IEvents) => formatDate(`${events.collectedDateTime}`, date)),
      axisLabel: {
        color: theme.colorSet.charts.axisLabel,
        fontFamily: theme.colorSet.lessFontFamily,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        fontFamily: theme.colorSet.lessFontFamily,
        formatter: (value: number) => numberFormatter(value),
        color: theme.colorSet.charts.axisLabel,
      },
      min: 0,
    },
    tooltip: {
      trigger: 'axis',
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
        fontFamily: theme.colorSet.lessFontFamily,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
      formatter: (params: Record<string, number>[]) => {
        return totalTooltipFormatter(entries[params[0].dataIndex], date);
      },
    },
    series: [
      {
        data: entries.map((events: IEvents) => events.count),
        type: 'line',
        color: theme.colorSet.charts.lineColor,
        name: 'Total',
        markPoint: {
          data: [
            { type: 'max', name: 'Max' },
            { type: 'min', name: 'Min' },
          ],
          label: {
            textStyle: {
              fontFamily: theme.colorSet.lessFontFamily,
            },
            show: true,
            formatter: (_params: LabelFormatterParams) => {
              const value = numberFormatter(_params.data.value);

              if (`${value}`.length >= markPointLableMaxLength) {
                return `${value}`.split('K').join('\nK');
              }

              return value;
            },
          },
        },
        markLine: isEmpty
          ? {}
          : {
              data: [
                {
                  type: 'average',
                  name: 'Average',
                },
              ],
              label: {
                show: false,
              },
              silent: true,
              symbol: 'none',
            },
        symbol: 'emptyCircle',
        areaStyle: { opacity: 0.1 },
        showSymbol: !isEmpty,
        symbolSize: 6,
        itemStyle: { borderWidth: 2 },
        cursor: hasClickHandler ? 'pointer' : 'auto',
        silent: !hasClickHandler,
      },
    ],
  };
};
