import { baseNorm } from './base';

export const severity = {
  critical: baseNorm.deepRed,
  high: baseNorm.red,
  meduim: baseNorm.amber,
  low: baseNorm.green,
  informational: baseNorm.grey,
  analysis: baseNorm.grey,
};
