import { Descriptions as AntdDescriptions } from 'antd';
import { Card } from 'shared';
import { CardFooter } from 'shared/Card/components/styled';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

export const Descriptions = styled(AntdDescriptions)`
  padding-top: 16px;

  .ant-descriptions-row {
    .ant-descriptions-item {
      padding: 0 0 29px 0;

      .ant-descriptions-item-label {
        width: 120px;
        font-weight: ${getCssVar('labelFontWeight')};
        color: ${getCssVar('textColor')};

        &:after {
          display: none;
        }
      }
    }

    &:last-child {
      .ant-descriptions-item {
        padding-bottom: 16px;
      }
    }
  }
`;

export const CardStyled = styled(Card)`
  ${CardFooter} {
    @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
      padding-top: ${({ theme }: { theme: Theme }) => theme.sizes[1]};
      margin-bottom: ${({ theme }: { theme: Theme }) => theme.sizes[1]};
    }
  }
`;
