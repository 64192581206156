import moment from 'moment';
import { Filter, PhishingLastThirtyDaysResponse } from 'services/api';
import { initialPagination } from 'app-constants';
import { initialOrderBy } from 'pages/PeoplePhishing/constants';
import { getPhishingActivityFilterValue } from '../PhishingByMonth/helpers';

const getPhishingDateFilterValue = () => {
  const THIRTY_DAYS = 30;
  const HOURS_IN_DAY = 24;
  const MINUTES_IN_HOUR = 60;
  const SECONDS_IN_MINUTE = 60;
  const MILLISECONDS = 1000;
  const THIRTY_DAYS_TIME =
    THIRTY_DAYS * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS;
  const endDate = moment().valueOf();
  const startDate = endDate - THIRTY_DAYS_TIME;

  return [`${startDate}`, `${endDate}`];
};

export const getRedirectParams = (name: string) => ({
  orderBy: initialOrderBy,
  pagination: initialPagination,
  filter: {
    fields: [
      {
        name: 'phishingActivity',
        value: [getPhishingActivityFilterValue(name)],
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'phishingDate',
        value: getPhishingDateFilterValue(),
        type: Filter.type.RANGE,
      },
    ],
  },
});

export const getTotalClickRedirectParams = () => ({
  orderBy: initialOrderBy,
  pagination: initialPagination,
  filter: {
    fields: [
      {
        name: 'phishingDate',
        value: getPhishingDateFilterValue(),
        type: Filter.type.RANGE,
      },
    ],
  },
});

export const getChartValues = (phishingLastThirtyDays: PhishingLastThirtyDaysResponse) => {
  if (phishingLastThirtyDays.details?.providedCredentials !== undefined) {
    const { providedCredentials, ...otherValues } = phishingLastThirtyDays.details;

    return {
      ...otherValues,
      providedCredentialsOrDownloadedAnAttachment: providedCredentials,
    };
  }

  return phishingLastThirtyDays.details || {};
};
