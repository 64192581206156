import styled from 'styled-components';
import { Drawer } from 'antd';
import { getCssVar } from 'styles/theme';

export const FiltersDrawer = styled(Drawer)`
  .ant-drawer-content {
    background-color: ${getCssVar('table.filters.mobile.bg')};

    .ant-drawer-header {
      padding: 16px;
      background-color: ${getCssVar('table.filters.mobile.bg')};
      border: none;

      .ant-drawer-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .ant-drawer-body {
      padding: 16px;

      > div {
        padding: 0;
      }

      .ant-row {
        background-color: ${getCssVar('table.filters.mobile.bg')};

        .ant-btn-link {
          color: ${getCssVar('table.filters.mobile.resetIconColor')};
        }

        label {
          color: ${getCssVar('table.filters.mobile.color')};
          font-weight: ${getCssVar('labelFontWeight')};
        }
      }
    }
  }
`;
