import { useTheme } from 'styled-components';
import { Tooltip } from 'antd';
import { icons } from 'styles/theme';
import { useApp, useExcludedVulnerabilitiesStatusFilter } from 'store';
import { HiddenFilterIcon } from 'shared/styled';

export const ExcludeFilterTooltip = () => {
  const { isMobile } = useApp();
  const theme = useTheme();
  const vulnerabilitiesFilterbyStatus = useExcludedVulnerabilitiesStatusFilter() as string[];

  if (vulnerabilitiesFilterbyStatus.includes('suppressed')) {
    return (
      <Tooltip
        title='You currently have a filter enabled, this chart does not represent the whole estate'
        trigger={isMobile ? 'click' : 'hover'}
        placement={isMobile ? 'top' : 'right'}
      >
        <HiddenFilterIcon
          component={icons.hidden_filter}
          style={{ verticalAlign: 'middle', fontSize: theme.sizes[6] }}
        />
      </Tooltip>
    );
  }

  return null;
};
