import styled from 'styled-components';
import { Descriptions as AntdDescriptions, Row as AntRow } from 'antd';
import { Theme } from 'styles/theme/types';
import { Box, Card } from 'shared';
import { CardFooter } from 'shared/Card/components/styled';
import { Link } from 'react-router-dom';
import { breakpoints, getCssVar } from 'styles/theme';

type Props = { theme: Theme; withoutPaddingBottom?: boolean };

export const Row = styled(AntRow)<Props>`
  row-gap: ${({ theme }: Props) => theme.spacing[8]} !important;
  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    row-gap: ${({ theme }: Props) => theme.spacing[4]} !important;
  }
`;

export const CardStyled = styled(Card)<Props>`
  ${CardFooter} {
    @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
      padding-top: ${({ theme }: Props) => theme.sizes[1]};
    }
  }
`;

export const ExpandedRowContainer = styled.div`
  color: ${getCssVar('textColor')};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: Props) => theme.spacing[1]};
  padding-left: ${({ theme }: Props) => theme.spacing[8]};
  width: ${({ theme }: Props) => theme.sizes.full};
`;

export const ExpandedLabel = styled.div`
  font-size: 16px;
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
`;

export const ExpandedValue = styled.span<{ ml?: string }>`
  font-size: 14px;
  font-weight: ${({ theme }: Props) => theme.fontWeights.normal};
  margin-left: ${({ ml }: Props & { ml?: string }) => ml};
  white-space: pre-wrap;
`;

export const Descriptions = styled(AntdDescriptions)<Props>`
  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    padding-top: ${({ theme }: Props) => theme.sizes[4]};
  }

  .ant-descriptions-title {
    color: ${getCssVar('textColor')};
  }

  .ant-descriptions-row {
    .ant-descriptions-item {
      padding: ${({ theme }: Props) => `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[7]}`};

      .ant-descriptions-item-label {
        width: ${({ theme }: Props) => theme.sizes[36]};
        align-items: center;
        font-weight: ${getCssVar('labelFontWeight')};
        color: ${getCssVar('textColor')};

        &:after {
          display: none;
        }
      }

      .anticon {
        color: ${getCssVar('activeColor')};
      }
    }

    &:last-child {
      .ant-descriptions-item {
        padding-bottom: ${({ theme }: Props) => theme.sizes[0]};

        @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
          padding-bottom: ${({ theme }: Props) => theme.sizes[4]};
        }
      }
    }
  }
`;

export const PatchSummaryLink = styled(Link)<Props>`
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

export const DescriptionBox = styled(Box)<Props>`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: ${({ theme, withoutPaddingBottom }: Props) =>
    withoutPaddingBottom
      ? theme.sizes[0]
      : `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[7]}`};

  @media (max-width: ${breakpoints.md}) {
    padding-top: ${({ theme, withoutPaddingBottom }: Props) =>
      withoutPaddingBottom ? theme.sizes[0] : theme.sizes[4]};
    padding-bottom: ${({ theme }: Props) => theme.sizes[4]};
  }
`;

export const Title = styled(Box)<Props>`
  align-items: center;
  color: ${getCssVar('textColor')};
  font-weight: ${getCssVar('labelFontWeight')};
  width: 50%;

  &:after {
    display: none;
  }
`;

export const BoxIcon = styled(Box)<Props>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
`;

export const VulnerabilityTitle = styled(Box)`
  color: ${getCssVar('table.specialColumnTitleColor')};
`;
