export const baseNasstar = {
  green: '#43a047',
  amber: '#FFC000',
  red: '#eb4a70',
  deepRed: '#9c233f',
  grey: '#eeecec',
  blue: '#2a26f9',
  pink: '#e03b92',

  borderColor: '#D3D1CF',
  primaryBg: '#FFFFFF',

  shadow: `0px 4px 12px rgba(0, 0, 0, 0.06)`,
};
