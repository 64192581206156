import { PropsWithChildren } from 'react';
import { Box } from 'shared';
import { TfaStepContent } from './styled';

type Props = {
  title: string | JSX.Element;
  description: string | JSX.Element | string[];
  isLast?: boolean;
};

export const TwoFAStep = ({ title, description, children }: PropsWithChildren<Props>) => {
  return (
    <Box>
      {title}
      {Array.isArray(description) ? (
        description.map((line) => <TfaStepContent key={line}>{line}</TfaStepContent>)
      ) : (
        <TfaStepContent>{description}</TfaStepContent>
      )}
      <Box>{children}</Box>
    </Box>
  );
};
