import { Col } from 'antd';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';
import { Link } from './link';
import { Primary } from './primary';
import { Simple } from './simple';
import { Button as AntdButton, ButtonProps } from './Button';
import { Secondary } from './secondary';
import { Danger } from './danger';

type Props = {
  theme: Theme;
};

export const Button = styled(AntdButton)<Props>`
  align-items: center;
  color: ${getCssVar('primaryColor')};
  display: inline-flex;
  font-size: ${getCssVar('button.fontSize')};
  font-weight: ${getCssVar('button.fontWeight')};
  justify-content: center;
  text-transform: ${getCssVar('button.textTransform')};

  ${(props: ButtonProps) => {
    switch (props.type) {
      case 'primary':
        if (props.danger) {
          return Danger;
        }

        return Primary;
      case 'secondary':
        return Secondary;
      case 'link':
        return Link;
      default:
        return Simple;
    }
  }}

  &.collapse {
    font-weight: ${({ theme }) => theme.fontWeights.light};
  }

  &.ant-btn-link {
    text-transform: none;
  }
`;

export const WrapperCol = styled(Col)`
  a.ant-btn {
    padding-bottom: 0;
  }
`;
