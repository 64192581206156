import styled, { css } from 'styled-components';
import { ThemeProps } from '../../services/interfaces';
import { getCssVar, breakpoints } from '../../styles/theme';

export const PageContainer = styled.div<ThemeProps>`
  background: ${getCssVar('notFoundPage.pageBackground')};
  display: flex;
  height: 100vh;
  justify-content: center;
  min-height: 400px;
  width: 100vw;

  ${({ theme }) =>
    theme.colorSet.notFoundPage.backgroundImage &&
    css`
      background-image: ${({ theme }) => `url(${theme.colorSet.notFoundPage.backgroundImage})`};
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @media (max-width: ${breakpoints.lg}) {
        background-size: cover;
      }
    `}
`;

export const BlockContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 20px;
  position: relative;
  width: 100%;
`;

export const Code404Wrapper = styled.div<ThemeProps>`
  align-items: center;
  backdrop-filter: blur(15px);
  background: ${getCssVar('notFoundPage.background')};
  border: 8px solid ${getCssVar('notFoundPage.borderColor')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 530px;
  padding: 32px 30px 34px;
  width: 100%;

  h2 {
    color: ${getCssVar('notFoundPage.textColor')};
    font-size: 120px;
    font-weight: 900;
    line-height: 120px;
    margin-bottom: 8px;
  }

  p {
    color: ${getCssVar('notFoundPage.textColor')};
    font-size: ${getCssVar('notFoundPage.fontSize')};
    font-weight: ${getCssVar('notFoundPage.fontWeight')};
    line-height: ${getCssVar('notFoundPage.lineHeight')};
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  a {
    color: ${getCssVar('notFoundPage.backLinkColor')};
  }
`;

export const Logo = styled.img`
  bottom: 75px;
  position: absolute;

  @media (max-height: ${({ theme }) => theme.breakpoints.smMax}) {
    bottom: 25px;
  }
`;
