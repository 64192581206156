import { Button, Icon, InnerLayoutProps as InnerLayout } from 'shared';
import { colors, icons } from 'styles/theme';
import { Themes } from 'themes/types';
import { MessageType } from 'store';
import { getSupportEmail, getSupportPhone } from 'themes/helpers';
import { showMessage } from '../../services/helpers';

export type EditMode = 'snackbar' | 'maintenanceMessage';
export const TEXT_AREA_MAX_LENGTH = 200;

export const GUTTER = 32;

export enum TooltipText {
  SnackbarText = 'The notification will be shown for every logged in user',
  MaintenancePageMessage = 'Frequency by which passwords are automatically expired by the system, if not changed by the user.  If the password expires, the user will be required to reset their password before they can login.',
}

export enum LabelTitles {
  SnackbarText = 'Snack bar',
  MaintenancePageMessage = 'Maintenance page message',
}

export enum FormFieldLabels {
  SnackbarText = 'snackbarText',
  MaintenancePageMessage = 'maintenancePageMessage',
}

export const maintenanceSettingsTitle = 'Maintenance settings';

export const toggleNotifyUsersTitle = 'Notify users';

export const getLayoutConfig = (): InnerLayout => ({
  title: maintenanceSettingsTitle,
});

export const icon = (
  <Icon component={icons.info_outline} className='tooltipInfo' color={colors.brown[100]} />
);

export const showSnackBarText = (snackbarViewMode?: string, themeName?: Themes) => {
  return showMessage(
    {
      content: (
        <>
          <span style={{ fontWeight: 800 }}>Smartbloc</span> {snackbarViewMode}
          <br />
          For any urgent issues please contact the support desk on {getSupportPhone(themeName)} or
          drop us an email at{' '}
          <a href={`mailto:${getSupportEmail(themeName)}`} target='_blank' rel='noreferrer'>
            {getSupportEmail(themeName)}
          </a>
          .<br />
          Apologies for any inconvenience.
        </>
      ),
      duration: 8,
      style: { maxWidth: '900px', margin: 'auto', lineHeight: '28px' },
    },
    MessageType.Warning,
  );
};

interface IEditButtonProps {
  showButton: boolean;
  editType: EditMode;
  action: (edit: EditMode) => void;
}

export const EditButton = ({ showButton, action, editType }: IEditButtonProps) => {
  if (!showButton) {
    return null;
  }

  return (
    <Button
      style={{ left: '10px' }}
      type='link'
      icon={<Icon component={icons.edit} />}
      onClick={() => action(editType)}
    >
      Edit
    </Button>
  );
};
