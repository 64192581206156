import { MonthlyReportResponse } from 'services/api';
import moment from 'moment/moment';
import { getCapitalizedString } from 'services/helpers';
import { dateFormat } from 'app-constants';
import { getLabelPriority, getNormalizedStatus } from 'pages/SupportRequests/helpers/helpers';
import { getSortedDashboardScores } from '../../helpers';
import {
  ClosedState,
  CreatedAtCell,
  PriorityCell,
  SecurityIncidenceTable,
  Events,
  Alerts,
  Active,
  Incidents,
  StatusCell,
  SummaryCell,
  LabelsWrapper,
  Label,
  TableWrapper,
  TextWrapper,
  TicketCell,
  UpdatedAtCell,
  TotalScorePieWrapper,
  StatisticsWrapper,
} from './styled';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { CyberResilienceScoreDescriptionScoreMessage, Badge, Page } from '../styled';
import { asidePieSize, asidePieFontSize } from '../config';
import { BackgroundImage } from '../../components/BackgroundImage';

type Props = {
  technologyRank: string;
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
};

const formatNumber = (num = 0) => {
  if (!num) {
    return num;
  }

  const NUMBER_ROUNDING_MILLION = 6;
  const NUMBER_ROUNDING_MILLION_SLICE = -3;
  const NUMBER_BILLION = 1e9;

  const numString = num.toString();
  const regEx = /\B(?=(\d{3})+(?!\d))/g;

  if (+num >= NUMBER_BILLION) {
    return `${(num / NUMBER_BILLION).toFixed(1)}B`;
  }

  if (numString.length > NUMBER_ROUNDING_MILLION) {
    const newNum = numString.slice(0, NUMBER_ROUNDING_MILLION_SLICE).replace(regEx, '.');

    return `${newNum}M`;
  }

  return numString.replace(regEx, ',');
};

export function PdfPage8({ data, onChartLoaded, technologyRank }: Props) {
  const { dashboardScores, alarmsCount, eventsCount, supportRequests } = data;

  const scores = dashboardScores || [];
  const checkingDashboardScores = getSortedDashboardScores([...scores])[scores.length - 1];
  const technologyScore = checkingDashboardScores?.technology;

  const securityIncidentsClosed = supportRequests?.securityIncidents?.closed || 0;
  const securityIncidentsActive = supportRequests?.securityIncidents?.active || 0;
  const securityIncidentsOpened = supportRequests?.securityIncidents?.opened || 0;

  const openedList = data.supportRequests?.securityIncidents?.openedList || [];

  return (
    <Page>
      <BackgroundImage fileName='slide-8.jpg' />

      <TextWrapper>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(technologyRank) }}
        />
        <ul>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
        </ul>
      </TextWrapper>

      <TotalScorePieWrapper>
        <GradientPieReport
          score={technologyScore}
          onFinished={onChartLoaded}
          width={asidePieSize}
          height={asidePieSize}
          fontSize={asidePieFontSize}
        />
      </TotalScorePieWrapper>

      <StatisticsWrapper>
        <div className='statistic-block'>
          <Events>{formatNumber(eventsCount)}</Events>
        </div>
        <div className='statistic-block'>
          <Alerts>{formatNumber(alarmsCount)}</Alerts>
        </div>
        <div className='statistic-block'>
          <Incidents>{formatNumber(securityIncidentsOpened)}</Incidents>
        </div>
        <div className='statistic-block'>
          <Active hasValue={securityIncidentsActive > 0}>
            {formatNumber(securityIncidentsActive)}
          </Active>
        </div>
        <div className='statistic-block'>
          <ClosedState>{formatNumber(securityIncidentsClosed)}</ClosedState>
        </div>

        <LabelsWrapper>
          <Label>Events</Label>
          <Label>Alarms</Label>
          <Label>Incindents</Label>
          <Label>Active</Label>
          <Label>Closed</Label>
        </LabelsWrapper>
      </StatisticsWrapper>

      <TableWrapper>
        <Badge className='title'>Top 5 security incidents</Badge>

        <SecurityIncidenceTable>
          <thead>
            <tr>
              <PriorityCell>Priority</PriorityCell>
              <TicketCell>Ticket #</TicketCell>
              <th>Summary</th>
              <StatusCell>Status</StatusCell>
              <CreatedAtCell className='text-right'>Created date</CreatedAtCell>
              <UpdatedAtCell className='text-right'>Updated date</UpdatedAtCell>
            </tr>
          </thead>
          <tbody>
            {openedList.map(({ id, summary, created, updated, priority, status }) => (
              <tr key={id}>
                <td>{getLabelPriority(priority as string)}</td>
                <td>{id}</td>
                <td>
                  <SummaryCell>
                    <div>{summary}</div>
                  </SummaryCell>
                </td>
                <td>{getCapitalizedString(getNormalizedStatus(status as string))}</td>
                <td className='text-right'>{moment(created).format(dateFormat)}</td>
                <td className='text-right'>{moment(updated).format(dateFormat)}</td>
              </tr>
            ))}
          </tbody>
        </SecurityIncidenceTable>
      </TableWrapper>
    </Page>
  );
}
