import { ChangeEvent, ChangeEventHandler, useCallback } from 'react';
import { isFirefox } from 'react-device-detect';
import { useFormik } from 'formik';
import { Form as AntdForm, Spin } from 'antd';
import { Box, Icon } from 'shared';
import { replaceSpace } from 'services/helpers';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { auth, authApi, useApp, useAuth } from 'store';
import { useTheme } from 'styled-components';
import { InputEmail, InputPassword, StyledButton } from './styled';

const initialValues = {
  email: '',
  password: '',
};

const maxInputLength = 50;

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('Email must be a valid email')
    .required('Email is a required field'),
  password: Yup.string().trim().required('Password is a required field'),
});

export const Form = () => {
  const dispatch = useDispatch();
  const { isPending } = useAuth();
  const theme = useTheme();
  const iconProps = {
    color: theme.colorSet.login.formInput.iconColor,
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      dispatch(auth.actions.setError(''));
      await dispatch(authApi.login(values));
    },
  });

  const { isMobile } = useApp();

  const handleChange: ChangeEventHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value.length <= maxInputLength) {
        formik.handleChange(event);
      }
    },
    [formik],
  );

  return (
    <AntdForm
      layout='vertical'
      onFinish={formik.handleSubmit}
      initialValues={formik.initialValues}
      className='auth-form'
    >
      <InputEmail
        name='email'
        placeholder='Email'
        value={replaceSpace(formik.values.email)}
        onChange={handleChange}
        height={isMobile ? '48px' : undefined}
        addonBefore={<Icon component={theme.icons.person_outline} {...iconProps} />}
        validateStatus={formik.errors.email && formik.touched.email ? 'error' : 'success'}
        help={formik.errors.email && formik.touched.email ? formik.errors.email : undefined}
        onBlur={formik.handleBlur}
      />
      <InputPassword
        type='password'
        name='password'
        placeholder='Password'
        height={isMobile ? '31px' : undefined}
        value={replaceSpace(formik.values.password)}
        onChange={handleChange}
        addonBefore={<Icon component={theme.icons.lock} {...iconProps} />}
        validateStatus={formik.errors.password && formik.touched.password ? 'error' : 'success'}
        help={
          formik.errors.password && formik.touched.password ? formik.errors.password : undefined
        }
        onBlur={formik.handleBlur}
      />
      {isFirefox && isPending && (
        <Box display='flex' justify='center'>
          <Spin />
        </Box>
      )}
      <StyledButton
        type='primary'
        htmlType='submit'
        loading={isPending}
        disabled={isPending}
        block
        $isHidden={isFirefox && isPending}
      >
        Login
      </StyledButton>
    </AntdForm>
  );
};
