import styled, { css } from 'styled-components';

export const CyberResilienceScoreDescription = styled.div`
  left: 446px;
  max-width: 450px;

  position: absolute;
  top: 58px;
`;

export const TotalScorePieWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  right: 24px;
  top: 66px;
`;

export const DescriptionCommon = css`
  max-width: 215px;
  position: absolute;

  top: 361px;
`;

export const CyberResilienceScoreProcessDescription = styled.div`
  ${DescriptionCommon};
  left: 273px;
`;

export const CyberResilienceScorePeopleDescription = styled.div`
  ${DescriptionCommon};
  left: 543px;
`;

export const CyberResilienceScoreTechnologyDescription = styled.div`
  ${DescriptionCommon};
  left: 809px;
`;

export const PieWrapperCommon = css`
  align-items: center;
  background: white;
  border: 1.5px solid black;
  border-radius: 50%;
  display: flex;
  height: 63px;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 260px;
  width: 63px;
`;

export const ProcessPieWrapper = styled.div`
  ${PieWrapperCommon};
  left: 266px;
`;

export const PeoplePieWrapper = styled.div`
  ${PieWrapperCommon};
  left: 536px;
`;

export const TechnologyPieWrapper = styled.div`
  ${PieWrapperCommon};
  right: 190px;
`;

export const CenteredIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
`;
