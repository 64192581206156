import { Alert as AntdAlert } from 'antd';
import styled, { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

export const Alert = styled(AntdAlert)`
  ${(props) =>
    props.type === 'error' &&
    css`
      background-color: ${getCssVar('alert.error.bg')};
      border-color: ${getCssVar('alert.error.borderColor')};

      .ant-alert-message {
        color: ${getCssVar('alert.error.color')};
      }
    `};
  ${(props) =>
    props.banner &&
    css`
      background-color: ${getCssVar('alert.banner.bg')};

      .ant-alert-message {
        color: ${getCssVar('alert.banner.color')};
      }

      .ant-alert-icon {
        color: ${getCssVar('alert.banner.iconColor')};
        height: auto;
        width: auto;
      }

      .ant-alert-close-text {
        color: ${getCssVar('alert.banner.color')};
        font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
        opacity: 0.5;
        transition: opacity 0.3s;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      .ant-btn-link {
        color: ${getCssVar('alert.banner.linkColor')};

        &:not(:disabled) {
          &:hover,
          &:focus {
            color: ${getCssVar('alert.banner.linkColor')};
          }
        }
      }
    `};
`;
