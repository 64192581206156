import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout as AntdLayout } from 'antd';
import { Box, Icon } from 'shared';
import { app, useApp, useAuth } from 'store';
import { useScreenSizes } from 'services/hooks/useScreenSizes';
import { Routes, TwoFASteps } from 'services/entities';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { getLoginRightBackground } from 'themes/helpers';
import { Themes } from 'themes/types';
import { AltLink } from './types';
import {
  Content,
  Header,
  Layout,
  LogoIcon,
  NasstarFooter,
  Sider,
  Link,
  FormWrap,
  FormTitle,
  FormSubTitle,
} from './components';
import { Smartbloc } from './components/Smartbloc';

export type OuterLayoutProps = {
  title?: string | JSX.Element;
  subTitle?: string | React.ReactNode;
  altLink?: AltLink;
  status?: React.ReactNode;
  formClassName?: string;
};

export const OuterLayout = ({
  title,
  subTitle,
  altLink,
  status,
  children,
  formClassName,
}: PropsWithChildren<OuterLayoutProps>) => {
  const history = useLocation();

  const dispatch = useDispatch();

  const theme = useTheme();
  const { themeName } = useApp();
  const isNasstarTheme = themeName === Themes.Nasstar;

  const {
    twofa: { step },
  } = useAuth();
  const [isDesktop, isTablet, isMobile, isLargeDesktop] = useScreenSizes();

  const siderWidth = isDesktop ? theme.sizes['2xl'] : theme.sizes.full;
  const isLoginFormStep = history.pathname === Routes.ROOT && step === TwoFASteps.INITIAL;

  React.useEffect(() => {
    if (
      isDesktop !== undefined &&
      isTablet !== undefined &&
      isMobile !== undefined &&
      isLargeDesktop !== undefined
    ) {
      dispatch(app.actions.setScreenSizes({ isLargeDesktop, isDesktop, isTablet, isMobile }));
    }
  }, [isLargeDesktop, isDesktop, isTablet, isMobile, dispatch]);

  return (
    <Layout className={themeName}>
      <Sider className={themeName} $isDesktop={isDesktop}>
        <Header>
          <LogoIcon component={theme.icons.normLogoNew} />
        </Header>
        <Content align='middle'>
          {isLoginFormStep && <Smartbloc />}
          <FormWrap className={formClassName}>
            {status && <Box>{status}</Box>}
            {title && <FormTitle level={3}>{title}</FormTitle>}
            {subTitle && <FormSubTitle>{subTitle}</FormSubTitle>}
            <Box mt={theme.spacing[5]}>{children}</Box>
            {altLink && (
              <Box textAlign='center' pt={theme.spacing[4]}>
                <Link to={altLink.to}>{altLink.title}</Link>
              </Box>
            )}
          </FormWrap>
        </Content>
        {isNasstarTheme && (
          <NasstarFooter>
            <Icon component={theme.icons.poweredByNew} />
          </NasstarFooter>
        )}
      </Sider>
      <AntdLayout.Content>
        {getLoginRightBackground({
          themeName,
          isMobile,
          width: isDesktop ? `calc(${theme.sizes.full} - ${siderWidth})` : theme.sizes.full,
        })}
      </AntdLayout.Content>
    </Layout>
  );
};
