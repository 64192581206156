import { useMemo } from 'react';
import { MonthlyReportResponse } from 'services/api';
import { IDate } from 'services/interfaces';
import { subTitleFormatterSum } from 'pages/PatchManagement/helpers';
import { IEvents } from 'pages/ThreatDetection/types';
import {
  ChartTitle,
  CurrentLimitHeaderCell,
  EventsChart,
  LicenseUsageTable,
  LicenseUsageTableWrapper,
  ServiceConsumption,
  ServiceHeaderCell,
  SubTitle,
  UsedHeaderCell,
  InfoContainer,
  LicenseUsageTitle,
  ChartTitleWrapper,
  ChartWrapper,
} from './styled';
import { AreaReportChart } from '../../components/Charts/AreaReportChart';
import { BackgroundImage } from '../../components/BackgroundImage';
import { Page } from '../styled';

export type RowTitle = Record<string, string>;

const tableScoreRowsTitle: RowTitle = {
  vulnerabilityAgents: 'Vulnerability Agents',
  threatDetectionServices: 'Threat Detection (Services)',
  cyberSafetyAndPhishing: 'Cyber Safety and Phishing',
  threatDetectionAgents: 'Threat Detection Agents',
};

type Props = {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  reportDate: IDate;
};

export function PdfPage9({
  data: { licenseUsage, eventsChart },
  onChartLoaded,
  reportDate,
}: Props) {
  const eventsData = eventsChart?.allEvents || [];
  const isUp = Number(eventsChart?.trendingPercent) > 0;
  const totalEventsCount = subTitleFormatterSum([eventsChart?.allEventsCount || 0], 1);

  const chartSubTitle = `${isUp ? '+' : ''} ${
    eventsChart?.trendingPercent || 0
  }% vs last period ${totalEventsCount}`;

  const tableData = useMemo(
    () =>
      Object.entries(licenseUsage || {}).map(([key, value]) => ({
        currentLimit: value?.currentLimit || 0,
        used: value?.used || 0,
        service: tableScoreRowsTitle[key],
      })),
    [licenseUsage],
  );

  return (
    <Page>
      <BackgroundImage fileName='slide-9.jpg' useFilter={false} />

      <ServiceConsumption>
        <ul>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
        </ul>
      </ServiceConsumption>

      <InfoContainer>
        <ChartWrapper className='chart-wrapper'>
          <ChartTitleWrapper>
            <ChartTitle>All events</ChartTitle>
            <SubTitle>{chartSubTitle}</SubTitle>
          </ChartTitleWrapper>

          <EventsChart className='events-chart'>
            <AreaReportChart
              values={{ values: eventsData as IEvents[], date: reportDate }}
              onChartLoaded={onChartLoaded}
              width='100%'
              height='260px'
            />
          </EventsChart>
        </ChartWrapper>

        <LicenseUsageTableWrapper>
          <LicenseUsageTitle>Licence usage</LicenseUsageTitle>

          <LicenseUsageTable>
            <thead>
              <tr>
                <ServiceHeaderCell>Service</ServiceHeaderCell>
                <UsedHeaderCell>Used</UsedHeaderCell>
                <CurrentLimitHeaderCell>Current Limit</CurrentLimitHeaderCell>
              </tr>
            </thead>
            <tbody>
              {tableData.map(({ service, used, currentLimit }) => (
                <tr key={service}>
                  <td>{service}</td>
                  <td
                    style={
                      {
                        // color: (currentLimit as number) <= used ? 'rgb(235, 100, 74)' : '#9ccc65',
                        // fontWeight: 'bold',
                      }
                    }
                  >
                    {used}
                  </td>
                  <td>{currentLimit}</td>
                </tr>
              ))}
            </tbody>
          </LicenseUsageTable>
        </LicenseUsageTableWrapper>
      </InfoContainer>
    </Page>
  );
}
