export const baseDarkNorm = {
  green: '#4EA72E', // 78, 167, 46
  amber: '#FFC000', // 255, 192, 0
  red: '#FF7F5C', // 255, 127, 92
  deepRed: '#B30000', // 179, 0, 0
  black: '#231f20', // 35, 31, 32
  grey: '#A6A6A6', // 166, 166, 166
  blue: '#0049ff', // 0, 73, 255

  blueHover: '#2A26F9',
  blueActive: '#2C25DD',

  deepRedHover: '#872424',
  deepRedActive: '#721C24',

  lightBlue: '#7285FF',
  spanishGray: '#929699',

  bodyBg: '#231f20',
  primaryBg: '#2e2a2b',
  secondaryBg: '#383637',

  borderColor: '#4F4D4D',
  divider: '#383637',
  shadow: `0px 4px 12px rgba(0, 0, 0, 0.06)`,
};
