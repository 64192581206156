import React from 'react';
import { isFirefox, isIOS } from 'react-device-detect';
import { InputProps } from 'antd';
import { TextAreaProps } from 'rc-textarea';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { Icon } from 'shared';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useTheme } from 'styled-components';
import { icons } from '../../../styles/theme';
import {
  FormItemStyled,
  Input as StyledInput,
  InputPassword,
  InputTextarea,
} from './components/styled';
import { PasswordStrengthBar } from './components/PasswordStrengthBar';

const DEFAULT_MIN_PASSWORD_LENGHT = 6;

export type CustomInputProps = InputProps &
  TextAreaProps & {
    label?: string | JSX.Element;
    help?: React.ReactNode;
    validateStatus?: ValidateStatus;
    isFeedbackCentered?: boolean;
    onReset?: () => void | React.FormEventHandler<HTMLInputElement>;
    noSpace?: boolean;
    width?: string;
    minLength?: number;
    height?: string;
    required?: boolean;
  };

export const Input = ({
  label,
  type,
  help,
  validateStatus,
  isFeedbackCentered,
  noSpace,
  width,
  height,
  minLength,
  required,
  ...inputProps
}: CustomInputProps) => {
  const theme = useTheme();
  const iconProps = {
    dimension: theme.sizes[6],
    color: theme.colorSet.inputs.prefixIconColor,
  };

  const inputPasswordProps = {
    ...inputProps,
    size: 'large' as SizeType,
    iconRender: (visible: boolean) =>
      visible ? (
        <Icon component={theme.icons.visibility_off} {...iconProps} />
      ) : (
        <Icon component={theme.icons.visibility} {...iconProps} />
      ),
  };

  const inputTextareaProps = {
    ...inputProps,
  };

  const renderInput = (inputType: string | undefined) => {
    switch (inputType) {
      case 'password':
        return (
          <InputPassword
            {...inputPasswordProps}
            $isFirefox={isFirefox}
            $isIOS={isIOS}
            $value={inputPasswordProps.value}
          />
        );
      case 'password-check':
        return (
          <>
            <InputPassword
              {...inputPasswordProps}
              $isFirefox={isFirefox}
              $isIOS={isIOS}
              $value={inputPasswordProps.value}
            />
            <PasswordStrengthBar
              value={inputProps.value as string}
              minPasswordLength={minLength || DEFAULT_MIN_PASSWORD_LENGHT}
            />
          </>
        );
      case 'textarea':
        return <InputTextarea {...inputTextareaProps} />;
      case 'search':
        return (
          <StyledInput
            type='search'
            prefix={<Icon component={icons.search} />}
            allowClear
            {...inputProps}
          />
        );
      default:
        return <StyledInput {...inputProps} />;
    }
  };

  return (
    <FormItemStyled
      labelColor={theme.colorSet.inputs.label.textColor}
      label={label}
      help={help}
      isFeedbackCentered={isFeedbackCentered}
      validateStatus={validateStatus}
      style={{ marginBottom: noSpace ? 0 : theme.spacing[5] }}
      width={width}
      height={height}
      required={required}
    >
      {renderInput(type)}
    </FormItemStyled>
  );
};
