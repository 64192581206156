import { colors } from '../colors';
import { fontWeights } from '../fontWeights';
import { mainFontFamily } from '../Nasstar/fontsNasstar';
import { baseDarkNorm } from './base';

export const process = {
  labelWeight: fontWeights.bold,
  labelInputsWeight: fontWeights.medium,
  labelFontFamily: mainFontFamily,
  success: baseDarkNorm.green,
  expired: baseDarkNorm.deepRed,
  inProgress: baseDarkNorm.red,
  soonExpired: '#f8d1c8',
  labelUppercase: 'none',
  hoverBg: baseDarkNorm.black,
  dateColor: '#808080',
  helpMessageBg: '#5B5959',
  helpMessageColor: colors.black,
  certificate: {
    statusLabelColor: baseDarkNorm.lightBlue,
  },
};
