import styled from 'styled-components';
import { LogoPlaceholder as StyledLogoPlaceholder } from '../styled';

export const LogoPlaceholder = styled(StyledLogoPlaceholder)`
  bottom: 246px;
  color: black;
  font-size: 28px;
  left: 50%;

  transform: translateX(-50%);
`;
