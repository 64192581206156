import { InnerTabsWrapper, InnerTabsStyled } from './styled';
import { TabsProps } from './types';

const DEFAULT_HEIGHT = '44px';

export const InnerTabs = ({
  children,
  tabHeight = DEFAULT_HEIGHT,
  tabMargin,
  ...props
}: TabsProps) => {
  return (
    <InnerTabsWrapper tabHeight={tabHeight} tabMargin={tabMargin} className='custom-tabs-wrapper'>
      <InnerTabsStyled className='custom-tabs-inner' {...props}>
        {children}
      </InnerTabsStyled>
    </InnerTabsWrapper>
  );
};
