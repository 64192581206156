import { RestorePasswordSteps, Routes } from 'services/entities';
import { OuterLayoutProps, Icon, Box } from 'shared';
import { Theme } from 'styles/theme/types';
import { AltLink } from 'shared/layouts/Outer/types';

export const getLayoutProps = (
  step: number,
  email: string,
  { icons, sizes, spacing, colorSet }: Theme,
): OuterLayoutProps => {
  const altLink: AltLink = {
    to: Routes.ROOT,
    title: (
      <Box d='flex' align='center' justify='center'>
        <Icon component={icons.arrow_left_alt} />
        <Box ml={spacing[1]}>Return to login</Box>
      </Box>
    ),
  };

  switch (step) {
    case RestorePasswordSteps.INSTRUCTIONS_SENT:
      return {
        title: 'Check your email',
        altLink,
        status: <Icon component={icons.done} color={colorSet.successColor} dimension={sizes[20]} />,
        subTitle: (
          <>
            We’ve sent an email to <strong>{email}</strong> with password reset instructions.
          </>
        ),
        formClassName: 'step-email-sent',
      };
    case RestorePasswordSteps.RESET:
      return {
        title: 'Reset password',
      };
    default:
      return {
        title: 'Forgot your password?',
        subTitle: 'Enter your email and we will send you instructions to reset your password.',
        altLink,
      };
  }
};
