import React from 'react';
import { OrderBy } from 'services/api';
import { getCapitalizedString } from 'services/helpers';
import { Icon, noSortingField } from 'shared';
import { OptionType } from 'shared/Table/types';
import { getCssVar, icons } from 'styles/theme';
import { OrderArrows, Popup, PopupItem, StyledButton, Title } from './styled';

export interface SortPopupProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  fields: OptionType[];
  setOrderBy: (orderBy: OrderBy) => void;
  defaultSort: OrderBy;
  selectedSortField: string;
  setSelectedSortField: (sortField: string) => void;
  orderBy?: OrderBy;
}

export const SortPopup = ({
  isVisible,
  setIsVisible,
  fields,
  setOrderBy,
  defaultSort,
  selectedSortField,
  setSelectedSortField,
  orderBy,
}: SortPopupProps) => {
  const [orderField, setOrderField] = React.useState(orderBy?.field || noSortingField);
  const [orderType, setOrderType] = React.useState<OrderBy.type>(orderBy?.type || OrderBy.type.ASC);

  const onCloseSortPopup = React.useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const handleClose = React.useCallback(() => {
    setOrderField(selectedSortField);
    setOrderType(orderBy?.type || OrderBy.type.ASC);
    onCloseSortPopup();
  }, [selectedSortField, orderBy, onCloseSortPopup]);

  const getArrowColor = (type: OrderBy.type) => {
    let arrowColor = getCssVar('inactiveColor');

    if (orderType === type) {
      arrowColor = getCssVar('table.th.iconColor');
    }

    return arrowColor;
  };

  const handleItemClick = React.useCallback(
    (value: string) => {
      if (value) {
        setOrderField(value);
      }

      if (value !== orderField) {
        setOrderType(orderType);

        return null;
      }

      return setOrderType(orderType === OrderBy.type.ASC ? OrderBy.type.DESC : OrderBy.type.ASC);
    },
    [orderType, orderField],
  );

  const handleNoSortingClick = React.useCallback(() => {
    setOrderField(noSortingField);
    setSelectedSortField(noSortingField);
    setOrderBy({
      field: defaultSort.field,
      type: defaultSort.type || OrderBy.type.ASC,
    });
    onCloseSortPopup();
  }, [setSelectedSortField, setOrderBy, defaultSort, onCloseSortPopup]);

  const handleApplyClick = () => {
    setSelectedSortField(orderField);
    setOrderBy({
      field: orderField,
      type: orderType,
    });
    onCloseSortPopup();
  };

  return (
    <Popup
      className='sort-modal'
      title={
        <Title noSorting={orderField === noSortingField}>
          <span>Sort by</span>
          {orderField !== noSortingField && (
            <StyledButton type='primary' onClick={handleApplyClick}>
              Apply
            </StyledButton>
          )}
        </Title>
      }
      visible={isVisible}
      width={240}
      footer={null}
      onCancel={handleClose}
      closable={false}
    >
      <PopupItem
        key='noSorting'
        active={orderField === noSortingField}
        onClick={handleNoSortingClick}
      >
        No sorting
      </PopupItem>
      {fields.map((field: OptionType) => {
        return (
          <PopupItem
            key={field.value}
            active={orderField === field.value}
            onClick={() => handleItemClick(field.value)}
          >
            {typeof field.label === 'string' ? field.label : getCapitalizedString(field.value)}
            {field.value === orderField && (
              <OrderArrows>
                <Icon component={icons.order_arrow} color={getArrowColor(OrderBy.type.ASC)} />
                <Icon component={icons.order_arrow} color={getArrowColor(OrderBy.type.DESC)} />
              </OrderArrows>
            )}
          </PopupItem>
        );
      })}
    </Popup>
  );
};
