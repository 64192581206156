import React from 'react';
import { ActionPopup, Box, Icon, Button } from 'shared';
import { AppDispatch, useCustomer, useNewsNotifications } from 'store';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NotificationStatus } from 'services/api';
import { Routes } from 'services/entities';
import { getCssVar, icons } from 'styles/theme';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { Spacer } from 'shared/TableFilters/styled';
import { showPopup } from '../../Notifications/helpers';
import { popupConfig } from '../../Notifications/constant';

export const AdminButtons = () => {
  const history = useHistory();
  const customerId = useCustomer();
  const dispatch = useDispatch<AppDispatch>();
  const { notificationDetails } = useNewsNotifications();
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const isDraft = notificationDetails.status === NotificationStatus.DRAFTS;

  const changeStatusClickHandler = () => {
    showPopup({
      popupId: isDraft ? popupConfig.publish.id : popupConfig.unPublish.id,
      setPopupDetails,
      setIsVisiblePopup,
      notificationDetails,
      dispatch,
      customerId,
      onSuccess: () => {},
      history,
    });
  };

  const deleteClickHandler = () => {
    showPopup({
      popupId: popupConfig.delete.id,
      setPopupDetails,
      setIsVisiblePopup,
      notificationDetails,
      dispatch,
      customerId,
      onSuccess: () => history.push(Routes.NOTIFICATIONS),
      history,
    });
  };

  return (
    <>
      <Box display='flex' flexDirection='row'>
        <Button
          type='link'
          icon={<Icon component={isDraft ? icons.file_upload : icons.uploadNotAvailable} />}
          onClick={() => changeStatusClickHandler()}
        >
          {isDraft ? 'Publish' : 'Unpublish'}
        </Button>

        <Spacer w='10px' />

        <Button
          type='link'
          icon={<Icon component={icons.delete} />}
          onClick={() => deleteClickHandler()}
          color={getCssVar('errorSecondaryColor')}
        >
          Delete
        </Button>
      </Box>
      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
        />
      )}
    </>
  );
};
