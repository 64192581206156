import { CertificateSchema } from 'services/api';
import { PermissionsLevel } from 'app-constants';
import { ProcessItem } from './ProcessItem';
import { staticCertificates } from '../../../../constants';

type ProcessProps = {
  certificates?: Array<CertificateSchema>;
  permission: string;
};

export const ProcessList = ({ certificates = [], permission }: ProcessProps) => {
  const items = permission === PermissionsLevel.NoAccess ? staticCertificates : certificates;

  const shouldRender = permission !== PermissionsLevel.NoAccess && certificates.length > 0;

  return (
    <>
      {shouldRender &&
        items?.map((certificate: CertificateSchema) => {
          return (
            <ProcessItem
              {...certificate}
              key={certificate.title}
              isLocked={permission === PermissionsLevel.NoAccess}
            />
          );
        })}
    </>
  );
};
