import styled from 'styled-components';
import { ActionsWrapper, GoalsWrapper } from '../styled';

export const NewGoals = styled(GoalsWrapper)``;

export const NewActions = styled(ActionsWrapper)`
  color: white;

  left: 678px;
  max-width: 300px;
  position: absolute;
  top: 260px;

  ul > li {
    margin-bottom: 15px;
  }
`;
