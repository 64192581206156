import styled from 'styled-components';
import { AsideDescription } from '../styled';

export const CyberResilienceScoreText = styled(AsideDescription)`
  left: 81px;
  max-width: 320px;
  top: 210px;
`;

export const ScoreWrapper = styled.div`
  position: absolute;
  right: 121px;
  top: 100px;
`;

export const ScoreDifferenceMonth = styled.div``;

export const ScoreDifferenceWrapper = styled.div`
  font-size: 16px;
  position: absolute;
  right: 156px;
  text-align: center !important;
  top: 328px;
  width: 309px;
  z-index: 1001;
`;

export const ScoreDifferenceQuarter = styled.div``;

export const ArrowIndicator = styled.div`
  font-size: 22px;
  position: absolute;
  right: 245px;
  top: 250px;
  z-index: 1001;
`;

export const TotalWrapper = styled.div`
  left: 450px;
  padding-left: 50px;
  padding-right: 50px;
  position: absolute;
  top: 485px;
  width: 595px;

  table {
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
  }
`;
