import React from 'react';
import { useApp, useCEPlus, useCustomerChange, useLoading } from 'store';
import { Card, ScrollContainer, ShowMoreButton } from 'shared';
import { BarChart } from 'shared/charts';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { LinkContainer } from 'shared/Link/LinkContainer';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { Routes } from 'services/entities';
import { numberFormatter } from 'services/helpers';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { getQueryString } from 'services/api/core/request';
import { cyberEssentialPlusApi } from 'store/cyberEssentialPlus/thunks';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import {
  cePlusBackRoute,
  ITEM_CHART_HEIGHT,
  MAX_EVENTS_COUNT,
  MIN_CHART_HEIGHT,
  tooltipTitles,
} from '../../constants';
import { ChartContainer, HardwareWrapper } from '../../styled';
import { getTopHardwareParams, viewMoreConfig } from '../../helpers';
import { getOption } from '../../barChartHelper';

export const Top5ServerHardware = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile } = useApp();
  const loading = useLoading([cyberEssentialPlusApi.getTopServerHardware.typePrefix]);
  const { topServerHardware } = useCEPlus();
  const { selectedTags } = useCEPlus();

  const colors = [
    theme.colorSet.baseColors.red,
    theme.colorSet.baseColors.blue,
    theme.colorSet.baseColors.grey,
    theme.colorSet.baseColors.amber,
    theme.colorSet.baseColors.green,
  ];

  const barChartValue = topServerHardware?.chart || [];

  const customerChange = useCustomerChange([cyberEssentialPlusApi.getTopServerHardware.typePrefix]);
  const isShowEmptyChart = customerChange[cyberEssentialPlusApi.getTopServerHardware.typePrefix];

  const isScrollable = barChartValue.length > MAX_EVENTS_COUNT;

  const heightOfChart = !isScrollable
    ? MIN_CHART_HEIGHT
    : MIN_CHART_HEIGHT + ITEM_CHART_HEIGHT * (barChartValue.length - MAX_EVENTS_COUNT);

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => {
        if (!params.name) {
          return null;
        }

        const getParamByName = topServerHardware?.chart?.find((data) => data.title === params.name);

        return history.push(
          `${Routes.CMDB}${getQueryString(
            getTopHardwareParams(
              getParamByName?.manufacturer,
              getParamByName?.product,
              true,
              selectedTags,
            ),
          )}`,
          cePlusBackRoute,
        );
      },
    }),
    [history, selectedTags, topServerHardware?.chart],
  );

  return (
    <Card
      title='Top 5 server hardware'
      tooltipTitle={tooltipTitles.topServerHardware}
      subTitle={
        <SubTitle boldText={numberFormatter(topServerHardware?.count || 0, 1)} customText='Total' />
      }
      showDivider={false}
      height={theme.sizes.full}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getTopServerHardware.typePrefix]}
    >
      <HardwareWrapper>
        <ScrollContainer width='600px'>
          <ChartContainer isScrollable={isScrollable}>
            <TotalCustomTooltipContainer>
              <RenderChartWrapper
                values={barChartValue}
                emptyText='No top server hardware yet'
                isShowEmptyChart={isShowEmptyChart}
              >
                <BarChart
                  values={barChartValue}
                  isMobile={isMobile}
                  getOption={getOption}
                  onEvents={onEvents}
                  height={heightOfChart}
                  color={colors}
                  isLoading={loading[cyberEssentialPlusApi.getTopServerHardware.typePrefix]}
                  isAsyncAnimation
                />
              </RenderChartWrapper>
            </TotalCustomTooltipContainer>
          </ChartContainer>
        </ScrollContainer>

        <LinkContainer
          borderTop={!isMobile ? '' : `${theme.sizes.px} solid ${theme.colors.blue[50]}`}
          hasBorder
        >
          <ShowMoreButton
            to={{
              pathname: viewMoreConfig().CMDBServer.route,
              search: viewMoreConfig().CMDBServer.search,
              state: cePlusBackRoute,
            }}
          >
            See all sever hardware
          </ShowMoreButton>
        </LinkContainer>
      </HardwareWrapper>
    </Card>
  );
};
