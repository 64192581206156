import { Input as AntdInput } from 'antd';
import styled, { css } from 'styled-components';
import { encryptedPasswordFireFoxFontFamily } from 'styles/theme/Norm/fontsNorm';
import { CommonProps, Password, StrengthBarCellProps, TextInputProps } from '../types';
import { FormItem } from './FormItem';
import { getCssVar } from '../../../../styles/theme';

export const StrengthBarCell = styled.div<StrengthBarCellProps>`
  background-color: ${(props) => props.bgColor};
  border-radius: ${({ theme }: StrengthBarCellProps) => theme.radius['2sm']};
  height: ${({ theme }: StrengthBarCellProps) => theme.sizes[2]};
`;

export const StrengthBar = styled.div`
  column-gap: ${({ theme }: CommonProps) => theme.spacing[2]};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: ${({ theme }: CommonProps) => theme.spacing[1]};
`;

export const Input = styled(AntdInput)`
  ${(props) =>
    props.type === 'search' &&
    css`
      .ant-input-suffix .anticon {
        font-size: 16px;
        right: 18px;
      }
    `};
`;

export const InputPassword = styled(AntdInput.Password)<Password>`
  input[type='password'] {
    font-family: ${({ $value, $isFirefox, $isIOS }) =>
      ($isFirefox || $isIOS) && $value && encryptedPasswordFireFoxFontFamily};
  }
`;

export const InputTextarea = styled(AntdInput.TextArea)``;

export const FormItemStyled = styled(FormItem)`
  margin-bottom: ${({ theme }: TextInputProps) => theme.spacing[5]};
  width: ${({ width }) => width};

  .ant-form-item-label {
    .ant-form-item-required, label {
      font-size: 15px;
      font-weight: ${({ theme }: TextInputProps) => theme.fontWeights.medium}; !important;
      color: ${({ labelColor }: TextInputProps & { labelColor: string }) => labelColor};
    }

    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      position: relative;
      top: -2px;
      margin-right: 0;
      font-weight: ${({ theme }: TextInputProps) => theme.fontWeights.normal};
      color: ${getCssVar('inputs.label.requiredIconColor')};
    }
  }


  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper > input.ant-input,
    .ant-input {
      height: ${({ height }) => height};
      box-shadow: none;
      border-color: ${getCssVar('inputs.borderColor')};
       color: ${getCssVar('inputs.textColor')};
       background: ${getCssVar('inputs.backgroundColor')};

      &:hover {
        background: ${getCssVar('inputs.backgroundColor')};
        border-color: ${getCssVar('inputs.borderColorHover')};
      }
      &:focus {
        background: ${getCssVar('inputs.backgroundColor')};
        border-color: ${getCssVar('inputs.borderColorHover')};
      }
      
      &::placeholder {
          color: ${getCssVar('inputs.textColorDisabled')};
        }
      
      &:disabled {
        border-color: ${getCssVar('inputs.borderColorDisabled')};
        background-color: ${getCssVar('inputs.backgroundColorDisabled')};
        color: ${getCssVar('inputs.textColorDisabled')};
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: ${getCssVar('inputs.textColor')};
        box-shadow: 0 0 0 1000px ${getCssVar('inputs.backgroundColor')} inset !important;
        background: ${getCssVar('inputs.backgroundColor')};
        border-color: ${getCssVar('inputs.borderColor')};

        &::selection {
          -webkit-text-fill-color: ${getCssVar('inputs.textColor')};
           background-color: #a6a6a680;
        }

        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          border-color:${getCssVar('inputs.borderColorHover')};
          background: ${getCssVar('inputs.backgroundColor')};
          box-shadow: 0 0 0 1000px ${getCssVar('inputs.backgroundColor')} inset;
        }
      }
    }
    
    .ant-input-group-addon {
      background-color: ${getCssVar('inputs.prefixIconBackground')};
      border-color: ${getCssVar('inputs.borderColor')};
      color: ${getCssVar('inputs.prefixIconColor')};
      padding: 0 5px;
      transition: 300ms;
    }
    .ant-input-password-icon {
      color: ${getCssVar('inputs.prefixIconColor')};
    }
    .ant-input-clear-icon, .clear-icon {
      color: ${getCssVar('inputs.prefixIconColor')};
    }
    .ant-input-prefix {
      color:${getCssVar('inputs.prefixIconColor')};
    }
    .ant-input-password-icon {
      svg path:first-child {
        fill: transparent;
      }
    }

    .ant-input-affix-wrapper {
      background: ${getCssVar('inputs.backgroundColor')};
      border-color: ${getCssVar('inputs.borderColor')};
      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
      }

      &:hover {
        border-color: ${getCssVar('inputs.borderColorHover')};
      }

      &.ant-input-affix-wrapper-disabled {
        border-color: ${getCssVar('inputs.borderColorDisabled')};
        background-color: ${getCssVar('inputs.backgroundColorDisabled')};

        input.ant-input {
          background-color: transparent;
        }
      }
    }
  }

  .ant-input-wrapper {
    .ant-input-affix-wrapper {
      background: ${getCssVar('inputs.backgroundColor')};
      border-color: ${getCssVar('inputs.borderColor')};
      box-shadow: none;
    }
    &:hover {
      .ant-input-group-addon, .ant-input-affix-wrapper,  input.ant-input {
        border-color: ${getCssVar('inputs.borderColorHover')};
      }
    }
  }

  ${(props) =>
    props.validateStatus === 'error' &&
    css`
      .ant-input {
        border-color: ${getCssVar('inputs.borderColor')};

        &:hover,
        &:focus {
          border-color: ${getCssVar('inputs.borderColorHover')};
        }
      }
    `};

  ${({ validateStatus }: TextInputProps) =>
    validateStatus === 'success' &&
    css`
      .ant-form-item-explain.ant-form-item-explain-success {
        display: none;
      }
    `};

  ${({ isFeedbackCentered }: TextInputProps) => {
    if (isFeedbackCentered) {
      return ".ant-form-item-explain.ant-form-item-explain-error [role='alert'] { text-align: center; }";
    }

    return '';
  }}
  
  &.ant-form-item-has-error {
   & :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      background: ${getCssVar('inputs.backgroundColor')};
      border-color: ${getCssVar('inputs.borderColorError')};

      &:hover,
      &:focus {
        border-color: ${getCssVar('inputs.borderColorErrorHover')};
        background: ${getCssVar('inputs.backgroundColor')};
        box-shadow: none;
      }
    }
    
    .ant-form-item-explain-error {
      color: ${getCssVar('inputs.errorMessageColor')} !important;
      transition: 300ms;
    }
    
   & .ant-input-wrapper {
       & .ant-input-group-addon, .ant-input-affix-wrapper {
        border-color: ${getCssVar('inputs.borderColorError')};
        background: ${getCssVar('inputs.backgroundColor')};
      }
      
      & .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
         border-color: ${getCssVar('inputs.borderColorErrorHover')};
         background: ${getCssVar('inputs.backgroundColor')};
         box-shadow: none;
      }

      &:hover {
        .ant-input-group-addon, .ant-input-affix-wrapper, input.ant-input {
          border-color: ${getCssVar('inputs.borderColorErrorHover')};
          background: ${getCssVar('inputs.backgroundColor')};
        }
      }
    }
  }
  
  &:hover {
  .ant-form-item-explain-error {
      color: ${getCssVar('inputs.errorMessageColorHover')};
    }
  }

  @media (max-width: 992px) {
      width: 100%;
  }
`;
