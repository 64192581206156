import { VulnerabilityDetails } from 'services/api';
import { VulnerabilityTitle } from 'pages/CmdbHostDetails/styled';
import { getFormattedDate, getCapitalizedString, getRenderValue } from 'services/helpers';
import { Box, TagColoring } from 'shared';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { ColumnsType } from 'antd/es/table/interface';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { TagVulnStatus } from 'shared/TagColoring/VulnStatus';
import { TableTitles } from './constants';

export const columns: ColumnsType<VulnerabilityDetails> = [
  {
    key: 'subject',
    title: TableTitles.Subject,
    dataIndex: 'subject',
    render: (subject: string) => (
      <VulnerabilityTitle w='280px'>
        <span>{getRenderValue(subject, NO_VALUE_LABEL)}</span>
      </VulnerabilityTitle>
    ),
  },
  {
    key: 'category',
    title: TableTitles.Category,
    dataIndex: 'category',
    render: (category: string) => (
      <Box w='120px'>
        <span>{getRenderValue(category)}</span>
      </Box>
    ),
    sorter: true,
    showSorterTooltip: false,
  },
  {
    key: 'severity',
    title: TableTitles.Severity,
    dataIndex: 'severity',
    className: 'center-head-title',
    align: 'center',
    render: (severity: string, record: VulnerabilityDetails) => {
      const statusRender = record.vulnerabilityStatus ? (
        <TagVulnStatus title={getCapitalizedString(record.vulnerabilityStatus)} />
      ) : (
        ''
      );
      const severityRender = severity ? (
        <TagColoring title={getCapitalizedString(getSeverityLevelName(severity))} />
      ) : (
        emptyDataString
      );

      return (
        <>
          <Box w={statusRender && '190px'}>
            {severityRender}
            &nbsp;&nbsp;
            {statusRender}
          </Box>
        </>
      );
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    key: 'firstFound',
    title: TableTitles.FirstFound,
    dataIndex: 'firstFound',
    width: '160px',
    className: 'right-head-title',
    align: 'right',
    render: (date: string) => (
      <Box w='160px'>
        <Box>{getFormattedDate(date)}</Box>
      </Box>
    ),
    sorter: true,
    showSorterTooltip: false,
  },
  {
    key: 'lastFound',
    title: TableTitles.LastFound,
    dataIndex: 'lastFound',
    width: '160px',
    className: 'right-head-title',
    align: 'right',
    render: (date: string) => (
      <Box textAlign='right' w='160px'>
        {getFormattedDate(date)}
      </Box>
    ),
    sorter: true,
    showSorterTooltip: false,
  },
];
