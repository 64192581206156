import { colors } from '../colors';
import { fontWeights } from '../fontWeights';

export const process = {
  labelWeight: fontWeights.semibold,
  labelInputsWeight: fontWeights.semibold,
  labelFontFamily: 'Open sans',

  success: '#38A078',
  expired: colors.purple[550],
  inProgress: colors.yellow[460],
  soonExpired: 'rgba(235, 74, 112, 0.3)',

  labelUppercase: 'uppercase',
  hoverBg: 'rgba(224, 227, 232, 0.2)',
  dateColor: colors.white,
  helpMessageBg: colors.black,
  helpMessageColor: colors.white,
  certificate: {
    statusLabelColor: '#2a26f9',
  },
};
