import { useMemo } from 'react';
import { MonthlyReportResponse } from 'services/api';
import { getCapitalizedString } from 'services/helpers';
import {
  CyberResilienceScoreText,
  ScoreDifferenceMonth,
  ScoreDifferenceQuarter,
  ScoreWrapper,
  ScoreDifferenceWrapper,
  ArrowIndicator,
  TotalWrapper,
} from './styled';
import { getSortedDashboardScores } from '../../helpers';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { CyberResilienceScoreDescriptionScoreMessage, Page } from '../styled';
import { BackgroundImage } from '../../components/BackgroundImage';
import { TotalScoreTable } from '../../components/TotalScoreTable';

const getDifferenceBetweenMonths = (month = 0, secondMonth = 0) => {
  const result = month - secondMonth;

  if (result > 0) {
    return `+${result}`;
  }

  return result;
};

const renderIcon = (totalScore = 0, prevScore = 0) => {
  if (totalScore < prevScore) {
    return <span className='color-red'>▼</span>;
  }

  if (totalScore > prevScore) {
    return <span className='color-green'>▲</span>;
  }

  return null;
};

const MONTH_ON_QUARTER = 4;

type Props = {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  totalRank: string;
};

export function PdfPage4({ data, onChartLoaded, totalRank }: Props) {
  const scores = data?.dashboardScores || [];
  const sortedDashboardScores = getSortedDashboardScores([...scores]);

  const checkingDashboardScores = sortedDashboardScores[scores.length - 1];
  const totalScore = checkingDashboardScores?.total || 0;

  const differenceForMonth = useMemo(
    () =>
      getDifferenceBetweenMonths(totalScore, sortedDashboardScores[scores.length - 2]?.total || 0),
    [scores.length, sortedDashboardScores, totalScore],
  );

  const differenceForQuarter = useMemo(
    () =>
      getDifferenceBetweenMonths(
        totalScore,
        sortedDashboardScores[scores.length - MONTH_ON_QUARTER]?.total || 0,
      ),
    [scores.length, sortedDashboardScores, totalScore],
  );

  return (
    <Page>
      <BackgroundImage fileName='slide-4.jpg' />

      <CyberResilienceScoreText>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(totalRank) }}
        />
        <ul>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
        </ul>
      </CyberResilienceScoreText>

      <ScoreWrapper>
        <GradientPieReport
          score={totalScore || 0}
          width='380px'
          height='380px'
          onFinished={onChartLoaded}
          fontSize={80}
          titleFontSize={8}
        />
      </ScoreWrapper>

      <ScoreDifferenceWrapper>
        <ScoreDifferenceMonth>{differenceForMonth} on last month</ScoreDifferenceMonth>
        <ScoreDifferenceQuarter>{differenceForQuarter} on last quarter</ScoreDifferenceQuarter>
      </ScoreDifferenceWrapper>

      <ArrowIndicator>
        {renderIcon(totalScore, sortedDashboardScores[scores.length - 2]?.total)}
      </ArrowIndicator>
      <TotalWrapper>
        <TotalScoreTable totalScoreTable={scores} />
      </TotalWrapper>
    </Page>
  );
}
