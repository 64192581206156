import { BackgroundImage } from '../../components/BackgroundImage';
import { Page } from '../styled';

export function PdfPage12() {
  return (
    <Page>
      <BackgroundImage fileName='slide-12.jpg' />
    </Page>
  );
}
