import { useRef } from 'react';
import { isEmptyObject } from 'services/helpers';
import { useApp, useLoading, usePatchManagement, usePlatform } from 'store';
import { Card } from 'shared';
import { Legend, SingleBar } from 'shared/charts';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import ReactECharts from 'echarts-for-react';
// import { useHistory } from 'react-router';
// import { EChartsEventParams } from 'shared/charts/interfaces';
// import { Routes } from 'services/entities';
// import { getQueryString } from 'services/api/core/request';
import { useTheme } from 'styled-components';
import { patchDashboardApi } from 'store/patchManagement/dashboard/thunks';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { getOption } from './helpers';
import {
  // getPatchByStatusParams,
  getPatchesByStatusChartColors,
  PatchByStatusBarChartTitles,
  // PBBackRoute,
  TooltipTitles,
} from '../../constants';
import { subTitleFormatterSum } from '../../helpers';
import { ScrollWrapper } from '../../styled';

export const PatchesByStatus = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const platform = usePlatform();
  // const history = useHistory();
  const chartRef = useRef<ReactECharts>();
  const {
    metrics: { patchesByOSStatus },
  } = usePatchManagement();
  const loading = useLoading([patchDashboardApi.getPatchesByOSStatus.typePrefix]);
  const {
    successCount: success = 0,
    // successPatches,
    failedCount: failed = 0,
    // failedPatches,
    installedCount: alreadyInstalled = 0,
    // installedPatches,
  } = patchesByOSStatus;

  const isTotalEmpty = subTitleFormatterSum([failed, success, alreadyInstalled], 1) === 0;

  // const patchIds = React.useMemo(
  //   () => ({
  //     [PatchByStatusBarChartTitles.SUCCESS]: successPatches || [],
  //     [PatchByStatusBarChartTitles.FAILED]: failedPatches || [],
  //     [PatchByStatusBarChartTitles.INSTALLED]: installedPatches || [],
  //   }),
  //   [successPatches, failedPatches, installedPatches],
  // );

  // Currently disabled as we do not need to redirect to patches grid

  // const redirectToPatchesGrid = useCallback(
  //   (patchId) =>
  //     history.push(
  //       `${Routes.PATCH_MANAGEMENT_PATCHES}${getQueryString(getPatchByStatusParams(patchId))}`,
  //       PBBackRoute,
  //     ),
  //   [history],
  // );

  // const handleAnnotationClick = (statusTitle: string) => {
  //   const patchId = patchIds[statusTitle as keyof typeof patchIds] || [];

  //   redirectToPatchesGrid(patchId);
  // };

  // const onEvents = React.useMemo(
  //   () => ({
  //     click: (params?: EChartsEventParams) =>
  //       redirectToPatchesGrid(patchIds[params?.name as keyof typeof patchIds] || []),
  //   }),
  //   [redirectToPatchesGrid, patchIds],
  // );

  return (
    <Card
      title={`${platform} patches by status`}
      showDivider={false}
      subTitle={
        <SubTitle
          boldText={subTitleFormatterSum([failed || 0], 1)}
          customText='Failed patches installs'
        />
      }
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles(platform).WindowsPatchesByStatus}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}` : theme.sizes[5]
      }
      showLoader={loading[patchDashboardApi.getPatchesByOSStatus.typePrefix]}
    >
      {!isEmptyObject(patchesByOSStatus) && !isTotalEmpty ? (
        <ScrollWrapper>
          <SingleBar
            values={{ success, alreadyInstalled, failed } || {}}
            getOption={getOption}
            height='300px'
            // onEvents={onEvents}
            chartRef={(r: ReactECharts) => {
              chartRef.current = r;
            }}
            isLoading={loading[patchDashboardApi.getPatchesByOSStatus.typePrefix]}
          />
          <Legend
            values={
              {
                [PatchByStatusBarChartTitles.SUCCESS]: success,
                [PatchByStatusBarChartTitles.INSTALLED]: alreadyInstalled,
                [PatchByStatusBarChartTitles.FAILED]: failed,
              } || {}
            }
            colors={getPatchesByStatusChartColors(theme)}
            direction='row'
            marginTop={theme.sizes[4]}
            cursor='default'
            // onAnnotationClick={handleAnnotationClick}
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
          />
        </ScrollWrapper>
      ) : (
        emptyMessage(`No ${platform} patches by status yet`)
      )}
    </Card>
  );
};
