import { colors } from '../../colors';
import { baseNorm } from '../base';
import { inputsBase } from './base';

export const inputs = {
  label: {
    textColor: inputsBase.textColor,
    requiredIconColor: baseNorm.deepRed,
  },
  backgroundColor: inputsBase.backgroundColor,
  backgroundColorDisabled: inputsBase.backgroundColorDisabled,
  textColor: inputsBase.textColor,
  textColorDisabled: inputsBase.textColorDisabled,
  borderColor: inputsBase.borderColor,
  borderColorHover: inputsBase.borderColorHover,
  borderColorDisabled: inputsBase.borderColor,
  borderColorError: baseNorm.red,
  borderColorErrorHover: colors.red[400],
  errorMessageColor: colors.red[700],
  errorMessageColorHover: colors.red[700],
  prefixIconColor: inputsBase.iconColor,
  prefixIconBackground: inputsBase.backgroundColor,
};
