import { Button, Modal, ProgressBar, LoadingDots } from 'shared';
import { Skeleton } from 'antd';

type PopupWithProgressProps = {
  visible: boolean;
  percent: number;
  disableCancel?: boolean;
  handleCancel?: () => void;
};

const START_VALUE = 5;

export const PopupWithProgress = ({
  visible,
  percent = 0,
  handleCancel,
  disableCancel = true,
}: PopupWithProgressProps) => {
  const footer = [
    <Button key='cancel' type='secondary' onClick={handleCancel} disabled={disableCancel}>
      Cancel
    </Button>,
  ];

  const text = percent < START_VALUE ? 'Sending request' : 'Please wait. Preparing report';
  const title = <LoadingDots>{text}</LoadingDots>;

  return (
    <Modal title={title} visible={visible} footer={footer} closable={false} centered={true}>
      {percent > 0 && <ProgressBar percent={percent} />}
      {percent === 0 && (
        <Skeleton.Button
          active
          size='default'
          block={true}
          style={{ height: 8, borderRadius: 20, display: 'block' }}
        />
      )}
    </Modal>
  );
};
