import styled from 'styled-components';
import { Colors, SegmentedPieData, Values } from 'shared/charts/types';
import { LabelFormatterParams } from 'shared/charts/interfaces';
import { Theme } from 'styles/theme/types';
import { getPercentage } from 'services/helpers';
import { ReportChart } from './ReportChart';
import { Legend } from './Legend';

interface Props {
  onChartLoaded: () => void;
  theme: Theme;
  colors: Colors;
  values: Values;
  height?: string;
  width?: string;
  title: string;
  legendAsColumn?: boolean;
}

const MIN_WIDTH_TO_SHOW_LABEL = 4;

const getOption = ({
  values,
  theme,
  colors,
  totalValue,
}: {
  values: Values;
  theme: Theme;
  colors: Colors;
  totalValue: number;
  getCustomDataHandler?: (values: Values, colors?: Colors) => SegmentedPieData;
}) => {
  const seriesList = Object.entries(values).map(([name, value]) => {
    const percentageValue = getPercentage(value, totalValue);

    return {
      type: 'bar',
      stack: 'total',
      barWidth: '100%',
      name,
      data: [value],
      label: {
        formatter: (params: LabelFormatterParams) => {
          return `${getPercentage(Number(params.value), totalValue)}%`;
        },
        position: 'insideRight',
        show: percentageValue > MIN_WIDTH_TO_SHOW_LABEL,
        fontFamily: theme.colorSet.fontFamily,
        fontSize: 13,
        lineHeight: 1,
        // fontWeight: theme.fontWeights.bold,
      },
      itemStyle: {
        color: colors[name],
      },
    };
  });

  return {
    // set bar to fill container
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      containLabel: false,
    },
    xAxis: {
      type: 'value',
      show: false,
      max: totalValue, // set bar indicator length to max value
    },
    yAxis: {
      type: 'category',
      data: [''],
      show: false,
    },
    legend: { show: false },
    series: seriesList,
  };
};

export const Wrapper = styled.div`
  .title {
    margin-bottom: 5px;
    font-size: 12px;

    span {
      font-weight: bold;
    }
  }

  .legend {
    margin-top: 12px;
    padding: 0 10px;
  }
`;

export function BarReportChartWithLegend({
  onChartLoaded,
  theme,
  colors,
  values,
  height = '28px',
  width = '100%',
  title,
  legendAsColumn = false,
}: Props) {
  const totalValue = Object.values(values).reduce((a, b) => a + b, 0);

  return (
    <Wrapper>
      <div className='title'>
        <span>{totalValue} </span>
        {title}
      </div>

      <ReportChart
        onFinished={onChartLoaded}
        option={getOption({
          values,
          theme,
          colors,
          totalValue,
        })}
        width={width}
        height={height}
      />

      <div className='legend'>
        <Legend values={values} colors={colors} asColumn={legendAsColumn} />
      </div>
    </Wrapper>
  );
}
