import React, { useEffect } from 'react';
import { Form, Input, Space } from 'antd';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  maintenanceSetting,
  MessageType,
  useCustomer,
  useMaintenanceSettings,
} from 'store';
import { Box, Button } from 'shared';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { PayloadAction } from '@reduxjs/toolkit';
import { Routes } from 'services/entities';
import { showError, showMessage } from 'services/helpers';
import { RequestStatus } from 'store/loading/slice';
import { maintenanceSettingsApi } from 'store/maintenance/thunks';
import { Spacer } from 'shared/TableFilters/styled';
import { MaintenanceSettingsRequest, SuccessResponse } from 'services/api';
import {
  EditModeWrapper,
  ExampleText,
  InputLabel,
  MaintenanceEditModeMessage,
  SmartBlockText,
  ViewModeWrapper,
} from '../../style';
import { FormFieldLabels, TEXT_AREA_MAX_LENGTH } from '../../helpers';
import { Preview } from '../Preview';
import { IEditProps } from '../../types';

export const MaintenanceEditMode = ({ hideEditMode }: IEditProps) => {
  const customerId = useCustomer();
  const { maintenanceSettings } = useMaintenanceSettings();
  const { TextArea } = Input;
  const dispatch = useDispatch<AppDispatch>();

  const initialValues: MaintenanceSettingsRequest = {
    maintenancePageMessage: maintenanceSettings?.maintenancePageMessage || '',
  };
  const validationSchema = Yup.object({
    maintenancePageMessage: Yup.string()
      .trim()
      .required('Message text should not be empty')
      .max(TEXT_AREA_MAX_LENGTH),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (requestBody: MaintenanceSettingsRequest) => {
      const result = (await dispatch(
        maintenanceSettingsApi.updateMaintenanceSettings({ customerId, requestBody }),
      )) as PayloadAction<
        SuccessResponse,
        string,
        {
          requestStatus: string;
        },
        {
          message: string;
        }
      >;

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        const errorMessage = result.error.message;

        showError(errorMessage || undefined);

        return;
      }

      if (result.meta.requestStatus === RequestStatus.Fulfilled) {
        showMessage(
          {
            content: `Maintenance page message has been successfully updated.`,
          },
          MessageType.Success,
        );

        hideEditMode('maintenanceMessage');
      }
    },
  });

  useEffect(() => {
    dispatch(
      maintenanceSetting.actions.setMaintenancePageMessageViewMode(
        maintenanceSettings?.maintenancePageMessage || '',
      ),
    );
  }, [dispatch, maintenanceSettings]);

  const inputHandleBar = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(maintenanceSetting.actions.setMaintenancePageMessageViewMode(event.target.value));
    formik.handleChange(event);
  };

  const cancelEditMode = () => {
    dispatch(
      maintenanceSetting.actions.setMaintenancePageMessageViewMode(
        maintenanceSettings?.maintenancePageMessage || '',
      ),
    );
    hideEditMode('maintenanceMessage');
  };

  return (
    <Form layout='vertical' onFinish={formik.handleSubmit} initialValues={formik.initialValues}>
      <MaintenanceEditModeMessage>
        <Box>
          <InputLabel
            name={FormFieldLabels.MaintenancePageMessage}
            label='Text'
            required
            help={formik.errors.maintenancePageMessage}
            validateStatus={formik.errors.maintenancePageMessage ? 'error' : 'success'}
          >
            <EditModeWrapper isError={!!formik.errors.maintenancePageMessage}>
              <SmartBlockText>Smartbloc</SmartBlockText>
              <TextArea
                cols={100}
                defaultValue={maintenanceSettings?.maintenancePageMessage}
                onChange={(event) => inputHandleBar(event)}
                showCount
                name={FormFieldLabels.MaintenancePageMessage}
                maxLength={TEXT_AREA_MAX_LENGTH}
                bordered={false}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </EditModeWrapper>
          </InputLabel>
          <ExampleText>
            Text example: «Smartblock is unavailable till 23:59 5th March due to essential
            maintenance».
          </ExampleText>
        </Box>
        <Box>
          <ViewModeWrapper>
            <Link
              to={{
                pathname: Routes.MAINTENANCE_PAGE_VIEW_MODE,
              }}
              target='_blank'
            >
              <Preview />
            </Link>
          </ViewModeWrapper>
          <Spacer h='20' />
          <Space>
            <Button type='primary' htmlType='submit'>
              Save
            </Button>
            <Button type='secondary' onClick={() => cancelEditMode()}>
              Cancel
            </Button>
          </Space>
        </Box>
      </MaintenanceEditModeMessage>
    </Form>
  );
};
