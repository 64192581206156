import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Form, Space } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Box, Button, Icon, Input, Select } from 'shared';
import * as Yup from 'yup';
import { AppDispatch, MessageType, useApp, useCustomer } from 'store';
import { HostAssetOwnerSchema } from 'services/api';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { RequestStatus } from 'store/loading/slice';
import { showError, showMessage } from 'services/helpers';
import { useTheme } from 'styled-components';
import { MAX_LENGTH_VALUE, priorityOptions } from './constants';

export type AssetOwnerFormProps = {
  handleSave: () => void;
  handleCancel: () => void;
  assetOwner?: HostAssetOwnerSchema;
};

export const AssetOwnerForm = ({ handleSave, handleCancel, assetOwner }: AssetOwnerFormProps) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const customer = useCustomer();
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useApp();

  const initialValues = assetOwner || {};

  const validationSchema = Yup.object({
    name: Yup.string().max(MAX_LENGTH_VALUE),
    department: Yup.string().max(MAX_LENGTH_VALUE),
    priority: Yup.string(),
  });

  const onSubmitForm = React.useCallback(
    async (values: HostAssetOwnerSchema) => {
      const requestBody = Object.entries(values).reduce(
        (acc: { [key: string]: string }, value: [string, string | undefined]) => {
          if (!value[1]) {
            return acc;
          }

          return { ...acc, [value[0]]: value[1] };
        },
        {},
      );
      const result = await dispatch(
        assetDetailsApi.setsAssetOwner({
          customerId: customer,
          hostId: id,
          requestBody,
        }),
      );

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        showError();
      }

      showMessage({ content: 'Success' }, MessageType.Success);
      handleSave();
    },
    [dispatch, customer, id, handleSave],
  );

  const { handleSubmit, values, errors, handleChange, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitForm,
  });

  return (
    <Box
      mt={!isMobile ? theme.sizes[0] : theme.sizes[4]}
      mb={!isMobile ? theme.sizes[0] : theme.sizes[4]}
    >
      <Form layout='vertical' onFinish={handleSubmit} initialValues={initialValues}>
        <Input
          label='Name'
          type='text'
          id='name'
          name='name'
          value={values.name}
          onChange={handleChange}
          validateStatus={errors.name ? 'error' : 'success'}
          maxLength={MAX_LENGTH_VALUE}
        />

        <Input
          label='Department'
          type='text'
          name='department'
          value={values.department}
          onChange={handleChange}
          validateStatus={errors.department ? 'error' : 'success'}
          maxLength={MAX_LENGTH_VALUE}
        />

        <Form.Item name='priority' label='Priority'>
          <Select
            suffixIcon={
              <Icon component={theme.icons.arrow_drop_down} className='ant-select-suffix' />
            }
            options={priorityOptions}
            value={values.priority}
            onChange={(value: SelectValue) => setFieldValue('priority', value)}
            showArrow
          />
        </Form.Item>

        <Space>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
          <Button type='secondary' onClick={handleCancel}>
            Cancel
          </Button>
        </Space>
      </Form>
    </Box>
  );
};
