import styled from 'styled-components';
import { Button, Input } from 'shared';

export const StyledInput = styled(Input)`
  height: 40px;

  .ant-input-clear-icon-hidden {
    display: none;
  }

  .ant-input-suffix {
    cursor: pointer;
    padding-top: 10px;

    .anticon-close-circle {
      display: none;
    }
  }
`;

export const DeleteButton = styled(Button)`
  transform: none;

  .anticon {
    height: auto;
    width: auto;

    svg {
      width: 12px;
    }
  }
`;

export const ManageTagButton = styled(DeleteButton)`
  margin-right: 5px;
`;
