import { useMemo } from 'react';
import { Theme } from 'styles/theme/types';
import {
  PatchVsConfigurationResponse,
  ReportTopMostVulnerableHostsItem,
  ReportTopVulnerabilitiesItem,
  VulnerabilityChangesPointSchema,
  VulnerabilityChartPointSchema,
} from 'services/api';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { getCapitalizedString, getPercentage } from 'services/helpers';
import { VulnerabilitiesTitles } from 'pages/VulnerabilityManagement/constants';
import {
  AverageTimeToRemediate,
  AllVulnerabilitiesWrapper,
  MostVulnerableHosts,
  PatchesVsConfigurationChartWrapper,
  TechnologyVulnerabilityManagementDescription,
  TotalScorePieWrapper,
  TopVulnerabilities,
  ChangesTitle,
  ChartsContainer,
  TopInfoWrapper,
} from './styled';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { MultiColumnsReportChart, Values } from '../../components/Charts/MultiColumnsReportChart';
import { asidePieSize, asidePieFontSize } from '../config';
import { CyberResilienceScoreDescriptionScoreMessage, Page } from '../styled';
import { BarReportChartWithLegend } from '../../components/Charts/BarReportChartWithLegend';
import { BackgroundImage } from '../../components/BackgroundImage';

interface TechnologyVulnerabilityManagementProps {
  technologyScore: number | undefined | null;
  technologyRank: string;
  onChartLoaded: () => void;
  chart: VulnerabilityChangesPointSchema[];
  totalChange: number;
  totalCount: number;
  averageDaysToRemediate: number;
  theme: Theme;
  mostVulnerableHosts?: Array<ReportTopMostVulnerableHostsItem>;
  topVulnerabilities?: Array<ReportTopVulnerabilitiesItem>;
  patchVsConfiguration?: PatchVsConfigurationResponse;
  suppressedVulnerabilities?: number;
}

const SuppressedVulnerabilities = ({ vulnerabilitiesCount }: { vulnerabilitiesCount?: number }) => {
  if (!vulnerabilitiesCount) {
    return null;
  }

  return <li>{`Excluding ${vulnerabilitiesCount} suppressed vulnerabilities`}</li>;
};

export function PdfPage7({
  technologyScore,
  technologyRank,
  onChartLoaded,
  chart,
  totalChange,
  totalCount,
  averageDaysToRemediate,
  theme,
  patchVsConfiguration = { patch: 0, configuration: 0 },
  mostVulnerableHosts = [],
  topVulnerabilities = [],
  suppressedVulnerabilities,
}: TechnologyVulnerabilityManagementProps) {
  const allVulnerabilitiesValue = useMemo<Values>(() => {
    return [...chart]
      .sort((valueA: VulnerabilityChartPointSchema, valueB: VulnerabilityChartPointSchema) => {
        if (valueA.criticality && valueB.criticality) {
          return Number(valueB.criticality) - Number(valueA.criticality);
        }

        return 0;
      })
      .reduce<Values>((acc, value: VulnerabilityChangesPointSchema) => {
        if (!value.criticality || !value.count) {
          return { ...acc };
        }

        const key = getSeverityLevelName(value.criticality);
        const prevValue = value.count - (value.previousDifference || 0);

        return {
          ...acc,
          [key]: {
            value: value.count,
            prevDiffValue: value.previousDifference,
            prevDiffPercentage: getPercentage(Number(value.previousDifference), prevValue),
            prevValue,
          },
        };
      }, {});
  }, [chart]);

  const isNegative = totalChange > 0;
  const prevTotalCount = totalCount - totalChange;
  const totalChangePercentage = getPercentage(totalChange, prevTotalCount);

  return (
    <Page>
      <BackgroundImage fileName='slide-7.jpg' />

      <TechnologyVulnerabilityManagementDescription>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(technologyRank) }}
        />
        <ul>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <li>
            Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del
            mi, quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam,
            odit pereped ut qui quisimi.
          </li>
          <SuppressedVulnerabilities vulnerabilitiesCount={suppressedVulnerabilities} />
        </ul>
      </TechnologyVulnerabilityManagementDescription>

      <TotalScorePieWrapper>
        <GradientPieReport
          score={technologyScore}
          onFinished={onChartLoaded}
          width={asidePieSize}
          height={asidePieSize}
          fontSize={asidePieFontSize}
        />
      </TotalScorePieWrapper>

      <AverageTimeToRemediate>
        <div>{`${averageDaysToRemediate}`}</div>
        <div>{`day${averageDaysToRemediate !== 1 ? 's' : ''}`}</div>
      </AverageTimeToRemediate>

      <ChartsContainer>
        <AllVulnerabilitiesWrapper>
          <ChangesTitle>
            <span>Changes this month: </span>
            <span>{`${isNegative ? '+' : ''}${totalChange} (${totalChangePercentage}%)`}</span>
          </ChangesTitle>

          <MultiColumnsReportChart
            onChartLoaded={onChartLoaded}
            theme={theme}
            values={allVulnerabilitiesValue}
            height='120px'
            width='100%'
          />
        </AllVulnerabilitiesWrapper>

        <PatchesVsConfigurationChartWrapper>
          <BarReportChartWithLegend
            values={patchVsConfiguration}
            theme={theme}
            colors={{
              configuration: theme.colorSet.baseColors.green,
              patch: theme.colorSet.baseColors.amber,
            }}
            title='Total'
            onChartLoaded={onChartLoaded}
          />
        </PatchesVsConfigurationChartWrapper>

        <TopInfoWrapper>
          <MostVulnerableHosts>
            <div className='title'>{VulnerabilitiesTitles.TopFiveMostVulnerableHosts}</div>

            {mostVulnerableHosts.map(({ id, hostname }, index) => (
              <div key={id}>{`${index + 1}. ${hostname}`}</div>
            ))}
          </MostVulnerableHosts>

          <TopVulnerabilities>
            <div className='title'>{VulnerabilitiesTitles.TopFiveVulnerabilities}</div>

            {topVulnerabilities.map(({ id, title }, index) => (
              <div key={id}>{`${index + 1}. ${title}`}</div>
            ))}
          </TopVulnerabilities>
        </TopInfoWrapper>
      </ChartsContainer>
    </Page>
  );
}
