import { Icon, Box, BoxProps } from 'shared';
import { icons } from 'styles/theme';
import { ReactNode } from 'react';
import { HistoryState } from 'services/interfaces';
import { ShowMoreButtonStyled, ShowMoreLinkStyled } from './showMore';

export type ShowMoreButtonProps = BoxProps & {
  onClick?: () => void;
  to?:
    | string
    | {
        pathname?: string;
        search?: string;
        state?: HistoryState;
      };
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  block?: boolean;
};

export const ShowMoreButton = ({
  to,
  children,
  disabled,
  block = false,
  ...props
}: ShowMoreButtonProps) => {
  const content = <span>{children || 'View more'}</span>;

  return (
    <Box {...props}>
      {to ? (
        <ShowMoreLinkStyled to={to} disabled={disabled}>
          {content}
          <Icon component={icons.arrow_right_alt} />
        </ShowMoreLinkStyled>
      ) : (
        <ShowMoreButtonStyled block={block} type='link' disabled={disabled}>
          {content}
          <Icon component={icons.arrow_right_alt} />
        </ShowMoreButtonStyled>
      )}
    </Box>
  );
};
