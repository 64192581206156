import styled from 'styled-components';
import { Box } from 'shared';
import { Table, AsideDescription, AsidePieWrapper } from '../styled';
import { smallFontSize } from '../config';

export const TextWrapper = styled(AsideDescription)`
  max-width: 270px;
  top: 320px;
  word-wrap: break-word;
`;

export const TotalScorePieWrapper = styled(AsidePieWrapper)``;

export const StatisticsWrapper = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 385px;
  position: absolute;
  top: 50px;

  > .statistic-block:not(:last-child) {
    margin-right: 65px;
  }
`;

export const Statistics = styled(Box)`
  align-items: center;
  background: ${({ theme }) => theme.colorSet.baseColors.blue};
  border-radius: 50%;
  color: #fff;

  display: flex;

  font-size: 21px;
  font-weight: 700;
  height: 65px;
  justify-content: center;

  line-height: 1;
  position: relative;
  width: 65px;
`;

export const Events = styled(Statistics)`
  height: 115px;
  width: 115px;
`;

export const Alerts = styled(Statistics)`
  height: 90px;
  width: 90px;
`;

export const Incidents = styled(Statistics)``;

export const Active = styled(Statistics)<{ hasValue: boolean }>`
  background: ${({ theme, hasValue }) => {
    return hasValue ? theme.colorSet.baseColors.red : theme.colorSet.baseColors.blue;
  }};

  height: 50px;
  width: 50px;
`;

export const ClosedState = styled(Statistics)`
  background: ${({ theme }) => theme.colorSet.baseColors.black};
  height: 50px;
  width: 50px;
`;

export const LabelsWrapper = styled.div`
  bottom: -5px;
  left: 0;
  position: absolute;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  text-align: center;

  &:nth-child(1) {
    left: 38px;
  }

  &:nth-child(2) {
    left: 205px;
  }

  &:nth-child(3) {
    left: 337px;
  }

  &:nth-child(4) {
    left: 471px;
  }

  &:nth-child(5) {
    left: 584px;
  }
`;

export const TableWrapper = styled.div`
  position: absolute;
  right: 41px;
  top: 231px;
  width: 580px;

  .title {
    margin-bottom: 15px;
  }
`;

export const SecurityIncidenceTable = styled(Table)`
  font-size: ${smallFontSize};

  tr > td:last-child,
  tr > th:last-child {
    text-align: right;
  }

  tr > th {
    padding: 0px 0px 5px;
  }

  tr > td {
    padding: 6px 0;
    border-bottom: 0.5px solid lightgrey;
  }

  tr:first-child > td {
    padding-top: 12px;
  }

  tr:last-child > td {
    border-bottom: none;
  }
`;

const Cell = styled.th`
  width: 55px;
`;

export const PriorityCell = styled(Cell)``;
export const TicketCell = styled(Cell)``;
export const StatusCell = styled(Cell)``;

/* stylelint-disable */
export const SummaryCell = styled.div`
  max-width: 245px;

  > div {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
/* stylelint-enable */

export const CreatedAtCell = styled(Cell)`
  width: 85px;
`;
export const UpdatedAtCell = styled(CreatedAtCell)``;
