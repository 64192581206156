import styled, { css } from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { Box, Button as SharedButton } from 'shared';
import { Theme } from 'styles/theme/types';
import { breakpoints, getCssVar } from 'styles/theme';
import { ThemeProps } from '../../services/interfaces';

const { Text } = Typography;

type Props = {
  theme: Theme;
  $isTablet?: boolean;
  $isSearchField?: boolean;
  $isFilterFields?: boolean;
};

type SpacerProps = {
  w?: string;
  h?: string;
};

export const TableFiltersContainer = styled.div<Props>`
  background: ${getCssVar('table.filters.bg')};
  padding: ${({ $isSearchField }: Props) => ($isSearchField ? '15px 16px 4px' : '0')};
  position: ${({ $isTablet }: Props) => ($isTablet ? 'static' : 'sticky')};
  top: 0;
  z-index: 999;
`;

export const TableFiltersContainerRow = styled(Row)`
  align-items: start;
  display: flex;
  gap: ${({ gutter }) => gutter}px;

  @media (min-width: ${({ theme }: Props) => theme.breakpoints.xxl}) {
    flex-direction: row;
  }
`;

export const FiltersRow = styled(Row)`
  display: flex;
  gap: ${({ gutter }) => gutter}px;

  @media (min-width: ${({ theme }: Props) => theme.breakpoints.xxl}) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    width: 100vw;
  }
`;

export const FilterLabelCol = styled(Col)`
  align-items: end;
  display: flex;
  margin-bottom: 20px;

  > label {
    font-weight: 600;
  }

  max-width: 100%;

  @media (min-width: ${({ theme }: Props) => theme.breakpoints.xxl}) {
    padding-top: 30px;
    flex: 0 0 auto;
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    flex: 1 1 100%;
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.mdMax}) {
    justify-content: space-between;
    margin-bottom: 24px;
    svg {
      width: 27px;
      height: 27px;
    }

    > .ant-typography {
      font-size: 21px;
      font-weight: 700;
    }
  }
`;

export const Spacer = styled(Box)<SpacerProps>`
  height: ${({ h }: SpacerProps) => `${h}px`};
  width: ${({ w }: SpacerProps) => `${w}px`};
`;

export const SearchCol = styled(Col)<Props>`
  display: flex;
  margin-bottom: 8px;
  padding-top: 21px;

  .ant-form-item {
    width: 334px;
  }
`;

export const FilterActionsCol = styled(Col)`
  display: flex;

  label {
    bottom: 3px;
    position: relative;
  }

  @media (min-width: ${({ theme }: Props) => theme.breakpoints.xxl}) {
    flex: 0 0 auto;
    max-width: calc(100% - 330px);
  }
`;

export const FilterFieldsCol = styled(Col)`
  flex: 0 0 auto;
  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    width: ${({ theme }: Props) => theme.sizes.full};
  }
`;

export const InputsRow = styled(Row)`
  gap: ${({ gutter }) => gutter}px;
`;

const ResetFilterColStyles = css`
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding-left: 4px !important;
  padding-top: 22px;

  @media (min-width: ${({ theme }: Props) => theme.breakpoints.xxl}) {
    flex: 0 0 auto;
  }
  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    justify-content: flex-start;
    padding-top: 0;
  }
`;

export const StyledButton = styled(SharedButton)`
  margin: 8px auto;
  width: 96%;

  > span {
    text-align: center;
    width: 100%;
  }
`;

export const ResetFilterCol = styled(Col)`
  ${ResetFilterColStyles};
`;

export const ShowMoreFiltersButtonCol = styled(Col)`
  ${ResetFilterColStyles};
`;

type MoreFiltersButtonProps = {
  theme: Theme;
  checked?: boolean;
};

export const MoreFiltersButton = styled(SharedButton)<MoreFiltersButtonProps>`
  .more-filter-btn-text {
    display: inline-flex;
    position: relative;

    ${({ checked }) =>
      checked &&
      `&:after {
          align-self: self-start;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: ${getCssVar('table.filters.moreFiltersIcon')};
      }`};
  }
`;

export const SearchInputContainer = styled.div<Props>`
  background: ${getCssVar('table.filters.mobile.search.bg')};
  display: flex;
  padding: 12px;
`;

type FilterTitleProps = {
  theme: Theme;
  $isMobile?: boolean;
};

export const FilterTitle = styled(Text)<FilterTitleProps>`
  color: ${({ $isMobile }) =>
    $isMobile ? getCssVar('table.filters.mobile.title') : getCssVar('textColor')};
  font-size: 16px;
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
  line-height: 20px;

  @media (max-width: ${breakpoints.mdMax}) {
    font-size: 20px !important;
    font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.black};
    line-height: 24px;
  }
`;
