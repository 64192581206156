import { PatchVendorSeverity } from 'services/api';
import { baseDarkNorm } from './base';

export const pieChart = {
  CurrentTrainingStatusSuccess: baseDarkNorm.green,
  CurrentTrainingStatusStarted: baseDarkNorm.amber,
  CurrentTrainingStatusNotStarted: baseDarkNorm.deepRed,

  CurrentRefresherTestsPassed: baseDarkNorm.green,
  CurrentRefresherTestsIgnored: baseDarkNorm.red,
  CurrentRefresherTestsFailed: baseDarkNorm.deepRed,

  PhishingLast30DaysOpened: baseDarkNorm.amber,
  PhishingLast30DaysNoActions: baseDarkNorm.green,
  PhishingLast30DaysClicked: baseDarkNorm.red,
  PhishingLast30DaysProvidedCredentials: baseDarkNorm.deepRed,

  ActiveAlarms: baseDarkNorm.blue,
  UnActiveAlarms: baseDarkNorm.red,

  PatchesBySeverity: {
    [PatchVendorSeverity._5]: baseDarkNorm.red,
    [PatchVendorSeverity._4]: baseDarkNorm.amber,
    [PatchVendorSeverity._3]: baseDarkNorm.green,
    [PatchVendorSeverity._2]: baseDarkNorm.blue,
    [PatchVendorSeverity._1]: baseDarkNorm.grey,
  },
  Radius: ['66%', '94%'],
  RestSectorOpacity: '0',
};
