import { Radio as AntdRadio } from 'antd';
import styled, { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

const ActiveStyles = css`
  background-color: ${getCssVar('radio.active.bg')};
  border-color: ${getCssVar('radio.active.color')} !important;

  &:after {
    background-color: ${getCssVar('radio.active.color')};
  }
`;

export const Radio = styled(AntdRadio)`
  color: ${getCssVar('textColor')};

  .ant-radio-inner {
    border-color: ${getCssVar('radio.borderColor')};
    background-color: transparent;
    z-index: 1;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }

  .ant-radio-disabled {
    opacity: ${getCssVar('radio.disabledOpacity')};

    .ant-radio-inner {
      border-color: ${getCssVar('radio.borderColor')} !important;
      background-color: ${getCssVar('radio.active.bg')};
    }

    &.ant-radio-checked {
      .ant-radio-inner {
        ${ActiveStyles};
      }

      &:after {
        display: none;
      }
    }
  }

  .ant-radio-checked:not(.ant-radio-disabled) {
    .ant-radio-inner {
      ${ActiveStyles};
    }
  }

  &:hover,
  &:focus {
    span:not(.ant-radio-checked):not(.ant-radio-disabled) {
      .ant-radio-inner {
        border-color: ${getCssVar('radio.borderColor')};
      }
    }
  }

  .ant-radio {
    &:not(.ant-radio-disabled) {
      &:after {
        border: none;
        position: absolute;
        content: '';
        left: -7px;
        top: -7px;
        width: calc(100% + 14px);
        height: calc(100% + 14px);
        border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
        background: ${getCssVar('radio.hover')};
        visibility: visible;
        transition: all 0.2s ease-out;
        animation: none;
        opacity: 0;
      }
    }
  }

  &.hover,
  &:hover,
  &:focus {
    .ant-radio {
      &:after {
        opacity: 1;
      }
    }
  }

  &.active,
  &:active {
    .ant-radio {
      &:after {
        opacity: 0;
      }
    }
  }
`;
