import styled, { css } from 'styled-components';
import { Input as AntdInput, Typography } from 'antd';
import { breakpoints, getCssVar } from 'styles/theme';
import { Box, Button, Input as InputShared } from 'shared';
import { Theme } from 'styles/theme/types';
import { ThemeProps } from '../../../services/interfaces';

type FormProps = {
  validateStatus: '' | 'success' | 'warning' | 'error' | 'validating';
};

type ButtonProps = {
  $isHidden?: boolean;
};

type InputTwoFAProps = {
  $isRequestError: boolean;
  theme: Theme;
};

const TITLE_LEVEL_3 = 3;

const inputAutoFillStyles = css`
  &:-webkit-autofill {
    -webkit-text-fill-color: ${getCssVar('login.formInput.textColor')};
    box-shadow: 0 0 0 50px ${getCssVar('login.formInput.backgroundColor')} inset !important;
    caret-color: ${getCssVar('login.formInput.textColor')};
    transition: background-color 5000s ease-in-out 0s, border-color 200ms linear 0s;

    &::selection {
      -webkit-text-fill-color: ${getCssVar('login.formInput.textColor')};
      background-color: #a6a6a680;
    }

    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border-color: ${getCssVar('login.formInput.borderColorHover')};
    }
  }
`;

export const Input = styled(AntdInput)`
  input {
    font-size: ${({ theme }) => theme.sizes[6]};
    font-weight: ${({ theme }) => theme.fontWeights.black};
    height: ${({ theme }) => theme.sizes[17]};
    letter-spacing: ${({ theme }) => theme.spacing['2.5']};
    text-align: center;
  }
`;
export const StyledButton = styled(Button)<ButtonProps>`
  display: ${({ $isHidden }) => $isHidden && 'none'};
  height: 44px;
  @media (max-width: ${breakpoints.lg}) {
    height: 48px;
  }
`;

export const FormInput = styled(InputShared)<FormProps>`
  .auth-form .ant-form-item-control-input & {
    &.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
      background-color: ${getCssVar('login.formInput.backgroundColor')};
      border-color: ${getCssVar('login.formInput.borderColor')};
      height: 40px;

      &:hover {
        background-color: ${getCssVar('login.formInput.backgroundColor')};
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
      &:focus {
        background-color: ${getCssVar('login.formInput.backgroundColor')};
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
      .ant-form-item-has-error & {
        border-color: ${getCssVar('login.formInput.borderColorError')};
      }
    }

    & input.ant-input,
    &.ant-input {
      background-color: ${getCssVar('login.formInput.backgroundColor')} !important;
      border-color: ${getCssVar('login.formInput.borderColor')};
      color: ${getCssVar('login.formInput.textColor')};
      ${inputAutoFillStyles}
      &:hover {
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
      .ant-form-item-has-error & {
        border-color: ${getCssVar('login.formInput.borderColorError')};
      }
    }
    .ant-input-group-addon {
      background-color: ${getCssVar('login.formInput.iconBackgroundColor')};
      border-color: ${getCssVar('login.formInput.borderColor')};
    }
    & .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
      background-color: ${getCssVar('login.formInput.backgroundColor')};
      border-color: ${getCssVar('login.formInput.borderColor')};
      height: 40px;

      input.ant-input {
        background-color: ${getCssVar('login.formInput.backgroundColor')} !important;
        border-color: ${getCssVar('login.formInput.borderColor')};

        ${inputAutoFillStyles}
        &:hover {
          background-color: ${getCssVar('login.formInput.backgroundColor')};
          border-color: ${getCssVar('login.formInput.borderColorHover')};
        }
        &:focus {
          background-color: ${getCssVar('login.formInput.backgroundColor')};
          border-color: ${getCssVar('login.formInput.borderColorHover')};
        }
      }

      @media (max-width: ${breakpoints.mdMax}) {
        height: 48px;
      }
    }
    .ant-input-wrapper:focus-within,
    .ant-input-wrapper:hover {
      input.ant-input,
      .ant-input-group-addon,
      .ant-input-affix-wrapper {
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
    }
    input.ant-input {
      background-color: ${getCssVar('login.formInput.backgroundColor')};
      border-color: ${getCssVar('login.formInput.borderColor')};
      color: ${getCssVar('login.formInput.textColor')};

      &:hover,
      &:focus {
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
      ${inputAutoFillStyles}
    }
    .ant-input-password-icon svg {
      fill: ${getCssVar('login.formInput.iconColor')};
    }
  }
  .auth-form
    .ant-form-item-has-error
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    & {
    .ant-input-group-addon,
    .ant-input-affix-wrapper,
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      background-color: ${getCssVar('login.formInput.backgroundColor')};
      border-color: ${getCssVar('login.formInput.borderColorError')};

      &:hover {
        background-color: ${getCssVar('login.formInput.backgroundColor')};
        border-color: ${getCssVar('login.formInput.borderColorError')};
      }
    }
    & :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    &.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
      background-color: ${getCssVar('login.formInput.backgroundColor')};
      border-color: ${getCssVar('login.formInput.borderColorError')};
      &:focus {
        background-color: ${getCssVar('login.formInput.backgroundColor')} !important;
        border-color: ${getCssVar('login.formInput.borderColorError')};
      }
      &:hover {
        background-color: ${getCssVar('login.formInput.backgroundColor')} !important;
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
    }
    .ant-input-wrapper:hover {
      .ant-input-group-addon,
      input.ant-input {
        background-color: ${getCssVar('login.formInput.backgroundColor')};
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
    }
    &:hover {
      input.ant-input {
        background-color: ${getCssVar('login.formInput.backgroundColor')};
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
      .ant-input-group-addon,
      .ant-input-affix-wrapper {
        background-color: ${getCssVar('login.formInput.backgroundColor')};
        border-color: ${getCssVar('login.formInput.borderColorHover')};
      }
    }
  }
`;

export const InputEmail = styled(FormInput)<FormProps>``;
export const InputPassword = styled(FormInput)<FormProps>``;

export const InputTwoFA = styled(Input)<InputTwoFAProps>`
  .step-tfa-setup &,
  .step-tfa-confirm & {
    background-color: ${getCssVar('login.tfa.inputBackgroundColor')};
    border-color: ${({ $isRequestError }: InputTwoFAProps) =>
      $isRequestError
        ? `${getCssVar('login.formInput.borderColorError')}`
        : `${getCssVar('login.formInput.borderColor')}`};
    box-shadow: none;

    input {
      background-color: ${getCssVar('login.tfa.inputBackgroundColor')};
      color: ${getCssVar('login.tfa.inputTextColor')};
      height: 100%;

      &:disabled {
        background-color: ${getCssVar('login.tfa.inputBackgroundColor')} !important;
        border: none !important;
        color: ${getCssVar('login.tfa.inputBorderColor')} !important;
      }
    }

    height: 68px;
    max-width: 280px;

    &:hover {
      border-color: ${getCssVar('login.formInput.borderColorHover')};
      box-shadow: none;
    }
  }
`;

export const TwoFAWrapper = styled(Box)<ThemeProps>`
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonCopy = styled(Button)<ThemeProps>`
  &.ant-btn.ant-btn-link {
    color: ${getCssVar('login.tfa.copyButtonColor')};
    font-size: ${({ theme }) => theme.spacing[4]};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    margin-bottom: ${({ theme }) => theme.spacing['2.5']};

    &:hover {
      color: ${getCssVar('login.tfa.copyButtonHoverColor')};
    }
  }
`;

export const TfaStepTitle = styled(Typography.Title)<ThemeProps & { level?: number }>`
  &.ant-typography {
    color: ${getCssVar('login.tfa.textColor')};
    font-size: ${({ theme }) => theme.spacing[4]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    ${({ level, theme }) =>
      level === TITLE_LEVEL_3 &&
      `
      font-size: ${theme.spacing[6]};
    `}

    @media (max-width: ${breakpoints.lg}) {
      color: #ffffff;
    }
  }
`;

export const TfaStepContent = styled(Typography.Paragraph)<ThemeProps>`
  color: ${getCssVar('login.tfa.textColor')};
  font-size: ${({ theme }) => theme.spacing['3.6']};
  margin-bottom: ${({ theme }) => theme.spacing[4]};

  a {
    color: ${getCssVar('login.tfa.linkColor')};

    &:hover {
      color: ${getCssVar('login.tfa.linkColorHover')};
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    color: #ffffff;
  }
`;

export const TfaCode = styled(Typography.Paragraph)<ThemeProps>`
  color: ${getCssVar('login.tfa.textColor')};
  font-size: ${({ theme }) => theme.spacing['4.5']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  @media (max-width: ${breakpoints.lg}) {
    color: ${getCssVar('login.tfa.mobile.textColor')};
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: ${({ theme }) => theme.spacing[4]};
  }
`;

export const InvalidCodeMessage = styled(Typography.Paragraph)<ThemeProps>`
  color: ${getCssVar('login.formInput.errorMessageColor')};
  font-size: ${({ theme }) => theme.spacing['4.2']};
  padding-top: ${({ theme }) => theme.spacing[4]};
`;
