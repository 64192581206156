import { CertificateStatusSchema } from 'services/api';

export const ABOUT_TO_EXPIRE_MONTHS = 3;

export const CERTIFICATE_STATUSES = {
  NOT_CERTIFIED: 'Not Certified', // No date
  CERTIFIED: 'Certified', // If the date is in the future by more than one month
  EXPIRED: 'Expired', //  If the date is in the past
  RENEWAL: 'Renewal', // If the date is in less than one month
};

export const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 8,
  xl: 8,
  xxl: 8,
};

export enum CertificateIcons {
  CyberEssentials = 'cyber_essentials',
  ActiveCyberEssentials = 'cyber_essentials_active',
  SelfAssessment = 'self_assessment',
  ActiveSelfAssessment = 'self_assessment_active',
  ISO = 'iso',
  ActiveISO = 'iso_active',
}

export const FailedCertificateStatuses = [
  CertificateStatusSchema.FAILED,
  CertificateStatusSchema.CANCELLED,
];
