import styled from 'styled-components';

export const ServiceChangesTable = styled.div`
  color: #fff;
  padding: 240px 70px 0px;

  position: relative;

  .row {
    border-bottom: 1px solid;

    border-color: ${({ theme }) => theme.colors.gray[520]};
    width: 100%;

    &:first-child {
      padding-top: 5px;
    }

    .column {
      display: inline-block;
      min-height: 45px;
      padding: 15px 15px;

      text-align: left;

      &:first-child {
        min-width: 587px;
      }

      &:nth-child(2) {
        min-width: 191px;
      }
    }
  }
`;
