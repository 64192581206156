import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { Box, Input, Select, Icon, Button } from 'shared';
import { icons, fontWeights, spacing } from 'styles/theme';
import { Popup } from 'shared/ActionPopup/styled';
import { PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, MessageType, useCustomer, useLoading } from 'store';
import { RequestStatus } from 'store/loading/slice';
import * as Yup from 'yup';
import {
  SuccessResponse,
  SuppressionGridResponse,
  UpdateVulnerabilitySuppressionRequest,
} from 'services/api';
import { showError, showMessage } from 'services/helpers';
import { Form } from 'antd';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { SelectValue } from 'antd/lib/select';
import {
  FieldNames,
  formErrorMessages,
  BUSINESS_REASON_MAX_LENGTH,
  suppressionModalTitle,
  FormFields,
} from '../constants';
import { FormValues } from '../types';
import { getSuppressionStatusOptions } from '../helpers';

type UpdateSuppressionPopupProps = {
  isVisible: boolean;
  suppression?: SuppressionGridResponse;
  setIsVisible: (value: boolean) => void;
  onSuccess: () => void;
};

const validationSchema = Yup.object({
  [FieldNames.Status]: Yup.string().required(formErrorMessages[FieldNames.Status].isRequired),
  [FieldNames.BusinessReason]: Yup.string()
    .trim()
    .required(formErrorMessages[FieldNames.BusinessReason].isRequired)
    .max(BUSINESS_REASON_MAX_LENGTH, formErrorMessages[FieldNames.BusinessReason].maxLength),
});

export const UpdateSuppressionPopup = ({
  isVisible,
  suppression,
  setIsVisible,
  onSuccess,
}: UpdateSuppressionPopupProps) => {
  const customer = useCustomer();
  const { id } = useParams<{ id: string }>();
  const dispatch: AppDispatch = useDispatch();
  const loading = useLoading([vulnerabilitiesApi.setVulnerabilitySuppression.typePrefix]);
  const onCancel = React.useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);
  const suppressionStatusOptions = getSuppressionStatusOptions(suppression?.status);

  const suppressionOnSubmit = React.useCallback(
    async (requestBody: UpdateVulnerabilitySuppressionRequest) => {
      const api = vulnerabilitiesApi.setVulnerabilitySuppression({
        customerId: customer,
        vulnerabilityId: id,
        requestBody: { ...requestBody },
      });

      const result = (await dispatch(api)) as PayloadAction<
        SuccessResponse,
        string,
        { requestStatus: string },
        { message: string }
      >;

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        const errorMessage = result.error.message;

        showError(errorMessage || undefined);

        return;
      }

      showMessage(
        {
          content: `Vulnerability suppression has been updated.`,
        },
        MessageType.Success,
      );
      onSuccess();
      onCancel();
    },
    [dispatch, customer, id, onSuccess, onCancel],
  );

  const formik = useFormik({
    validationSchema,
    initialValues: {
      status: suppressionStatusOptions[0].value,
      businessReason: '',
    },
    onSubmit: (values: FormValues) =>
      suppressionOnSubmit({
        status: values.status.trim(),
        businessReason: values.businessReason.trim(),
      }),
  });

  return (
    <Popup
      className='actions-modal'
      title={suppressionModalTitle}
      visible={isVisible}
      onCancel={onCancel}
      confirmLoading={loading[vulnerabilitiesApi.setVulnerabilitySuppression.typePrefix]}
      footer={[
        <Button key='cancel' type='secondary' onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={() => formik.handleSubmit()}
          disabled={!formik.dirty || !formik.isValid}
          loading={formik.isSubmitting}
        >
          Save
        </Button>,
      ]}
    >
      <Form layout='vertical' onFinish={formik.handleSubmit} initialValues={formik.initialValues}>
        <Form.Item label={FormFields.status.label} rules={[{ required: true }]}>
          <Select
            value={formik.values.status}
            options={suppressionStatusOptions}
            onChange={(value: SelectValue) => {
              formik.setFieldValue('status', value);
            }}
            suffixIcon={<Icon className='ant-select-suffix' component={icons.arrow_drop_down} />}
          />
        </Form.Item>
        <Form.Item label={FormFields.businessReason.label} rules={[{ required: true }]}>
          <Input
            type='textarea'
            id={FormFields.businessReason.name}
            name={FormFields.businessReason.name}
            onChange={formik.handleChange}
            value={formik.values[FormFields.businessReason.name as FieldNames]}
            validateStatus={
              formik.errors[FormFields.businessReason.name as keyof FormikErrors<FormValues>] &&
              formik.touched[FormFields.businessReason.name as keyof FormikTouched<FormValues>]
                ? 'error'
                : 'success'
            }
            onBlur={formik.handleBlur}
            maxLength={FormFields.businessReason.maxLength}
            required={FormFields.businessReason.required}
            placeholder={FormFields.businessReason.placeholder}
            height={FormFields.businessReason.height}
            help={
              formik.errors[FormFields.businessReason.name] &&
              formik.touched[FormFields.businessReason.name]
                ? formik.errors[FormFields.businessReason.name]
                : null
            }
            style={{ fontSize: 15 }}
          />
          {!formik.errors.businessReason && (
            <Box
              textAlign='right'
              fontSize='14px'
              fontWeight={fontWeights.light}
              marginTop={`-${spacing['4']}`}
              marginBottom={spacing['5']}
            >
              {formik.values.businessReason?.length} / {FormFields.businessReason.maxLength}
            </Box>
          )}
        </Form.Item>
      </Form>
    </Popup>
  );
};
