import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

export const NormRebrandingWrap = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  width: 100%;

  span.anticon {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    padding-left: 30px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 5;
    min-height: 400px;

    svg {
      max-width: 80vh;
      width: 90%;
      min-width: 260px;
    }
  }

  span.circle {
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 0px 164px 50px #e3f0f8;
    font-size: 0;
    margin: auto;
    position: absolute;

    &.circle__top {
      height: 90vh;
      min-height: 300px;
      min-width: 300px;
      width: 90vh;
      z-index: 1;
    }

    &.circle__middle {
      height: 60vh;
      min-height: 200px;
      min-width: 200px;
      width: 60vh;
      z-index: 2;
    }

    &.circle__center {
      height: 30vh;
      min-height: 100px;
      min-width: 100px;
      width: 30vh;
      z-index: 3;
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    position: fixed;
    left: 0;
    top: 0;
    span.anticon {
      display: none;
    }
  }

  @media (max-height: ${breakpoints.xsMax}) {
    span.anticon {
      padding-left: 15px;
    }
  }
`;
