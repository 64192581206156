import { ChangeEvent, ChangeEventHandler, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Form, Space } from 'antd';
import { Box, Button, Icon, Input } from 'shared';
import * as Yup from 'yup';
import { AppDispatch, MessageType, useApp, useCustomer, useTagsManagement } from 'store';
import { HostAssetOwnerSchema, TagSchema } from 'services/api';
import { tagsManagement } from 'store/tagsManagement/slice';
import { getCssVar, icons } from 'styles/theme';
import { showMessage } from 'services/helpers';
import { tagsManagementApi } from 'store/tagsManagement/thunks';
import { SaveEditButton } from './styled';
import { defaultFieldsValues, MAX_TAG_NAME_LENGTH_DESKTOP } from '../constant';
import { MAX_LENGTH_VALUE } from '../../CmdbHostDetails/components/AssetOwner/constants';
import { EditableTag } from '../../../services/interfaces';

export type AssetOwnerFormProps = {
  handleSave?: () => void;
  handleCancel?: () => void;
  assetOwner?: HostAssetOwnerSchema;
  editableTag?: EditableTag | null;
};

export const EditNameForm = ({ editableTag }: AssetOwnerFormProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { tagsList } = useTagsManagement();
  const { isMobile } = useApp();
  const customerId = useCustomer();

  const validationSchema = Yup.object({
    name: Yup.string().trim().max(MAX_LENGTH_VALUE),
  });

  useEffect(() => {
    return () => {
      dispatch(tagsManagement.actions.setEditTags(null));
    };
  }, [dispatch]);

  const handleCancel = () => {
    dispatch(tagsManagement.actions.setEditTags(null));
  };

  const formik = useFormik({
    initialValues: { name: editableTag?.name } || defaultFieldsValues,
    validationSchema,
    onSubmit: ({ name }: TagSchema) => {
      if (editableTag?.id && !formik.errors.name) {
        dispatch(
          tagsManagementApi.editTagById({
            customerId,
            tagId: editableTag.id,
            requestBody: { name: name?.trim() },
          }),
        );

        showMessage(
          {
            content: `Tag has been successfully edited.`,
          },
          MessageType.Success,
        );
      }
    },
  });

  const onEditTagHandler: ChangeEventHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value.length <= MAX_TAG_NAME_LENGTH_DESKTOP) {
        if (tagsList?.some((item) => item.name === event.currentTarget.value.trim())) {
          formik.handleChange(event);
          const delay = 1;

          setTimeout(() => formik.setErrors({ name: 'Tag name should be unique' }), delay);

          return;
        }

        formik.handleChange(event);
      }
    },
    [formik, tagsList],
  );

  return (
    <Form
      layout='vertical'
      onFinish={formik.handleSubmit}
      initialValues={formik.initialValues}
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'flex-start',
      }}
    >
      <Box display='flex' flexDirection={isMobile ? 'row' : 'column'}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Input
            type='text'
            id='name'
            name='name'
            defaultValue={editableTag?.name}
            value={formik.values.name}
            onChange={onEditTagHandler}
            validateStatus={formik.errors.name && formik.touched.name ? 'error' : 'success'}
            maxLength={MAX_LENGTH_VALUE}
            noSpace
          />
          <Space>
            <SaveEditButton
              htmlType='submit'
              type='primary'
              disabled={!!formik.errors.name || !formik?.values?.name?.trim().length}
            >
              <Icon component={icons.done} color={getCssVar('checkBox.active.color')} />
            </SaveEditButton>
            {!isMobile && (
              <Button type='link' onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </Space>
        </Box>
        {formik.errors.name && <Box color={getCssVar('errorColor')}>{formik.errors.name}</Box>}
      </Box>
      {isMobile && (
        <Button type='link' onClick={handleCancel}>
          Cancel
        </Button>
      )}
    </Form>
  );
};
