import { useTheme } from 'styled-components';
import { SmartBlocWrap, SmartBlocIcon } from './styled';

export const Smartbloc = () => {
  const { icons } = useTheme();

  return (
    <SmartBlocWrap>
      <SmartBlocIcon component={icons.smartBlocNew} />
    </SmartBlocWrap>
  );
};
