import styled from 'styled-components';
import { getCssVar } from 'styles/theme';
import { Box } from '../Box';

export const SegmentedWrapper = styled(Box)`
  .rc-segmented {
    background-color: ${getCssVar('leftMenu.themeSwitcher.bg')};
    border-radius: 6px;
    height: 36px;

    &-group {
      border-radius: 6px;
    }

    &-thumb {
      background-color: ${getCssVar('leftMenu.themeSwitcher.item.bg')};
      border-radius: 6px;
      color: ${getCssVar('textColor')};
    }

    &-item {
      border-radius: 6px;
      color: ${getCssVar('textColor')};
      opacity: 50%;

      &-selected {
        background-color: ${getCssVar('leftMenu.themeSwitcher.item.bg')};
        color: ${getCssVar('leftMenu.themeSwitcher.item.selected')};
        opacity: initial;
      }
    }
  }
`;
