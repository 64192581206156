import { IEChartsOption } from 'shared/charts/interfaces';
import { Theme } from 'styles/theme/types';
import { startCase, toLower, upperFirst } from 'lodash';
import { CultureDimensionSchema, CultureResponse } from 'services/api';
import { cultureTooltipFormatter } from 'shared/charts/formatters';
import { MAX_PERCENT } from 'app-constants';

const getName = (name: string) => upperFirst(toLower(startCase(name)));

const getData = (
  dimensions: CultureDimensionSchema[],
): {
  values: number[];
  dimensionNames: { name: string }[];
  tooltip: { name: string; value: number; percentage: number }[];
} => {
  const values: number[] = [];
  const dimensionNames: { name: string; min: number; max: number }[] = [];
  const tooltip: { name: string; value: number; percentage: number }[] = [];

  dimensions.map((dimension: CultureDimensionSchema) => {
    if (
      dimension.total === undefined ||
      dimension.dimensionName === undefined ||
      dimension.responses?.favorable === undefined
    ) {
      return dimension;
    }

    const percentage = +((dimension.responses?.favorable / dimension.total) * MAX_PERCENT).toFixed(
      2,
    );

    values.push(percentage);
    dimensionNames.push({ name: dimension.dimensionName, min: 0, max: MAX_PERCENT });
    tooltip.push({
      name: dimension.dimensionName,
      value: dimension.responses?.favorable,
      percentage,
    });

    return dimension;
  });

  return { values, dimensionNames, tooltip };
};

const getRadarValues = (values: CultureResponse) => {
  let indicator: { name: string }[] = [];
  const data: {
    value: number[];
    name: string;
    tooltip: { name: string; value: number; percentage: number }[];
  }[] = [];

  Object.entries(values).map((entry) => {
    if (!entry[1]) {
      return entry;
    }

    const { values, dimensionNames, tooltip } = getData(entry[1].dimensions || []);

    data.push({ value: values, name: getName(entry[0]), tooltip });
    indicator = dimensionNames;

    return entry;
  });

  return { indicator, data };
};

export const getOption = (
  values: CultureResponse,
  theme: Theme,
  radius: number,
  isMobile?: boolean,
): IEChartsOption => {
  const { indicator, data } = getRadarValues(values);

  return {
    color: [theme.colorSet.charts.lineColor, theme.colorSet.charts.lineColor],
    radar: {
      shape: 'polygin',
      splitLine: {
        lineStyle: {
          color: theme.colorSet.charts.culture.lineColor,
        },
      },
      splitArea: {
        areaStyle: {
          color: [
            theme.colorSet.charts.culture.areaStyle.internalColor,
            theme.colorSet.charts.culture.areaStyle.externalColor,
          ],
          opacity: 0.2,
        },
      },
      axisNameGap: 12,
      indicator,
      radius,
      axisName: {
        width: 10,
        fontSize: theme.sizes['3.4'],
        fontFamily: theme.colorSet.lessFontFamily,
        color: theme.colorSet.charts.axisLabel,
        overflow: 'break',
      },
      splitNumber: 4,
    },
    series: [
      {
        name: 'data',
        type: 'radar',
        data,
      },
    ],
    tooltip: isMobile
      ? undefined
      : {
          show: true,
          trigger: 'item',
          formatter: (params) => cultureTooltipFormatter(params.data.tooltip, params.data.name),
          textStyle: {
            color: theme.colorSet.charts.tooltip.color,
            fontFamily: theme.colorSet.lessFontFamily,
          },
          backgroundColor: theme.colorSet.charts.tooltip.bg,
          borderColor: theme.colorSet.charts.tooltip.bg,
        },
  };
};
