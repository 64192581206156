import { useTheme } from 'styled-components';
import {
  ActionsWrapper,
  GoalsWrapper,
  GoalTitle,
  GoalText,
  ActionItem,
  ActionMarker,
  ActionText,
  Page,
} from '../styled';
import { BackgroundImage } from '../../components/BackgroundImage';

export function PdfPage2() {
  const { colorSet } = useTheme();

  return (
    <Page>
      <BackgroundImage fileName='slide-2.jpg' />

      <GoalsWrapper>
        <GoalTitle>Goal 1:</GoalTitle>
        <GoalText>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</GoalText>

        <GoalTitle>Goal 2:</GoalTitle>
        <GoalText>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</GoalText>

        <GoalTitle>Goal 3:</GoalTitle>
        <GoalText>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</GoalText>
      </GoalsWrapper>

      <ActionsWrapper>
        <ActionItem>
          <ActionMarker bgColor={colorSet.baseColors.green} />
          <ActionText>
            <span>Action 1: </span>
            <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
          </ActionText>
        </ActionItem>

        <ActionItem>
          <ActionMarker bgColor={colorSet.baseColors.amber} />
          <ActionText>
            <span>Action 2: </span>
            <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
          </ActionText>
        </ActionItem>

        <ActionItem>
          <ActionMarker bgColor={colorSet.baseColors.red} />
          <ActionText>
            <span>Action 3: </span>
            <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
          </ActionText>
        </ActionItem>
      </ActionsWrapper>
    </Page>
  );
}
