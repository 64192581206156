import { useCallback, useState } from 'react';
import { icons } from 'styles/theme';
import { useAuth } from 'store';
import { NotificationsHeader } from 'shared/layouts/Inner/components/NotificationsHeader';
import { IconMenu, MainMenuDrawer } from 'shared/layouts/Inner/components';
import { MobileSidebarWrapper } from './styled';

export function MobileSidebar() {
  const { user } = useAuth();
  const [isVisible, setIsVisible] = useState(false);

  const drawerToggleHandler = useCallback(() => {
    setIsVisible((value) => !value);
  }, []);

  return (
    <MobileSidebarWrapper>
      <IconMenu component={icons.menu} onClick={drawerToggleHandler} />
      <NotificationsHeader />
      <MainMenuDrawer
        visible={isVisible}
        onClose={drawerToggleHandler}
        userName={user?.name || ''}
      />
    </MobileSidebarWrapper>
  );
}
