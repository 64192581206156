import { TagProps } from 'antd';
import { Tag, UcFirstLetter } from 'shared';
import { TagValue } from 'shared/TagColoring/constants';
import { SeverityOptionLabel } from '../constants';

type TagColoringProps = TagProps & {
  title: string;
};

export const TagColoring = ({ title, ...tagProps }: TagColoringProps) => {
  const tagType = (title: string) => {
    switch (title) {
      case SeverityOptionLabel.URGENT:
        return TagValue.Critical;
      case SeverityOptionLabel.CRITICAL:
        return TagValue.Error;
      case SeverityOptionLabel.SERIOUS:
        return TagValue.Warning;
      case SeverityOptionLabel.MEDIUM:
        return TagValue.Success;
      default:
        return TagValue.Default;
    }
  };

  return (
    <Tag key={title} {...tagProps} title={title} className={`ant-tag-${tagType(title)}`}>
      {UcFirstLetter(title)}
    </Tag>
  );
};
