import moment from 'moment/moment';
import { BoostScoreItemSchema, MonthlyReportResponse } from 'services/api';
import {
  EmailSecurity,
  Encryption,
  MobileDeviceManagement,
  MultiFactorAuthentication,
  TextWrapper,
  ScoreWrapper,
  FailedIcon,
  SuccessIcon,
} from './styled';
import { getSortedDashboardScores } from '../../helpers';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { asidePieFontSize } from '../config';
import { BackgroundImage } from '../../components/BackgroundImage';
import { Page } from '../styled';

enum BoostScoreTitles {
  ENCRYPTION = 'Encryption',
  MULTI_FACTOR_AUTHENTICATION = 'Multi-factor Authentication',
  EMAIL_SECURITY = 'Email security',
  MOBILE_DEVICE_MANAGEMENT = 'Mobile Device Management',
}

const componentMapper = {
  [BoostScoreTitles.ENCRYPTION]: Encryption,
  [BoostScoreTitles.MULTI_FACTOR_AUTHENTICATION]: MultiFactorAuthentication,
  [BoostScoreTitles.EMAIL_SECURITY]: EmailSecurity,
  [BoostScoreTitles.MOBILE_DEVICE_MANAGEMENT]: MobileDeviceManagement,
};

type Props = {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
};

export function PdfPage10({ data, onChartLoaded }: Props) {
  const scores = data?.dashboardScores || [];
  const checkingDashboardScores = getSortedDashboardScores([...scores])[scores.length - 1];
  const boostScore = checkingDashboardScores?.boost;
  const { boostScoreItems } = data;

  const renderIcon = (boostType: BoostScoreItemSchema) =>
    moment().valueOf() > moment(boostType?.expirationDate).valueOf() || !boostType?.vendor ? (
      <FailedIcon />
    ) : (
      <SuccessIcon />
    );

  return (
    <Page>
      <BackgroundImage fileName='slide-10.jpg' />

      <ScoreWrapper>
        <GradientPieReport
          height='120px'
          width='120px'
          fontSize={asidePieFontSize}
          score={boostScore || 0}
          onFinished={onChartLoaded}
        />
      </ScoreWrapper>

      <TextWrapper>
        <div className='title'>Insert Title here:</div>

        <ul>
          <li>Insert copy here. Pelictaspis experat emoluptatur.</li>
          <li>Insert copy here. Pelictaspis experat emoluptatur.</li>
          <li>Insert copy here. Pelictaspis experat emoluptatur.</li>
          <li>Insert copy here. Pelictaspis experat emoluptatur.</li>
        </ul>
      </TextWrapper>

      {boostScoreItems?.map((score: BoostScoreItemSchema) => {
        const PositionWrapper = componentMapper[score?.title as BoostScoreTitles] ?? 'div';

        return <PositionWrapper key={score.expirationDate}>{renderIcon(score)}</PositionWrapper>;
      })}
    </Page>
  );
}
