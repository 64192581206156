import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

type Props = {
  theme: Theme;
  color?: string;
};

export const Link = css<Props>`
  &:not(.ant-table-body) {
    text-transform: none;
    padding: 0;
    font-size: 16px;
    font-weight: ${getCssVar('link.fontWeight')};
    color: ${({ color }) => color || getCssVar('link.color')};

    &:not(:disabled) {
      .anticon {
        position: relative;

        svg {
          fill: ${({ color }) => color || getCssVar('link.color')};
        }

        &:after {
          content: '';
          border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
          background: ${getCssVar('link.bg')};
          position: absolute;
          left: 50%;
          top: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
          transition: all 0.25s ease;
        }
      }

      &:hover,
      &.hover,
      &:focus,
      &:active,
      &.active {
        opacity: 1;
        text-decoration: underline;

        .anticon {
          &:after {
            opacity: 0.24;
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    &:disabled {
      color: ${getCssVar('link.disabledColor')};
    }
  }

  .anticon + span,
  span + .anticon {
    margin-left: 8px;
  }
`;
