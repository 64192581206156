import React, { useCallback, useEffect } from 'react';
import { uniq } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  useApp,
  useLoading,
  MessageType,
  AppDispatch,
  useCustomer,
  useAuth,
  dplApi,
  useDbl,
} from 'store';
import {
  InnerLayout,
  MobileTable,
  MobileTableWrapper,
  EmptyTablePreview,
  ExpandedRowContainer,
  Box,
} from 'shared';
import { useGetPermission } from 'services/hooks';
import { RequestStatus } from 'store/loading/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DPLCategory,
  DPLDocumentSchema,
  DPLDocumentsResponse,
  GridRequestParams,
} from 'services/api';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { useFilter } from 'services/hooks/useFilter';
import {
  defaultPageSize,
  defaultCurrent,
  PermissionOptions,
  loadingNotificationMessage,
} from 'app-constants';
import { CustomTable } from 'shared/Table/CustomTable';
import { showError, showMessage } from 'services/helpers';
import { filtersFields, initialOrderBy, searchSettings } from './constants';
import { getColumns } from './components/TableColumns';
import { getLayoutConfig } from './helpers';
import { CategoryTable } from './components/CaregoryTable/CaregoryTable';
import { UploadPopup, ValuesType } from './components/UploadPopup/UploadPopup';

export const DataProtectionLibrary = () => {
  const { user } = useAuth();
  const { categories } = useDbl();
  const customerId = useCustomer();
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const loading = useLoading([dplApi.getCategories.typePrefix, dplApi.uploadFile.typePrefix]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { isMobile } = useApp();

  const [params, updateParams] = useFilter({
    apiCall: dplApi.getCategories,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
    noRequest: true,
  });

  const permission = useGetPermission(PermissionOptions.DataProtection);

  const layoutConfig = getLayoutConfig(
    () => setIsModalVisible(true),
    permission,
    categories,
    user.isSuperAdmin,
  );

  useEffect(() => {
    dispatch(dplApi.getCategories(params));
    // eslint-disable-next-line
  }, [params.customerId]);

  useEffect(() => {
    if (!params.filter?.search && !params.filter?.fields) {
      return;
    }

    setIsSearching(true);
    dispatch(dplApi.fetchTableContent(params))
      .then((e) => {
        const result = e as PayloadAction<
          DPLDocumentsResponse,
          string,
          { arg: GridRequestParams; requestId: string; requestStatus: string },
          never
        >;

        setExpandedRowKeys(
          uniq(result.payload?.rows?.map((e: DPLDocumentSchema) => e?.categoryId || '')),
        );
      })
      .finally(() => {
        setIsSearching(false);
      });
    // eslint-disable-next-line
  }, [params.filter?.search, params.filter?.fields]);

  React.useEffect(() => {
    if (loading[dplApi.getCategories.typePrefix]) {
      return;
    }

    if (Object.entries(loading).some((action: [string, boolean]) => action[1])) {
      showMessage(loadingNotificationMessage, MessageType.Loading);
    }
  }, [loading]);

  const columns = getColumns();

  const handleUpload = useCallback(
    async (file: File, data: ValuesType) => {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const blob = new Blob([e?.target?.result as ArrayBuffer], {
          type: 'application/octet-stream',
        });
        const result = (await dispatch(
          dplApi.uploadFile({
            customerId,
            ...data,
            createdDate: data.createdDate || 0,
            requestBody: blob,
            filename: file.name,
          }),
        )) as PayloadAction<
          { message: string; showing?: boolean },
          string,
          { requestStatus: string },
          { message: string }
        >;

        if (result.meta.requestStatus === RequestStatus.Rejected) {
          showError(result.payload.showing ? result.payload.message : undefined);

          return;
        }

        showMessage({ content: `File ${file.name} successfully uploaded.` }, MessageType.Success);
        updateParams({ ...params, filter: { ...params.filter } });
      };
    },
    [dispatch, customerId, params, updateParams],
  );
  const emptyMessage = (
    <EmptyTablePreview
      icon='empty_table'
      alignCenter
      textContent={
        <Box>
          No documents and files available yet
          <br /> Here will be displayed all documents that you will be able to download.
        </Box>
      }
    />
  );

  return (
    <InnerLayout {...layoutConfig}>
      {!isMobile ? (
        <CustomTable<DPLCategory, GridRequestParams>
          data={{ rows: categories, count: categories?.length }}
          columns={getTableColumns(columns, params.orderBy)}
          params={params}
          searchSettings={searchSettings}
          setParams={updateParams}
          expandable={{
            expandedRowRender: (row: DPLCategory) => (
              <ExpandedRowContainer>
                <CategoryTable categoryId={row.id || ''} filters={params.filter} />
              </ExpandedRowContainer>
            ),
            onExpandedRowsChange: (expandedRows: readonly React.Key[]) => {
              setExpandedRowKeys(expandedRows as string[]);
            },
          }}
          expandedRowKeys={expandedRowKeys}
          noPagination
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          filterFields={filtersFields}
          isLoadingContent={loading[dplApi.getCategories.typePrefix] || isSearching}
          emptyMessage={emptyMessage}
        />
      ) : (
        <MobileTableWrapper>
          <MobileTable
            data={{ rows: categories, count: categories?.length }}
            columns={columns}
            params={params}
            searchSettings={searchSettings}
            initialOrderBy={initialOrderBy}
            setParams={updateParams}
            filterFields={filtersFields}
            noPagination
            isLoadingContent={loading[dplApi.getCategories.typePrefix]}
            emptyMessage={emptyMessage}
            sortable={false}
          />
        </MobileTableWrapper>
      )}
      {isModalVisible && (
        <UploadPopup
          categories={categories}
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          handleOk={handleUpload}
          uploadFileSize={user.uploadFileSize?.documents}
        />
      )}
    </InnerLayout>
  );
};
