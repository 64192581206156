import { createRef, useCallback, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { EChartsOption } from 'echarts-for-react/src/types';
import styled from 'styled-components';
import { EChartToImage } from '.';

type GradientReportContainerProps = {
  score?: number | null;
  height?: string;
  width?: string;
  onFinished: () => void;
  option: EChartsOption;
};

const Wrapper = styled(EChartToImage)`
  border-radius: 0;
  border-width: 0;
`;

export function ReportChart({
  score,
  width = '60px',
  height = '60px',
  onFinished,
  option,
}: GradientReportContainerProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = createRef<ReactECharts>();

  const onChartReadyHandler = useCallback((instance) => {
    instance.on('finished', () => {
      setIsLoaded(true);
    });
  }, []);

  return (
    <Wrapper
      chartRef={ref}
      height={height}
      width={width}
      isLoaded={isLoaded}
      score={score}
      onFinished={onFinished}
    >
      <ReactECharts
        style={{ height, width }}
        onChartReady={onChartReadyHandler}
        ref={ref}
        opts={{ renderer: 'canvas' }}
        option={option}
      />
    </Wrapper>
  );
}
