import React, { useCallback, useRef } from 'react';
import { useApp, useLoading, usePatchManagement, usePlatform } from 'store';
import { Card } from 'shared';
import { Legend, SingleBar } from 'shared/charts';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { useHistory } from 'react-router';
import ReactECharts from 'echarts-for-react';
import { useTheme } from 'styled-components';
import { patchDashboardApi } from 'store/patchManagement/dashboard/thunks';
import { initialPagination } from 'app-constants';
import { Filter, PatchStatus } from 'services/api';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { Routes } from 'services/entities';
import { getQueryString } from 'services/api/core/request';
import { isEmptyObject } from 'services/helpers';
import { ScrollWrapper } from '../../../styled';
import { getLegendValues } from '../../../helpers';
import {
  getPatchesByCategoryColors,
  initialOrderByPatches,
  PBBackRoute,
  TooltipTitles,
} from '../../../constants';
import { getOption, getSecurityFilter } from './helpers';

export const ByCategory = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const history = useHistory();
  const platform = usePlatform();
  const { patchesGroupedBy } = usePatchManagement();
  const loading = useLoading([patchDashboardApi.getPatchesGroupedBy.typePrefix]);
  const chartRef = useRef<ReactECharts>();

  const categoryData = patchesGroupedBy?.find(({ chart }) => chart === 'category');

  const legendValues = getLegendValues(categoryData?.points, 'title');

  const getPatchParams = (value: string[]) => ({
    orderBy: initialOrderByPatches,
    pagination: initialPagination,
    filter: {
      fields: [
        {
          name: 'patchStatus',
          value: [PatchStatus.MISSING],
          type: Filter.type.MULTIPLE,
        },
        {
          name: 'category',
          value,
          type: Filter.type.MULTIPLE,
        },
      ],
    },
  });

  const redirectToPatchesGrid = useCallback(
    (isSecurity) =>
      history.push(
        `${Routes.PATCH_MANAGEMENT_PATCHES}${getQueryString(getPatchParams(isSecurity))}`,
        PBBackRoute,
      ),
    [history],
  );

  const handleAnnotationClick = (name: string) => {
    if (!name) {
      return;
    }

    redirectToPatchesGrid(getSecurityFilter(name));
  };

  const onEvents = React.useMemo(
    () => ({
      click: (params?: EChartsEventParams) =>
        redirectToPatchesGrid(getSecurityFilter(params?.name)),
    }),
    [redirectToPatchesGrid],
  );

  return (
    <Card
      title='By category'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles(platform).ByCategory}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}` : `${theme.sizes[5]}`
      }
      showLoader={loading[patchDashboardApi.getPatchesGroupedBy.typePrefix]}
    >
      {!isEmptyObject(legendValues) ? (
        <ScrollWrapper>
          <SingleBar
            values={legendValues}
            getOption={getOption}
            height='300px'
            onEvents={onEvents}
            chartRef={(r: ReactECharts) => {
              chartRef.current = r;
            }}
            isLoading={loading[patchDashboardApi.getPatchesGroupedBy.typePrefix]}
          />
          <Legend
            values={legendValues}
            colors={getPatchesByCategoryColors(theme)}
            direction='row'
            marginTop={theme.sizes[4]}
            cursor='pointer'
            onAnnotationClick={handleAnnotationClick}
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
          />
        </ScrollWrapper>
      ) : (
        emptyMessage('No missing patches by category yet')
      )}
    </Card>
  );
};
