import { Colors, Values } from 'shared/charts/types';
import styled from 'styled-components';
import { startCase, toLower, upperFirst } from 'lodash';
import { calcPercentage } from 'shared/charts';

type Props = {
  values: Values;
  colors: Colors;
  asColumn?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div:not(:last-child) {
    margin-bottom: 8px;
    margin-right: 20px;
  }
`;

const WrapperColumn = styled.div`
  display: block;

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Row = styled.div`
  font-size: 10px;
  line-height: 1;
`;

const Indicator = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  display: inline-block;
  height: 8px;
  margin-right: 3px;
  width: 8px;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.gray[530]};
  display: inline-block;
  text-wrap: nowrap;

  span:first-child {
    margin-right: 6px;
  }
`;

export function Legend({ values, colors, asColumn = false }: Props) {
  const percentages = calcPercentage(values);
  const WrapperComponent = asColumn ? WrapperColumn : Wrapper;

  return (
    <WrapperComponent>
      {Object.entries(values).map(([key, value]) => (
        <Row key={key}>
          <Indicator color={colors[key]} />
          <Label>
            <span>{upperFirst(toLower(startCase(key)))}</span>
            <span>
              {value} ({percentages[key] || 0}%)
            </span>
          </Label>
        </Row>
      ))}
    </WrapperComponent>
  );
}
