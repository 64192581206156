import { colors } from '../../colors';
import { inputsBase } from './base';

export const datePicker = {
  checked: {
    bg: inputsBase.secondaryColor,
    borderColor: inputsBase.secondaryColor,
    inputBg: colors.gray[80],
  },
  bg: inputsBase.backgroundColor,
  textColor: inputsBase.textColor,
  iconColor: '#00000040',
  borderColor: colors.gray[100],
  hoverBorderColor: colors.gray[300],
  color: colors.green[200],
  pickerPanelBg: colors.gray[100],
  pickerPanelBorder: colors.gray[300],
  pickerPanelTextColor: inputsBase.primaryColor,
  rangeHoverBorderColor: inputsBase.secondaryColor,
  innerBg: '#f0efef',
  cellRangeBg: inputsBase.backgroundColorDisabled,
  disabledRangeBG: inputsBase.backgroundColorDisabled,
  clearIconColor: 'rgb(166, 158, 153)',
  nextPrevIconColor: colors.black,
  nextPrevIconColorHover: inputsBase.primaryColor,
};
