import { Dropdown } from 'antd';
import React from 'react';
import { Icon, ActionsDDL } from 'shared';
import { useTheme } from 'styled-components';
import { CustomMenuItem, Menu } from 'shared/Menu/Menu.styled';
import { manageTagDropDownItems, ManageTagDropdownLabels } from '../constants';
import { BulkActionTagsSelector } from './BulkActionTagsSelector';

type IManageTagDropdownProps = {
  children: JSX.Element | JSX.Element[];
  placement?: 'bottomLeft' | 'bottomCenter';
  disabled?: boolean;
  onClick: (soft: boolean, action?: string, selectedTag?: string) => void;
};

type DropdownItem = {
  key: string;
  label: string;
  isDanger?: boolean;
};

export const ManageTagDropdown = ({
  children,
  onClick,
  placement,
  disabled,
}: IManageTagDropdownProps) => {
  const theme = useTheme();
  const [showTagLabel, setShowLabel] = React.useState('');
  const handleItemClick = ({ key }: { key: React.Key }) => {
    setShowLabel(key as string);
  };

  React.useEffect(() => {
    return () => {
      setShowLabel('');
    };
  }, []);

  const defaultDropDownOptions = (
    <ActionsDDL onClick={handleItemClick}>
      {manageTagDropDownItems.map((item: DropdownItem) => {
        return (
          <ActionsDDL.Item
            key={item.key}
            danger={item.isDanger}
            icon={<Icon component={item.isDanger ? theme.icons.delete : theme.icons.add} />}
          >
            {item.label}
          </ActionsDDL.Item>
        );
      })}
    </ActionsDDL>
  );

  const dropDownActions = (
    <Menu>
      <CustomMenuItem>
        <BulkActionTagsSelector
          closeSelector={() => setShowLabel('')}
          label={showTagLabel}
          hideCreateTagButton={showTagLabel === ManageTagDropdownLabels.AddTags}
          selectTag={onClick}
        />
      </CustomMenuItem>
    </Menu>
  );

  const dropdownProps: {
    visible?: boolean;
  } = {};

  if (showTagLabel) {
    dropdownProps.visible = true;
  }

  return (
    <Dropdown
      overlay={showTagLabel ? dropDownActions : defaultDropDownOptions}
      trigger={['click']}
      disabled={disabled}
      placement={placement || 'bottomLeft'}
      {...dropdownProps}
    >
      {children}
    </Dropdown>
  );
};
