import React, { ReactNode, useCallback, useState } from 'react';
import Truncate from 'react-truncate';
import { ReadMoreButton } from './styled';

type Props = {
  text: string | ReactNode;
  lines?: number;
  lessText?: string;
  moreText?: string;
};

const defaultLines = 4;

export function ReadMoreText({
  text,
  lines = defaultLines,
  lessText = 'See less',
  moreText = 'See more',
}: Props) {
  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleTruncate = useCallback(
    (isTruncated: boolean) => {
      if (truncated !== isTruncated) {
        setTruncated(isTruncated);
      }
    },
    [truncated],
  );

  const toggleExpanded = useCallback(() => {
    setExpanded((value) => !value);
  }, []);

  return (
    <>
      <Truncate
        lines={expanded ? false : lines}
        onTruncate={handleTruncate}
        ellipsis={
          <>
            ...{' '}
            <ReadMoreButton type='link' onClick={toggleExpanded}>
              {moreText}
            </ReadMoreButton>
          </>
        }
      >
        {text}
      </Truncate>
      {!truncated && expanded && (
        <ReadMoreButton type='link' onClick={toggleExpanded} style={{ marginLeft: '5px' }}>
          {lessText}
        </ReadMoreButton>
      )}
    </>
  );
}
