import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Routes } from 'services/entities';
import { Gutter } from 'antd/lib/grid/row';
import { useModal } from 'services/hooks/useModal';
import {
  adminUsersApi,
  AppDispatch,
  authApi,
  useAdminUsers,
  useApp,
  useCustomer,
  useLoading,
} from 'store';
import { Col, Row } from 'antd';
import { Button, EmptyTablePreview, InnerLayout, MobileTable } from 'shared';
import { GridRequestParams, UserSchema } from 'services/api';
import { useGetPermission } from 'services/hooks';
import { backlinkRoutes } from 'app-constants/routes';
import { CustomTable } from 'shared/Table/CustomTable';
import {
  defaultCurrent,
  defaultPageSize,
  PermissionOptions,
  PermissionsLevel,
} from 'app-constants';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { MobileTableCol } from 'shared/Table/styled';
import { getLayoutConfig } from './helpers';
import { getColumns } from './components/TableColumns';
import { filterFields, initialOrderBy, searchSettings } from './constants';
import { MobileUserActions } from './components/MobileUserActions';
import { CreateUserPopup } from './components/CreateUserPopup';
import { useFilter } from '../../services/hooks/useFilter';
import { CreateModalMode } from './types';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const FULL_WIDTH_COLS = 32;

export const Users = () => {
  const modal = useModal();
  const isFullAccess = useGetPermission(PermissionOptions.AdminUsers) === PermissionsLevel.Full;
  const customerId = useCustomer();
  const { data } = useAdminUsers();

  const loading = useLoading([
    adminUsersApi.adminUsersTableContent.typePrefix,
    adminUsersApi.lockUser.typePrefix,
    adminUsersApi.unlockUser.typePrefix,
    adminUsersApi.deleteUser.typePrefix,
    adminUsersApi.resetUserTwofa.typePrefix,
    authApi.resetUserPasswordInit.typePrefix,
  ]);
  const history = useHistory();
  const { isMobile } = useApp();
  const GUTTER: [Gutter, Gutter] = !isMobile
    ? [H_GUTTER, V_GUTTER]
    : [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

  const dispatch: AppDispatch = useDispatch();

  const [params, updateParams] = useFilter({
    apiCall: adminUsersApi.adminUsersTableContent,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
  });

  const hasTableContentOrFilter = Boolean(data.count || (!data.count && params.filter));

  const layoutConfig = getLayoutConfig(modal.showModal, isFullAccess && hasTableContentOrFilter);

  const getUsersTableContent = React.useCallback(() => {
    dispatch(adminUsersApi.adminUsersTableContent(params));
  }, [dispatch, params]);

  const redirectToUserDetails = React.useCallback(
    (record: UserSchema) => {
      history.push(`${Routes.USERS}/${record.id}`, {
        backTo: {
          route: `${Routes.USERS}`,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[Routes.USERS]}`,
        },
      });
    },
    [history],
  );

  const getActions = React.useCallback(
    (usersRow: UserSchema) => (
      <MobileUserActions usersRow={usersRow} onSuccess={getUsersTableContent} />
    ),
    [getUsersTableContent],
  );

  const emptyMessage = (
    <EmptyTablePreview
      textContent='No users yet'
      loading={loading[adminUsersApi.adminUsersTableContent.typePrefix]}
      withoutMarginTop
      actionButton={
        isFullAccess ? (
          <Button type='primary' onClick={() => modal.showModal()}>
            Create user
          </Button>
        ) : undefined
      }
    />
  );

  return (
    <InnerLayout {...layoutConfig}>
      {isMobile ? (
        <Row gutter={GUTTER}>
          <MobileTableCol span={FULL_WIDTH_COLS} flex='auto'>
            <MobileTable<UserSchema, GridRequestParams>
              data={data}
              searchSettings={searchSettings}
              columns={getColumns(getUsersTableContent, false)}
              filterFields={filterFields}
              onRowClick={redirectToUserDetails}
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              isLoadingContent={loading[adminUsersApi.adminUsersTableContent.typePrefix]}
              getActions={isFullAccess ? getActions : undefined}
              emptyMessage={emptyMessage}
            />
          </MobileTableCol>
        </Row>
      ) : (
        <Row gutter={GUTTER}>
          <Col span={FULL_WIDTH_COLS} xs={24} xxl={24}>
            <CustomTable<UserSchema, GridRequestParams>
              data={data}
              columns={getTableColumns(
                getColumns(getUsersTableContent, isFullAccess),
                params.orderBy,
              )}
              params={params}
              setParams={updateParams}
              searchSettings={searchSettings}
              defaultPageSize={defaultPageSize}
              defaultCurrent={defaultCurrent}
              filterFields={filterFields}
              isLoadingContent={loading[adminUsersApi.adminUsersTableContent.typePrefix]}
              onRowClickHandle={redirectToUserDetails}
              emptyMessage={emptyMessage}
            />
          </Col>
        </Row>
      )}
      {modal.isModalShown && (
        <CreateUserPopup
          isVisible={modal.isModalShown}
          mode={CreateModalMode.Create}
          setIsVisible={(value: boolean) => (value ? modal.showModal() : modal.hideModal())}
          onSuccess={getUsersTableContent}
        />
      )}
    </InnerLayout>
  );
};
