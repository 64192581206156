import { Box, Tag } from 'shared';
import { useTheme } from 'styled-components';
import { getCssVar } from 'styles/theme';
import { getConnectionColor, getConnectionStatus } from '../../helpers';

export type StatusProp = {
  isConnected?: boolean;
};

export const Status = ({ isConnected = false }: StatusProp) => {
  const theme = useTheme();
  const status = getConnectionStatus(isConnected);
  const color = getConnectionColor(isConnected);

  return (
    <Box
      fontFamily={getCssVar('process.labelFontFamily')}
      fontWeight={theme.colorSet.process.labelWeight}
      fontSize={theme.sizes['3.4']}
      position='absolute'
      top={`-${theme.spacing['0.5']}`}
      left='32px'
    >
      <Tag color={color} style={{ margin: 0, padding: '2px 6px' }}>
        {status}
      </Tag>
    </Box>
  );
};
