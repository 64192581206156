import styled from 'styled-components';
import { AsideDescription } from '../styled';

export const TextWrapper = styled(AsideDescription)`
  color: #fff;
  left: 83px;
  max-width: 220px;
  top: 222px;

  .title {
    font-weight: bold;
  }

  ul {
    margin: 20px 0;
  }
`;

export const ScoreWrapper = styled.div`
  position: absolute;
  right: 77px;
  top: 238px;
`;

export const Encryption = styled.div`
  left: 399px;
  position: absolute;
  top: 274px;
`;

export const MultiFactorAuthentication = styled(Encryption)`
  left: 532px;
`;

export const EmailSecurity = styled(Encryption)`
  left: 643px;
`;

export const MobileDeviceManagement = styled(Encryption)`
  left: 751px;
`;

export const FailedIcon = styled.div`
  border-radius: 50%;
  height: 47px;
  position: relative;
  width: 47px;

  &:before,
  :after {
    background-color: #fff;
    content: ' ';
    height: 25px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 2px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const SuccessIcon = styled.div`
  border-radius: 50%;
  height: 47px;
  position: relative;
  width: 47px;

  &:before {
    border: 2px solid #fff;
    border-left: none;
    border-top: none;
    content: ' ';
    height: 24px;
    left: 16px;
    position: absolute;
    top: 7px;
    transform: rotate(40deg);
    width: 13px;
  }
`;
