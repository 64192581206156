import React, { useCallback, useRef } from 'react';
import { isEmptyObject, numberFormatter } from 'services/helpers';
import { useApp, useCEPlus, useLoading } from 'store';
import { Card } from 'shared';
import { Legend, SingleBar } from 'shared/charts';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import ReactECharts from 'echarts-for-react';
import { useHistory } from 'react-router';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { Routes } from 'services/entities';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { useTheme } from 'styled-components';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { cyberEssentialPlusApi } from 'store/cyberEssentialPlus/thunks';
import { getQueryString } from 'services/api/core/request';
import { getOption } from './helpers';
import {
  cePlusBackRoute,
  getTopClientOperatingSystemChartColors,
  tooltipTitles,
} from '../../constants';
import { getLegendColors, getLegendValues } from '../../../PatchManagement/helpers';
import { ScrollWrapper } from '../../styled';
import { getFullCMDBParams, viewMoreConfig } from '../../helpers';

export const TopClientOperatingSystem = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const history = useHistory();
  const chartRef = useRef<ReactECharts>();
  const { topClientOperatingSystem } = useCEPlus();
  const { selectedTags } = useCEPlus();

  const loading = useLoading([cyberEssentialPlusApi.getTopClientOperatingSystem.typePrefix]);

  const redirectToCMDBGrid = useCallback(
    (osName: string) => {
      const getParamByName =
        topClientOperatingSystem?.chart?.find((data) => data.title === osName) || {};

      history.push(
        `${Routes.CMDB}${getQueryString(getFullCMDBParams(getParamByName, selectedTags))}`,
        cePlusBackRoute,
      );
    },
    [history, topClientOperatingSystem?.chart, selectedTags],
  );

  const legendValues = getLegendValues(topClientOperatingSystem?.chart);

  const handleAnnotationClick = (osName: string) => {
    redirectToCMDBGrid(osName);
  };

  const onEvents = React.useMemo(
    () => ({
      click: (params?: EChartsEventParams) => {
        if (!params?.name) {
          return null;
        }

        return redirectToCMDBGrid(params.name);
      },
    }),
    [redirectToCMDBGrid],
  );

  return (
    <Card
      title='Top 5 client operating system'
      showDivider={false}
      subTitle={
        <SubTitle
          boldText={numberFormatter(topClientOperatingSystem.count || 0, 1)}
          customText='Total'
        />
      }
      height={theme.sizes.full}
      tooltipTitle={tooltipTitles.topClientOperatingSystem}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getTopClientOperatingSystem.typePrefix]}
      viewMore={viewMoreConfig(selectedTags).client}
    >
      {!isEmptyObject(legendValues) ? (
        <ScrollWrapper>
          <TotalCustomTooltipContainer>
            <SingleBar
              values={legendValues || {}}
              getOption={getOption}
              height='300px'
              onEvents={onEvents}
              chartRef={(r: ReactECharts) => {
                chartRef.current = r;
              }}
              isLoading={loading[cyberEssentialPlusApi.getTopClientOperatingSystem.typePrefix]}
            />

            <Legend
              values={legendValues || {}}
              colors={getLegendColors(legendValues, getTopClientOperatingSystemChartColors(theme))}
              direction='row'
              marginTop={theme.sizes[4]}
              cursor='pointer'
              onAnnotationClick={handleAnnotationClick}
              isMobile={isMobile}
              isMenuCollapsed={isMenuCollapsed}
              isShowingUnchangedText
            />
          </TotalCustomTooltipContainer>
        </ScrollWrapper>
      ) : (
        emptyMessage(`No top client operating system yet`)
      )}
    </Card>
  );
};
