import styled from 'styled-components';
import { ThemeProps } from 'services/interfaces';
import { Box, Tag } from 'shared';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';
import { EmailsChartContainer } from '../Dashboard/components/styled/styled';
import { ChartContainerProps } from '../ThreatDetection/components/styled';

type WrapperProps = {
  $collapsed?: boolean;
  $isEmptyPieChart?: boolean;
  color?: string;
};

export const VulnerabilityWrapper = styled(Box)<ThemeProps & WrapperProps>`
  align-items: start;
  display: flex;
  flex-direction: row;
  height: 150px;
  justify-content: center;

  @media (max-width: ${({ $collapsed }: WrapperProps) => ($collapsed ? '1300px' : '1450px')}) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: ${({ $isEmptyPieChart }: WrapperProps) => ($isEmptyPieChart ? '300px' : '490px')};
  }
`;

export const StatusWrapper = styled(Tag)<ThemeProps & WrapperProps>`
  align-items: center;
  color: ${getCssVar('textColor')};
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-weight: 400;
  max-width: 150px;
  padding: 4px 6px;
  text-transform: none;
`;

export const AssetsChart = styled(EmailsChartContainer)<ThemeProps>``;

export const VendorChart = styled(AssetsChart)<ThemeProps>`
  .chart-container {
    width: 100%;
  }
`;

export const ScrollWrapper = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xml}) {
    overflow-x: auto;
    .echarts-for-react,
    .ant-radio-group {
      min-width: 600px;
    }
  }

  ::-webkit-scrollbar {
    height: ${getCssVar('scrollbar.height')};
    bottom: 10px;
    width: 50px;
  }

  ::-webkit-scrollbar-track {
    background: ${getCssVar('scrollbar.bg')};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${getCssVar('scrollbar.color')};
    border-radius: 10px;
  }
`;

export const HardwareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  div::-webkit-scrollbar-track {
    margin-right: 0px !important;
  }
`;

export const ChartContainer = styled.div<ChartContainerProps>`
  @media (min-width: ${({ theme }: ChartContainerProps) => theme.breakpoints.md}) {
    overflow-y: ${({ isScrollable }: ChartContainerProps) => (isScrollable ? 'scroll' : 'hidden')};

    .echarts-for-react {
      max-height: 350px;
    }
  }
`;
