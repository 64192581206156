import styled from 'styled-components';

const minBarItemWidth = 15;

export const BarItem = styled.div<{ isEmpty?: boolean }>`
  background: #000;
  line-height: 20px;
  min-width: ${({ isEmpty }) => (isEmpty ? 1 : minBarItemWidth)}px;
  text-wrap: nowrap;
  transition: opacity 0.3s ease-in-out;

  &:first-child {
    border-radius: 2px 0 0 2px;
  }

  &:last-child {
    border-radius: 0 2px 2px 0;
  }
`;

export const Bar = styled.div`
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;
