import { ReactNode } from 'react';
import { parseHtml, removeShowMoreAttribute, removeStyleAttribute } from 'services/helpers';
import { ReadMoreText } from 'shared/ReadMoreText/ReadMoreText';
import {
  CommonDetailsBlock,
  CommonDetailsHeader,
  CommonDetailsContent,
  CommonDetailsMain,
} from './styled';

type CommonDataItemProps = {
  title: string;
  data?: string;
};

export const CommonDetailsItem = ({ title, data }: CommonDataItemProps) => {
  if (!data) {
    return null;
  }

  return (
    <CommonDetailsBlock>
      <CommonDetailsHeader>{title}</CommonDetailsHeader>
      <CommonDetailsMain>
        <CommonDetailsContent>
          <ReadMoreText
            lines={3}
            text={parseHtml(removeStyleAttribute(removeShowMoreAttribute(data))) as ReactNode}
          />
        </CommonDetailsContent>
      </CommonDetailsMain>
    </CommonDetailsBlock>
  );
};
