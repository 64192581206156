import { colors } from '../colors';
import { icons } from '../icons';

export const leftMenu = {
  headerBgColor: colors.black,
  logoTop: 'menu-logo-top-nasstar.svg',
  logoTopSmall: 'menu-logo-top-small-nasstar.svg',
  logoTopWidth: '189',
  logoTopWidthCollapsed: '26',
  logoTopHeight: '29',
  logoTopHeightCollapsed: '24',
  toggleIconColor: colors.gray[510],
  toggleIconColorMobile: colors.gray[510],

  avatarColor: colors.black,
  userNameColor: colors.white,
  userAvatarColor: colors.pink[500],
  logoutColor: colors.gray[530],

  logoBottom: icons.poweredByNormMenuLogo,
  logoBottomCollapsed: icons.poweredByNormMenuLogoCollapsed,

  drawerFooterLogo: icons.drawerFooterLogo,

  footerBgColor: colors.black,

  themeSwitcher: {
    item: {
      bg: colors.white,
      selected: colors.black,
    },
    bg: '#0000000f',
  },
};
