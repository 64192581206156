import { IEChartsOption } from 'shared/charts/interfaces';
import { Values } from 'shared/charts/types';
import { Theme } from 'styles/theme/types';
import { cePlusTooltips } from 'shared/charts/formatters';
import { getTopClientOperatingSystemChartColors, MAX_CHART_TEXT_LENGTH } from '../../constants';
import { trimmedString } from '../../helpers';

export const getOption = (
  values: Values,
  theme: Theme,
  hasClickHandler: boolean,
): IEChartsOption => {
  const BAR_WIDTH = 90;
  const seriesBasics = {
    type: 'bar',
    barWidth: BAR_WIDTH,
    animation: true,
    cursor: hasClickHandler ? 'pointer' : 'auto',
    silent: !hasClickHandler,
  };

  const colors = getTopClientOperatingSystemChartColors(theme);

  const cuttingName = Object.keys(values).map((name: string) =>
    trimmedString(name, MAX_CHART_TEXT_LENGTH),
  );

  const seriesData = Object.values(values).map((value: number) => value);

  const data = Object.keys(values).map((value: string, index: number) => {
    return {
      name: value,
      value: seriesData[index],
      itemStyle: { color: colors[index] },
      cursor: hasClickHandler ? 'pointer' : 'auto',
    };
  });

  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => cePlusTooltips(params[0]),
      axisPointer: {
        type: 'shadow',
      },
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
    },
    xAxis: {
      data: cuttingName,
      axisLabel: {
        show: true,
        interval: 0,
        color: theme.colorSet.charts.axisLabel,
      },
    },
    yAxis: {
      min: 0,
      axisLabel: {
        fontSize: theme.sizes['3.4'],
        color: theme.colorSet.charts.axisLabel,
      },
    },
    grid: {
      left: 50,
      right: 0,
      top: 10,
      height: 270,
    },
    series: [
      {
        ...seriesBasics,
        data,
        type: 'bar',
        name: 'Total',
        cursor: hasClickHandler ? 'pointer' : 'auto',
        silent: !hasClickHandler,
      },
    ],
  };
};
