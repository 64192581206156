import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { breakpoints, getCssVar } from 'styles/theme';
import { Card } from 'shared';
import { CardBody } from 'shared/Card/components/styled';

type Props = { theme: Theme; firstRowBold?: boolean };
const fontSize = 3.5;

export const DateOfSending = styled.span`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: Props) => theme.sizes[fontSize]};
  margin-left: ${({ theme }: Props) => theme.spacing[1]};
  opacity: 0.5;
`;

export const Email = styled.p`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: Props) => theme.sizes[fontSize]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.light};
  margin-bottom: ${({ theme }: Props) => theme.spacing[4]};
  opacity: 0.8;
`;

export const DescriptionContainer = styled.div`
  color: ${getCssVar('textColor')};
  padding: ${({ theme }: Props) => `${theme.spacing[4]} ${theme.spacing[0]}`};

  && {
    .ant-descriptions-row:last-child {
      .ant-descriptions-item {
        padding: ${({ theme }: Props) => `${theme.spacing['2.5']} ${theme.spacing[0]}`};
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding: ${({ theme }: Props) => theme.spacing[0]};
  }
`;

export const EmailTitle = styled.h3`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: Props) => theme.spacing[4]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }: Props) => theme.spacing[1]};

  @media (max-width: ${breakpoints.md}) {
    margin-top: 10px;
  }
`;

export const Line = styled.div`
  background-color: ${({ theme }: Props) => theme.colors.gray[100]};
  height: ${({ theme }: Props) => theme.sizes['0.5']};
  margin-bottom: ${({ theme }: Props) => theme.spacing[2]};
  margin-top: ${({ theme }: Props) => theme.spacing[2]};
  width: 100%;
`;
export const SubTitle = styled.h2`
  font-size: ${({ theme }: Props) => theme.sizes[5]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }: Props) => theme.spacing[6]};
`;

export const Summary = styled.h4`
  font-size: ${({ theme }: Props) => theme.sizes[4]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }: Props) => theme.spacing[1]};
`;

export const Paragraph = styled.p`
  margin-bottom: ${({ theme }: Props) => theme.spacing[4]};
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: ${({ theme }: Props) => theme.spacing[2]};
  }
`;

export const DescriptionStyled = styled.div`
  * {
    color: ${getCssVar('textColor')};
    font-family: ${getCssVar('fontFamily')};
  }

  word-break: break-word;

  td b {
    font-size: 16px !important;
    font-weight: 700;
  }

  img {
    max-width: 100% !important;
    min-width: 100% !important;
    object-fit: contain;
    width: 100% !important;
  }

  pre {
    white-space: pre-wrap;
  }

  table {
    table-layout: fixed;
    width: 100% !important;
  }

  td {
    min-width: 20px;
    width: 100% !important;
    word-break: break-word;
  }

  @media (max-width: ${breakpoints.xl}) {
    img {
      object-fit: contain;
    }
  }
`;

export const SummaryDetails = styled.p`
  font-weight: ${getCssVar('labelFontWeight')};
  margin: ${({ theme }: Props) => `${theme.spacing['2.5']} ${theme.spacing[0]}`};
`;

export const WrapperDescriptionTable = styled.div`
  tbody {
    tr {
      .ant-descriptions-item-label {
        font-weight: ${({ theme }: Props) => theme.fontWeights.normal};
      }
    }

    .ant-descriptions-row:first-child {
      td {
        .ant-descriptions-item-label,
        .ant-descriptions-item-content {
          font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
        }
      }
    }
  }
`;

export const Hyphen = styled.span`
  text-align: center;
  width: 20px;
`;

export const StyledCard = styled(Card)`
  ${CardBody} {
    margin-bottom: ${({ theme }: Props) => theme.spacing['1.5']};
  }
`;

export const EmailsMessages = styled.div`
  margin-top: ${({ theme }: Props) => theme.spacing[5]};

  h3 {
    margin-top: ${({ theme }: Props) => theme.spacing[0]};
  }

  ${CardBody} {
    margin-bottom: ${({ theme }: Props) => theme.spacing['1.5']};
  }

  :last-child {
    ${Line} {
      display: none;
    }
  }
`;
