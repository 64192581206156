import styled from 'styled-components';
import { LogoPlaceholder as StyledLogoPlaceholder } from '../styled';

export const LogoPlaceholder = styled(StyledLogoPlaceholder)`
  bottom: 30px;
  left: 553px;
`;

export const PageTitleDate = styled.div`
  bottom: 105px;
  font-size: 18px;

  left: 553px;
  position: absolute;
`;
