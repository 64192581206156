import { colors } from '../../colors';
import { inputsBase } from './base';

export const select = {
  bg: inputsBase.backgroundColor,
  textColor: inputsBase.textColor,
  backgroundColor: inputsBase.backgroundColor,
  iconColor: colors.gray[500],

  backgroundColorDisabled: inputsBase.backgroundColorDisabled,
  textColorDisabled: inputsBase.textColorDisabled,
  borderColorDisabled: inputsBase.borderColor,

  cascader: {
    backgroundColor: colors.white,
    item: {
      checkedBackgroundColor: colors.gray[20],
      checkedIconColor: colors.brown[500],
      hoverBackgroundColor: '#0000000a',
    },
  },
  tag: {
    backgroundColor: colors.gray[300],
    borderColor: colors.gray[200],
    iconColor: colors.gray[500],
    hoverIconColor: colors.gray[700],
  },
  selector: {
    borderColor: colors.gray[90],
    hoverBorderColor: colors.gray[150],
    focusBorderColor: colors.blue[550],
    checked: {
      borderColor: colors.black,
      backgroundColor: colors.gray[80],
      hoverBorderColor: colors.gray[70],
    },
  },
  dropDownActions: {
    backgroundColor: inputsBase.backgroundColor,
    backgroundColorHover: colors.gray[40],
    dangerColor: colors.red[400],
    iconColor: inputsBase.iconColor,
    dividerColor: colors.gray[70],
  },
};
