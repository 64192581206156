import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';
import { getCssVar } from 'styles/theme';

export const Select = styled(AntdSelect)`
  + div .ant-select-dropdown {
    padding: 0;
    background-color: ${getCssVar('select.backgroundColor')};

    .ant-select-item-option {
      color: ${getCssVar('select.textColor')};

      &:hover {
        background-color: ${getCssVar('select.cascader.item.hoverBackgroundColor')};
      }

      &[aria-selected='true'] {
        background-color: ${getCssVar('select.cascader.item.checkedBackgroundColor')};

        & .anticon-check {
          color: ${getCssVar('select.cascader.item.checkedIconColor')};
        }
      }
    }
  }

  &.ant-select.checked:not(.ant-select-disabled) {
    .ant-select-selector {
      background-color: ${getCssVar('select.selector.checked.backgroundColor')};
      border: 2px solid ${getCssVar('select.selector.checked.borderColor')};
    }

    &:hover {
      .ant-select-selector {
        border: 2px solid ${getCssVar('select.selector.checked.hoverBorderColor')};
      }
    }
  }

  &:not(.ant-select-customize-input):not(.ant-select-disabled) {
    & .ant-select-selector {
      border-color: ${getCssVar('select.selector.borderColor')};
      background-color: ${getCssVar('select.backgroundColor')};

      &:hover {
        background-color: ${getCssVar('select.backgroundColor')};
        border-color: ${getCssVar('select.selector.hoverBorderColor')};
      }
    }
  }
  &.ant-select-checked {
    &:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${getCssVar('select.selector.checked.borderColor')};
      background-color: ${getCssVar('select.selector.checked.backgroundColor')};
    }
  }

  &.ant-select-multiple {
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ant-select-selection-item {
      height: 28px;
      line-height: 26px;
      margin-top: 0;
      margin-bottom: 0;
      padding-inline-start: 5px;
      background: ${getCssVar('select.tag.backgroundColor')};
      border-color: ${getCssVar('select.tag.borderColor')};
    }

    .ant-select-selection-item-remove {
      color: ${getCssVar('select.tag.iconColor')};

      &:hover,
      &:focus {
        color: ${getCssVar('select.tag.hoverIconColor')};
      }
    }

    .ant-select-selector {
      padding: 0 4px;
    }
  }

  .ant-select-selection-overflow-item-rest {
    .ant-select-selection-item {
      border-color: transparent;
      background: transparent;
      padding-left: 0;
    }
  }

  .ant-select-arrow {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    color: ${getCssVar('select.iconColor')};
  }

  .ant-select-clear {
    font-size: 16px;
    margin-top: -8px;
    right: 18px;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    border-color: ${getCssVar('select.selector.focusBorderColor')};
  }

  .ant-select-item-option-state {
    color: ${getCssVar('activeColor')} !important;
  }

  &.ant-select-disabled {
    .ant-select-selector {
      background-color: ${getCssVar('select.backgroundColorDisabled')} !important;
      border-color: ${getCssVar('select.borderColorDisabled')} !important;
      color: ${getCssVar('select.textColorDisabled')} !important;

      .ant-select-selection-placeholder {
        color: ${getCssVar('select.textColorDisabled')} !important;
      }
    }
  }
`;
