import styled from 'styled-components';
import { Card } from 'shared';
import { breakpoints, getCssVar } from 'styles/theme';
import { Theme } from 'styles/theme/types';
import { Col, Form } from 'antd';
import { AccessMessageStyled, CardBody } from 'shared/Card/components/styled';
import { ThemeProps } from 'services/interfaces';

interface DashboardProps {
  $collapsed?: boolean;
}

export const TotalStressCard = styled(Card)`
  ${CardBody} {
    padding-top: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
  }

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[7]};
  }
`;

export const HiddenContainer = styled.div`
  position: fixed;
  z-index: -999999;
`;

export const StyledCard = styled(Card)`
  ${CardBody} {
    margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
  }
`;

export const PeopleStyledCard = styled(StyledCard)`
  ${AccessMessageStyled} {
    margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[5]};
  }
`;

export const WithTm = styled.span`
  &:after {
    bottom: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
    content: 'TM';
    font-size: ${({ theme }: { theme: Theme }) => theme.sizes[3]};
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.semibold};
    position: relative;
  }
`;

export const StressScoresContainer = styled.div<DashboardProps>`
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${breakpoints.md}) {
    margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[0]};
    width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
    align-items: stretch;
  }
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing['6.1']};
  min-height: 186px;
  width: 96%;

  > div {
    width: 30%;
    @media (max-width: ${breakpoints.xml}) {
      padding-top: ${({ $collapsed }) => ($collapsed ? '0' : '20px')};
      width: ${({ $collapsed }) => ($collapsed ? '30%' : '45%')};
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 46%;
    }
  }
`;

export const BoostScoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  @media (max-width: ${breakpoints.md}) {
    margin: ${({ theme }: { theme: Theme }) => theme.spacing[0]};
  }
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-explain-error {
    color: ${getCssVar('errorColor')};
  }
`;

export const DataProtectionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  @media (max-width: ${breakpoints.md}) {
    margin: ${({ theme }: { theme: Theme }) => theme.spacing[0]};
  }

  a {
    align-self: flex-start;
    margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[6]};
  }
`;

type CertificatesContainerProps = {
  isMobile?: boolean;
  theme: Theme;
};

export const CertificatesContainer = styled.div<CertificatesContainerProps>`
  display: flex;
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
  margin-top: ${({ theme }: { theme: Theme }) => theme.spacing['3.4']};
  width: ${({ theme }) => theme.sizes.full};

  @media (max-width: ${breakpoints.xl}) {
    flex-wrap: wrap;
  }
  @media (max-width: ${breakpoints.md}) {
    flex-direction: ${({ isMobile }: { isMobile?: boolean }) => (isMobile ? 'column' : 'row')};
    height: auto;
    margin: ${({ theme }: { theme: Theme }) => theme.spacing[0]};
  }
`;

type ColProps = {
  theme: Theme;
  $isMobile: boolean;
};

export const ColWithCustomBorderBottom = styled(Col)<ColProps>`
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.md}) {
    ${CardBody} {
      position: relative;

      :after {
        position: absolute;
        content: '';
        border-bottom: ${({ theme, $isMobile }) =>
          $isMobile && `${theme.spacing['0.5']}  solid ${getCssVar('card.headBorderColor')}`};
        width: 90%;
        top: ${({ theme }) => theme.spacing[0]};
        z-index: 1;
      }
    }
  }
`;

export const StressScoreDigits = styled.span<ThemeProps>`
  color: ${getCssVar('textColor')} !important;
  font-size: ${getCssVar('stressScore.digits.fontSize')} !important;
  font-weight: ${getCssVar('stressScore.digits.fontWeight')} !important;
`;

export const StressScoreLabel = styled.span<ThemeProps>`
  font-weight: ${({ theme }) => theme.fontWeights.bold} !important;
`;
