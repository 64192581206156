import styled from 'styled-components';
import { AsideDescription, AsidePieWrapper } from '../styled';
import { smallFontSize } from '../config';

export const TechnologyVulnerabilityManagementDescription = styled(AsideDescription)`
  max-width: 260px;
  top: 319px;
`;

export const TotalScorePieWrapper = styled(AsidePieWrapper)``;

export const AverageTimeToRemediate = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 74px;
  justify-content: center;
  left: 345px;
  line-height: 1;
  position: absolute;
  top: 375px;
  width: 74px;

  div:first-child {
    font-size: 26px;
    font-weight: bold;
  }
`;

export const ChartsContainer = styled.div`
  margin-left: 552px;
  margin-right: 50px;
  position: relative;
`;

export const PatchesVsConfigurationChartWrapper = styled.div`
  position: absolute;
  top: 278px;
  width: 100%;
`;

export const AllVulnerabilitiesWrapper = styled.div`
  position: absolute;
  top: 75px;
  width: 100%;
`;

export const ChangesTitle = styled.div`
  font-weight: 700;
  margin-top: 8px;

  > span:first-child {
    font-weight: normal;
  }
`;

export const TopInfoWrapper = styled.div`
  font-size: ${smallFontSize};
  position: absolute;
  top: 435px;
  width: 100%;

  .title {
    font-weight: 700;
    margin-bottom: 5px;
  }
`;

const TopColumnWrapper = styled.div`
  display: inline-block;
  flex-shrink: 0;
  font-size: 11px;

  div {
    margin-bottom: 5px;

    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
  }

  max-width: 250px;
`;

export const MostVulnerableHosts = styled(TopColumnWrapper)`
  margin-right: 40px;
`;

export const TopVulnerabilities = styled(TopColumnWrapper)``;
