import { icons } from 'styles/theme';
import { Icon } from 'shared';
import { NormRebrandingWrap } from '../../styled';

export const NormRebranding = ({ width = '0px' }: { width?: string }) => (
  <NormRebrandingWrap style={{ width }}>
    <span className='circle circle__top' />
    <span className='circle circle__middle' />
    <span className='circle circle__center' />
    <Icon component={icons.normLoginLogo} />
  </NormRebrandingWrap>
);
