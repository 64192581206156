import moment from 'moment';
import styled, { useTheme } from 'styled-components';
import { formatData } from 'pages/ThreatDetection/helpers';
import { numberFormatter } from 'services/helpers';
import { RadioButtonsDays } from 'shared/RadioButtonsWithRangePicker/constants';
import { IEChartsOption, LabelFormatterParams } from 'shared/charts/interfaces';
import { IDate } from 'services/interfaces';
import { IEvents } from 'pages/ThreatDetection/types';
import { Theme } from 'styles/theme/types';
import { ReportChart } from './ReportChart';

type Values = { values: IEvents[]; date?: IDate };

const getOption = (params: Values, theme: Theme): IEChartsOption => {
  const date: IDate = params.date
    ? params.date
    : {
        startDate: moment().subtract(RadioButtonsDays.OneMonth, 'days').startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf(),
      };

  const entries = formatData(params.values, date);
  const markPointLableMaxLength = 5;
  const isEmpty =
    entries?.length <= 1 && entries[0]?.count === 0 && entries[0]?.collectedDateTime === 0;

  const commonStyles = {
    axisLine: {
      show: true,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'lightgrey',
        width: 0.3,
      },
      interval: 3,
    },
  };

  return {
    grid: {
      top: 30,
      bottom: 30,
      right: 20,
      left: 35,
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: false, show: false },
      data: isEmpty
        ? []
        : entries.map((events: IEvents) => moment(+events.collectedDateTime).format('MMM DD')),
      axisLabel: {
        fontSize: 9,
        fontFamily: theme.colorSet.lessFontFamily,
        // rotate: -90,
        interval: 3,
      },
      ...commonStyles,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => numberFormatter(value),
        fontSize: 9,
        fontFamily: theme.colorSet.lessFontFamily,
      },
      min: 0,
      ...commonStyles,
    },
    series: [
      {
        data: entries.map((events: IEvents) => events.count),
        type: 'line',
        name: 'Total',
        lineStyle: {
          width: 1.5,
          color: theme.colorSet.baseColors.blue,
        },
        markPoint: {
          symbolSize: 35,
          data: [
            { type: 'max', name: 'Max' },
            { type: 'min', name: 'Min' },
          ],
          label: {
            fontSize: 9,
            fontFamily: theme.colorSet.lessFontFamily,
            show: true,
            formatter: (_params: LabelFormatterParams) => {
              const value = numberFormatter(_params.data.value);

              if (`${value}`.length >= markPointLableMaxLength) {
                return `${value}`.split('K').join('\nK');
              }

              return `${value}`;
            },
          },
          itemStyle: {
            color: theme.colorSet.baseColors.blue,
            borderColor: theme.colorSet.baseColors.blue,
          },
        },
        markLine: isEmpty
          ? {}
          : {
              data: [
                {
                  type: 'average',
                  name: 'Average',
                },
              ],
              label: {
                show: false,
              },
              silent: true,
              symbol: 'none',
              lineStyle: {
                color: 'black',
                type: 'dotted',
                width: 1,
              },
            },
        symbol: 'circle',
        showSymbol: !isEmpty,
        symbolSize: 6,
        silent: true,
      },
    ],
  };
};

interface Props {
  onChartLoaded: () => void;
  values: Values;
  height: string;
  width: string;
}

export const Wrapper = styled.div`
  > div {
    background: transparent;
    z-index: 0;
  }
`;

export function AreaReportChart({ width, height, values, onChartLoaded }: Props) {
  const theme = useTheme();

  return (
    <Wrapper>
      <ReportChart
        width={width}
        height={height}
        onFinished={onChartLoaded}
        option={getOption(values, theme)}
      />
    </Wrapper>
  );
}
