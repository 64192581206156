import { colors } from '../../colors';
import { baseNorm } from '../base';

export const inputsBase = {
  backgroundColor: colors.white,
  backgroundColorDisabled: '#0000000a',
  textColor: baseNorm.black,
  textColorDisabled: baseNorm.grey,
  borderColor: colors.gray[90], // #E4E4E4
  borderColorHover: colors.gray[150], // #BFBDBD
  borderColorDisabled: colors.gray[90], // #E4E4E4
  iconColor: baseNorm.grey,
  activeColor: baseNorm.blue,
  disabledOpacity: 0.3,
  hoverColor: 'rgba(35, 31, 32, 0.1)',
};
