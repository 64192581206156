import { Link as RouterLink } from 'react-router-dom';
import { Col as AntdCol, Row as AntdRow, Typography } from 'antd';
import AntSider from 'antd/lib/layout/Sider';
import styled from 'styled-components';
import AntdLayout, { Content as AntdContent, Header as AntdHeader } from 'antd/lib/layout/layout';
import { Theme } from 'styles/theme/types';
import { breakpoints, getCssVar } from 'styles/theme';
import { Icon } from 'shared/Icon';

type Props = { theme: Theme; $isMobile?: boolean; $isDesktop?: boolean };

export const ContentLayout = styled(AntdContent)`
  height: 100%;
`;

export const Layout = styled(AntdLayout)`
  height: ${({ theme }: Props) => theme.sizes.full};
  min-height: ${({ theme }: Props) => theme.sizes.pageHeight};
`;

export const Header = styled(AntdHeader)`
  background-color: ${({ theme }: Props) => theme.colors.transparent};
  padding: 0 ${({ theme }) => theme.spacing[6]};
`;

export const Sider = styled(AntSider)<{ $isDesktop?: boolean }>`
  background-color: ${getCssVar('login.sidebar.background')};
  flex: 0 0 ${({ theme }) => theme.sizes['2xl']} !important;
  max-width: ${({ theme }) => theme.sizes['2xl']} !important;
  min-width: ${({ theme }) => theme.sizes['2xl']} !important;
  width: ${({ theme }) => theme.sizes['2xl']} !important;
  z-index: 2;

  @media (max-width: ${breakpoints.lg}) {
    background-color: ${getCssVar('login.sidebar.mobile.background')};
    flex: 0 0 ${({ theme }) => theme.sizes.full} !important;
    max-width: ${({ theme }) => theme.sizes.full} !important;
    min-width: ${({ theme }) => theme.sizes.full} !important;
    width: ${({ theme }) => theme.sizes.full} !important;
    width: 100%;
  }
`;

export const FormWrap = styled.div`
  background-color: ${getCssVar('login.formWrap.background')};
  border-radius: ${getCssVar('login.formWrap.borderRadius')};
  margin: ${getCssVar('login.formWrap.margin')};
  max-width: ${getCssVar('login.formWrap.maxWidth')};
  padding: ${getCssVar('login.formWrap.padding')};
  text-align: center;
  width: 100%;

  &.step-tfa-setup,
  &.step-tfa-confirm {
    max-width: 500px;

    .ant-alert-error {
      display: none;
    }
  }

  .nasstar & {
    .ant-form-item-explain-error {
      text-align: left;
    }
  }
  & .ant-alert.ant-alert-error {
    background-color: ${getCssVar('login.alert.error.backgroundColor')};
    border-color: ${getCssVar('login.alert.error.borderColor')};
    text-align: center;
    .ant-alert-message {
      color: ${getCssVar('login.alert.error.textColor')};
    }
  }
  & .auth-form .ant-form-item-has-error .ant-form-item-explain-error {
    color: ${getCssVar('login.formInput.errorMessageColor')};
    text-align: left;
  }

  @media (max-width: ${breakpoints.lg}) {
    background-color: ${getCssVar('login.formWrap.mobile.background')};
  }
`;

export const FormTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${getCssVar('login.formTitle.color')};
    font-size: ${getCssVar('login.formTitle.fontSize')};
    font-weight: ${getCssVar('login.formTitle.fontWeight')};
    margin-bottom: ${getCssVar('login.formTitle.marginBottom')};

    @media (max-width: ${breakpoints.lg}) {
      color: ${getCssVar('login.formTitle.mobile.color')};
    }
  }
`;

export const FormSubTitle = styled(Typography.Paragraph)`
  &.ant-typography {
    color: ${getCssVar('login.formSubTitle.color')};
    font-size: ${getCssVar('login.formSubTitle.fontSize')};
    margin-bottom: ${getCssVar('login.formSubTitle.margin')};
    @media (max-width: ${breakpoints.lg}) {
      color: ${getCssVar('login.formSubTitle.mobile.color')};
    }
  }
`;

export const Row = styled(AntdRow)<{ height?: string }>`
  height: ${(props) => props.height};
`;

export const Link = styled(RouterLink)<Props>`
  color: ${getCssVar('login.link.color')};
  display: inline-block;
  font-size: ${getCssVar('login.link.fontSize')};
  font-weight: ${getCssVar('login.link.fontWeight')};
  line-height: 20px;
  min-width: 132px;

  &:hover {
    color: ${getCssVar('login.link.hoverColor')};
  }

  @media (max-width: ${breakpoints.lg}) {
    color: ${getCssVar('login.link.mobile.color')};
    font-size: ${getCssVar('login.link.mobile.fontSize')};
    font-weight: ${getCssVar('login.link.mobile.fontWeight')};

    &:hover {
      color: ${getCssVar('login.link.mobile.hoverColor')};
    }
  }
`;

export const InputsCol = styled(AntdCol)`
  width: 348px;
  @media (max-width: ${breakpoints.md}) {
    width: 88%;
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 335px;
  }
  @media (max-width: ${breakpoints.lg}) {
    .ant-form-item-explain.ant-form-item-explain-error {
      div {
        color: ${getCssVar('errorColor')};
      }
    }

    > div {
      a {
        color: ${getCssVar('primaryColor')};
      }
    }
  }
`;

const StyledIcon = styled(Icon)<{ $isMobile?: boolean }>`
  height: auto;
  width: auto;
`;

export const NormRect = styled(StyledIcon)`
  bottom: 109px;
  height: 196px;
  position: absolute;

  right: 50px;

  width: 358px;
  z-index: 1;

  path {
    fill: ${({ theme }) => theme.colors.brown[100]};
  }
`;

export const LogoIcon = styled(Icon)`
  color: ${getCssVar('login.sidebar.logoColor')};

  @media (max-width: ${breakpoints.lg}) {
    color: ${getCssVar('login.sidebar.mobile.logoColor')};
  }
`;

export const SmartBlocWrap = styled.div`
  display: flex;
  height: 45px;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing[5]};
  width: 100%;
`;

export const SmartBlocIcon = styled(Icon)`
  color: ${getCssVar('login.sidebar.smartBlocColor')};
  height: 45px;
  width: 200px;

  @media (max-width: ${breakpoints.lg}) {
    color: ${getCssVar('login.sidebar.mobile.smartBlocColor')};
  }
`;

export const NasstarLogoIcon = styled(Icon)`
  path {
    fill: ${({ color }) => color};
  }
`;

export const NasstarFooter = styled.footer`
  height: 40px;
  margin-bottom: 40px;
  padding: 0 40px;
  text-align: right;
  width: 100%;

  span {
    height: 45px;
    width: 150px;
  }
`;
