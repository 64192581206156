import { Tooltip } from 'antd';
import { Props } from './types';
import { Button } from '../buttons/styled';
import { DownloadIcon } from '../layouts/Inner/components';
import { icons } from '../../styles/theme';
import { Icon } from '../Icon';
import { useScreenSizes } from '../../services/hooks';
import { Wrapper } from './DownloadDocButton.styled';

export function DownloadDocButton({
  onDownload,
  loading,
  title,
  tooltip,
  icon = icons.download,
}: Props) {
  const [isDesktop, , isMobile] = useScreenSizes();

  return (
    <Wrapper>
      <Button
        type='link'
        style={{ height: '24px' }}
        icon={<DownloadIcon component={icon} />}
        onClick={onDownload}
        loading={loading}
      >
        {title || 'Download report'}
      </Button>
      {tooltip && (
        <Tooltip
          title={tooltip}
          placement={isMobile ? 'top' : 'right'}
          trigger={isMobile ? 'click' : 'hover'}
          overlayStyle={{ minWidth: isDesktop ? '400px' : 'auto' }}
        >
          <Icon
            component={icons.info_outline}
            className='tooltipInfo'
            style={{ verticalAlign: 'middle', marginTop: '-1px' }}
          />
        </Tooltip>
      )}
    </Wrapper>
  );
}
