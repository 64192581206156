import { Checkbox as AntdCheckbox } from 'antd';
import styled, { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';

const ActiveStyles = css`
  background-color: ${getCssVar('checkBox.active.bg')} !important;
  border-color: ${getCssVar('checkBox.active.bg')} !important;

  &:after {
    border-color: ${getCssVar('checkBox.active.color')};
  }
`;

export const CheckBoxStyles = css`
  color: ${getCssVar('textColor')};

  .ant-checkbox {
    &,
    &:hover,
    &:focus {
      .ant-checkbox-inner {
        border-color: ${getCssVar('checkBox.borderColor')};
      }
    }

    .ant-checkbox-inner {
      &,
      &:hover,
      &:focus {
        border-radius: ${({ theme }: { theme: Theme }) => theme.radius.sm};
        background-color: transparent;
      }
    }

    &.ant-checkbox-checked {
      &,
      &:hover,
      &:focus {
        .ant-checkbox-inner {
          ${ActiveStyles};
        }
      }
    }

    &.ant-checkbox-disabled {
      opacity: ${getCssVar('checkBox.disabledOpacity')};

      .ant-checkbox-inner {
        border-color: ${getCssVar('checkBox.borderColor')} !important;
        background-color: transparent !important;

        &:after {
          border-color: ${getCssVar('checkBox.active.color')};
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          ${ActiveStyles};
        }
      }

      &.ant-checkbox {
        &:after {
          display: none;
        }
      }
    }

    &:after {
      border: none;
      position: absolute;
      content: '';
      left: -7px;
      top: -7px;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
      background: ${getCssVar('checkBox.hover')};
      visibility: visible;
      transition: all 0.2s ease-out;
      animation: none;
      opacity: 0;
    }
  }

  &.hover,
  &:hover,
  &:focus,
  &.active,
  &:active {
    .ant-checkbox {
      &:after {
        opacity: 1;
      }
    }
  }

  &.active,
  &:active {
    .ant-checkbox {
      &:after {
        background: transparent;
      }
    }
  }
`;

export const Checkbox = styled(AntdCheckbox)`
  ${CheckBoxStyles};
`;
