import { useMemo } from 'react';
import { Col, Empty, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AppState } from 'store';
import { isEmptyObject } from 'services/helpers';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  ColTotal,
  ReactEChartsLegend,
  ReactEChartsStyled,
  ReactEChartsTotal,
  ReactEChartsTotalLabel,
  RowContainer,
  TitleParagraph,
} from './styled';
import { Colors, Values } from '../types';
import { Legend } from '../Legend/Legend';
import { EChartsEventParams } from '../interfaces';
import { getOption } from './helpers';

type Props = {
  title?: string;
  values: Values;
  colors?: Colors;
  fullInformation?: boolean;
  onEvents?: Record<string, (params?: EChartsEventParams) => void>;
  formatName?: (name: string) => string;
  isLoading?: boolean;
};

export const StackedHorizontalBarFull = ({
  values,
  colors,
  fullInformation,
  title,
  onEvents,
  formatName,
  isLoading = false,
}: Props) => {
  const theme = useTheme();
  const isMobile = useSelector(({ app: { isMobile } }: AppState) => isMobile);
  const breakpoints = useBreakpoint();
  const defaultColors = {
    urgent: theme.colorSet.severity.critical,
    critical: theme.colorSet.severity.high,
    serious: theme.colorSet.severity.meduim,
    medium: theme.colorSet.severity.low,
    minimal: theme.colorSet.severity.informational,
    analysing: theme.colorSet.severity.analysis,
  };
  const getColors = colors || defaultColors;

  const option = useMemo(() => {
    if (isLoading) {
      return {};
    }

    return getOption(values, getColors, fullInformation, isMobile, Boolean(onEvents), theme);
  }, [fullInformation, getColors, isLoading, isMobile, onEvents, theme, values]);

  const { Text } = Typography;
  const total = Object.values(values).reduce((a, b) => a + b, 0);

  return (
    <RowContainer gutter={24} align='middle'>
      {fullInformation && !isEmptyObject(values) && (
        <ColTotal>
          <ReactEChartsTotal>{total}</ReactEChartsTotal>

          <ReactEChartsTotalLabel>Total</ReactEChartsTotalLabel>
        </ColTotal>
      )}

      <Col flex='auto'>
        {title && (
          <TitleParagraph>
            <Text strong>{title}</Text>
          </TitleParagraph>
        )}

        {isEmptyObject(values) && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No vulnerabilities present' />
        )}

        {!isEmptyObject(values) && (
          <ReactEChartsStyled
            option={option}
            onEvents={onEvents}
            opts={{ renderer: 'canvas' }}
            notMerge
          />
        )}

        {fullInformation && (
          <ReactEChartsLegend>
            <Legend
              values={values}
              colors={getColors}
              direction={breakpoints.xxl ? 'row' : 'column'}
              formatName={formatName}
              isMobile={isMobile}
              isMenuCollapsed={false}
            />
          </ReactEChartsLegend>
        )}
      </Col>
    </RowContainer>
  );
};
