import React from 'react';
import { useApp, useCEPlus, useLoading } from 'store';
import { Card } from 'shared';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { SegmentedPie } from 'shared/charts';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { Routes } from 'services/entities';
import { getQueryString } from 'services/api/core/request';
import { initialPagination } from 'app-constants';
import { Filter } from 'services/api';
import { isEmptyObject } from 'services/helpers';
import { cyberEssentialPlusApi } from 'store/cyberEssentialPlus/thunks';
import { initialOrderBy } from 'pages/PatchManagementPatches/constants';
import { getLegendColors, getLegendValues } from 'pages/PatchManagement/helpers';
import {
  cePlusBackRoute,
  TOP_OPERATING_SYSTEMS,
  IFilterField,
  tooltipTitles,
} from '../../constants';
import { validateField, validateFieldValue, viewMoreConfig } from '../../helpers';

export const Windows10Versions = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const history = useHistory();
  const { topOS, selectedTags } = useCEPlus();

  const loading = useLoading([cyberEssentialPlusApi.getTopOS.typePrefix]);

  const colors = [
    theme.colorSet.baseColors.blue,
    theme.colorSet.baseColors.amber,
    theme.colorSet.baseColors.green,
    theme.colorSet.baseColors.red,
    theme.colorSet.baseColors.deepRed,
    theme.colorSet.baseColors.grey,
    theme.colorSet.baseColors.blue,
    theme.colorSet.baseColors.amber,
    theme.colorSet.baseColors.green,
    theme.colorSet.baseColors.red,
    theme.colorSet.baseColors.deepRed,
  ];

  const limit = colors.length - 1;

  const otherRedirectValue = React.useMemo(
    () => topOS?.chart?.slice(limit).map((item) => item?.title),
    [limit, topOS?.chart],
  );
  const getOsParamsParams = (value: string[], tagsAssets?: string[]) => {
    const filterFields: IFilterField[] = [
      {
        name: 'product',
        value: TOP_OPERATING_SYSTEMS,
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'osVersion',
        value,
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'tagsAssets',
        value: tagsAssets || [],
        validate: (fieldValue) => validateFieldValue(fieldValue as string[]),
        type: Filter.type.MULTIPLE,
      },
    ];

    return {
      orderBy: initialOrderBy,
      pagination: initialPagination,
      filter: {
        fields: validateField(filterFields),
      },
    };
  };

  const redirectToAssetsList = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      let value = [params.name];

      if (params.name === 'Other') {
        value = otherRedirectValue as string[];
      }

      history.push(
        `${Routes.CMDB}${getQueryString(getOsParamsParams(value, selectedTags))}`,
        cePlusBackRoute,
      );
    },
    [selectedTags, history, otherRedirectValue],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToAssetsList(params),
    }),
    [redirectToAssetsList],
  );

  const legendValues = getLegendValues(topOS?.chart, 'title', colors.length - 1);

  const totalClickHandler = () => {
    history.push(viewMoreConfig(selectedTags).top10Windows);
  };

  return (
    <Card
      title='Windows versions | Top 10'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={tooltipTitles.windowsVersions}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getTopOS.typePrefix]}
      viewMore={viewMoreConfig(selectedTags).top10Windows}
    >
      {topOS?.chart && !isEmptyObject(legendValues) ? (
        <SegmentedPie
          values={legendValues}
          colors={getLegendColors(legendValues, colors)}
          direction='row'
          entitiesName=' '
          style={
            isMobile ? { height: '160px', marginTop: `-${theme.spacing[0]}` } : { height: '320px' }
          }
          isMobile={isMobile}
          isMenuCollapsed={isMenuCollapsed}
          onEvents={onEvents}
          totalClickHandler={totalClickHandler}
          isShowingUnchangedText
          isLoading={loading[cyberEssentialPlusApi.getTopOS.typePrefix]}
          isAsyncAnimation
        />
      ) : (
        emptyMessage(`No windows top versions yet`)
      )}
    </Card>
  );
};
