import { colors } from '../../colors';
import { inputsBase } from './base';

export const datePicker = {
  checked: {
    bg: '#0049FF',
    borderColor: '#0049FF',
    inputBg: '#323332',
  },
  bg: inputsBase.backgroundColor,
  textColor: colors.white,
  iconColor: '#707070',
  borderColor: '#4F4D4D',
  hoverBorderColor: '#8A8A8A',
  rangeHoverBorderColor: '#0049FF',
  color: '#707070',
  pickerPanelBg: inputsBase.backgroundColor,
  pickerPanelBorder: inputsBase.backgroundColor,
  pickerPanelTextColor: '#7285FF',
  innerBg: '#233260',
  cellRangeBg: '#292A29',
  disabledRangeBG: inputsBase.backgroundColor,
  clearIconColor: '#707070',
  nextPrevIconColor: '#8A8A8A',
  nextPrevIconColorHover: colors.white,
};
