import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from 'styles/theme';
import { AsideDescription, AsidePieWrapper } from '../styled';

export const ScoreBackground = styled.div`
  align-items: center;
  background: transparent;
  border: 2px solid #000000;
  border-radius: 50%;
  display: flex;
  height: 155px;
  justify-content: center;
  left: 46px;
  position: absolute;
  top: 20px;
  width: 155px;

  &::before {
    background: transparent;
    border: 2px solid #000000;
    border-radius: 50%;
    content: '';
    height: 55px;
    left: 139px;
    position: absolute;
    top: 73px;
    width: 55px;
  }

  &::after {
    background: ${getCssVar('baseColors.blue')};
    border-radius: 50%;
    content: '';
    height: 14px;
    left: 137px;
    position: absolute;
    top: 35px;
    width: 14px;
  }
`;

export const Background = styled.div`
  background-image: linear-gradient(90deg, #ececec 0%, #ffffff 100%);
  height: 100%;
  width: 100%;
`;

export const EllipseBackground = styled.div`
  background-color: white;
  border: 2px solid #000000;
  border-bottom-left-radius: 1200px;
  border-top-left-radius: 1200px;
  height: 602px;
  left: 34%;
  position: absolute;
  top: -3px;
  width: 100%;
`;

export const ProcessText = styled(AsideDescription)``;

export const TotalScorePieWrapper = styled(AsidePieWrapper)``;

export const ProcessListWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 68px;

  top: 224px;

  > .process-list-item:not(:last-child) {
    margin-right: 30px;
  }
`;

interface ProcessItemContainerProps {
  color?: string;
  dateColor: string;
  isIcon: boolean;
  isLocked?: boolean;
  statusColor?: string;
}

export const ProcessItemContainer = styled.div<ProcessItemContainerProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};

  .icon {
    display: inline-block;
    line-height: 1;
    padding: 5px;
    position: relative;

    > .anticon {
      display: inline-block;
      height: 100%;
      width: 100%;

      svg {
        height: auto;
      }
    }

    > .lockicon {
      filter: ${({
        theme,
      }) => `drop-shadow(${theme.spacing.px} ${theme.spacing.px} ${theme.spacing[0]} ${theme.colors.black})
          drop-shadow(${theme.spacing['0.5']} ${theme.spacing['0.5']} ${theme.spacing[1]} ${theme.colors.blackAlpha[50]})`};
      height: 10px;
      left: 0;
      opacity: 1;
      position: absolute;
      top: 0;
      width: 10px;

      > svg {
        color: ${({ theme }) => theme.colors.gray[150]};
      }
    }

    &::after {
      background-color: ${(props: ProcessItemContainerProps) => props.color};
      border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
      content: '';
      display: ${(props: ProcessItemContainerProps) => (props.isIcon ? 'none' : 'block')};
      height: ${({ theme }: { theme: Theme }) => theme.sizes[2]};
      left: 2px;
      position: absolute;
      top: 2px;
      width: ${({ theme }: { theme: Theme }) => theme.sizes[2]};
    }
  }

  .icon-cyber-essentials {
    left: 6px;
  }

  .title {
    margin-bottom: 15px;
    min-width: 160px;
    opacity: ${({ isLocked }: ProcessItemContainerProps) => (isLocked ? '0.3' : 1)};
    padding: 6px;
  }

  .status-wrapper {
    color: ${({ theme }: { theme: Theme }) => theme.colorSet.baseColors.black};
    font-size: 12px;
    margin-top: 10px;
  }

  .date {
    margin-top: 3px;
    text-align: center;
  }
`;
