import moment from 'moment';
import { fourMonthsTime, oneDayTime, oneMonthTime } from 'app-constants';
import { IDate } from 'services/interfaces';

export const getSubTitleArrow = (percentValue: number | undefined) => {
  if (percentValue) {
    return percentValue <= 0 ? 'down' : 'up';
  }

  return undefined;
};

export const numberFormatter = (value: number, float = 0) => {
  const COMPARED_NUMBER_K = 999;
  const COMPARED_NUMBER_M = 999999;
  const THOUSAND = 1000;
  const MILLION = 1000000;
  const BILLION = 1e9;
  const FLOAT_VALUE = float;
  const ADDED_VALUE_K = 'K';
  const ADDED_VALUE_M = 'M';
  const ADDED_VALUE_B = 'B';

  if (value >= BILLION) {
    return `${(value / BILLION).toFixed(FLOAT_VALUE)}${ADDED_VALUE_B}`;
  }

  if (value > COMPARED_NUMBER_M) {
    return `${(value / MILLION).toFixed(FLOAT_VALUE)}${ADDED_VALUE_M}`;
  }

  if (value > COMPARED_NUMBER_K) {
    return `${(value / THOUSAND).toFixed(FLOAT_VALUE)}${ADDED_VALUE_K}`;
  }

  return value;
};

export const getPeriod = (startDate: number, endDate: number) => {
  return endDate - startDate;
};

export const formatDate = (key: string, date: IDate, isYearByDays?: boolean) => {
  let format = 'MMM DD';
  const period = getPeriod(date.startDate, date.endDate);

  if (period < oneDayTime) {
    format = 'HH:mm';
  }

  if (period < oneMonthTime - 1 && period >= oneDayTime) {
    format = 'MMM DD HH:mm';
  }

  if (period < fourMonthsTime && period >= oneMonthTime - 1) {
    format = 'MMM DD';
  }

  if (period >= fourMonthsTime) {
    format = !isYearByDays ? 'MMM YYYY' : 'DD-MMM-YYYY';
  }

  return moment(+key).format(format);
};
