import styled, { css } from 'styled-components';
import { Tabs } from 'antd';
import { getCssVar } from 'styles/theme';
import { TabsProps } from './types';

type TabsWrapperProps = Omit<TabsProps, 'children'>;

const TabsWrapperStyles = css<TabsWrapperProps>`
  .ant-tabs-nav-list {
    height: ${({ tabHeight }) => tabHeight || 'auto'};
    margin-left: ${({ tabMargin }) => tabMargin || '0px'};
  }

  .ant-tabs-nav-operations {
    color: ${getCssVar('tabsNavigation.inactiveTabColor')} !important;
  }
`;

export const TabsWrapper = styled.div<TabsWrapperProps>`
  .ant-tabs,
  .ant-tabs-nav-list,
  .ant-tabs-nav-wrap {
    overflow: visible !important;
  }

  ${TabsWrapperStyles};
`;

const TabInactiveStyles = css`
  color: ${getCssVar('tabsNavigation.inactiveTabColor')};
  font-size: 15px;
  padding: 12px;

  &:hover {
    color: ${getCssVar('tabsNavigation.inactiveTabHoverColor')};
  }
`;

const TabActiveStyles = css`
  background: ${getCssVar('tabsNavigation.activeTabBg')};
  box-shadow: ${getCssVar('tabsNavigation.boxShadow')};
  color: ${getCssVar('tabsNavigation.activeTabColor')};

  .ant-tabs-tab-btn {
    color: ${getCssVar('tabsNavigation.activeTabColor')};
  }
`;

export const TabsStyled = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-tab {
      ${TabInactiveStyles};
      border-radius: 6px 6px 0 0;
      padding: 16px;
    }

    .ant-tabs-tab-active {
      ${TabActiveStyles};
    }

    .ant-tabs-ink-bar {
      opacity: 0;
    }
  }

  /* Bottom border indicator */
  .ant-tabs-nav::before {
    display: none;
  }
`;

export const InnerTabsWrapper = styled.div<TabsWrapperProps>`
  ${TabsWrapperStyles};
`;

export const InnerTabsStyled = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0 0 24px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      margin: 0 0 16px;
    }

    .ant-tabs-tab {
      ${TabInactiveStyles};
    }

    .ant-tabs-tab-active {
      ${TabActiveStyles};
      background: transparent;
      box-shadow: none;
    }
  }

  /* Bottom border indicator */
  .ant-tabs-nav::before {
    border-bottom: ${getCssVar('tabsNavigation.borderBottom')};
  }
`;
