import { Empty } from 'antd';
import { getCssVar } from 'styles/theme';

export const noMathCriteria = (
  <>
    <span>There are no results matching your search criteria.</span>
    <br />
    <span>Check the spelling, or try a different search.</span>
  </>
);

export const emptyMessage = (text: string) => (
  <Empty
    style={{ color: `${getCssVar('table.tr.color')}` }}
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={text}
  />
);
