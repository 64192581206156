import { Notification } from 'services/api';
import { getRowsForSummaryTable } from '../helpers';
import { Table } from './Table/Table';

interface IProps {
  notificationDetails: Notification;
  isNormUser: boolean;
}

export const NotificationStatusInfo = ({ notificationDetails, isNormUser = false }: IProps) => {
  const summaryRows = getRowsForSummaryTable(notificationDetails, isNormUser);

  return <Table tableData={summaryRows} />;
};
