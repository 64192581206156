import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { InnerLayout } from 'shared';
import { OSTypes, VulnerabilityStatus } from 'services/api';
import { useRowGap } from 'services/hooks';
import { AppDispatch, useCEPlus, useCustomer, usePlatform } from 'store';
import { CEStatus, DEFAULT_LIMIT, TOP_OPERATING_SYSTEMS } from './constants';
import { getLayoutConfig, lastWeek, penultimateWeek } from './helpers';
import {
  HardwareManufacturer,
  OpenVulnerabilities,
  OperatingSystemDistribution,
  Top5ClientHardware,
  Top5ServerHardware,
  TopClientOperatingSystem,
  TopServerOperatingSystem,
  TotalAssetsInScope,
  TotalVulnerabilitiesInScope,
  VendorPatchAvailable,
  Windows10Versions,
} from './components';
import { cyberEssentialPlusApi } from '../../store/cyberEssentialPlus/thunks';
import { tagsManagementApi } from '../../store/tagsManagement/thunks';

export const CEPlus = () => {
  const platform = usePlatform();
  const dispatch: AppDispatch = useDispatch();
  const customerId = useCustomer();
  const { gapNumber } = useRowGap();
  const { detectionsAfterTwoWeeks } = useCEPlus();
  const { selectedTags } = useCEPlus();

  const isCompliant = !detectionsAfterTwoWeeks?.count;

  const layoutConfig = getLayoutConfig({
    status: isCompliant ? CEStatus.Compliant : CEStatus.NotCompliant,
  });

  useEffect(() => {
    const vulnerabilityStatus = [VulnerabilityStatus.CONFIRMED];

    dispatch(tagsManagementApi.getTagsList(customerId));
    dispatch(
      cyberEssentialPlusApi.getLastWeekDetections({
        customerId,
        from: lastWeek,
        vulnerabilityStatus,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getPenultimateWeekDetections({
        customerId,
        from: penultimateWeek,
        to: lastWeek,
        vulnerabilityStatus,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getDetectionsAfterTwoWeeks({
        customerId,
        to: penultimateWeek,
        vulnerabilityStatus,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getTotalAssetsInScope({
        customerId,
        vulnerabilityStatus,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getTotalVulnerabilitiesInScope({
        customerId,
        vulnerabilityStatus,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getVendorPatchAvailable({
        customerId,
        vulnerabilityStatus,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getOperatingSystemDistribution({
        customerId,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getTopClientOperatingSystem({
        customerId,
        osType: OSTypes.CLIENT,
        limit: DEFAULT_LIMIT,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getTopServerOperatingSystem({
        customerId,
        osType: OSTypes.SERVER,
        limit: DEFAULT_LIMIT,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getTopOS({
        customerId,
        operatingSystem: TOP_OPERATING_SYSTEMS,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getTopHardwareManufacturer({
        customerId,
        limit: DEFAULT_LIMIT,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getTopClientHardware({
        customerId,
        limit: DEFAULT_LIMIT,
        tagIds: selectedTags || [],
      }),
    );
    dispatch(
      cyberEssentialPlusApi.getTopServerHardware({
        customerId,
        isServer: true,
        limit: DEFAULT_LIMIT,
        tagIds: selectedTags || [],
      }),
    );
  }, [dispatch, customerId, platform, selectedTags]);

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} xs={24} md={24} xxl={24}>
          <OpenVulnerabilities />
        </Col>

        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={8}>
          <TotalAssetsInScope />
        </Col>
        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={8}>
          <TotalVulnerabilitiesInScope />
        </Col>
        <Col span={gapNumber} xs={24} sm={24} xl={24} xxl={8}>
          <VendorPatchAvailable />
        </Col>

        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={12}>
          <OperatingSystemDistribution />
        </Col>
        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={12}>
          <TopClientOperatingSystem />
        </Col>

        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={12}>
          <TopServerOperatingSystem />
        </Col>
        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={12}>
          <Windows10Versions />
        </Col>

        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={8}>
          <HardwareManufacturer />
        </Col>
        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={8}>
          <Top5ClientHardware />
        </Col>
        <Col span={gapNumber} xs={24} sm={24} xl={12} xxl={8}>
          <Top5ServerHardware />
        </Col>
      </Row>
    </InnerLayout>
  );
};
