import React, { useEffect, useState } from 'react';
import { Form, Input, Space } from 'antd';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  maintenanceSetting,
  MessageType,
  useApp,
  useCustomer,
  useMaintenanceSettings,
} from 'store';
import { Box, Button, Icon } from 'shared';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { PayloadAction } from '@reduxjs/toolkit';
import { useTheme } from 'styled-components';
import { getCssVar } from 'styles/theme';
import { showError, showMessage } from 'services/helpers';
import { RequestStatus } from 'store/loading/slice';
import { Spacer } from 'shared/TableFilters/styled';
import { MaintenanceSettingsRequest, SuccessResponse } from 'services/api';
import { maintenanceSettingsApi } from 'store/maintenance/thunks';
import {
  EditModeWrapper,
  ExampleText,
  InputLabel,
  SmartBlockText,
  SnackBarEditModeMessage,
} from '../../style';
import { FormFieldLabels, showSnackBarText, TEXT_AREA_MAX_LENGTH } from '../../helpers';
import { IEditProps } from '../../types';
import { getSupportEmail, getSupportPhone } from '../../../../themes/helpers';
import { Preview } from '../Preview';

export const SnackBarEditMode = ({ hideEditMode }: IEditProps) => {
  const customerId = useCustomer();
  const { maintenanceSettings } = useMaintenanceSettings();
  const { themeName } = useApp();
  const { TextArea } = Input;
  const [snackbarViewMode, setSnackbarViewMode] = useState(maintenanceSettings?.snackbarText);
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const initialValues: MaintenanceSettingsRequest = {
    snackbarText: maintenanceSettings?.snackbarText || '',
  };
  const validationSchema = Yup.object({
    snackbarText: Yup.string()
      .trim()
      .required('Snackbar text should not be empty')
      .max(TEXT_AREA_MAX_LENGTH),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (requestBody: MaintenanceSettingsRequest) => {
      const result = (await dispatch(
        maintenanceSettingsApi.updateMaintenanceSettings({ customerId, requestBody }),
      )) as PayloadAction<
        SuccessResponse,
        string,
        {
          requestStatus: string;
        },
        {
          message: string;
        }
      >;

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        const errorMessage = result.error.message;

        showError(errorMessage || undefined);

        return;
      }

      if (result.meta.requestStatus === RequestStatus.Fulfilled) {
        showMessage(
          {
            content: `Snack bar text has been successfully updated.`,
          },
          MessageType.Success,
        );

        hideEditMode('snackbar');
      }
    },
  });

  useEffect(() => {
    dispatch(
      maintenanceSetting.actions.setMaintenancePageMessageViewMode(
        maintenanceSettings?.maintenancePageMessage || '',
      ),
    );
  }, [dispatch, maintenanceSettings]);

  const inputHandleBar = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSnackbarViewMode(event.target.value);
    formik.handleChange(event);
  };

  return (
    <Form layout='vertical' onFinish={formik.handleSubmit} initialValues={formik.initialValues}>
      <InputLabel
        name={FormFieldLabels.SnackbarText}
        label='Text'
        required
        help={formik.errors.snackbarText}
        validateStatus={formik.errors.snackbarText ? 'error' : 'success'}
      >
        <EditModeWrapper isError={!!formik.errors.snackbarText}>
          <SmartBlockText>Smartbloc</SmartBlockText>
          <TextArea
            cols={100}
            defaultValue={maintenanceSettings?.snackbarText}
            onChange={(event) => inputHandleBar(event)}
            showCount
            maxLength={TEXT_AREA_MAX_LENGTH}
            bordered={false}
            name={FormFieldLabels.SnackbarText}
            autoSize={{ minRows: 3, maxRows: 3 }}
          />
        </EditModeWrapper>
      </InputLabel>
      <ExampleText>
        Text example: «Smartblock will be unavailable from 00:00 4th March through to 23:59 5th
        March due to essential maintenance».
      </ExampleText>
      <Spacer h='20' />
      <SnackBarEditModeMessage>
        <Icon component={theme.icons.infoWarning} color={getCssVar('status.warning.bg')} />
        For any urgent issues please contact the support desk on {getSupportPhone(themeName)} or
        drop us an email at{' '}
        <a href={`mailto:${getSupportEmail(themeName)}`} target='_blank' rel='noreferrer'>
          {getSupportEmail(themeName)}
        </a>
        .<br />
        <Box>Apologies for any inconvenience.</Box>
      </SnackBarEditModeMessage>
      <Spacer h='20' />
      <Preview onPress={() => showSnackBarText(snackbarViewMode, themeName)} />
      <Spacer h='20' />
      <Space>
        <Button type='primary' htmlType='submit'>
          Save
        </Button>
        <Button type='secondary' onClick={() => hideEditMode('snackbar')}>
          Cancel
        </Button>
      </Space>
    </Form>
  );
};
