import { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  documents,
  documentsApi,
  useApp,
  useAuth,
  useCustomer,
  useDocuments,
  useLoading,
} from 'store';
import {
  ActionPopup,
  Button,
  EmptyTablePreview,
  Icon,
  InnerLayout,
  MobileTable,
  MobileTableWrapper,
  Box,
} from 'shared';
import { useGetPermission } from 'services/hooks';
import { DocumentSchema, DocumentsService, GridRequestParams, BlobScanStatus } from 'services/api';
import { getQueryString } from 'services/api/core/request';
import { icons } from 'styles/theme';
import { ActionClickHandlersType } from 'services/interfaces';
import { useHistory } from 'react-router';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { useFilter } from 'services/hooks/useFilter';
import { downloadDocument } from 'services/helpers/downloadDocument';
import {
  ActionNames,
  defaultCurrent,
  defaultPageSize,
  PermissionOptions,
  PermissionsLevel,
} from 'app-constants';
import { CustomTable } from 'shared/Table/CustomTable';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { getColumns } from './components/TableColumns';
import {
  deletePopupDetails,
  filtersFields,
  initialOrderBy,
  searchSettings,
  selections,
} from './components/constants';
import { getActions, getLayoutConfig, showPopup } from './helpers';
import { Upload } from './components/Upload';

const UPDATE_INTERVAL = 8000;

export const Documents = () => {
  const { tableContent, downloading } = useDocuments();
  const customerId = useCustomer();
  const { user } = useAuth();
  const loading = useLoading([
    documentsApi.fetchTableContent.typePrefix,
    documentsApi.uploadFile.typePrefix,
    documentsApi.deleteFile.typePrefix,
  ]);
  const dispatch: AppDispatch = useDispatch();
  const { isMobile } = useApp();
  const isInit = useRef(true);
  const history = useHistory();

  const [updateInterval, setUpdateInterval] = useState<NodeJS.Timeout>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isActionPopupVisible, setIsActionPopupVisible] = useState(false);
  const [activePopupDetails, setActivePopupDetails] = useState<PopupConfigType | null>(null);
  const [dataSource, setDataSource] = useState<DocumentSchema[] | undefined>();

  const [params, updateParams] = useFilter({
    apiCall: documentsApi.fetchTableContent,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
  });

  const [isShowTable, setIsShowTable] = useState(true);
  const permission = useGetPermission(PermissionOptions.Documents);

  const layoutConfig = getLayoutConfig(
    () => setIsModalVisible(true),
    isShowTable && permission === PermissionsLevel.Full,
  );

  const downloadFile = useCallback(
    async (filename: string) => {
      await downloadDocument({ dispatch, customer: customerId, filename });
      setIsActionPopupVisible(false);
    },
    [dispatch, customerId],
  );

  const fetchTableContent = useCallback(() => {
    if (isInit.current) {
      dispatch(documentsApi.fetchTableContent(params));

      isInit.current = false;

      return;
    }

    const { customerId, ...otherParams } = params;

    history.push(getQueryString(otherParams));
    dispatch(documentsApi.fetchTableContent(params));
  }, [dispatch, params, history]);

  const updateDocumentsByInterval = useCallback(async () => {
    const data = await DocumentsService.getDocuments(params);

    dispatch(documents.actions.setTableContent(data));
  }, [dispatch, params]);

  const isAnyDocumentScanning = useMemo(() => {
    return (tableContent?.rows as DocumentSchema[])?.some(({ scanstatus }: DocumentSchema) =>
      [BlobScanStatus.BEING_SCANNED, BlobScanStatus.PENDING_SCAN].includes(
        scanstatus as BlobScanStatus,
      ),
    );
  }, [tableContent?.rows]);

  // if any documents is scanning run setUpdateInterval for checking when scanning is finish
  useEffect(() => {
    if (updateInterval) {
      clearInterval(updateInterval);
    }

    if (isAnyDocumentScanning) {
      const timerId = setInterval(() => {
        updateDocumentsByInterval();
      }, UPDATE_INTERVAL);

      setUpdateInterval(timerId);
    }

    return () => {
      if (updateInterval) {
        clearInterval(updateInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyDocumentScanning, updateDocumentsByInterval]);

  const onClickActionButton = useCallback(
    (row: DocumentSchema, popupId: ActionNames) => {
      showPopup(
        popupId,
        setActivePopupDetails,
        setIsActionPopupVisible,
        row,
        dispatch,
        customerId,
        documentsApi,
        () => {
          if (params.pagination.page !== 1 && dataSource?.length === 1) {
            return updateParams({
              ...params,
              pagination: {
                ...params.pagination,
                page: params.pagination.page && params.pagination.page - 1,
              },
            });
          }

          return fetchTableContent();
        },
      );
    },

    [
      setIsActionPopupVisible,
      setActivePopupDetails,
      dispatch,
      params,
      dataSource,
      fetchTableContent,
      customerId,
      updateParams,
    ],
  );

  const columns = useMemo(
    () =>
      getColumns(
        downloadFile,
        onClickActionButton,
        downloading,
        isMobile,
        permission,
        user.isSuperAdmin,
      ),
    [downloadFile, onClickActionButton, downloading, permission, isMobile, user.isSuperAdmin],
  );

  useEffect(() => {
    if (tableContent && tableContent.count !== undefined && !params.filter) {
      setIsShowTable(tableContent.count > 0 || loading[documentsApi.fetchTableContent.typePrefix]);
    }
  }, [tableContent, params.filter, loading]);

  useEffect(() => {
    if (tableContent) {
      setDataSource(tableContent.rows as DocumentSchema[]);
    }
  }, [tableContent]);

  const actionsClickHandlers: ActionClickHandlersType<DocumentSchema> = {
    [ActionNames.Delete]: onClickActionButton,
    [ActionNames.Download]: (row: DocumentSchema) => downloadFile(row.name || ''),
  };

  const emptyMessage = (
    <EmptyTablePreview
      icon='empty_table'
      alignCenter
      textContent={
        <Box>
          No documents and files available yet
          <br /> Here will be displayed all documents that you will be able to download.
        </Box>
      }
      actionButton={
        <Button
          type='link'
          onClick={() => setIsModalVisible(true)}
          disabled={permission !== PermissionsLevel.Full}
          icon={<Icon component={icons.file_upload} style={{ marginRight: 5 }} />}
        >
          Upload file
        </Button>
      }
    />
  );

  return (
    <InnerLayout {...layoutConfig}>
      {!isMobile ? (
        <CustomTable<DocumentSchema, GridRequestParams>
          data={{ ...tableContent, rows: dataSource }}
          columns={getTableColumns(columns, params.orderBy)}
          params={params}
          setParams={updateParams}
          searchSettings={searchSettings}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          filterFields={filtersFields}
          isLoadingContent={loading[documentsApi.fetchTableContent.typePrefix]}
          emptyMessage={emptyMessage}
        />
      ) : (
        <MobileTableWrapper>
          <MobileTable
            data={{ ...tableContent, rows: dataSource }}
            columns={columns}
            params={params}
            initialOrderBy={initialOrderBy}
            setParams={updateParams}
            searchSettings={searchSettings}
            filterFields={filtersFields}
            isLoadingContent={loading[documentsApi.fetchTableContent.typePrefix]}
            getActions={getActions(
              actionsClickHandlers,
              permission === PermissionsLevel.Full,
              user.isSuperAdmin,
            )}
            emptyMessage={emptyMessage}
          />
        </MobileTableWrapper>
      )}

      <Upload
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        user={user}
        fetchTableContent={fetchTableContent}
        customerId={customerId}
        selections={selections}
        permission={permission}
        loading={loading[documentsApi.fetchTableContent.typePrefix]}
      />

      {isActionPopupVisible && (
        <ActionPopup
          isVisible={isActionPopupVisible}
          popupDetails={activePopupDetails || deletePopupDetails}
          setIsVisiblePopup={setIsActionPopupVisible}
        />
      )}
    </InnerLayout>
  );
};
