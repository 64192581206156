import React from 'react';
import { useApp } from 'store';
import { Button, Input } from 'shared';
import { POPUP_WIDTH } from 'app-constants';
import { Popup } from './styled';
import { PopupConfigType } from './types';
import { labelName, maxLength } from './constants';

type ActionPopupProps = {
  popupDetails: PopupConfigType;
  isVisible: boolean;
  setIsVisiblePopup: (isVisible: boolean) => void;
  inputType?: string;
};

export const ActionPopup = ({
  popupDetails,
  isVisible,
  setIsVisiblePopup,
  inputType = '',
}: ActionPopupProps) => {
  const [comment, setComment] = React.useState('');
  const { isMobile } = useApp();

  const handleCancel = () => {
    setIsVisiblePopup(false);
  };

  const handleOk = (comments: string) => {
    if (popupDetails.onApply) {
      popupDetails.onApply(comments);
      handleCancel();
    }
  };

  const handleChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  return (
    <Popup
      className='action-modal'
      title={popupDetails.title}
      visible={isVisible}
      width={!isMobile ? POPUP_WIDTH : '100vw'}
      onOk={() => handleOk(comment)}
      onCancel={handleCancel}
      footer={[
        <Button type='secondary' key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          danger={popupDetails.danger}
          onClick={() => handleOk(comment)}
        >
          {popupDetails.onApplyTitle}
        </Button>,
      ]}
    >
      <p>{popupDetails.text}</p>

      {popupDetails.isShowInput && (
        <>
          <label htmlFor={`id-${labelName}`}>{labelName}</label>

          <Input
            id={`id-${labelName}`}
            type={inputType}
            name='comment'
            onChange={handleChange}
            value={comment}
            maxLength={maxLength}
          />
        </>
      )}
    </Popup>
  );
};
