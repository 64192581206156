import React, { useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { useDispatch } from 'react-redux';
import { cybersafetyApi, useApp, useCustomer, useLicenseUsage, useLoading } from 'store';
import { isEmptyObject } from 'services/helpers';
import { Card } from 'shared';
import { Legend, SingleBar } from 'shared/charts';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { useTheme } from 'styled-components';
import { TooltipTitles } from '../../constants';
import { getOption } from './helpers';

export const LicenseUsage = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const chartRef = useRef<ReactECharts>();
  const customerId = useCustomer();
  const dispatch = useDispatch();
  const licenseUsage = useLicenseUsage();
  const loading = useLoading([cybersafetyApi.getLicenseUsage.typePrefix]);

  const singleBarLegendColors = {
    used: theme.colorSet.pieChart.CurrentRefresherTestsIgnored,
    free: theme.colorSet.baseColors.grey,
  };

  React.useEffect(() => {
    dispatch(
      cybersafetyApi.getLicenseUsage({
        customerId,
      }),
    );
  }, [dispatch, customerId]);

  return (
    <Card
      title='License usage'
      subTitle={
        <SubTitle
          boldText={
            licenseUsage && licenseUsage.free !== undefined && licenseUsage.used !== undefined
              ? licenseUsage.free + licenseUsage.used
              : ''
          }
        />
      }
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles.LicenseUsage}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cybersafetyApi.getLicenseUsage.typePrefix]}
    >
      {!isEmptyObject(licenseUsage) ? (
        <>
          <SingleBar
            values={licenseUsage || {}}
            chartRef={(r: ReactECharts) => {
              chartRef.current = r;
            }}
            getOption={getOption}
            height='300px'
            isLoading={loading[cybersafetyApi.getLicenseUsage.typePrefix]}
          />

          <Legend
            values={licenseUsage || {}}
            colors={singleBarLegendColors}
            direction='row'
            marginTop={theme.sizes[4]}
            chartRef={chartRef}
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
          />
        </>
      ) : (
        emptyMessage('No license usage data yet')
      )}
    </Card>
  );
};
