import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';

type BackgroundImageProps = {
  fileName: string;
  useFilter?: boolean;
};

const Image = styled.div<BackgroundImageProps>`
  background: ${({ fileName }) => `url('${PATH_TO_MONTHLY_REPORT_IMAGES}/${fileName}')`};
  background-repeat: no-repeat;
  background-size: auto 100%;

  height: 100%;
  position: absolute;

  width: 100%;
  z-index: 0;

  /* Fix issue with background render in puppeteer https://github.com/puppeteer/puppeteer/issues/2685 */

  ${({ useFilter }) => useFilter && '-webkit-filter: opacity(1) !important;'}
`;

export const BackgroundImage = ({ fileName, useFilter = true }: BackgroundImageProps) => {
  return <Image fileName={fileName} useFilter={useFilter} />;
};
