import React from 'react';
import { useDebounce } from 'services/hooks';
import { delay } from 'shared/TableFilters/constants';
import { SearchSettings } from '../types';
import { StyledInput } from './styled';
import { Filters } from '../../../services/api';
import { useApp } from '../../../store';
import { Icon } from '../../Icon';
import { icons } from '../../../styles/theme';

export interface DebouncedInputProps {
  searchSettings: SearchSettings;
  onDebouncedInputChange: (value: string, isResetClick?: boolean) => void;
  inputValue?: string;
  filterFields?: Filters;
  isResetFiltersClick?: boolean;
}

export const DebouncedInput = ({
  searchSettings,
  onDebouncedInputChange,
  inputValue,
  filterFields,
  isResetFiltersClick = false,
}: DebouncedInputProps) => {
  const [searchValue, setSearchValue] = React.useState(inputValue || '');
  const debouncedSearchValue = useDebounce(searchValue, delay);
  const isInit = React.useRef(true);
  const { isMobile } = useApp();
  const filterWithVulnerabilityStatusField = filterFields?.fields?.filter(
    (option) => option.name !== 'vulnerabilityStatus' && option.name !== 'status',
  );

  // clear search panel if no filter or use just vulnerability status filter
  React.useEffect(() => {
    if (isResetFiltersClick && !filterWithVulnerabilityStatusField?.length) {
      setSearchValue('');
    }

    if (isResetFiltersClick) {
      setSearchValue('');
    }
  }, [filterWithVulnerabilityStatusField?.length, isResetFiltersClick, isMobile]);

  const handleInputChange: React.ChangeEventHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchValue(event.target.value);
  };

  React.useEffect(() => {
    if (isInit.current) {
      isInit.current = false;

      return;
    }

    if (isMobile) {
      //  we have to remove double request if reset filter click
      onDebouncedInputChange(debouncedSearchValue.trimStart().trimEnd(), true);
    } else {
      onDebouncedInputChange(debouncedSearchValue.trimStart().trimEnd());
    }
  }, [onDebouncedInputChange, debouncedSearchValue, isMobile]);

  return (
    <StyledInput
      type='search'
      suffix={
        searchValue && (
          <Icon
            className='clear-icon'
            component={icons.select_clear_new}
            onClick={() => setSearchValue('')}
          />
        )
      }
      placeholder={searchSettings.placeholder}
      onChange={handleInputChange}
      value={searchValue}
      noSpace
    />
  );
};
