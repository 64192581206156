import React from 'react';
import { useLocation } from 'react-router-dom';
import { Row } from 'antd';
import { useDispatch } from 'react-redux';
import { ExpandedRowContainer, InnerLayout, MobileTable, MobileTableCol } from 'shared';
import { emptyMessage as setEmptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { defaultPageSize, defaultCurrent } from 'app-constants';
import {
  AppDispatch,
  cybersafetyApi,
  useApp,
  useCustomer,
  useCybersafety,
  useLoading,
} from 'store';
import { GridRequestParams, PhishingAndTrainingActivitySchema } from 'services/api';
import { HistoryState } from 'services/interfaces';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { useFilter } from 'services/hooks/useFilter';
import { Table, CustomTable } from './styled';
import { expandTableColumns, getColumns } from './components/TableColumns';
import { getLayoutConfig } from './helpers';
import { filterFields, initialOrderBy, searchSettings } from './constants';

const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;

const GUTTER: [number, number] = [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

export const PeoplePhishing = () => {
  const { state } = useLocation<HistoryState>();
  const layoutConfig = getLayoutConfig(state);
  const { isMobile, isChartsFontLoaded } = useApp();
  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();

  const columns = getColumns(!!isChartsFontLoaded);
  const loading = useLoading([cybersafetyApi.getPhishingAndTraining.typePrefix]);

  const { phishingAndTraining } = useCybersafety();

  const [params, updateParams] = useFilter({
    apiCall: cybersafetyApi.getPhishingAndTraining,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
  });

  const emptyMessage = setEmptyMessage('No phishing and training activity yet');

  return (
    <InnerLayout {...layoutConfig}>
      {isMobile ? (
        <Row gutter={GUTTER}>
          <MobileTableCol flex='auto'>
            <MobileTable<PhishingAndTrainingActivitySchema, GridRequestParams>
              data={phishingAndTraining}
              searchSettings={searchSettings}
              columns={columns}
              filterFields={filterFields}
              onRowClick={(record: PhishingAndTrainingActivitySchema) => record}
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              isLoadingContent={loading[cybersafetyApi.getPhishingAndTraining.typePrefix]}
              emptyMessage={emptyMessage}
            />
          </MobileTableCol>
        </Row>
      ) : (
        <CustomTable
          data={phishingAndTraining}
          columns={getTableColumns(columns, params.orderBy)}
          searchSettings={searchSettings}
          params={params}
          setParams={updateParams}
          expandable={{
            expandedRowRender: (record: PhishingAndTrainingActivitySchema) => (
              <ExpandedRowContainer>
                <Table
                  columns={expandTableColumns}
                  dataSource={record.emails || []}
                  pagination={false}
                />
              </ExpandedRowContainer>
            ),
          }}
          filterFields={filterFields}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          isLoadingContent={loading[cybersafetyApi.getPhishingAndTraining.typePrefix]}
          emptyMessage={emptyMessage}
        />
      )}
    </InnerLayout>
  );
};
