import React from 'react';
import { MobileTable } from 'shared';
import { useDispatch } from 'react-redux';
import { spacing } from 'styles/theme';
import { ExpandedLabel, ExpandedRowContainer, ExpandedValue } from 'pages/CmdbHostDetails/styled';
import { useParams, useHistory, useLocation } from 'react-router';
import { Empty } from 'antd';
import { defaultPageSize, defaultCurrent, initialPagination, NO_VALUE_LABEL } from 'app-constants';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { CustomTable } from 'shared/Table/CustomTable';
import { GridRequestParams, VulnerabilityDetails } from 'services/api';
import {
  useApp,
  useAssetDetails,
  useCustomer,
  useAssetDetailsPotentialVulnerabilities,
  useLoading,
} from 'store';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { isEmpty } from 'lodash';
import { parseHtmlAsString } from 'services/helpers';
import { columns } from './TableColumns';
import {
  filterFields,
  confirmedVulnerabilitiesFilter,
  initialOrderBy,
  searchSettings,
  potentialVulnerabilitiesFilter,
} from './constants';
import { getFiltersFields } from './helpers';

export const VulnerabilitiesTable = () => {
  const customerId = useCustomer();
  const { state } = useLocation<HistoryState>();
  const { isMobile } = useApp();
  const { id } = useParams<{
    id: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [params, setParams] = React.useState<GridRequestParams>({
    customerId,
    orderBy: initialOrderBy,
    pagination: initialPagination,
    filter: { ...confirmedVulnerabilitiesFilter },
  });
  const { detailsVulnerabilitiesListByHost, detailsAdditionalInformation } = useAssetDetails();
  const loading = useLoading([assetDetailsApi.getDetailsVulnerabilitiesListByHost.typePrefix]);
  const emptyMessage = (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No vulnerabilities present' />
  );
  const isPotentialVulnerabilitiesActive = useAssetDetailsPotentialVulnerabilities();

  const updateParams = React.useCallback(
    (updatedParams: GridRequestParams | ((prevParams: GridRequestParams) => GridRequestParams)) => {
      setParams(updatedParams);
    },
    [],
  );

  const redirectToVulnerabilityDetails = React.useCallback(
    (record: VulnerabilityDetails) => {
      const searchParam = record.id ? '?type=asset' : '';

      history.push(`${Routes.VULNERABILITIES_LIST}/${record.id || record.qid}${searchParam}`, {
        backTo: {
          route: `${Routes.CMDB}/${id}`,
          search: history.location.search,
          title: `Back to `,
          id: detailsAdditionalInformation.hostname,
        },
        rootBackTo: state?.rootBackTo,
      });
    },
    [history, id, detailsAdditionalInformation, state],
  );

  React.useEffect(() => {
    const newVulnerabilityStatusFilter = isPotentialVulnerabilitiesActive
      ? potentialVulnerabilitiesFilter
      : confirmedVulnerabilitiesFilter;

    if (isEmpty(params.filter)) {
      params.filter = { ...confirmedVulnerabilitiesFilter };
    }

    if (params.filter?.fields) {
      params.filter.fields = getFiltersFields(
        params.filter.fields,
        newVulnerabilityStatusFilter.fields[0],
      );
    }

    dispatch(
      assetDetailsApi.getDetailsVulnerabilitiesListByHost({ ...params, hostId: id, customerId }),
    );
  }, [dispatch, customerId, id, params, isPotentialVulnerabilitiesActive]);

  if (isMobile) {
    return (
      <MobileTable<VulnerabilityDetails, GridRequestParams>
        data={detailsVulnerabilitiesListByHost}
        searchSettings={searchSettings}
        params={params}
        setParams={updateParams}
        initialOrderBy={initialOrderBy}
        columns={getTableColumns(columns, params.orderBy)}
        filterFields={filterFields}
        onRowClick={redirectToVulnerabilityDetails}
        scroll='1000px'
        isLoadingContent={loading[assetDetailsApi.getDetailsVulnerabilitiesListByHost.typePrefix]}
        emptyMessage={emptyMessage}
      />
    );
  }

  return (
    <CustomTable<VulnerabilityDetails, GridRequestParams>
      columns={getTableColumns(columns, params.orderBy)}
      data={detailsVulnerabilitiesListByHost}
      params={params}
      setParams={updateParams}
      filterFields={filterFields}
      searchSettings={searchSettings}
      defaultPageSize={defaultPageSize}
      defaultCurrent={defaultCurrent}
      expandable={{
        expandedRowRender: (record: VulnerabilityDetails) => (
          <ExpandedRowContainer>
            <ExpandedLabel>Result</ExpandedLabel>
            <ExpandedValue>{parseHtmlAsString(record.results || NO_VALUE_LABEL)}</ExpandedValue>
            <ExpandedLabel>
              Port <ExpandedValue ml={spacing[1]}>{record.port || NO_VALUE_LABEL}</ExpandedValue>
            </ExpandedLabel>
          </ExpandedRowContainer>
        ),
      }}
      maxFilterFieldsLength={1}
      onRowClickHandle={redirectToVulnerabilityDetails}
      isLoadingContent={loading[assetDetailsApi.getDetailsVulnerabilitiesListByHost.typePrefix]}
      emptyMessage={emptyMessage}
    />
  );
};
